import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import MonacoEditor from 'react-monaco-editor';
import {
  Drawer,
  Button,
  Collapse,
  Popover,
  Input,
  Row,
  Col,
  Select,
  message,
  Form,
  Segmented
} from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CaretRightOutlined, EnterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

import { getUserDetails, getUserProfile } from '../../../selectors/layout';
import { getTranslationCache } from '../../../selectors/language';

import { constant } from './Constants';
import themeSettings from './themeSettings.json';
import localStorage from '../../../utils/localStorage';
import {
  StyledComponent,
  StyledTable,
  DrawerFooter,
  StyledButton
} from '../../../commons/styles/layout';
import '.././style.css';
import ReportTypeComponent from '../ReportTypeConfiguration/ReportTypeComponent';
const { Option } = Select;
const { Panel } = Collapse;

const customPanel = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'
};

class ReportConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      manocoEditorValue: '',
      editList: [],
      clicked: false,
      tabName: '',
      datasource: [],
      editObj: {},
      reportTypeList: [],
      type: '',
      userProfile: props.userProfile,
      userId: props.userDeatils,
      translationCache: props.translationCache || [],
      saveReportData: false,
      editType: 'Form',
      initialState: {}
    };
    this.query = '';
    this.showHideTextArea = false;
    this.reportConfig = React.createRef();
    this.hRef = React.createRef();
  }

  componentDidMount() {
    this._getReportList();
    this._getReportTypeList();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.UserDetails !== this.props.UserDetails) {
      this.setState({
        userId: this.props.UserDetails
      });
    }
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.language !== this.props.language ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        language: this.props.language,
        userProfile: this.props.userProfile
      });
    }
  }
  _getReportList = () => {
    const siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    const healthHeaders = {
      method: 'GET',
      url: `/api/report/get?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(healthHeaders)
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({ datasource: response.data });
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.info(err.response.data.message);
        }
      });
  };

  _getReportTypeList = () => {
    const siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    const healthHeaders = {
      method: 'GET',
      url: `/api/report/reportTypesList?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(healthHeaders)
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({ reportTypeList: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClose = () => {
    this.query = '';
    this.showHideTextArea = false;
    if (this.reportConfig.current) {
      this.reportConfig.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };
  onTabNameInput = (e) => {
    this.setState({ tabName: e.target.value });
  };
  addNewTab = () => {
    let { editList, tabName } = this.state;
    let newObj = {
      TabName: tabName,
      JSON: {},
      type: 'save'
    };
    this.query = '';
    this.showHideTextArea = false;
    editList.push(newObj);
    this.setState({ editList, clicked: false });
  };

  handleClickChange = (visible) => {
    this.setState({
      clicked: visible
    });
  };

  hide = () => {
    this.setState({
      clicked: false
    });
  };

  addNewReport = () => {
    if (this.reportConfig.current) {
      this.reportConfig.current.resetFields();
    }
    this.query = '';
    this.showHideTextArea = false;
    this.setState({
      visible: true,
      editObj: {},
      editList: [],
      saveReportData: false
    });
  };

  saveReport = (object, operation, userId) => {
    const { editObj, editList, editID } = this.state;
    if (operation === 'delete') {
      this.reportConfig.current
        .validateFields()
        .then(async () => {
          this._deleteReportAPI(editID);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (operation === 'save') {
      this.reportConfig.current
        .validateFields()
        .then(async (values) => {
          const model = this.monaco ? this.monaco.editor.getModel() : null;
          const value = model ? model.getValue() : null;
          if (typeof JSON.parse(value) !== 'string' && this.state.editType === 'Json-editor') {
            let newObj = {
              JSON: JSON.parse(value),
              TabName: object.TabName,
              ReportType: values.ReportType,
              ReportName: values.ReportName,
              CreatedBy: userId
            };

            if (this.query && this.query !== '') {
              newObj.JSON.api.query = this.query;
            }
            this._saveReportAPI(newObj);
          } else if (this.state.editType === 'Form') {
            let newObj = {
              JSON: editObj,
              TabName: object.TabName,
              ReportType: values.ReportType,
              ReportName: values.ReportName,
              CreatedBy: userId
            };

            if (this.query && this.query !== '') {
              newObj.JSON.api.query = this.query;
            }
            this._saveReportAPI(newObj);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (operation === 'update') {
      this.reportConfig.current
        .validateFields()
        .then(async () => {
          if (operation === 'update') {
            const model = this.monaco ? this.monaco.editor.getModel() : null;
            const value = model ? model.getValue() : null;
            if (typeof JSON.parse(value) !== 'string' && this.state.editType === 'Json-editor') {
              let newObj = {
                JSON: JSON.parse(value),
                TabName: object.TabName,
                ReportType: editList[0].ReportType,
                ReportName: editList[0].ReportName,
                CreatedBy: userId,
                // type: operation,
                Id: editID
              };
              if (this.query && this.query !== '') {
                newObj.JSON.api.query = this.query;
              }
              // this.setState({editObj:newObj})
              this._updateReportAPI(newObj);
            } else if (this.state.editType === 'Form') {
              let newObj = {
                JSON: editObj,
                TabName: object.TabName,
                ReportType: editList[0].ReportType,
                ReportName: editList[0].ReportName,
                CreatedBy: userId,
                // type: operation,
                Id: editID
              };
              if (this.query && this.query !== '') {
                newObj.JSON.api.query = this.query;
              }
              this._updateReportAPI(newObj);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  saveData = (editObj) => {
    editObj = {
      ...editObj
    };
    this.setState({ editObj });
  };
  _saveReportAPI = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'POST',
      url: `/api/report/create?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { ...payload, SiteId: siteId }
    };
    axios(deviceTypeObject)
      .then(() => {
        this.setState({
          visible: false
        });
        if (this.reportConfig.current) {
          this.reportConfig.current.resetFields();
        }
        this._getReportList();
        this.query = '';
        this.showHideTextArea = false;
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.info(err.response.data.message);
        }
      });
  };

  _updateReportAPI = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'PATCH',
      url: `/api/report/update?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { ...payload, SiteId: siteId }
    };
    axios(deviceTypeObject)
      .then(() => {
        this.setState({
          visible: false
        });
        if (this.reportConfig.current) {
          this.reportConfig.current.resetFields();
        }
        this._getReportList();
        this.query = '';
        this.showHideTextArea = false;
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.info(err.response.data.message);
        }
      });
  };

  _getReportJsonList = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'GET',
      url: `/api/report/get?SiteId=${siteId}&ReportName=${payload.ReportName}&ReportType=${payload.ReportType}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(deviceTypeObject)
      .then((response) => {
        let editList = response.data.map((edit) => {
          let newObj = {
            ...edit,
            editEditor: edit.JSON
          };
          return newObj;
        });

        this.setState({
          editList
        });
      })
      .catch(function () {});
  };

  _deleteReportAPI = (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'DELETE',
      url: `/api/report/delete?SiteId=${siteId}&Id=${Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(deviceTypeObject)
      .then(() => {
        this.setState({
          visible: false
        });
        this._getReportList();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.info(err.response.data.message);
        }
      });
  };

  editorDidMount = (editor) => {
    setTimeout(function () {
      editor.getAction('editor.action.formatDocument').run();
    }, 300);
  };

  editorWillMount = (monaco) => {
    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({});
  };

  addQuery = (e) => {
    this.query = e.target.value;
  };

  textArea = () => {
    this.showHideTextArea = !this.showHideTextArea;
    this.setState({ showHideTextArea: this.showHideTextArea });
  };

  handleImport = (e) => {
    let context = this;
    var reader = new FileReader();
    reader.readAsText(e[0]);
    reader.onloadend = function () {
      let fileData = JSON.parse(reader.result);
      delete fileData.editObj.ReportName;
      fileData.editList.map((list) => {
        return (list.type = 'save');
      });

      context.setState({ ...fileData });
    };
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8, color: 'white !important' }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  render() {
    let userDeatils = this.props.UserDetails;
    const userId = userDeatils && userDeatils.Id;
    const { translationCache, userProfile, saveReportData } = this.state;
    const siteId = localStorage.get('currentSite');

    const columns = [
      {
        title:
          translationCache && translationCache[`${constant.Report_Name}`]
            ? translationCache[`${constant.Report_Name}`]
            : `${constant.Report_Name}`,
        dataIndex: 'ReportName',
        key: 'ReportName',
        ...this.getColumnSearchProps('ReportName'),
        width: '60%',
        render: (text, record) => {
          return (
            <Link
              style={{ color: '#173049' }}
              to={{ pathname: `/rubus/report/${record.ReportType}/${record.ReportName}` }}
            >
              {text}
            </Link>
          );
        }
      },
      {
        title:
          translationCache && translationCache[`${constant.Report_Type}`]
            ? translationCache[`${constant.Report_Type}`]
            : `${constant.Report_Type}`,
        dataIndex: 'ReportType',
        key: 'ReportType',
        ...this.getColumnSearchProps('ReportType')
      }
    ];
    const { editObj, reportTypeList, tabName, initialState } = this.state;

    let permissionsArray = [];
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][siteId] &&
      userProfile['belongsTo']['sites'][siteId]['role'] &&
      userProfile['belongsTo']['sites'][siteId]['role']['Permission']
        .filter((item) => item.resource === 'reports')
        .map((name) => permissionsArray.push(name.action));

    return (
      <StyledComponent style={{ minHeight: window.innerHeight - 69 }}>
        <Drawer
          title={'Configuration'}
          placement="right"
          width={1500}
          onClose={this.onClose}
          visible={this.state.visible}
          closable
        >
          <Form
            layout="vertical"
            ref={this.reportConfig}
            name="Site_form"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              ReportType: initialState.ReportType,
              ReportName: initialState.ReportName
            }}
            onFinishFailed={this.onFinishFailed}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={18}>
                <Form.Item
                  label="Report Name :"
                  name="ReportName"
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.ReportName !== curValues.ReportName ||
                    prevValues.ReportType !== curValues.ReportType
                  }
                >
                  <Input
                    placeholder="Please enter Report Name"
                    disabled={!initialState.ReportName ? false : true}
                  />
                </Form.Item>
                <Form.Item label="Report Type :" name="ReportType">
                  <Select
                    placeholder="Please select Report Type"
                    disabled={!initialState.ReportType ? false : true}
                  >
                    {reportTypeList.map((reportType, index) => {
                      return (
                        <Option key={index} value={reportType.ReportType}>
                          {reportType.ReportType}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Popover
            content={
              <div style={{ display: 'flex' }}>
                <Input placeholder="Enter TabName" size="small" onChange={this.onTabNameInput} />
                <EnterOutlined
                  style={{
                    fontSize: '22px',
                    marginTop: '1px',
                    marginLeft: '4px',
                    cursor: 'pointer'
                  }}
                  onClick={this.addNewTab}
                />
              </div>
            }
            title="Enter TabName"
            trigger="click"
            visible={this.state.clicked}
            onVisibleChange={this.handleClickChange}
          >
            <Button type={'primary'}>Add Items</Button>
          </Popover>
          {this.state.editList.map((list, key) => {
            return (
              <Collapse
                key={key}
                bordered={false}
                style={{ marginTop: '20px' }}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0}></CaretRightOutlined>
                )}
              >
                <Panel header={list.TabName || tabName} style={customPanel}>
                  <Segmented
                    options={['Form', 'Json-editor']}
                    value={this.state.editType}
                    onChange={(e) => this.setState({ editType: e })}
                  />
                  {this.state.editType === 'Form' ? (
                    <ReportTypeComponent
                      editObj={editObj ? editObj : {}}
                      reportType={editObj.ReportType}
                      reportName={editObj.ReportName}
                      currentTab={list.TabName}
                      reportObject={list.editEditor}
                      list={list}
                      // updatefunction={this._getReportJsonList}
                      tabName={list.tabName}
                      saveReportData={saveReportData}
                      saveData={this.saveData}
                    />
                  ) : (
                    <MonacoEditor
                      width="1000"
                      height="500"
                      language="json"
                      theme="vs-dark"
                      value={JSON.stringify(list.editEditor)}
                      options={{
                        selectOnLineNumbers: false
                      }}
                      ref={(el) => (this.monaco = el)}
                      // onChange={(e) => this.monacoEditorOnChange(e, key)}
                      editorWillMount={this.editorWillMount}
                      editorDidMount={this.editorDidMount}
                    />
                  )}

                  {list.type && list.type === 'save' ? (
                    <Button
                      style={{ color: 'white !important' }}
                      shape="round"
                      className="reportbutton"
                      onClick={() => this.saveReport(list, 'save', userId)}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      shape="round"
                      style={{ color: 'white !important' }}
                      disabled={!permissionsArray.includes('update') ? true : false}
                      onClick={() => this.saveReport(list, 'update', userId)}
                      className="reportbutton"
                    >
                      Update
                    </Button>
                  )}
                  {
                    <Button
                      shape="round"
                      className="reportbutton"
                      disabled={!permissionsArray.includes('delete') ? true : false}
                      onClick={() => this.saveReport(list, 'delete', userId)}
                      style={{ marginLeft: '5px', color: 'white !important' }}
                    >
                      Delete
                    </Button>
                  }
                </Panel>
              </Collapse>
            );
          })}
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8, color: 'white !important' }}>
              Cancel
            </Button>
          </DrawerFooter>
        </Drawer>
        <StyledButton onClick={this.addNewReport} style={{ color: 'white !important' }}>
          <PlusOutlined />
          {translationCache && translationCache[`${constant.New_Report}`]
            ? translationCache[`${constant.New_Report}`]
            : `${constant.New_Report}`}
        </StyledButton>

        <StyledTable
          theme={themeSettings}
          columns={columns}
          dataSource={this.state.datasource}
          onRow={(record) => {
            return {
              onClick: () => {
                if (this.reportConfig.current) {
                  this.reportConfig.current.setFieldsValue(record);
                }
                this.setState({
                  visible: true,
                  editObj: record.JSON,
                  editID: record.Id,
                  initialState: record,
                  saveReportData: true
                });

                this._getReportJsonList(record);
              }
            };
          }}
        />
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  UserDetails: getUserDetails(),
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ReportConfiguration);
