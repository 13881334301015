import React from 'react';
import { Checkbox, Input, Select, Tabs, Radio, Button, Form, Drawer, InputNumber } from 'antd';
import { SelectDropDown } from './CSS/styles.js';
import { defaultGraphTypeProps, graphTypeList } from './DefaultGraphTypeProps';
import { DrawerFooter } from './CSS/styles.js';
import TagInputWidget from './Widgets/Selection/TagInputWidget';
import ColorInputWidget from './Widgets/Selection/ColorInputWidget';
import { DeleteOutlined } from '@ant-design/icons';

const Option = Select.Option;
const TabPane = Tabs.TabPane;

class ConfigurationModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      graph: '',
      type: 'historic',
      selection: 'none',
      activeKey: '1',
      query: '',
      queryData: [],
      refreshTime: '',
      checked: false,
      enableExcel: false,
      num: ''
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.editData !== this.props.editData) {
      let editData = this.props.editData;
      if (editData.length > 0) {
        this.setState({
          ...editData[0],
          title: editData[0].title,
          graph: editData[0].graph,
          type: editData[0].type,
          selection: editData[0].selection,
          checked: editData[0].checked,
          query: editData[0].query,
          queryRowsToColumns: editData[0].queryRowsToColumns,
          enableMock: editData[0].enableMock,
          enableExcel: editData[0].enableExcel,
          refreshTime: editData[0].refreshTime,
          [editData[0].graph]: editData[0][editData[0].graph]
        });
      }
    }
  }

  renderWidget(graphTypeProps) {
    let { graph, num } = this.state;
    switch (graphTypeProps.Widget) {
      case 'Input':
        return (
          <Form.Item label={graphTypeProps.DisplayName}>
            <Input
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              onChange={(e) =>
                this.setState({
                  [graph]: {
                    ...this.state[graph],
                    [graphTypeProps.Key]: e.target.value
                  }
                })
              }
            />
          </Form.Item>
        );
      case 'Number':
        return num === 'string' && graphTypeProps.DisplayName === 'Interval' ? null : (
          <Form.Item label={graphTypeProps.DisplayName}>
            <InputNumber
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              onChange={(e) =>
                this.setState({
                  [graph]: {
                    ...this.state[graph],
                    [graphTypeProps.Key]: e
                  }
                })
              }
            />
          </Form.Item>
        );
      case 'TextArea':
        return (
          <Form.Item label={graphTypeProps.DisplayName}>
            <Input.TextArea
              rows={3}
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              onChange={(e) =>
                this.setState({
                  [graph]: {
                    ...this.state[graph],
                    [graphTypeProps.Key]: e.target.value
                  }
                })
              }
            />
          </Form.Item>
        );
      case 'Select':
        return (
          <Form.Item label={graphTypeProps.DisplayName}>
            <Select
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              onChange={(value) => {
                this.setState({
                  [graph]: {
                    ...this.state[graph],
                    [graphTypeProps.Key]: value
                  },
                  num: value
                });
              }}
            >
              {graphTypeProps &&
                graphTypeProps.Options &&
                Array.isArray(graphTypeProps.Options) &&
                graphTypeProps.Options.map((optionObject, index) => {
                  return (
                    <Option key={index} value={optionObject}>
                      {optionObject}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        );
      case 'TagList':
        return (
          <Form.Item label={graphTypeProps.DisplayName}>
            <TagInputWidget
              graphTypeProps={graphTypeProps}
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              addedTagList={(e) => this.addedTagList(e, graphTypeProps.Key)}
            />
          </Form.Item>
        );
      case 'ColorList':
        return (
          <Form.Item label={graphTypeProps.DisplayName}>
            <ColorInputWidget
              graphTypeProps={graphTypeProps}
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              addedTagList={(e) => this.addedTagList(e, graphTypeProps.Key)}
            />
          </Form.Item>
        );
      default:
        return <div></div>;
    }
  }

  addedTagList = (valueList, key) => {
    let { graph } = this.state;
    this.setState({
      [graph]: {
        ...this.state[graph],
        [key]: valueList
      }
    });
  };
  addNextQuery = () => {
    let { queryData } = this.state;
    queryData.push(queryData.length + 1);
    this.setState({
      queryData
    });
  };

  render() {
    let { activeKey, graph, title, refreshTime, checked, type, selection, queryData } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    let defaultProps = {
      activeKey: '1',
      title: '',
      graph: '',
      type: '',
      selection: '',
      query: '',
      queryData: [],
      queryRowsToColumns: false,
      enableMock: false,
      refreshTime: '',
      checked: true
    };
    return (
      <Drawer
        width={1200}
        closable
        title="Panel Configuration"
        visible={this.props.visible}
        onClose={this.props.onCancel}
      >
        <Tabs activeKey={activeKey} onChange={(activeKey) => this.setState({ activeKey })}>
          <TabPane tab="Config" key="1">
            <Form {...formItemLayout}>
              <Form.Item label="Panel Name">
                <Input
                  type="text"
                  value={title}
                  onChange={(e) => this.setState({ title: e.target.value })}
                />
              </Form.Item>
              <Form.Item label="Graph Type">
                <SelectDropDown
                  onChange={(graph) => {
                    this.setState({ graph });
                  }}
                  value={graph}
                >
                  {graphTypeList &&
                    Array.isArray(graphTypeList) &&
                    graphTypeList.map((graphType, index) => {
                      return (
                        <Option key={index} value={graphType.value}>
                          {graphType.key}
                        </Option>
                      );
                    })}
                </SelectDropDown>
              </Form.Item>
              <Form.Item label="Type">
                <Radio.Group
                  value={type}
                  onChange={(e) => {
                    this.setState({
                      type: e.target.value
                    });
                  }}
                >
                  <Radio.Button value="live">Live</Radio.Button>
                  <Radio.Button value="historic">Historic</Radio.Button>
                  <Radio.Button value="summary">Summary</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Selection Type">
                <Select
                  value={selection}
                  onChange={(e) => {
                    this.setState({
                      selection: e
                    });
                  }}
                >
                  <Option value="datePicker">DatePicker</Option>
                  <Option value="datePickerLive">DatePicker Live</Option>
                  <Option value="dateRangePicker">DateRangePicker</Option>
                  <Option value="dateMonthYearPicker">DayMonthYearPicker</Option>
                  <Option value="deviceSelection">Device Selection</Option>
                  <Option value="none">None</Option>
                </Select>
              </Form.Item>

              {defaultGraphTypeProps &&
                defaultGraphTypeProps[graph] &&
                Array.isArray(defaultGraphTypeProps[graph]) &&
                defaultGraphTypeProps[graph].map((graphTypeProps) => {
                  return this.renderWidget(graphTypeProps);
                })}

              <Form.Item label="Refresh Time">
                <Input
                  type="text"
                  value={refreshTime}
                  onChange={(e) => {
                    this.setState({
                      refreshTime: e.target.value
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label=" ">
                <Checkbox
                  checked={checked}
                  style={{ color: 'black' }}
                  onChange={(e) => {
                    this.setState({
                      checked: e.target.checked
                    });
                  }}
                >
                  Refresh TimeStamp
                </Checkbox>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Query" key="2">
            <div>
              <Checkbox
                checked={this.state.queryRowsToColumns}
                onChange={(value) => {
                  this.setState({
                    queryRowsToColumns: value.target.checked
                  });
                }}
              >
                Query RowsToColumns
              </Checkbox>
              <Checkbox
                checked={this.state.enableMock}
                onChange={(value) => {
                  this.setState({
                    enableMock: value.target.checked
                  });
                }}
              >
                Enable mock
              </Checkbox>

              <Input.TextArea
                style={{ marginTop: '10px', width: '80%' }}
                placeholder="Query"
                autosize={{ minRows: 4 }}
                onChange={(e) => {
                  this.setState({ query: e.target.value });
                }}
                value={this.state.query}
              />

              {queryData && Array.isArray(queryData) && queryData.length < 2 ? (
                <Button type="primary" style={{ marginTop: '5px' }} onClick={this.addNextQuery}>
                  Add Query
                </Button>
              ) : null}

              {queryData &&
                Array.isArray(queryData) &&
                queryData.map((queryIndex, index) => {
                  return (
                    <>
                      <Input.TextArea
                        key={index}
                        style={{ marginTop: '10px', width: '80%' }}
                        placeholder={`Query ${queryIndex}`}
                        autosize={{ minRows: 4 }}
                        onChange={(e) => {
                          this.setState({ [`query${queryIndex}`]: e.target.value });
                        }}
                        value={this.state[`query${queryIndex}`]}
                      />
                      <DeleteOutlined
                        onClick={() => {
                          this.setState({
                            queryData: queryData.splice(queryIndex, 1)
                          });
                        }}
                      />
                    </>
                  );
                })}
            </div>
          </TabPane>
        </Tabs>
        <DrawerFooter>
          <Button
            style={{ marginRight: 8 }}
            onClick={() => {
              this.props.onCancel();
              this.setState({
                ...defaultProps
              });
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              this.props.onOk(this.state);
              this.setState({
                ...defaultProps
              });
            }}
            type="primary"
          >
            Save
          </Button>
        </DrawerFooter>
      </Drawer>
    );
  }
}

export default ConfigurationModalContent;
