import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { Row, Col, Button, Form, Input, DatePicker, Collapse } from 'antd';

import SelectComponennt from '../Widgets/SelectComponent';

import '../eLogCalls';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

class SubSectionMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: cloneDeep(props.formObject) || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: ''
    };
  }

  componentDidMount() {
    this.setState({
      formObject: this.props.formObject,
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : []
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: cloneDeep(this.props.formObject),
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }

  addDownTime = () => {
    let { formObject } = this.state;
    if (
      formObject &&
      formObject['subSection'] &&
      formObject['subSection']['headerValue'] &&
      formObject['subSection']['headerValue'].Equipment
    ) {
      let EquipmentKey = formObject['subSection']['headerValue'].Equipment;
      formObject = {
        ...formObject,
        [EquipmentKey]: {
          columns: formObject['subSection'].columns,
          datasource: {}
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };

  onInputChange = (columnValue, columnKey, name) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          [columnKey]: columnValue
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onHeaderInputChange = (columnValue, columnKey, name) => {
    let formObject = cloneDeep(this.props.formObject);

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        headerValue: {
          ...(formObject && formObject[name] && formObject[name].headerValue
            ? formObject[name].headerValue
            : {}),
          [columnKey]: columnValue
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onChangeRemarks = (value, name, key) => {
    let { formObject } = this.state;
    if (
      formObject &&
      formObject[name] &&
      formObject[name].datasource &&
      formObject[name].datasource.Cause
    ) {
      formObject[name].datasource.Cause[key] = value;
    }
    this.props.downTimeData(formObject, false);
  };

  handleKeyPress = () => {};

  onChange = (date, columnKey, name) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          [columnKey]: {
            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  renderSwitch(formObject, columns, name) {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onInputChange(e, columns.key, name)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')]
            }}
            showTime={{ format: 'HH:mm' }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? [
                    moment(formObject[name].datasource[columns.key]['startDate'], dateFormat),
                    moment(formObject[name].datasource[columns.key]['endDate'], dateFormat)
                  ]
                : undefined
            }
            format="YYYY-MM-DD HH:mm"
            onChange={(e) => this.onChange(e, columns.key, name)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : ''
            }
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
    }
  }

  renderHeaderSwitch(formObject, columns, name) {
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            onChange={(e) => this.onHeaderInputChange(e.target.value, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onHeaderInputChange(e, columns.key, name)}
            handleKeyPress={this.handleKeyPress}
          />
        );

      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
    }
  }

  render() {
    let { getFieldDecorator } = this.props.form;
    let { formObject } = this.props;
    return (
      <div style={{ marginTop: '30px', marginBottom: '30px', color: 'black', fontWeight: 'bold' }}>
        <Form layout="inline" className="batchUpdateForm1">
          <Row>
            {formObject && formObject['subSection'] && formObject['subSection'].header
              ? formObject['subSection'].header.map((header, index) => {
                  return (
                    <div key={index}>
                      <Col span={12}>
                        <Form.Item
                          required={false}
                          label={
                            <span style={{ marginLeft: header.marginLeft }}>
                              {header.displayName}
                            </span>
                          }
                        >
                          {getFieldDecorator(
                            header.key,
                            {}
                          )(<div>{this.renderHeaderSwitch(formObject, header, 'subSection')}</div>)}
                        </Form.Item>
                      </Col>
                    </div>
                  );
                })
              : null}
          </Row>
        </Form>

        <Row>
          <Button
            type="primary"
            className="saveButton"
            style={{ margin: '10px 0' }}
            onClick={this.addDownTime}
          >
            Add Equipment
          </Button>
        </Row>
        <Form className="batchUpdateForm1">
          <Collapse bordered={false} className="subSectionCollapse">
            {formObject &&
              Object.keys(formObject).map((name) => {
                if (name !== 'subSection') {
                  return (
                    <Panel header={name} key={name}>
                      <div
                        style={{
                          margin: '10px 0',
                          padding: '20px'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            boxSizing: 'border-box'
                          }}
                        >
                          {formObject && formObject[name] && formObject[name].columns
                            ? formObject[name].columns.map((columns, index) => {
                                return (
                                  <div key={index} style={{ paddingRight: '4em' }}>
                                    <Form.Item
                                      required={false}
                                      label={
                                        <span style={{ marginLeft: columns.marginLeft }}>
                                          {columns.displayName}
                                        </span>
                                      }
                                    >
                                      <div>{this.renderSwitch(formObject, columns, name)}</div>
                                    </Form.Item>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </Panel>
                  );
                }
                return {};
              })}
          </Collapse>
        </Form>
      </div>
    );
  }
}

export default SubSectionMain;
