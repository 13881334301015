import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { Button, Form, Input, InputNumber, message, Collapse, Tooltip, DatePicker } from 'antd';

import SelectComponennt from '../Widgets/SelectComponent';
import { dailyShift, morningShift, nightShift, defaultRange } from '../../Constants/index.js';

import { StyledElogbook } from '../CSS/style';
import { DeleteFilled } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const newLocal = true;
class EquipmentBatchMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: props.formObject || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: '',
      equipmentName: '',
      BodyCount: '',
      batchArray: [],
      date: moment().format('YYYY-MM-DD'),
      shift: 'daily',
      equipmentList: [],
      isOpen: false
    };
  }

  componentDidMount() {
    this.setState({
      formObject: this.props.formObject,
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : []
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: this.props.formObject,
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }
  /*{ *Author* : Swathi,
   *Description* : To get mainHeader mandatory fields status }*/
  _getMandatoryStatus = () => {
    let { formObject } = this.props;
    let requiredStatus = false;
    if (formObject && formObject && formObject.mainHeader) {
      formObject.mainHeader.map((formHeader) => {
        let val = formObject.mainHeaderValue;
        if (formHeader && formHeader.key && formHeader.required === true) {
          if (val && val[formHeader.key]) {
            return {};
          } else {
            requiredStatus = true;
          }
        }
        return {};
      });
    }
    return requiredStatus;
  };
  /* { *Author* : Swathi,
   *Description* : This method will trigger when you click on AddEquipment Button} */
  addEquipment = async () => {
    let { formObject } = this.props;
    let mandatoryStatus = await this._getMandatoryStatus();
    let equipmentExist = false;
    if (formObject.mainHeaderValue.EquipmentName) {
      let equipmentName = formObject.mainHeaderValue.EquipmentName;
      Object.keys(formObject['tabs']).map((eqpName) => {
        if (eqpName === equipmentName) {
          message.error('The equipment is already selected');
          equipmentExist = true;
        }
        return {};
      });
    }
    if (!mandatoryStatus && !equipmentExist) {
      // let newObj = {}
      if (formObject && formObject && formObject.mainHeaderValue) {
        formObject &&
          formObject &&
          formObject.mainHeader &&
          formObject.mainHeader.map((column) => {
            if (
              column &&
              column['autoTabs'] &&
              column['autoTabs']['status'] &&
              column['autoTabs']['status'] === true
            ) {
              // newObj = {
              //     "ReferenceKey": formObject && formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[column.key] ?
              //         formObject && formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[column.key] : null,
              //     "JsonKey": formObject && formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[column.key] ?
              //         formObject && formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[column.key] : null,
              // }
            }
            return {};
          });
        formObject = {
          ...formObject,
          tabs: {
            ...(formObject && formObject['tabs']),
            [formObject.mainHeaderValue.EquipmentName]: {
              ...(formObject && formObject['tabs'] && formObject['tabs']['defaultData']),
              mainHeaderValue: formObject.mainHeaderValue,
              ReferenceAttributes: {
                JsonKey: ['tabs', formObject.mainHeaderValue.EquipmentName]
              }
              // "ReferenceAttributes": {
              //     ...newObj
              // }
            }
          }
        };
        this.props.downTimeData(formObject, false);
      }
    } else {
      if (mandatoryStatus) {
        message.error('Please select mandatory fields');
      }
    }
  };

  /* { *Author* : Swathi,
   *Description* : Equipment column inputNumber widget onChange method} */
  inputNumberChange = (minValue, maxValue, value, key, tabs, dynamicTabName, form, batchKey) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      (maxValue !== undefined || value === '')
    ) {
      this.onInputChange(
        parseInt(value),
        key,
        tabs,
        dynamicTabName,
        form,
        batchKey,
        minValue,
        maxValue
      );
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputChange(
        parseInt(value),
        key,
        tabs,
        dynamicTabName,
        form,
        batchKey,
        minValue,
        maxValue
      );
    } else {
      this.onInputChange('', key, tabs, dynamicTabName, form, batchKey, minValue, maxValue);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  /* { *Author* : Swathi,
   *Description* :  Equipment columns onChange function} */
  onInputChange = (columnValue, columnKey, tabs, dynamicTabName, form, batchKey, min) => {
    let { formObject } = this.state;
    // if (columnValue < 0 && typeof (columnValue) === "number" && min > 0) {
    //     // Do Something
    //     message.error("Please Input Positive Values Only")
    // }

    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
    } else {
      formObject = {
        ...formObject,
        [tabs]: {
          ...formObject[tabs],
          [dynamicTabName]: {
            ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
              ? formObject[tabs][dynamicTabName]
              : {}),
            [form]: {
              ...(formObject &&
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName][form]
                ? formObject[tabs][dynamicTabName][form]
                : {}),
              datasource: {
                ...(formObject[tabs] &&
                formObject[tabs][dynamicTabName] &&
                formObject[tabs][dynamicTabName][form] &&
                formObject[tabs][dynamicTabName][form].datasource
                  ? formObject[tabs][dynamicTabName][form].datasource
                  : {}),
                // [columnKey]: columnValue
                [batchKey]: {
                  ...(formObject &&
                  formObject[tabs] &&
                  formObject[tabs][dynamicTabName] &&
                  formObject[tabs][dynamicTabName][form] &&
                  formObject[tabs][dynamicTabName][form].datasource &&
                  formObject[tabs][dynamicTabName][form].datasource[batchKey]
                    ? formObject[tabs][dynamicTabName][form].datasource[batchKey]
                    : {}),
                  [columnKey]: columnValue
                }
              },
              ReferenceAttributes: {
                JsonKey: [tabs, dynamicTabName, form]
              }
            }
          }
        }
      };
      if (columnKey === 'ProductionOutput') {
        let sum = 0;
        let productionOutputSum =
          formObject &&
          formObject[tabs] &&
          formObject[tabs][dynamicTabName] &&
          formObject[tabs][dynamicTabName][form].datasource;
        productionOutputSum &&
          Object.keys(productionOutputSum).map((index) => {
            sum = sum + +productionOutputSum[index].ProductionOutput;
            return {};
          });
        formObject = {
          ...formObject,
          [tabs]: {
            ...formObject[tabs],
            [dynamicTabName]: {
              ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
                ? formObject[tabs][dynamicTabName]
                : {}),
              headerValue: {
                ...(formObject[tabs] &&
                  formObject[tabs][dynamicTabName] &&
                  formObject[tabs][dynamicTabName].headerValue),
                TotalProductionOutput: sum
              }
            }
          }
        };
      }
      this.props.downTimeData(formObject, false);
    }
  };
  /* { *Author* : Swathi,
   *Description* : Remarks Field OnChange Method} */
  onChangeRemarks = (value, tabs, key) => {
    let { formObject } = this.state;
    if (
      formObject &&
      formObject[tabs] &&
      formObject[tabs].datasource &&
      formObject[tabs].datasource.cause
    ) {
      formObject[tabs].datasource.cause[key] = value;
    }
    this.props.downTimeData(formObject, false);
  };

  /* { *Author* : Swathi,
   *Description* :  Equipment column RangePicker OnOk Method} */
  onOk = (date, columnKey, tabs, dynamicTabName, form, batchKey) => {
    let { formObject } = this.state;

    let startDate2 = '';
    let endDate2 = '';
    startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
    endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
    if (date[0] !== null && date[1] !== null) {
      if (startDate2 === endDate2) {
        message.error('StartDateTime and EndDateTime should not be same');
        startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
        endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
        formObject = cloneDeep(this.props.formObject);
        formObject = {
          ...formObject,
          [tabs]: {
            ...(formObject[tabs] ? formObject[tabs] : {}),
            [dynamicTabName]: {
              ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
                ? formObject[tabs][dynamicTabName]
                : {}),
              [form]: {
                ...(formObject &&
                  formObject[tabs] &&
                  formObject[tabs][dynamicTabName] &&
                  formObject[tabs][dynamicTabName][form]),
                datasource: {
                  ...(formObject[tabs] &&
                    formObject[tabs][dynamicTabName] &&
                    formObject[tabs][dynamicTabName][form] &&
                    formObject[tabs][dynamicTabName][form].datasource),
                  [batchKey]: {
                    ...(formObject &&
                    formObject[tabs] &&
                    formObject[tabs][dynamicTabName] &&
                    formObject[tabs][dynamicTabName][form] &&
                    formObject[tabs][dynamicTabName][form].datasource &&
                    formObject[tabs][dynamicTabName][form].datasource[batchKey]
                      ? formObject[tabs][dynamicTabName][form].datasource[batchKey]
                      : {}),
                    [columnKey]: {}
                  }
                }
              }
            }
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (startDate2 !== endDate2) {
        startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
        endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
        formObject = cloneDeep(this.props.formObject);
        formObject = {
          ...formObject,
          [tabs]: {
            ...(formObject[tabs] ? formObject[tabs] : {}),
            [dynamicTabName]: {
              ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
                ? formObject[tabs][dynamicTabName]
                : {}),
              [form]: {
                ...(formObject &&
                  formObject[tabs] &&
                  formObject[tabs][dynamicTabName] &&
                  formObject[tabs][dynamicTabName][form]),
                datasource: {
                  ...(formObject[tabs] &&
                    formObject[tabs][dynamicTabName] &&
                    formObject[tabs][dynamicTabName][form] &&
                    formObject[tabs][dynamicTabName][form].datasource),
                  [batchKey]: {
                    ...(formObject &&
                    formObject[tabs] &&
                    formObject[tabs][dynamicTabName] &&
                    formObject[tabs][dynamicTabName][form] &&
                    formObject[tabs][dynamicTabName][form].datasource &&
                    formObject[tabs][dynamicTabName][form].datasource[batchKey]
                      ? formObject[tabs][dynamicTabName][form].datasource[batchKey]
                      : {}),
                    [columnKey]: {
                      StartDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                      EndDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                    }
                  }
                }
              }
            }
          }
        };

        let masterData = [
          {
            startHour1: '06:00',
            endHour1: '23:00',
            startHour2: '00:00',
            endHour2: '05:59'
          }
        ];
        if (newLocal) {
          let check = formObject && formObject['mainHeaderValue'] && ['mainHeaderValue'].Date;

          let startDate = moment(check).format('DD');
          let startHours = moment(date[0]).format('HH:mm');

          let endHours = moment(date[1]).format('HH:mm');

          let endDate = moment(date[1]).format('DD');
          masterData &&
            masterData.map((item) => {
              //  hours >= 6 && hours < 18)
              if (
                item.startHour1 <= startHours &&
                endHours <= item.endHour2 &&
                startDate !== endDate
              ) {
                // alert("no 1 overlap");
                formObject = cloneDeep(this.props.formObject);
                formObject = {
                  ...formObject,
                  [tabs]: {
                    ...(formObject[tabs] ? formObject[tabs] : {}),
                    [dynamicTabName]: {
                      ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
                        ? formObject[tabs][dynamicTabName]
                        : {}),
                      [form]: {
                        ...(formObject &&
                          formObject[tabs] &&
                          formObject[tabs][dynamicTabName] &&
                          formObject[tabs][dynamicTabName][form]),
                        datasource: {
                          ...(formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource),
                          [batchKey]: {
                            ...(formObject &&
                            formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource &&
                            formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              ? formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              : {}),
                            [columnKey]: {
                              StartDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                              EndDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                            }
                          }
                        }
                      }
                    }
                  }
                };
              } else if (
                item.startHour2 <= startHours &&
                endHours <= item.endHour2 &&
                startDate < endDate &&
                startDate !== endDate
              ) {
                formObject = {
                  ...formObject,
                  [tabs]: {
                    ...(formObject[tabs] ? formObject[tabs] : {}),
                    [dynamicTabName]: {
                      ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
                        ? formObject[tabs][dynamicTabName]
                        : {}),
                      [form]: {
                        ...(formObject &&
                          formObject[tabs] &&
                          formObject[tabs][dynamicTabName] &&
                          formObject[tabs][dynamicTabName][form]),
                        datasource: {
                          ...(formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource),
                          [batchKey]: {
                            ...(formObject &&
                            formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource &&
                            formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              ? formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              : {}),
                            [columnKey]: {
                              StartDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                              EndDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                            }
                          }
                        }
                      }
                    }
                  }
                };
              } else if (
                item.startHour1 <= startHours &&
                endHours <= item.endHour1 &&
                startDate === endDate
              ) {
                formObject = {
                  ...formObject,
                  [tabs]: {
                    ...(formObject[tabs] ? formObject[tabs] : {}),
                    [dynamicTabName]: {
                      ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
                        ? formObject[tabs][dynamicTabName]
                        : {}),
                      [form]: {
                        ...(formObject &&
                          formObject[tabs] &&
                          formObject[tabs][dynamicTabName] &&
                          formObject[tabs][dynamicTabName][form]),
                        datasource: {
                          ...(formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource),
                          [batchKey]: {
                            ...(formObject &&
                            formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource &&
                            formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              ? formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              : {}),
                            [columnKey]: {
                              StartDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                              EndDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                            }
                          }
                        }
                      }
                    }
                  }
                };
              } else {
                message.error('Please choose correct time ranges');
                formObject = cloneDeep(this.props.formObject);
                formObject = {
                  ...formObject,
                  [tabs]: {
                    ...(formObject[tabs] ? formObject[tabs] : {}),
                    [dynamicTabName]: {
                      ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
                        ? formObject[tabs][dynamicTabName]
                        : {}),
                      [form]: {
                        ...(formObject &&
                          formObject[tabs] &&
                          formObject[tabs][dynamicTabName] &&
                          formObject[tabs][dynamicTabName][form]),
                        datasource: {
                          ...(formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource),
                          [batchKey]: {
                            ...(formObject &&
                            formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource &&
                            formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              ? formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              : {}),
                            [columnKey]: {}
                          }
                        }
                      }
                    }
                  }
                };
              }
              return {};
            });
        }

        if (
          formObject[tabs] &&
          formObject[tabs][dynamicTabName] &&
          formObject[tabs][dynamicTabName][form] &&
          formObject[tabs][dynamicTabName][form].datasource
        ) {
          let datasource = formObject[tabs][dynamicTabName][form].datasource;
          Object.keys(datasource).map((datasourceKey) => {
            if (
              datasource[datasourceKey][columnKey] &&
              datasource[datasourceKey][columnKey]['EndDate'] &&
              batchKey !== parseInt(datasourceKey)
            ) {
              let startDate1 = moment(
                datasource &&
                  datasource[datasourceKey] &&
                  datasource[datasourceKey][columnKey] &&
                  datasource[datasourceKey][columnKey]['StartDate']
              ).format('YYYY-MM-DD HH:mm:ss');
              let endDate1 = moment(
                datasource &&
                  datasource[datasourceKey] &&
                  datasource[datasourceKey][columnKey] &&
                  datasource[datasourceKey][columnKey]['EndDate']
              ).format('YYYY-MM-DD HH:mm:ss');

              if (startDate1 <= endDate2 && startDate2 <= endDate1) {
                message.error('Dates Overlapping with' + startDate1 + '~' + endDate1);
                formObject = cloneDeep(this.props.formObject);
                formObject = {
                  ...formObject,
                  [tabs]: {
                    ...(formObject[tabs] ? formObject[tabs] : {}),
                    [dynamicTabName]: {
                      ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
                        ? formObject[tabs][dynamicTabName]
                        : {}),
                      [form]: {
                        ...(formObject &&
                          formObject[tabs] &&
                          formObject[tabs][dynamicTabName] &&
                          formObject[tabs][dynamicTabName][form]),
                        datasource: {
                          ...(formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource),
                          [batchKey]: {
                            ...(formObject &&
                            formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource &&
                            formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              ? formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              : {}),
                            [columnKey]: {}
                          }
                        }
                      }
                    }
                  }
                };
              } else {
                formObject = {
                  ...formObject,
                  [tabs]: {
                    ...(formObject[tabs] ? formObject[tabs] : {}),
                    [dynamicTabName]: {
                      ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
                        ? formObject[tabs][dynamicTabName]
                        : {}),
                      [form]: {
                        ...(formObject &&
                          formObject[tabs] &&
                          formObject[tabs][dynamicTabName] &&
                          formObject[tabs][dynamicTabName][form]),
                        datasource: {
                          ...(formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource),
                          [batchKey]: {
                            ...(formObject &&
                            formObject[tabs] &&
                            formObject[tabs][dynamicTabName] &&
                            formObject[tabs][dynamicTabName][form] &&
                            formObject[tabs][dynamicTabName][form].datasource &&
                            formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              ? formObject[tabs][dynamicTabName][form].datasource[batchKey]
                              : {}),
                            [columnKey]: {
                              StartDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                              EndDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                            }
                          }
                        }
                      }
                    }
                  }
                };
              }
            }
            return {};
          });
        } else {
          formObject = {
            ...formObject,
            [tabs]: {
              ...(formObject[tabs] ? formObject[tabs] : {}),
              [dynamicTabName]: {
                ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]
                  ? formObject[tabs][dynamicTabName]
                  : {}),
                [form]: {
                  ...(formObject &&
                    formObject[tabs] &&
                    formObject[tabs][dynamicTabName] &&
                    formObject[tabs][dynamicTabName][form]),
                  datasource: {
                    ...(formObject[tabs] &&
                      formObject[tabs][dynamicTabName] &&
                      formObject[tabs][dynamicTabName][form] &&
                      formObject[tabs][dynamicTabName][form].datasource),
                    [batchKey]: {
                      ...(formObject &&
                      formObject[tabs] &&
                      formObject[tabs][dynamicTabName] &&
                      formObject[tabs][dynamicTabName][form] &&
                      formObject[tabs][dynamicTabName][form].datasource &&
                      formObject[tabs][dynamicTabName][form].datasource[batchKey]
                        ? formObject[tabs][dynamicTabName][form].datasource[batchKey]
                        : {}),
                      [columnKey]: {
                        StartDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                        EndDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                      }
                    }
                  }
                }
              }
            }
          };
        }
      }
    } else {
      message.error('Please Input Start & End Times');
    }

    this.props.downTimeData(formObject, false);
  };
  /* { *Author* : Swathi,
   *Description* :  MainHeader DatePicker OnChange Method} */
  onDateHeaderMainChange = (date, columnKey) => {
    let { formObject } = this.state;
    let formObjectNew = {};
    if (columnKey === 'Date' && formObject) {
      Object.keys(formObject).map((tabs) => {
        formObject &&
          formObject[tabs] &&
          tabs !== 'mainHeader' &&
          tabs !== 'mainHeaderValue' &&
          Object.keys(formObject[tabs]).map((dynamicTabName) => {
            let datasourceNew = {};
            if (
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName].form &&
              formObject[tabs][dynamicTabName].form.columns &&
              dynamicTabName !== 'defaultData'
            ) {
              formObject[tabs][dynamicTabName].form.columns.map((formCol) => {
                return formCol.key;
              });
              formObject[tabs][dynamicTabName].form.datasource &&
                Object.keys(formObject[tabs][dynamicTabName].form.datasource).map(
                  (datasourceKey) => {
                    datasourceNew[datasourceKey] = {
                      ...formObject[tabs][dynamicTabName].form.datasource[datasourceKey],
                      TimeSelection: {}
                    };
                    return {};
                  }
                );
              formObjectNew = {
                ...formObject,
                [tabs]: {
                  ...formObject[tabs],
                  [dynamicTabName]: {
                    ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]),
                    form: {
                      ...formObject[tabs][dynamicTabName].form,
                      datasource: datasourceNew
                    }
                  }
                },
                mainHeaderValue: {
                  ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                  [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
                }
              };
              this.props.downTimeData(formObjectNew);
            } else {
              formObject = {
                ...formObject,

                mainHeaderValue: {
                  ...(formObject && formObject && formObject.mainHeaderValue
                    ? formObject.mainHeaderValue
                    : {}),
                  [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
                }
              };

              this.props.downTimeData(formObject, false);
            }
            return {};
          });
        return {};
      });
    } else {
      formObject = {
        ...formObject,
        mainHeaderValue: {
          ...(formObject && formObject && formObject.mainHeaderValue
            ? formObject.mainHeaderValue
            : {}),
          [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
        TimeSelection: {}
      };
      this.props.downTimeData(formObject, false);
    }
  };
  /*{ *Author* : Swathi,
   *Description* : To render Equipment columns} */
  renderFormSwitch(formObject, columns, tabs, dynamicTabName, form, batchKey) {
    const { shift } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName][form] &&
              formObject[tabs][dynamicTabName][form].datasource &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey] &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key]
                ? formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onInputChange(e.target.value, columns.key, tabs, dynamicTabName, form, batchKey)
            }
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName][form] &&
              formObject[tabs][dynamicTabName][form].datasource &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey] &&
              (formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key] ||
                formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key] === 0)
                ? formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key]
                : ''
            }
            // min={columns.min ? columns.min : 0}
            min="0"
            // max={columns.max ? columns.max : 1000000000}
            placeholder={columns.placeHolder}
            // onChange={(e) => this.onInputChange(e, columns.key, tabs, batchKey)}
            onBlur={(e) =>
              this.inputNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                tabs,
                dynamicTabName,
                form,
                batchKey
              )
            }
            onChange={(e) =>
              this.onInputChange(
                e,
                columns.key,
                tabs,
                dynamicTabName,
                form,
                batchKey,
                columns.min,
                columns.max
              )
            }
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            data={formObject[tabs].datasource}
            record={{ key: columns }}
            text={
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName][form] &&
              formObject[tabs][dynamicTabName][form].datasource &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey] &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key]
                ? formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key]
                : ''
            }
            equipment={dynamicTabName}
            formObject={formObject}
            handleFieldChange={(e) =>
              this.onInputChange(e, columns.key, tabs, dynamicTabName, form, batchKey)
            }
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            placeholder={['Start Time', 'End Time']}
            allowClear={false}
            // defaultValue={[moment().format,moment().format]}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            format="YYYY-MM-DD HH:mm"
            disabledTime={(e) => this.disabledRangeTime(e)}
            disabledDate={(e) =>
              this.disabledDateRange(
                {
                  ...(formObject && formObject ? formObject : {})
                },
                e
              )
            }
            value={
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName][form] &&
              formObject[tabs][dynamicTabName][form].datasource &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey] &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key] &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key][
                'StartDate'
              ] &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key]['EndDate']
                ? [
                    moment(
                      formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key][
                        'StartDate'
                      ],
                      dateFormat
                    ),
                    moment(
                      formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key][
                        'EndDate'
                      ],
                      dateFormat
                    )
                  ]
                : null
            }
            // onChange={(e) => this.onChange(e, columns.key, tabs, batchKey, columns)}
            onOk={(e) => this.onOk(e, columns.key, tabs, dynamicTabName, form, batchKey, columns)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName][form] &&
              formObject[tabs][dynamicTabName][form].datasource &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey] &&
              formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key]
                ? formObject[tabs][dynamicTabName][form].datasource[batchKey][columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onInputChange(e.target.value, columns.key, tabs, dynamicTabName, form, batchKey)
            }
          />
        );
    }
  }
  onBlur = () => {};
  /* { *Author* : Swathi,
   *Description* :  MainHeader Inputnumber OnChange Method} */
  onInputNumberHeaderMainChange = (minValue, maxValue, value, key) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onInputHeaderMainChange(parseInt(value), key, minValue, maxValue);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputHeaderMainChange(parseInt(value), key, minValue, maxValue);
    } else {
      this.onInputHeaderMainChange('', key, minValue, maxValue);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  /* { *Author* : Swathi,
   *Description* :mainHeader columns onChange function} */
  onInputHeaderMainChange = (columnValue, columnKey, min) => {
    let { formObject } = this.state;
    // if (columnValue < 0 && typeof (columnValue) === "number" && min > 0) {
    //     message.error("Please Input Positive Values Only")
    // }

    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
    } else {
      formObject = {
        ...formObject,
        mainHeaderValue: {
          ...(formObject && formObject && formObject.mainHeaderValue
            ? formObject.mainHeaderValue
            : {}),
          [columnKey]: columnValue
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };
  /* { *Author* : Swathi,
   *Description* : To render mainHeader columns} */
  renderMainSwitch(formObject, columns, tabs, batchKey) {
    const { shift } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key)}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject &&
              formObject.mainHeaderValue &&
              (formObject.mainHeaderValue[columns.key] ||
                formObject.mainHeaderValue[columns.key] === 0)
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            min="0"
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 1000000000}
            placeholder={columns.placeHolder}
            // onChange={(e) => this.onInputHeaderMainChange(e, columns.key, tabs)}
            onBlur={(e) =>
              this.onInputNumberHeaderMainChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key
              )
            }
            onChange={(e) =>
              this.onInputHeaderMainChange(
                columns.min,
                columns.max,
                e,
                columns.key,
                columns.min,
                columns.max
              )
            }
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date']
                ? moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss')
                : undefined
            }
            allowClear={false}
            disabledDate={this.disabledDate}
            // defaultValue={formObject &&
            //     formObject["mainHeaderValue"] &&
            //     formObject["mainHeaderValue"]['Date']
            //     ? moment(formObject["mainHeaderValue"]['Date'], 'YYYY-MM-DD HH:mm:ss')
            //     : moment()}
            // format={"DD-MM-YYYY"}
            // disabledDate={this.disabledDate}
            // disabled={formObject && Object.keys(formObject).length > 1 ? true : false}
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key)}
            style={{ width: 300 }}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            handleFieldChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            // showTime
            placeholder={['Start Time', 'End Time']}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            format="YYYY-MM-DD HH:mm"
            value={
              formObject &&
              formObject.datasource &&
              formObject.datasource[batchKey] &&
              formObject.datasource[batchKey][columns.key]
                ? [
                    moment(formObject.datasource[batchKey][columns.key]['StartDate'], dateFormat),
                    moment(formObject.datasource[batchKey][columns.key]['EndDate'], dateFormat)
                  ]
                : null
            }
            onChange={(e) => this.onChange(e, columns.key)}
          />
        );

      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key)}
          />
        );
    }
  }
  /* { *Author* : Swathi,
   *Description* :  disabled hours And  minutes based on shift selection} */
  disabledRangeTime = (current) => {
    let { shift, formObject } = this.state;
    if (
      current !== null &&
      formObject &&
      formObject.mainHeaderValue &&
      formObject.mainHeaderValue['Date']
    ) {
      let selectedDate = moment(
        formObject && formObject && formObject.mainHeaderValue && formObject.mainHeaderValue['Date']
      ).format('YYYY-MM-DD');
      let currentDate = moment(current).format('YYYY-MM-DD');
      let range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
      };
      if (shift === 'Morning Shift' && selectedDate === currentDate) {
        return {
          // disabledHours: () => range(18, 18),
          disabledHours: () => [0, 1, 2, 3, 4, 5, 18, 19, 20, 21, 22, 23],
          disabledMinutes: () => range(60, 60)
          // disabledSeconds: () => [55, 56],
        };
      } else if (shift === 'Night Shift' && selectedDate === currentDate) {
        return {
          disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
          disabledMinutes: () => range(60, 60)
        };
      } else if (shift === 'Night Shift' && selectedDate !== currentDate) {
        return {
          disabledHours: () => [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
          disabledMinutes: () => range(60, 60)
        };
      } else if (selectedDate === currentDate) {
        return {
          disabledHours: () => [0, 1, 2, 3, 4, 5],
          disabledMinutes: () => range(60, 60)
        };
      } else if (selectedDate !== currentDate) {
        return {
          disabledHours: () => [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
        };
      }
    }

    return {
      disabledHours: () => [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
      ]
    };
  };
  /* { *Author* : Swathi,
   *Description* :  disabled future dates in datePicker} */
  disabledDate = (current) => {
    return current > moment().endOf('day');
  };
  /* { *Author* : Swathi,
   *Description* :  disabled dates based on mainHeader selectedDate} */
  disabledDateRange = (formObject, current) => {
    if (formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date']) {
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).add(1, 'day').endOf('day') ||
        current < moment(selectedDate).startOf('day')
      );
    }
    return (
      current || current > moment().add(1, 'day').endOf('day') || current < moment().startOf('day')
    );
  };
  /* { *Author* : Swathi,
   *Description* :  datrePicker onchange method} */
  onDateChange = (date, dateString) => {
    this.setState({
      date: dateString
    });
  };
  /* { *Author* : Swathi,
   *Description* :  inputNumber onChnage method} */
  inputHeaderNumberChange = (minValue, maxValue, value, key, tabs, dynamicTabName) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onHeaderInputChange(parseInt(value), key, tabs, dynamicTabName, minValue, maxValue);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onHeaderInputChange(parseInt(value), key, tabs, dynamicTabName, minValue, maxValue);
    } else {
      this.onHeaderInputChange('', key, tabs, dynamicTabName, minValue, maxValue);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  /* { *Author* : Swathi,
   *Description* :  Equipment header columns onChange function} */
  onHeaderInputChange = (columnValue, columnKey, tabs, dynamicTabName, min) => {
    let formObject = cloneDeep(this.props.formObject);
    let headerValue = {};
    // if (columnValue < 0 && typeof (columnValue) === "number" && min > 0) {
    //     message.error("Please Input Positive Values Only")
    // }

    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
    } else {
      if (
        (columnKey === 'IsEquipmentActive' || columnKey === 'EquipmentActiveStatus') &&
        columnValue === 'No'
      ) {
        headerValue = {
          [columnKey]: columnValue,
          TotalProductionOutput: '0'
        };
        formObject = {
          ...formObject,
          [tabs]: {
            ...formObject[tabs],
            [dynamicTabName]: {
              ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]),
              header: formObject[tabs][dynamicTabName].header.map((headerObject) => {
                if (
                  headerObject.key !== 'EquipmentActiveStatus' &&
                  headerObject.key !== 'TotalProductionOutput'
                ) {
                  return {
                    ...headerObject,
                    hide: true
                  };
                } else {
                  return headerObject;
                }
              })
            }
          }
        };
      } else if (
        columnKey === 'BodyCount' &&
        formObject &&
        formObject[tabs][dynamicTabName] &&
        formObject[tabs][dynamicTabName] &&
        formObject[tabs][dynamicTabName]['headerValue'] &&
        formObject[tabs][dynamicTabName]['headerValue']['EquipmentActiveStatus'] === 'Yes'
      ) {
        formObject = {
          ...formObject,
          [tabs]: {
            ...formObject[tabs],
            [dynamicTabName]: {
              ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]),
              header: formObject[tabs][dynamicTabName].header.map((headerObject) => {
                if (
                  headerObject.key !== 'EquipmentActiveStatus' &&
                  headerObject.key !== 'TotalProductionOutput'
                ) {
                  return {
                    ...headerObject,
                    hide: false
                  };
                } else {
                  return headerObject;
                }
              })
            }
          }
        };
        headerValue = {
          [columnKey]: columnValue
        };
      } else if (columnKey === 'EquipmentActiveStatus' && columnValue === 'Yes') {
        headerValue = {
          [columnKey]: columnValue,
          TotalProduction: '0'
        };

        formObject = {
          ...formObject,
          [tabs]: {
            ...formObject[tabs],
            [dynamicTabName]: {
              ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]),
              header: formObject[tabs][dynamicTabName].header.map((headerObject) => {
                if (
                  headerObject.key !== 'EquipmentActiveStatus' &&
                  headerObject.key !== 'TotalProductionOutput'
                ) {
                  return {
                    ...headerObject,
                    hide: false
                  };
                } else {
                  return headerObject;
                }
              })
            }
          }
        };
      } else {
        headerValue = {
          [columnKey]: columnValue
        };
      }

      formObject = {
        ...formObject,
        [tabs]: {
          ...formObject[tabs],
          [dynamicTabName]: {
            ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]),
            headerValue: {
              ...(formObject &&
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName].headerValue
                ? formObject[tabs][dynamicTabName].headerValue
                : {}),
              ...headerValue
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };
  /* { *Author* : Swathi,
   *Description* : To render equipment header columns}*/
  renderHeaderSwitch(formObject, columns, tabs, dynamicTabName) {
    let mtdValidation = JSON.stringify(dynamicTabName).match('MTD');
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName].headerValue &&
              formObject[tabs][dynamicTabName].headerValue[columns.key]
                ? formObject[tabs][dynamicTabName].headerValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onHeaderInputChange(e.target.value, columns.key, tabs, dynamicTabName)
            }
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName].headerValue &&
              (formObject[tabs][dynamicTabName].headerValue[columns.key] ||
                formObject[tabs][dynamicTabName].headerValue[columns.key] === 0)
                ? formObject[tabs][dynamicTabName].headerValue[columns.key]
                : ''
            }
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 1000000000}
            // placeholder={columns.placeHolder}
            placeholder={
              columns.displayName &&
              mtdValidation &&
              Array.isArray(mtdValidation) &&
              mtdValidation.length > 0 &&
              columns.displayName === 'How Many Body Exists ?'
                ? 'Enter No of BatchCode'
                : columns.placeHolder
            }
            // onChange={(e) =>
            //     this.onHeaderInputChange(e, columns.key, tabs)
            // }
            onBlur={(e) =>
              this.inputHeaderNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                tabs,
                dynamicTabName
              )
            }
            onChange={(e) =>
              this.onHeaderInputChange(
                e,
                columns.key,
                tabs,
                dynamicTabName,
                columns.min,
                columns.max
              )
            }
            disabled={columns.disabled ? columns.disabled : false}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName].headerValue &&
              formObject[tabs][dynamicTabName].headerValue[columns.key]
                ? formObject[tabs][dynamicTabName].headerValue[columns.key]
                : undefined
            }
            equipment={dynamicTabName}
            formObject={formObject}
            handleFieldChange={(e) =>
              this.onHeaderInputChange(e, columns.key, tabs, dynamicTabName)
            }
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'textArea':
        return (
          <div>
            <Input.TextArea
              rows={4}
              style={{ width: 300 }}
              value={
                formObject[tabs] &&
                formObject[tabs][dynamicTabName] &&
                formObject[tabs][dynamicTabName].headerValue &&
                formObject[tabs][dynamicTabName].headerValue[columns.key]
                  ? formObject[tabs][dynamicTabName].headerValue[columns.key]
                  : ''
              }
              placeholder={columns.placeHolder}
              onChange={(e) =>
                this.onHeaderInputChange(e.target.value, columns.key, tabs, dynamicTabName)
              }
              autoSize={{ minRows: 2, maxRows: 4 }}
              // style={{ width: '100%' }}
              maxLength={250}
            />
            <div style={{ color: 'white' }}>
              Characters left :{' '}
              <span style={{ color: 'white', fontWeight: 'bold' }}>
                {formObject[tabs] &&
                formObject[tabs][dynamicTabName] &&
                formObject[tabs][dynamicTabName].mainHeaderValue &&
                formObject[tabs][dynamicTabName].mainHeaderValue[columns.key]
                  ? 250 - formObject[tabs][dynamicTabName].mainHeaderValue[columns.key].length
                  : 250}
                {/* {remarks && remarks !== null && remarks !== "null" ? 250 - (remarks.length) : 250} */}
              </span>
            </div>
          </div>
        );

      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName].headerValue &&
              formObject[tabs][dynamicTabName].headerValue[columns.key]
                ? formObject[tabs][dynamicTabName].headerValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onHeaderInputChange(e.target.value, columns.key, tabs, dynamicTabName)
            }
          />
        );
    }
  }
  /* { *Author* : Swathi,
   *Description* : This method will trigger when you click on Equipment Delete Icon }*/
  deleteHandler = (tabs, dynamicTabName) => {
    let { formObject } = this.props;
    delete formObject[tabs][dynamicTabName];
    formObject = { ...formObject };
    this.props.downTimeData(formObject, false);
  };
  /* { *Author* : Swathi,
   *Description* :  To Get date&time Ranges based on selected shift} */
  dateRanges = (shift) => {
    switch (shift) {
      case 'daily': {
        return dailyShift;
      }
      case 'Morning Shift': {
        return morningShift;
      }
      case 'Night Shift': {
        return nightShift;
      }
      default:
        return defaultRange;
    }
  };

  render() {
    let { formObject } = this.state;
    return (
      <StyledElogbook>
        <Form className="formCustomLabel" layout="vertical">
          <div
            style={{
              ...(this.props.status === 'Completed'
                ? {
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }
                : {})
            }}
          >
            <div className="formRowWrap">
              {formObject && formObject.mainHeader
                ? formObject.mainHeader.map((mainHeaderKey, index) => {
                    return (
                      <div key={index} className="subSectionFormBody">
                        <Form.Item
                          required={false}
                          label={
                            <span style={{ marginLeft: mainHeaderKey.marginLeft }}>
                              {mainHeaderKey.displayName}
                            </span>
                          }
                        >
                          <div>{this.renderMainSwitch(formObject, mainHeaderKey)}</div>
                        </Form.Item>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <Button
            type=""
            className="saveButton"
            style={{ margin: '10px 0' }}
            onClick={this.addEquipment}
          >
            Add Equipment
          </Button>
        </Form>
        <Form className="formCustomLabel" layout="vertical">
          {formObject && Object.keys(formObject).length > 1
            ? formObject &&
              Object.keys(formObject).map((tabs) => {
                if (tabs !== 'mainHeader' && tabs !== 'mainHeaderValue') {
                  return (
                    <>
                      <Collapse bordered={false} className="customCollapse">
                        {formObject &&
                          formObject[tabs] &&
                          Object.keys(formObject[tabs]).map((dynamicTabName) => {
                            let batchArray = [];
                            let noOfBodyCount =
                              formObject &&
                              formObject[tabs] &&
                              formObject[tabs][dynamicTabName] &&
                              formObject[tabs][dynamicTabName].headerValue &&
                              formObject[tabs][dynamicTabName].headerValue.BodyCount
                                ? formObject[tabs][dynamicTabName].headerValue.BodyCount
                                : 0;
                            let mtdValidation = JSON.stringify(dynamicTabName).match('MTD');
                            for (let i = 1; i <= noOfBodyCount; i++) {
                              batchArray.push(i);
                            }
                            if (dynamicTabName !== 'defaultData') {
                              return (
                                <Panel
                                  header={dynamicTabName}
                                  key={dynamicTabName}
                                  extra={
                                    <Tooltip placement="top" title={<span>Delete</span>}>
                                      <DeleteFilled
                                        style={{ fontSize: '20px' }}
                                        onClick={() => this.deleteHandler(tabs, [dynamicTabName])}
                                      />
                                    </Tooltip>
                                  }
                                >
                                  <div
                                    style={{
                                      margin: '20px 10px',
                                      ...(this.props.status === 'Completed'
                                        ? {
                                            opacity: newLocal ? 0.75 : 1,
                                            pointerEvents: newLocal ? 'none' : 'initial'
                                          }
                                        : {})
                                    }}
                                  >
                                    <div>
                                      <Form layout="inline" className="batchUpdateForm1">
                                        {formObject &&
                                        formObject[tabs] &&
                                        formObject[tabs][dynamicTabName] &&
                                        formObject[tabs][dynamicTabName].header
                                          ? formObject[tabs][dynamicTabName].header.map(
                                              (columns, index) => {
                                                if (columns && !columns.hide) {
                                                  return (
                                                    <div
                                                      key={index}
                                                      style={{ paddingRight: '2em' }}
                                                    >
                                                      <Form.Item
                                                        required={false}
                                                        label={
                                                          <span
                                                            style={{
                                                              marginLeft:
                                                                columns.displayName &&
                                                                mtdValidation &&
                                                                Array.isArray(mtdValidation) &&
                                                                mtdValidation.length > 0 &&
                                                                columns.displayName ===
                                                                  'How Many Body Exists ?'
                                                                  ? '27px'
                                                                  : columns.marginLeft
                                                            }}
                                                          >
                                                            {columns.displayName &&
                                                            mtdValidation &&
                                                            Array.isArray(mtdValidation) &&
                                                            mtdValidation.length > 0 &&
                                                            columns.displayName ===
                                                              'How Many Body Exists ?'
                                                              ? 'How Many Batches Exists ?'
                                                              : columns.displayName}
                                                          </span>
                                                        }
                                                      >
                                                        <div>
                                                          {this.renderHeaderSwitch(
                                                            formObject,
                                                            columns,
                                                            tabs,
                                                            dynamicTabName
                                                          )}
                                                        </div>
                                                      </Form.Item>
                                                    </div>
                                                  );
                                                }
                                                return {};
                                              }
                                            )
                                          : null}
                                        {/* <Button onClick={()=>this.deleteHandler(tabs)}>Delete</Button> */}
                                      </Form>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      flexWrap: 'wrap',
                                      boxSizing: 'border-box',
                                      ...(this.props.status === 'Completed'
                                        ? {
                                            opacity: newLocal ? 0.75 : 1,
                                            pointerEvents: newLocal ? 'none' : 'initial'
                                          }
                                        : {})
                                    }}
                                  >
                                    {formObject &&
                                      formObject[tabs] &&
                                      formObject[tabs][dynamicTabName] &&
                                      formObject[tabs][dynamicTabName].headerValue &&
                                      formObject[tabs][dynamicTabName].headerValue[
                                        'EquipmentActiveStatus'
                                      ] &&
                                      formObject[tabs][dynamicTabName].headerValue[
                                        'EquipmentActiveStatus'
                                      ] === 'Yes' &&
                                      batchArray &&
                                      batchArray.map((batchKey, index) => {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              margin: '10px 20px',
                                              background: '#285c8e',
                                              padding: '10px',
                                              display: 'flex',
                                              flexDirection: 'row',
                                              flexWrap: 'wrap',
                                              boxSizing: 'border-box'
                                            }}
                                          >
                                            {formObject &&
                                            formObject[tabs] &&
                                            formObject[tabs][dynamicTabName] &&
                                            formObject[tabs][dynamicTabName]['form'] &&
                                            formObject[tabs][dynamicTabName]['form'].columns
                                              ? formObject[tabs][dynamicTabName][
                                                  'form'
                                                ].columns.map((columns, subindex) => {
                                                  return (
                                                    <div
                                                      key={subindex}
                                                      style={{ paddingRight: '2em' }}
                                                    >
                                                      <Form.Item
                                                        required={false}
                                                        // label={columns.displayName}
                                                        label={
                                                          <span
                                                            style={{
                                                              marginLeft: columns.marginLeft
                                                            }}
                                                          >
                                                            {columns.displayName}
                                                          </span>
                                                        }
                                                      >
                                                        <div>
                                                          {this.renderFormSwitch(
                                                            formObject,
                                                            columns,
                                                            tabs,
                                                            dynamicTabName,
                                                            'form',
                                                            batchKey
                                                          )}
                                                        </div>
                                                      </Form.Item>
                                                    </div>
                                                  );
                                                })
                                              : null}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </Panel>
                              );
                            }
                            return {};
                          })}
                      </Collapse>
                    </>
                  );
                }
                return {};
              })
            : null}
        </Form>
      </StyledElogbook>
    );
  }
}

export default EquipmentBatchMain;
