import React from 'react';
import axios from 'axios';
import { Row, Col, Avatar } from 'antd';
import localStorage from '../../../../../utils/localStorage';
import history from '../../../../../commons/history';

import './landingpage.css';

const colStyle = {
  color: 'white',
  //border: '2px solid #333333',
  margin: '5px',
  padding: '5px',
  borderRadius: '10px',
  boxShadow: '5px 5px 30px #333333'
};
const titleStyle = {
  color: 'black',
  fontSize: '1.2em',
  fontWeight: 'bold',
  textAlign: 'center',
  // padding:'5px',
  borderBottom: '2px dotted black',
  marginBottom: '10px'
};
const avatarStyle = {
  height: '50px',
  width: '50px'
};
const statusStyle = {
  fontSize: '1em',
  paddingLeft: '5px',
  color: 'black',
  fontWeight: 'bold'
};
class GULFTRAINER extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: {},
      activeCrane: []
    };
  }
  timeTicket = null;
  // signal = axios.CancelToken.source();
  requestDeviceCheck = true;
  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this._getdeviceList();

    this.timeTicket = setInterval(() => {
      if (this.requestDeviceCheck) {
        // Request Pending status check for Device Stauts
        this._getdeviceList();
      }
    }, 1 * 60000);
  }

  componentWillUnmount() {
    // this.signal.cancel('Api is being canceled');
    clearInterval(this.timeTicket);
  }

  _getdeviceList = () => {
    this.requestDeviceCheck = false;
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    if (!siteId) {
      siteId = 'test';
    }
    const headers = {
      method: 'GET',
      // url: `/api/landingPage/getTrainStatus`,
      url: `/api/landingPage/landingpagedeviceStatus?SiteId=${siteId}`,

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
      // cancelToken: this.signal.token,
    };
    axios(headers)
      .then((response) => {
        if (response.data) {
          this.setState({ deviceList: response.data });
          this.requestDeviceCheck = true;
        }
      })
      .catch(() => {
        return {};
      });
  };

  onCraneImageClick = (craneObject) => {
    history.push(`${craneObject}`);
  };

  render() {
    let { deviceList } = this.state;
    // let colorStatus = {
    //   'No Data': '#808080',
    //   'Main Line': '#099c0c',
    //   Depot: '#3397da',
    //   Maintenance: '#ffc300'
    // };
    return (
      <div style={{ backgroundColor: '#243a4f', minHeight: window.innerHeight - 135 }}>
        <div>
          <Row
            style={{
              padding: '10px',
              marginBottom: '10px',
              backgroundColor: 'rgb(195, 200, 206)'
            }}
          >
            <Col span={3} idleset={2}></Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/MHC_Running_v1.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>RUNNING</span>
            </Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/MHC_Idle_v1.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>IDLE</span>
            </Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/Mobile Harbour Crane_v3.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>STOPPED</span>
            </Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/MHC_Alert_v1.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>DAMAGED/ALERT</span>
            </Col>
          </Row>
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#b3b3b3' }}>
            {deviceList &&
              deviceList['Mobile Harbour Crane'] &&
              Array.isArray(deviceList['Mobile Harbour Crane']) &&
              deviceList['Mobile Harbour Crane'].map((key, index) => {
                return (
                  <Col
                    key={index}
                    className="colRunning"
                    style={{
                      ...colStyle,
                      backgroundColor: key && key.color ? key.color : '',
                      width: '9%'
                    }}
                    onClick={() => this.onCraneImageClick('/rubus/singleHMI/static/MHC')}
                    width={'7%'}
                  >
                    <div style={titleStyle}>{key.deviceName} </div>
                    <div
                      className={'stop123'}
                      style={{ backgroundImage: key && key.url ? `url(${key.url})` : '' }}
                    ></div>
                  </Col>
                );
              })}
          </div>
        </div>
        <div>
          <Row
            style={{
              padding: '10px',
              marginBottom: '10px',
              backgroundColor: 'rgb(195, 200, 206)'
            }}
          >
            <Col span={3} idleset={2}></Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/QC_Running_V3.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>RUNNING</span>
            </Col>
            <Col span={5}>
              <Avatar shape="square" src={`/api/masterImages/QC_Idle_V3.png`} style={avatarStyle} />
              <span style={statusStyle}>IDLE</span>
            </Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/Quay_crane_V3.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>STOPPED</span>
            </Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/QC_Alert_V3.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>DAMAGED/ALERT</span>
            </Col>
          </Row>
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#b3b3b3' }}>
            {deviceList &&
              deviceList.Quay_crane &&
              Array.isArray(deviceList.Quay_crane) &&
              deviceList.Quay_crane.map((key, index) => {
                return (
                  <Col
                    key={index}
                    className="colRunning"
                    style={{
                      ...colStyle,
                      backgroundColor: key && key.color ? key.color : '',
                      width: '9%'
                    }}
                    onClick={() => this.onCraneImageClick('/rubus/singleHMI/static/QC SVG')}
                    width={'7%'}
                  >
                    <div style={titleStyle}>{key.deviceName} </div>
                    <div
                      className={'stop123'}
                      style={{ backgroundImage: key && key.url ? `url(${key.url})` : '' }}
                    ></div>
                  </Col>
                );
              })}
          </div>
        </div>

        <div>
          <Row
            style={{
              padding: '10px',
              marginBottom: '10px',
              backgroundColor: 'rgb(195, 200, 206)'
            }}
          >
            <Col span={3} idleset={2}></Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/RTG_Running_V3.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>RUNNING</span>
            </Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/RTG_Idle_V3.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>IDLE</span>
            </Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/RTG_crane_V3.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>STOPPED</span>
            </Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`/api/masterImages/RTG_Alert_V3.png`}
                style={avatarStyle}
              />
              <span style={statusStyle}>DAMAGED/ALERT</span>
            </Col>
          </Row>
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#b3b3b3' }}>
            {deviceList &&
              deviceList.RTG_crane &&
              Array.isArray(deviceList.RTG_crane) &&
              deviceList.RTG_crane.map((key, index) => {
                return (
                  <Col
                    key={index}
                    className="colRunning"
                    style={{
                      ...colStyle,
                      backgroundColor: key && key.color ? key.color : '',
                      width: '9%'
                    }}
                    onClick={() => this.onCraneImageClick('/rubus/singleHMI/static/RTG')}
                    width={'7%'}
                  >
                    <div style={titleStyle}>{key.deviceName} </div>
                    <div
                      className={'stop123'}
                      style={{ backgroundImage: key && key.url ? `url(${key.url})` : '' }}
                    ></div>
                  </Col>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default GULFTRAINER;
