import React from 'react';
import { Tree } from 'antd';

import { StyledApplication } from '../Device/styles';
import { DownOutlined } from '@ant-design/icons';
import './style.css';

class AssetHierarchyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let treeData = [
      {
        title: 'Location',
        key: '0-0',
        children: [
          {
            title: 'Wood_fuel_equipment',
            key: 'Wood_fuel_equipment',
            children: [
              {
                title: 'WFE1',
                key: '0-0-0-0'
              },
              {
                title: 'WFE2',
                key: '0-0-0-1'
              },
              {
                title: 'WFE3',
                key: '0-0-0-2'
              }
            ]
          },
          {
            title: 'Boiler_unit',
            key: '0-0-1',
            children: [
              {
                title: 'Boiler1',
                key: '0-0-1-0'
              }
            ]
          },
          {
            title: 'Generator',
            key: '0-0-2',
            children: [
              {
                title: 'Gen1',
                key: '0-0-2-0'
              },
              {
                title: 'Gen2',
                key: '0-0-2-1'
              }
            ]
          },
          {
            title: 'Turbine',
            key: '0-0-2',
            children: [
              {
                title: 'Turbine1',
                key: '0-0-2-0'
              },
              {
                title: 'Turbine2',
                key: '0-0-2-1'
              }
            ]
          }
        ]
      }
    ];

    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
        <Tree
          // className="treeView"
          //   style={{ background: "rgb(23, 48, 73)",color: "white" }}
          showLine
          switcherIcon={<DownOutlined />}
          defaultExpandAll
          treeData={treeData}
        />
      </StyledApplication>
    );
  }
}

export default AssetHierarchyView;
