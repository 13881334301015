import axios from 'axios';
import localStorage from '../../utils/localStorage';
import { message } from 'antd';

export async function getTrendData(config) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  const data = await fetch(`/api/trends/list?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      ...config,
      SiteId: siteId
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {});
  return data;
}

export async function getTrendConfig() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  const data = await fetch(`/api/deviceType/cache?SiteId=${siteId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {});

  return data === undefined ? [] : data;
}
export async function getParameterList(DeviceId) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'POST',
    url: `/api/deviceParameter/deviceParameters?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      DeviceCode: DeviceId,
      SiteId: siteId
    }
  };
  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return {
        data: {}
      };
    });
}

export async function getOperationList(ParameterType, GraphType) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const operatorGroup = {
    method: 'POST',
    url: `/api/trends/getOperators?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ParameterType,
      GraphType
    }
  };

  return axios(operatorGroup)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getBookmarkList(Module) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'GET',
    url: `/api/bookmark/get?SiteId=${siteId}&Module=${[]}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      Module: Module || ''
    }
  };
  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
        return [];
      }
    });
}

export async function CreateTrend(data) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'POST',
    url: `/api/bookmark/create?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data
  };

  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function UpdateTrends(params) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'PATCH',
    url: `/api/bookmark/update?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...params,
      SiteId: siteId
    }
  };

  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function DeleteTrend(Id) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'DELETE',
    url: `/api/bookmark/delete?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: Authorization
    }
  };

  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return {
        data: {}
      };
    });
}

export async function getbookMarkByname(Id) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'GET',
    url: `/api/bookmark/get?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: Authorization
    }
  };

  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return {
        data: {}
      };
    });
}
export async function getHierarchyData() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const deciceListApi = {
    method: 'GET',
    url: `/api/hierarchymodule/getUserHierarchy?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(deciceListApi)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getDefaultSiteName() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const deciceListApi = {
    method: 'GET',
    url: `/api/hierarchymodule/getDefaultHierarchy?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(deciceListApi)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
