import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { Row, Button, Form, Input, DatePicker, Collapse } from 'antd';

import SelectComponent from '../Widgets/SelectComponent';
import { PlusOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
let id = 0;

class DownTimeBatchMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: cloneDeep(props.formObject) || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: ''
    };
  }

  componentDidMount() {
    this.setState({
      formObject: this.props.formObject,
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : []
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: cloneDeep(this.props.formObject),
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }

  addDownTime = () => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      [id]: {
        columns:
          formObject['equipmentBatch'] && formObject['equipmentBatch'].columns
            ? formObject['equipmentBatch'].columns
            : [],
        datasource: {}
      }
    };
    id = id + 1;
    this.props.downTimeData(formObject, false);
  };

  onCause = (name) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...(formObject && formObject[name] && formObject[name].datasource
            ? formObject[name].datasource
            : {}),
          Cause: [
            ...(formObject &&
            formObject[name] &&
            formObject[name].datasource &&
            formObject[name].datasource.Cause
              ? formObject[name].datasource.Cause
              : []),
            ''
          ]
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onInputChange = (columnValue, columnKey, name) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          [columnKey]: columnValue
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onHeaderInputChange = (columnValue, columnKey, name) => {
    let formObject = cloneDeep(this.props.formObject);

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        headerValue: {
          ...(formObject && formObject[name] && formObject[name].headerValue
            ? formObject[name].headerValue
            : {}),
          [columnKey]: columnValue
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onChangeRemarks = (value, name, key) => {
    let { formObject } = this.state;
    if (
      formObject &&
      formObject[name] &&
      formObject[name].datasource &&
      formObject[name].datasource.Cause
    ) {
      formObject[name].datasource.Cause[key] = value;
    }
    this.props.downTimeData(formObject, false);
  };

  handleKeyPress = () => {};

  onChange = (date, columnKey, name) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          [columnKey]: {
            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  renderSwitch(formObject, columns, name) {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : ''
            }
            maxLength={columns && columns.maxLength ? columns.maxLength : 100}
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponent
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onInputChange(e, columns.key, name)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300, fontWeight: 'bold' }}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            showTime={{ format: 'HH:mm' }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? [
                    moment(formObject[name].datasource[columns.key]['startDate'], dateFormat),
                    moment(formObject[name].datasource[columns.key]['endDate'], dateFormat)
                  ]
                : undefined
            }
            format="YYYY-MM-DD HH:mm"
            onChange={(e) => this.onChange(e, columns.key, name)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : ''
            }
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
    }
  }

  renderHeaderSwitch(formObject, columns, name) {
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            maxLength={columns && columns.maxLength ? columns.maxLength : 100}
            onChange={(e) => this.onHeaderInputChange(e.target.value, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponent
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onHeaderInputChange(e, columns.key, name)}
            handleKeyPress={this.handleKeyPress}
          />
        );

      default:
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
    }
  }

  render() {
    let { getFieldDecorator } = this.props.form;
    let { formObject } = this.props;
    return (
      <div style={{ marginTop: '30px', marginBottom: '30px', color: 'black', fontWeight: 'bold' }}>
        <Form layout="inline" className="batchUpdateForm1">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              boxSizing: 'border-box'
            }}
          >
            {formObject && formObject['equipmentBatch'] && formObject['equipmentBatch'].header
              ? formObject['equipmentBatch'].header.map((header, index) => {
                  return (
                    <div key={index} style={{ paddingRight: '2em' }}>
                      <Form.Item
                        required={false}
                        label={
                          <span style={{ marginLeft: header.marginLeft }}>
                            {header.displayName}
                          </span>
                        }
                      >
                        {getFieldDecorator(
                          header.key,
                          {}
                        )(
                          <div>{this.renderHeaderSwitch(formObject, header, 'equipmentBatch')}</div>
                        )}
                      </Form.Item>
                    </div>
                  );
                })
              : null}
          </div>

          <Row>
            <Button
              type="primary"
              className="saveButton"
              style={{ margin: '10px 0' }}
              onClick={this.addDownTime}
            >
              Add DownTime
            </Button>
          </Row>
          <Collapse bordered={false} className="subSectionCollapse">
            {formObject &&
              Object.keys(formObject).map((name) => {
                if (name !== 'equipmentBatch') {
                  let downTimeValue = parseInt(name) + 1;
                  return (
                    <Panel header={<span>DownTime {downTimeValue}</span>} key={name}>
                      <div
                        style={{
                          margin: '20px 0',
                          padding: '40px',
                          border: '2px solid white'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            boxSizing: 'border-box'
                          }}
                        >
                          {formObject && formObject[name] && formObject[name].columns
                            ? formObject[name].columns.map((columns, datakey) => {
                                return (
                                  <div key={datakey} style={{ paddingRight: '2em' }}>
                                    <Form.Item
                                      required={false}
                                      label={
                                        <span style={{ marginLeft: columns.marginLeft }}>
                                          {columns.displayName}
                                        </span>
                                      }
                                    >
                                      <div>{this.renderSwitch(formObject, columns, name)}</div>
                                    </Form.Item>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                        <Row>
                          <Button
                            type="dashed"
                            onClick={() => this.onCause(name)}
                            style={{
                              margin: '10px 0',
                              background: '#006d75',
                              color: 'white',
                              fontWeight: 'bold',
                              border: '1px solid white'
                            }}
                          >
                            <PlusOutlined />
                            Add Cause
                          </Button>
                        </Row>

                        <Row style={{ color: '#006d75' }}>
                          {formObject &&
                            formObject[name] &&
                            formObject[name].datasource &&
                            formObject[name].datasource.Cause &&
                            Array.isArray(formObject[name].datasource.Cause) &&
                            formObject[name].datasource.Cause.map((CausesObject, key) => {
                              return (
                                <Row key={key}>
                                  <div>Cause {key}:</div>
                                  <Input.TextArea
                                    // value={remarks && remarks !== null && remarks !== "null" ? remarks : ""}
                                    onChange={(e) =>
                                      this.onChangeRemarks(e.target.value, name, key)
                                    }
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                    style={{ width: '600px' }}
                                    maxLength="100"
                                    value={CausesObject}
                                  />
                                </Row>
                              );
                            })}
                        </Row>
                      </div>
                    </Panel>
                  );
                }
                return {};
              })}
          </Collapse>
        </Form>
      </div>
    );
  }
}

export default DownTimeBatchMain;
