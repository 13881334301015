import localStorage from '../../../utils/localStorage';

export async function getDatasourceData() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/logbook/get?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  if (data && Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function _getRolesBySite() {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/role/get?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getAdhocTableList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');

  let data = await fetch(`/api/logbook/tableslist?SiteId=${siteId} `, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function getRecurrencePatternData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;

  const siteId = localStorage.get('currentSite');
  let data = await fetch(`/api/logbook/dropdown?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      tableName: 'master."Master_Recurrence"',
      columnName: 'Type'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return [];
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function _deleteSubmitBatch(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/logbook/submit  `, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function deleteFileUpload(fileName) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/master/deletefile `, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      fileName
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function getFormTypeData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');

  let data = await fetch(`/api/logbook/dropdown?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      tableName: 'master."Master_FormTypes"',
      columnName: 'Type'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getLocationData() {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/asset/get?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getELogTypeData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');

  let data = await fetch(`/api/logbook/dropdown?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      tableName: 'master."Master_BookTypes"',
      columnName: 'Type',
      conditionList: [{ key: 'Type', value: 'AdHoc' }],
      condition: 'Type',
      conditionvalue: 'AdHoc'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getTableMasters() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');

  let data = await fetch(`/api/logbook/tableslist?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}
