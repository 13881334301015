import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Spin, Select, DatePicker, Drawer, Tree, Modal, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Panel from './Panel';
import NewDashboard from './NewDashboard';
import localStorage from '../../../utils/localStorage';
import station from '../Utils/Images/Stations_V1.png';
import DayMonthYearSelection from '../DashboardComponents/Widgets/Selection/DayMonthYearSelection';
import HierarchyDropdownSelection from './Widgets/Selection/HierarchyDropdownSelection';
import { makeSelectThemeing, makeSelectTheme } from '../../../selectors/theme';
import { getMenuCollapse } from '../../../selectors/layout';
import { getNavigationMode } from '../../../selectors/theme';
import { translation } from '../Utils/Adapter/translationAdapter.js';

import { getTranslationCache } from '../../../selectors/language';
import { getDashboardByName } from './DashboardCalls';
import { DashboardDiv, DashboardHeaderDiv } from './CSS/styles.js';
import HierarchyModal from './Widgets/Selection/HierarchyModal';

import './CSS/Dashboard.css';
import { DownOutlined, UpSquareOutlined } from '@ant-design/icons';
import history from '../../../commons/history';

const { MonthPicker, WeekPicker, RangePicker } = DatePicker;
const { Option } = Select;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardName: '',
      visible: false,
      panels: [],
      graphDataForEdit: '',
      translationCache: props.translationCache || {},
      showDateRange: false,
      selectionDateRange: {
        startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
        endDate: moment().format('YYYY-MM-DD HH:mm'),
        timeBucket: '1m'
      },
      deviceList: [],
      selectionDate: moment().subtract(4, 'day').format('YYYY-MM-DD HH:mm:ss'),
      selectionMonth: moment().format('YYYY-MM'),
      selectionWeek: moment().startOf('week'),
      dayMonthYear: {
        startDate: moment().subtract('day').format('YYYY-MM-DD 00:00:00'),
        endDate: moment().format('YYYY-MM-DD 23:59:59'),
        timeBucket: '1h',
        selectionType: 'day'
      },
      shift: 'Morning Shift',
      loading: true,
      selectedThemeing: this.props.selectedThemeing,
      selectedTheme: this.props.selectedTheme,
      dropdownSelection: {},
      treeList: [],
      AssetName: '',
      AssetCode: '',
      DeviceCode: undefined,
      isModalVisible: false
    };
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.name) {
      let dashboardName = this.props.match.params.name;
      document.title = 'Dashboard-' + dashboardName;
      this.selectDashboard(dashboardName);
    }
  }

  componentDidUpdate(preprops) {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.name &&
      (this.props.match.params.name !== preprops.match.params.name ||
        this.props.menuCollapse !== preprops.menuCollapse ||
        preprops.navigationMode !== this.props.navigationMode)
    ) {
      if (document.getElementsByClassName('layout')[0]) {
        if (document.getElementsByClassName('ant-layout-sider-trigger')[0]) {
          this.divWidth = parseInt(
            document.getElementsByClassName('ant-layout-sider-trigger')[0].style.width,
            10
          );
        }
      }
      let dashboardName = this.props.match.params.name;
      document.title = 'Dashboard - ' + this.props.match.params.name;
      this.selectDashboard(dashboardName);
      this.setState({
        selectionDateRange: {
          startDate: moment().subtract(2, 'day').add(6, 'hour').format('YYYY-MM-DD 00:00:00'),
          endDate: moment().subtract(1, 'day').format('YYYY-MM-DD 23:59:59'),
          timeBucket: '1m'
        },
        selectionDate: moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
        selectionDateLive: moment().format('YYYY-MM-DD HH:mm:ss'),
        selectionMonth: moment().format('YYYY-MM'),
        selectionWeek: moment().startOf('week'),
        dayMonthYear: {
          startDate: moment().subtract(1, 'day').format('YYYY-MM-DD 00:00:00'),
          endDate: moment().subtract(1, 'day').format('YYYY-MM-DD 23:59:59'),
          timeBucket: '1h',
          selectionType: 'day'
        }
      });
    }
  }

  _getDevicesList = (payload) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let payloadData = payload && payload.payload ? payload.payload : '';
    const ObjectHeader = {
      method: payload.method,
      url: `${payload.url}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payloadData || {}
    };
    return axios(ObjectHeader)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  disabledDate = (current) => {
    return current > moment().startOf('day');
  };

  onChangeDatePicker = (value, dateString) => {
    this.setState({
      selectionDate: moment(dateString).format('YYYY-MM-DD 00:00:00')
    });
  };

  onChangeMonthPicker = (value, dateString) => {
    this.setState({
      selectionMonth: dateString
    });
  };

  onChangeWeekPicker = (value) => {
    this.setState({
      selectionWeek: value
    });
  };

  selectionpayload = (payload) => {
    this.setState({
      dayMonthYear: payload
    });
  };

  onDateRangePickerChange = (currentDate) => {
    let timeBucket = '';
    let selectionDateRange = {};
    if (currentDate && currentDate[0] && currentDate[1]) {
      let dayCount = currentDate[1].diff(currentDate[0], 'days');
      let hourCount = currentDate[1].diff(currentDate[0], 'hours');

      if (dayCount <= 2) {
        if (hourCount <= 1) {
          timeBucket = '1m';
        } else if (hourCount > 1 && hourCount <= 2) {
          timeBucket = '5m';
        } else if (hourCount >= 3 && hourCount <= 23) {
          timeBucket = '15m';
        } else {
          timeBucket = '1h';
        }
      } else if (dayCount > 2 && dayCount <= 60) {
        timeBucket = '1d';
      } else if (dayCount > 60) {
        timeBucket = '1week';
      } else {
        timeBucket = '';
      }

      selectionDateRange = {
        startDate: currentDate[0].format('YYYY-MM-DD HH:mm:ss'),
        endDate: currentDate[1].format('YYYY-MM-DD HH:mm:ss'),
        timeBucket
      };
    } else {
      selectionDateRange = {
        startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        timeBucket: '1h'
      };
    }
    this.setState({
      selectionDateRange
    });
  };

  handleDropdownChange = (value) => {
    let { dropdownSelection } = this.state;
    dropdownSelection = {
      ...dropdownSelection,
      ...value
    };
    this.setState({
      dropdownSelection
    });
  };

  hierarchyDropdownChange = (value, deviceList) => {
    let { dropdownSelection } = this.state;
    let selectedValue = value && Array.isArray(value) && value.length > 0 ? value[0] : undefined;
    if (selectedValue) {
      this.setState({
        isModalVisible: false,
        dropdownSelection: { ...dropdownSelection, DeviceCode: selectedValue },
        deviceList
      });
    }
  };

  onTreeSelect = async (keys, nodeobject) => {
    let { dashboards } = this.state;
    let payload = {
      ...(dashboards && dashboards.json && dashboards.json[0] && dashboards.json[0].apiPayload
        ? dashboards.json[0].apiPayload
        : {}),
      payload: {
        ...(dashboards &&
        dashboards.json &&
        dashboards.json[0] &&
        dashboards.json[0].apiPayload &&
        dashboards.json[0].apiPayload.payload
          ? dashboards.json[0].apiPayload.payload
          : {}),
        AssetCode: keys && keys[0] ? keys[0] : ''
      }
    };
    let deviceList = await this._getDevicesList(payload, 'dynamic');
    this.setState({
      toggleVisible: false,
      AssetCode: keys && keys[0] ? keys[0] : '',
      AssetName: nodeobject && nodeobject.node ? nodeobject.node.title : '',
      deviceList,
      DeviceCode: deviceList && deviceList[0] && deviceList[0].Value ? deviceList[0].Value : ''
    });
  };

  render() {
    let colorTheme = this.state.selectedTheme;
    const styles = {
      background: colorTheme
    };
    const {
      selectionDate,
      selectionDateLive,
      selectionMonth,
      selectionWeek,
      selectionDateRange,
      panels,
      deviceList,
      loading,
      DeviceCode,
      shift,
      dayMonthYear,
      dropdownSelection,
      dashboardName,
      toggleVisible,
      treeList,
      AssetCode,
      AssetName,
      isModalVisible
    } = this.state;
    let { translationCache } = this.props;
    let filteredDevice =
      dropdownSelection && dropdownSelection.DeviceCode
        ? deviceList.filter((device) => device.AliasCode === dropdownSelection.DeviceCode)
        : '';
    if (this.state.dashboardName === 'newdashboard') {
      return (
        <NewDashboard
          DeviceData={this.props.DeviceData}
          AssetData={this.props.AssetData}
          panels={this.state.panels}
        />
      );
    } else {
      return (
        <DashboardDiv className={'testDiv'} id="testdiv" style={styles}>
          <div
            style={{
              color: '#fff',
              display: 'flex',
              justifyContent: 'space-evenly',
              fontWeight: 'bold',
              'font-size': '18px'
            }}
          >
            {AssetName.length !== 0 ? `Station Name : ${AssetName}` : null}
          </div>
          {panels &&
          panels[0] &&
          panels[0].dashboardType === 'hierarchy' &&
          panels[0].hierarchyViewType === 'Tree' ? (
            <span
              style={{
                height: '40px',
                display: 'flex',
                justifyContent: 'flex-start',
                flexFlow: 'row wrap',
                marginTop: '10px'
              }}
            >
              <Input
                value={
                  filteredDevice &&
                  Array.isArray(filteredDevice) &&
                  filteredDevice[0] &&
                  filteredDevice[0].Name
                    ? `${filteredDevice[0].DeviceCode}${
                        filteredDevice[0].Name
                          ? `-${translation(filteredDevice[0].Name, translationCache)}`
                          : ''
                      }  `
                    : ''
                }
                size="small"
                style={{ width: 300, opacity: 1, height: '32px' }}
              />
              <Button
                type="primary"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  this.setState({ isModalVisible: !isModalVisible });
                }}
              >
                <UpSquareOutlined />
              </Button>
              <Modal
                title={translation('Select Hierarchy', translationCache)}
                className="hierarchyModal"
                visible={this.state.isModalVisible}
                bodyStyle={{ overflowY: 'scroll', height: '900px' }}
                footer={null}
                onCancel={() => {
                  this.setState({
                    isModalVisible: false
                  });
                }}
              >
                <HierarchyModal
                  value={
                    dropdownSelection && dropdownSelection.DeviceCode
                      ? dropdownSelection.DeviceCode
                      : DeviceCode
                  }
                  translationCache={this.props.translationCache}
                  language={this.props.language}
                  handleDropdownChange={this.hierarchyDropdownChange}
                  deviceList={this.state.deviceList}
                />
              </Modal>
              {this.state.dashboardName && this.state.dashboardName === 'Vibration Sensor' ? (
                <Button
                  type="primary"
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    history.push('/rubus/LogBook/Vibration Sensor Field Entry');
                  }}
                >
                  {translation('Redirect to logbook', translationCache)}
                </Button>
              ) : null}

              {this.state.dashboardName && this.state.dashboardName === 'Vibration Sensor' ? (
                <span>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={() => {
                      history.push({
                        pathname: `/rubus/FFT`,
                        state:
                          dropdownSelection && dropdownSelection.DeviceCode
                            ? dropdownSelection.DeviceCode
                            : DeviceCode
                      });
                    }}
                  >
                    {translation('FFT', translationCache)}
                  </Button>
                </span>
              ) : null}
            </span>
          ) : null}
          {panels && panels[0] && panels[0]['treeExists'] === 'true' ? (
            <div className="drawerToggle">
              <div
                onClick={() => {
                  this.setState({ toggleVisible: true });
                }}
              >
                <img
                  src={station}
                  alt="logo"
                  height={'50'}
                  width={'50'}
                  style={{ marginTop: '8px', width: '200%' }}
                />
              </div>
              <Drawer
                className="drawerBlackTheme"
                title="Basic Drawer"
                placement={'left'}
                closable={false}
                onClose={() => this.setState({ toggleVisible: false })}
                open={toggleVisible}
                key={'left'}
              >
                <Tree
                  className="treeBlackTheme"
                  showLine
                  value={AssetCode}
                  defaultExpandAll={true}
                  switcherIcon={<DownOutlined />}
                  onSelect={(selectedValue, nodeObject) =>
                    this.onTreeSelect(selectedValue, nodeObject)
                  }
                  treeData={treeList && Array.isArray(treeList) ? treeList : []}
                />
              </Drawer>
            </div>
          ) : null}
          {(panels && panels[0] && panels[0].dashboardType === 'dynamic') ||
          (panels && panels[0] && panels[0].dashboardType === 'multi') ||
          (panels &&
            panels[0] &&
            panels[0].dashboardType === 'hierarchy' &&
            panels[0].hierarchyViewType === 'Select') ||
          (panels && panels[0] && panels[0]['dateExists'] === 'true') ||
          (panels && panels[0] && panels[0]['monthExists'] === 'true') ||
          (panels && panels[0] && panels[0]['weekExists'] === 'true') ||
          (panels && panels[0] && panels[0]['dayMonthYearExists'] === 'true') ||
          (panels && panels[0] && panels[0]['dateRangeExists'] === 'true') ? (
            <DashboardHeaderDiv>
              {panels && panels[0] && panels[0].dashboardType === 'dynamic' ? (
                <Select
                  style={{ width: 200, marginTop: '10px', marginRight: '10px' }}
                  value={DeviceCode}
                  placeholder="Select Equipment"
                  onChange={(e) => {
                    this.setState({ DeviceCode: e });
                  }}
                >
                  {deviceList &&
                    Array.isArray(deviceList) &&
                    deviceList.map((device, index) => {
                      return (
                        <Option key={index} value={device.Value}>
                          {device.Key}
                        </Option>
                      );
                    })}
                </Select>
              ) : null}
              {panels &&
              panels[0] &&
              panels[0].dashboardType === 'hierarchy' &&
              panels[0].hierarchyViewType === 'Select' ? (
                <div>
                  <HierarchyDropdownSelection handleDropdownChange={this.hierarchyDropdownChange} />
                </div>
              ) : null}

              {panels && panels[0] && panels[0]['dayMonthYearExists'] === 'true' ? (
                <DayMonthYearSelection
                  selectionpayload={this.selectionpayload}
                  dashboardName={dashboardName}
                />
              ) : null}

              {panels && panels[0] && panels[0]['dateExists'] === 'true' ? (
                <DatePicker
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  format="YYYY-MM-DD"
                  onChange={this.onChangeDatePicker}
                  value={moment(this.state.selectionDate)}
                  disabledDate={this.disabledDate}
                  allowClear={false}
                />
              ) : null}

              {panels && panels[0] && panels[0]['dateRangeExists'] === 'true' ? (
                <RangePicker
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  placeholder={['Start Date', 'End Date']}
                  value={[
                    moment(this.state.selectionDateRange.startDate, 'YYYY-MM-DD HH:mm'),
                    moment(this.state.selectionDateRange.endDate, 'YYYY-MM-DD HH:mm')
                  ]}
                  disabledDate={this.disabledDate}
                  onChange={this.onDateRangePickerChange}
                />
              ) : null}

              {panels && panels[0] && panels[0]['monthExists'] === 'true' ? (
                <MonthPicker
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  disabledDate={this.disabledDate}
                  placeholder="Select month"
                  onChange={this.onChangeMonthPicker}
                  format="YYYY-MM"
                  value={moment(this.state.selectionMonth, 'YYYY-MM')}
                />
              ) : null}

              {panels && panels[0] && panels[0]['weekExists'] === 'true' ? (
                <WeekPicker
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  disabledDate={this.disabledDate}
                  placeholder="Select Week"
                  onChange={this.onChangeWeekPicker}
                  value={this.state.selectionWeek}
                />
              ) : null}

              {panels && panels[0] && panels[0]['shiftExists'] === 'true' ? (
                <Select
                  onChange={(e) => {
                    this.setState({ shift: e });
                  }}
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  value={this.state.shift}
                >
                  <Option value="Morning Shift">Morning Shift</Option>
                  <Option value="Night Shift">Night Shift</Option>
                </Select>
              ) : null}
            </DashboardHeaderDiv>
          ) : null}

          <div>
            {panels && panels.length > 0 && loading === false ? (
              <Panel
                dashboardName={this.state.dashboardName}
                panels={this.state.panels}
                drilldownRedirect={this.drilldownRedirect}
                editGraph={this.editGraph}
                DeviceCode={DeviceCode}
                AssetCode={AssetCode}
                selectionDate={selectionDate}
                selectionDateLive={selectionDateLive}
                selectionMonth={selectionMonth}
                selectionWeek={selectionWeek}
                selectionDateRange={selectionDateRange}
                dayMonthYear={dayMonthYear}
                shift={shift}
                divWidth={this.divWidth}
                navigationMode={this.props.navigationMode}
                dropdownSelection={dropdownSelection}
              />
            ) : loading === true ? (
              <div
                style={{
                  textAlign: 'center',
                  color: 'white'
                }}
              >
                <h2 style={{ color: 'white' }}>
                  <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                </h2>
              </div>
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  color: 'white'
                }}
              >
                <h2 style={{ color: 'white' }}>No Panels Configured</h2>
              </div>
            )}
          </div>
        </DashboardDiv>
      );
    }
  }

  getFormattedTreeData = (treeData) => {
    let data =
      treeData &&
      Array.isArray(treeData) &&
      treeData.map((treeObject) => {
        if (
          treeObject &&
          treeObject.children &&
          Array.isArray(treeObject.children) &&
          treeObject.children.length > 0
        ) {
          let children = this.getFormattedTreeData(treeObject.children);
          return {
            title: treeObject.Name,
            key: treeObject.Code,
            children
          };
        } else {
          return {
            title: treeObject.Name,
            key: treeObject.Code
          };
        }
      });
    return data;
  };

  selectDashboard(dashboardName) {
    if (dashboardName === 'newdashboard') {
      this.setState({
        panels: [],
        dashboardName,
        selectionDateRange: {
          startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
          endDate: moment().format('YYYY-MM-DD HH:mm'),
          timeBucket: '1m'
        },
        selectionDate: moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
        selectionDateLive: moment().format('YYYY-MM-DD HH:mm:ss'),
        selectionMonth: moment().format('YYYY-MM'),
        selectionWeek: moment().startOf('week')
      });
    } else {
      this.setState({
        panels: [],
        dashboardName,
        loading: true,
        selectionDateRange: {
          startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
          endDate: moment().format('YYYY-MM-DD HH:mm'),
          timeBucket: '1m'
        },
        selectionDate: moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
        selectionDateLive: moment().format('YYYY-MM-DD HH:mm:ss'),
        selectionMonth: moment().format('YYYY-MM'),
        selectionWeek: moment().startOf('week')
      });
      this.getDashboard(dashboardName);
    }
  }

  getDashboard = async (e) => {
    let deviceList = [];
    let treeList = [];
    let AssetCode = '';
    let AssetName = '';
    let DeviceCode = '';
    let dashboardObject = await getDashboardByName(e);
    let dashboards = dashboardObject && dashboardObject[0] ? dashboardObject[0] : {};
    if (dashboards) {
      if (
        dashboards.json &&
        dashboards.json[0] &&
        dashboards.json[0].dashboardType &&
        dashboards.json[0].treeExists === 'true'
      ) {
        if (
          dashboards.json[0].treePayload &&
          dashboards.json[0].treePayload.method &&
          dashboards.json[0].treePayload.url
        ) {
          let treeListData = await this._getDevicesList(dashboards.json[0].treePayload, 'tree');
          treeList = await this.getFormattedTreeData(treeListData);

          let defaultData = await this.ChildrenNames(treeList, []);
          AssetCode = defaultData && defaultData[0] && defaultData[0].key ? defaultData[0].key : '';
          AssetName =
            defaultData && defaultData[0] && defaultData[0].title ? defaultData[0].title : '';
        } else {
          treeList = [];
        }
      }

      if (
        dashboards.json &&
        dashboards.json[0] &&
        dashboards.json[0].apiPayload &&
        dashboards.json[0].apiPayload.method &&
        dashboards.json[0].apiPayload.url
      ) {
        if (
          dashboards.json[0].treeExists &&
          dashboards.json[0].treeExists === 'true' &&
          treeList &&
          treeList[0] &&
          treeList[0].key
        ) {
          let payload = {
            ...dashboards.json[0].apiPayload,
            payload: {
              ...dashboards.json[0].apiPayload.payload,
              AssetCode: AssetCode
            }
          };
          deviceList = await this._getDevicesList(payload, 'dynamic');
          DeviceCode =
            deviceList && deviceList[0] && deviceList[0].Value ? deviceList[0].Value : '';
        } else {
          deviceList = await this._getDevicesList(dashboards.json[0].apiPayload, 'dynamic');
          DeviceCode =
            deviceList && deviceList[0] && deviceList[0].Value ? deviceList[0].Value : '';
        }
      }

      if (
        dashboards.json &&
        dashboards.json[0] &&
        dashboards.json[0].dashboardType &&
        dashboards.json[0].dashboardType === 'hierarchy'
      ) {
        const userData = JSON.parse(localStorage.get('modules'));
        if (userData && userData.hierarchy) {
          let final = userData.hierarchy.map((userHierarchy) => {
            return this.renameProperty(userHierarchy, 'label', 'title', 'value', 'key');
          });
          let deviceCodeList = await this.ChildrenNames(final, []);
          if (deviceCodeList && deviceCodeList[0] && deviceCodeList[0].key) {
            DeviceCode = deviceCodeList[0].key;
          }
        }
        let payload = {
          url: '/api/device/get',
          method: 'GET'
        };
        deviceList = await this._getDevicesList(payload, 'hierarchy');
        // DeviceCode =
        //   deviceList && deviceList[0] && deviceList[0].AliasCode ? deviceList[0].AliasCode : '';
        this.hierarchyDropdownChange([DeviceCode], deviceList);
      }

      // let dashboardTranslation = await panelTranslation(dashboards, this.props.translationCache);
      this.setState({
        panels: dashboards.json,
        dashboardName: dashboards.Name,
        loading: false,
        deviceList,
        treeList,
        dashboards,
        DeviceCode,
        AssetCode,
        AssetName
      });
    } else {
      this.setState({
        loading: false
      });
    }
  };

  renameProperty = (obj, oldName1, newName1, oldName2, newName2) => {
    if (obj && obj.children) {
      let children = obj.children.map((objChildren) => {
        return this.renameProperty(objChildren, oldName1, newName1, oldName2, newName2);
      });
      obj['children'] = children;
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    } else {
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    }
  };

  ChildrenNames = (obj, data) => {
    obj.forEach((subObj) => {
      if (
        Object.prototype.hasOwnProperty.call(subObj, 'children') &&
        // subObj.hasOwnProperty('children') &&
        subObj.children instanceof Array &&
        subObj.children.length > 0
      ) {
        this.ChildrenNames(subObj.children, data);
      } else {
        if (Object.prototype.hasOwnProperty.call(subObj, 'key')) {
          // if (subObj.hasOwnProperty('key')) {
          data.push({ key: subObj.key, title: subObj.title });
        }
      }
    });

    return data;
  };

  editGraph = (graphData) => {
    let {
      DeviceCode,
      selectionDate,
      selectionDateLive,
      selectionMonth,
      selectionWeek,
      selectionDateRange,
      shift,
      dayMonthYear
    } = this.state;

    localStorage.set('graphData', JSON.stringify(graphData));
    this.props.history.push({
      pathname: `/rubus/dashboard/editpanel/${graphData.dashboardName}/${graphData.id}?kiosk=false`,
      selection: {
        DeviceCode,
        selectionDate,
        selectionDateLive,
        selectionMonth,
        selectionWeek,
        selectionDateRange,
        dayMonthYear,
        shift
      }
    });
  };

  drilldownRedirect = (url) => {
    this.props.history.push(`/rubus/dashboard/${url}`);
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  selectedTheme: makeSelectTheme(),
  menuCollapse: getMenuCollapse(),
  navigationMode: getNavigationMode()
});

export default connect(mapStateToProps)(Dashboard);
