import React, { Fragment } from 'react';
import { Table, Button, message, Input, Checkbox, DatePicker, Form, Drawer } from 'antd';
import { EditOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import constants from './constants';
import history from '../../commons/history';
import { translation } from '../../commons/translation';
import { getTranslationCache } from '../../selectors/language';
import { getRuleExecutionInfo, saveFeedback, getfeedBackInfo, updateFeedBack } from './Apicalls';
import { StyledDashboard, StyledTable, lightTheme } from './style';
import 'antd/lib/date-picker/style/css';
import { ClusterOutlined } from '@ant-design/icons';
import { getCurrentTimezone } from '../../selectors/layout';
const { RangePicker } = DatePicker;

class RulesFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      openModel: false,
      feedBackInfo: {},
      currentPage: 1,
      editFlag: false,
      selectedDate: {
        startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD 00:00'),
        endDate: moment().format('YYYY-MM-DD 23:59')
      }
    };
    this.hRef = React.createRef();
  }

  componentDidMount() {
    this.getRuleHistory([moment().format('YYYY-MM-DD 00:00'), moment().format('YYYY-MM-DD 23:59')]);
  }
  getRuleHistory = async (date) => {
    try {
      let queryString = this.props.location.search;
      let ruleId = queryString.split('=')[1];
      if (ruleId) {
        console.log('ruleId in ruleengine', ruleId);
        let ruleStatuInfo = await getRuleExecutionInfo(ruleId, date);
        this.setState({ tableData: ruleStatuInfo.data });
      }
    } catch (error) {
      message.error(`${error.response.data.message}`);
    }
  };

  handleFeedbackEvents = (e, type) => {
    let { feedBackInfo } = this.state;
    if (type === 'actions') {
      const { translationCache } = this.props;
      let translationAction;

      let keys = Object.keys(translationCache);

      let arrConverter = e.map((action) => {
        let data = keys.filter((labels) => {
          return translationCache[labels] === action;
        });
        if (data[0] === undefined) {
          return [action];
        } else {
          return data;
        }
      });
      translationAction = arrConverter.map((indexedArry) => {
        return indexedArry[0];
      });
      feedBackInfo['actions'] = translationAction;
    } else {
      feedBackInfo['comments'] = e.target.value;
    }
    this.setState({ feedBackInfo });
  };

  handleModelCancel = () => {
    this.hRef.current.resetFields();
    this.setState({ openModel: false });
  };

  handleFeedbacksave = async () => {
    const { feedBackInfo, editFlag } = this.state;
    try {
      if (!editFlag) {
        await saveFeedback(feedBackInfo);
        this.setState({ openModel: false });
      } else {
        await updateFeedBack(feedBackInfo);
        this.setState({ openModel: false, feedBackInfo: {} });
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  handlePagination = async (pageNumber) => {
    this.setState({ currentPage: pageNumber.current });
  };

  // onChangeDate = (date, dateString) => {
  //   this.getRuleHistory(dateString)
  //   this.setState({
  //     selectedDate:date
  //   })
  // }
  onChange = (value) => {
    this.getRuleHistory(value);
    this.setState({
      selectedDate: {
        startDate: moment(value[0]).format('YYYY-MM-DD HH:mm'),
        endDate: moment(value[1]).format('YYYY-MM-DD HH:mm')
      }
    });
  };

  onOk = () => {};

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  render() {
    const { selectedRecord, feedBackInfo, tableData, openModel, editFlag, selectedDate } =
      this.state;
    const { translationCache } = this.props;
    let translationAction;
    if (selectedRecord) {
      let values = Array.isArray(selectedRecord.CommunicationType)
        ? selectedRecord.CommunicationType
        : [selectedRecord.CommunicationType];
      translationAction = values.map((action) => {
        let data = translationCache[action] !== undefined ? translationCache[action] : action;
        return data;
      });
    }

    const columns = [
      {
        title: translation(constants.sno, translationCache),
        dataIndex: 'RuleId',
        render: (text, record, index) => {
          const { currentPage } = this.state;
          return (
            <span>
              {currentPage === 1 ? currentPage * (index + 1) : (currentPage - 1) * 10 + (index + 1)}
            </span>
          );
        }
      },
      {
        title: translation(constants.tagName, translationCache),
        dataIndex: 'ParameterCode',
        render: (text) => {
          return <span>{text}</span>;
        }
      },
      {
        title: translation(constants.tagValue, translationCache),
        dataIndex: 'ParameterValue',
        render: (text) => {
          return <span>{text}</span>;
        }
      },
      {
        title: translation(constants.communicationType, translationCache),
        dataIndex: 'CommunicationType',
        key: 'CommunicationType'
      },
      {
        title: translation(constants.exeTime, translationCache),
        dataIndex: 'CommunicationTime',
        key: 'CommunicationTime',
        render: (text) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
      },

      {
        title: translation(constants.fdBack, translationCache),
        key: 'Feedback',
        render: (text, record) => (
          <span>
            <EditOutlined
              onClick={async () => {
                const { feedBackInfo } = this.state;
                feedBackInfo.timeStamp = record.CommunicationTime;
                feedBackInfo.TagId = record.TagId;
                feedBackInfo.Id = record.Id;
                feedBackInfo.actions = [];
                feedBackInfo['comments'] = record.comments ? record.comments : '';
                let editFlag = false;
                try {
                  let feedBackData = await getfeedBackInfo(record.Id);
                  if (feedBackData.data.length > 0) {
                    feedBackInfo.actions = feedBackData.data[0].Action;
                    feedBackInfo['comments'] = feedBackData.data[0].Comments;
                    editFlag = true;
                  }
                } catch (error) {
                  message.warn("Can't Fetch Feedback Info");
                }
                if (this.hRef.current) {
                  this.hRef.current.setFieldsValue(feedBackInfo);
                }
                this.setState({
                  openModel: true,
                  selectedRecord: record,
                  feedBackInfo,
                  editFlag
                });
              }}
            />
          </span>
        )
      },
      {
        title: translation(constants.rootCause, translationCache),
        key: 'RootCause',
        render: (text, record) => (
          <span
            style={{ fontSize: '19px' }}
            onClick={async () => {
              const { feedBackInfo } = this.state;
              feedBackInfo.timeStamp = record.CommunicationTime;
              feedBackInfo.TagId = record.TagId;
              feedBackInfo.Id = record.Id;
              feedBackInfo.actions = [];
              feedBackInfo['comments'] = '';
              // let editFlag = false;
              this.props.history.push(
                `/rubus/Rule/RootCause/?Device=${record.Id}&Parameter=${record.ParameterCode}&EndTime=${record.CommunicationTime}`
              );
              try {
                let feedBackData = await getfeedBackInfo(record.RuleId);
                if (feedBackData.data.length > 0) {
                  feedBackInfo.actions = feedBackData.data[0].Action;
                  feedBackInfo['comments'] = feedBackData.data[0].Comments;
                  // editFlag = true;
                }
              } catch (error) {
                message.warn("Can't Fetch Feedback Info");
              }

              // this.setState({
              //   openModel: true,
              //   selectedRecord: record,
              //   feedBackInfo,
              //   editFlag,
              // });
            }}
          >
            <ClusterOutlined />
          </span>
        )
      }
    ];

    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 14 }
    };

    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <Fragment>
          <div>
            <Button
              type="primary"
              onClick={() => {
                history.goBack();
              }}
            >
              <CaretLeftOutlined />
              {translationCache && translationCache[`${constants.Go_Back}`]
                ? translationCache[`${constants.Go_Back}`]
                : `${constants.Go_Back}`}
            </Button>
            <div>
              {/* <DatePicker
                onChange={this.onChangeDate}
                style={{ width: "300px",float:"right" }}
                value={moment(this.state.selectedDate)}
                disabledDate={this.disabledDate}
                size="large" /> */}
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                style={{ width: '300px', float: 'right' }}
                format="YYYY-MM-DD HH:mm"
                value={[moment(selectedDate.startDate), moment(selectedDate.endDate)]}
                onChange={this.onChange}
                disabledDate={this.disabledDate}
                onOk={this.onOk}
              />
            </div>

            <StyledTable theme={lightTheme}>
              <Table
                style={{ marginTop: '3%' }}
                dataSource={tableData}
                columns={columns}
                onChange={this.handlePagination}
              />
            </StyledTable>

            <Drawer
              title="FeedBack"
              closable
              centered
              width={800}
              visible={openModel}
              footer={[
                <Button key="back" onClick={this.handleModelCancel}>
                  {translationCache &&
                  translationCache[`${constants.cancel}`] &&
                  translationCache[`${constants.cancel}`]
                    ? translationCache[`${constants.cancel}`]
                    : `${constants.cancel}`}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    this.handleFeedbacksave();
                  }}
                  style={{ fontWeight: 700, marginLeft: '5px' }}
                >
                  {editFlag
                    ? translationCache &&
                      translationCache[`${constants.update}`] &&
                      translationCache[`${constants.update}`]
                      ? translationCache[`${constants.update}`]
                      : `${constants.update}`
                    : translationCache &&
                      translationCache[`${constants.save}`] &&
                      translationCache[`${constants.save}`]
                    ? translationCache[`${constants.save}`]
                    : `${constants.save}`}
                </Button>
              ]}
              onClose={this.handleModelCancel}
            >
              <Form {...formItemLayout} ref={this.hRef} initialValues={feedBackInfo}>
                <Form.Item
                  label={
                    translationCache &&
                    translationCache[`${constants.timeStamp}`] &&
                    translationCache[`${constants.timeStamp}`]
                      ? translationCache[`${constants.timeStamp}`]
                      : `${constants.timeStamp}`
                  }
                >
                  <Input
                    readOnly
                    value={
                      feedBackInfo
                        ? moment
                            .utc(feedBackInfo.timeStamp, 'YYYY-MM-DD HH:mm:ss')
                            .tz(this.props.currentTimeZone)
                            .format('YYYY-MM-DD HH:mm:ss')
                        : null
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    translationCache &&
                    translationCache[`${constants.tagName}`] &&
                    translationCache[`${constants.tagName}`]
                      ? translationCache[`${constants.tagName}`]
                      : `${constants.tagName}`
                  }
                >
                  <Input readOnly value={feedBackInfo ? feedBackInfo.TagId : null} />
                </Form.Item>
                <Form.Item
                  label={
                    translationCache &&
                    translationCache[`${constants.action}`] &&
                    translationCache[`${constants.action}`]
                      ? translationCache[`${constants.action}`]
                      : `${constants.action}`
                  }
                >
                  <Checkbox.Group
                    options={translationAction}
                    value={
                      feedBackInfo !== undefined
                        ? Array.isArray(feedBackInfo.actions)
                          ? feedBackInfo.actions
                          : [feedBackInfo.actions]
                        : null
                    }
                    onChange={(e) => {
                      this.handleFeedbackEvents(e, 'actions');
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    translationCache &&
                    translationCache[`${constants.comment}`] &&
                    translationCache[`${constants.comment}`]
                      ? translationCache[`${constants.comment}`]
                      : `${constants.comment}`
                  }
                >
                  <Input.TextArea
                    value={feedBackInfo.comments}
                    onChange={(e) => this.handleFeedbackEvents(e)}
                  />
                </Form.Item>
              </Form>
            </Drawer>
          </div>
        </Fragment>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(RulesFeedback);
