import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Input, Button, Drawer, Select, Space, Table, message, Form } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

import localStorage from '../../../utils/localStorage';
import { StyledDashboard, DrawerFooter } from './styles';

import { getTranslationCache } from '../../../selectors/language';
import { constant } from './Constants';

import { StyledTable, lightTheme } from './style';
import './style.css';

const { Option } = Select;

class MasterConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      editObject: {},
      masterList: [],
      masterTableNames: [],
      translationCache: props.translationCache || [],
      unquieColumns: []
    };
    this.masterConfig = React.createRef();
    this.dynamicConfig = React.createRef();
  }

  componentDidMount = () => {
    this._getMasterList();
    this._getTableMasterList();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  _getTableMasterList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `/api/masterconfiguration/getTableList?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(Object)
      .then((response) => {
        this.setState({
          masterTableNames: response.data
        });
      })
      .catch(() => {});
  };

  _getMasterList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `/api/masterconfiguration/get?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(Object)
      .then((response) => {
        this.setState({
          masterList: response.data
        });
      })
      .catch(() => {});
  };

  addNew = () => {
    let { editObject } = this.state;
    const newObj = {
      title: '',
      key: '',
      dataIndex: '',
      widget: 'input',
      isMandatory: false,
      disableOnUpdate: false
    };
    editObject = {
      ...editObject,
      columns: [...(editObject && editObject.columns ? editObject.columns : []), newObj]
    };
    this.setState({
      editObject
    });
  };

  updateArray = (array, index, updateFn) => {
    return [...array.slice(0, index), updateFn(array[index]), ...array.slice(index + 1)];
  };

  handleFieldChange = (e, parameter, index) => {
    let { editObject } = this.state;
    let arrayUpdated = this.updateArray(editObject.columns, index, (item) => ({
      ...item,
      [parameter]: e
    }));
    editObject = {
      ...editObject,
      columns: arrayUpdated
    };
    this.setState({ editObject });
  };

  _curdOperation = (e, operation) => {
    let { editObject } = this.state;
    if (operation === 'create' || operation === 'update') {
      e.preventDefault();
      let columns = [];
      this.dynamicConfig.current
        .validateFields()
        .then((values) => {
          columns = values && values['columns'] ? values['columns'] : [];
        })
        .catch((error) => {
          console.log(error);
        });

      const siteId = localStorage.get('currentSite');
      this.masterConfig.current
        .validateFields()
        .then(async (values) => {
          let newObj = {
            MasterName: values.MasterName,
            TableName: values.TableName,
            JSON: {
              MasterName: values.MasterName,
              TableName: values.TableName,
              columns: columns,
              uniqueness: values.uniqueness,
              api: {
                read: {
                  url: values.readURL,
                  method: values.readMethod
                },
                create: {
                  url: values.createURL,
                  method: values.createMethod
                },
                update: {
                  url: values.updateURL,
                  method: values.updateMethod
                }
              }
            },
            SiteId: siteId
          };
          if (operation === 'create') {
            let saveResponse = await this._saveMasterData(newObj);
            if (saveResponse && saveResponse.message) {
              this.setState({
                visible: false
              });
              this._getMasterList();
              message.success(saveResponse.message);
            }
          } else {
            let updateResponse = await this._updateMasterData({ ...newObj, Id: editObject.Id });
            if (updateResponse && updateResponse.message) {
              this.setState({
                visible: false,
                editObject: {},
                dynamicEditObject: {}
              });
              this._getMasterList();
              message.success(updateResponse.message);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (operation === 'delete') {
      e.preventDefault();
      this.masterConfig.current
        .validateFields()
        .then(async () => {
          let deleteResponse = await this._deleteMasterData(editObject.Id);
          if (deleteResponse && deleteResponse.message) {
            this.setState({
              visible: false
            });
            this._getMasterList();
            message.success(deleteResponse.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  _saveMasterData = (data) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'POST',
      url: `/api/masterconfiguration/create?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(Object)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return { message: 'Error' };
      });
  };

  _updateMasterData = (data) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'PATCH',
      url: `/api/masterconfiguration/update?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(Object)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return { message: 'Error' };
      });
  };

  _deleteMasterData = (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'DELETE',
      url: `/api/masterconfiguration/delete?Id=${Id}&SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(Object)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return { message: 'Error' };
      });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onTableNameChange = (tableName) => {
    let { masterTableNames } = this.state;
    let columnsList =
      masterTableNames && masterTableNames[tableName] ? masterTableNames[tableName] : [];
    this.setState({
      columnsList,
      unquieColumns: []
    });
  };

  onColumnKeyChange = (value) => {
    let { unquieColumns } = this.state;
    unquieColumns = [...unquieColumns, value];
    this.setState({
      unquieColumns
    });
  };

  onTableClick = (edit) => {
    let editObject = edit.JSON ? edit.JSON : {};
    editObject = {
      ...editObject,
      Id: edit.Id,
      createURL:
        editObject && editObject.api && editObject.api.create && editObject.api.create.url
          ? editObject.api.create.url
          : '',
      createMethod:
        editObject && editObject.api && editObject.api.create && editObject.api.create.method
          ? editObject.api.create.method
          : '',
      readURL:
        editObject && editObject.api && editObject.api.read && editObject.api.read.url
          ? editObject.api.read.url
          : '',
      readMethod:
        editObject && editObject.api && editObject.api.read && editObject.api.read.method
          ? editObject.api.read.method
          : '',
      updateURL:
        editObject && editObject.api && editObject.api.update && editObject.api.update.url
          ? editObject.api.update.url
          : '',
      updateMethod:
        editObject && editObject.api && editObject.api.update && editObject.api.update.method
          ? editObject.api.update.method
          : ''
    };
    this.onTableNameChange(editObject.TableName);
    let unquieColumns = [];
    unquieColumns =
      editObject.columns &&
      editObject.columns.map((columnList) => {
        return columnList.key;
      });
    unquieColumns =
      unquieColumns &&
      unquieColumns.filter(function (val) {
        return editObject.uniqueness.indexOf(val) === -1;
      });
    if (this.masterConfig.current) {
      this.masterConfig.current.setFieldsValue(editObject);
      this.dynamicConfig.current.setFieldsValue({ columns: editObject.columns });
    }

    this.setState({
      editObject,
      unquieColumns,
      visible: true,
      dynamicEditObject: { columns: editObject.columns }
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
      editObject: {},
      dynamicEditObject: []
    });
    if (this.masterConfig.current || this.dynamicConfig.current) {
      this.masterConfig.current.resetFields();
      this.dynamicConfig.current.resetFields();
    }
  };

  onFinishFailed = () => {
    message.error(this.translation('please enter required fields'));
  };
  addNew = () => {
    if (this.masterConfig.current || this.dynamicConfig.current) {
      this.masterConfig.current.resetFields();
      this.dynamicConfig.current.resetFields();
    }
    this.setState({ visible: true, editObject: {}, dynamicEditObject: {} });
  };
  render() {
    let {
      visible,
      masterList,
      editObject,
      masterTableNames,
      columnsList,
      unquieColumns,
      dynamicEditObject
    } = this.state;
    let columns = [
      {
        title: this.translation(constant.Master_Name),
        dataIndex: 'MasterName',
        key: 'MasterName'
      },
      {
        title: this.translation(constant.Table_Name),
        dataIndex: 'TableName',
        key: 'TableName'
      },
      {
        title: this.translation('Action'),
        width: '600',
        render: (text, record) => (
          <>
            <Button
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => this.onTableClick(record)}
              size="middle"
            >
              {this.translation('Edit')}
            </Button>
            <Button style={{ marginRight: '5px' }} className="ant-btn" size="middle">
              <Link to={{ pathname: `/rubus/settingsPage/master/${record.MasterName}` }}>
                {this.translation('View')}
              </Link>
            </Button>
          </>
        )
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <Button
          type="primary"
          style={{ background: '#214972', marginBottom: '10px' }}
          onClick={() => this.addNew()}
        >
          <PlusOutlined /> {this.translation(constant.Add)}
        </Button>
        <StyledTable theme={lightTheme}>
          <Table className="masterTable" columns={columns} dataSource={masterList} />
        </StyledTable>
        <Drawer
          title={this.translation(constant.Configuration)}
          placement="right"
          closable
          width={800}
          onClose={this.onClose}
          visible={visible}
        >
          <Form
            layout="vertical"
            ref={this.masterConfig}
            name="dynamic_form_nest_item1"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            autoComplete="off"
            initialValues={editObject || {}}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              label={this.translation(constant.Master_Name)}
              name="MasterName"
              rules={[
                { required: true, message: this.translation('Please input your MasterName!!') }
              ]}
            >
              <Input
                style={{ width: '300px' }}
                placeholder={this.translation('Master Name')}
                disabled={editObject.MasterName ? true : false}
              />
            </Form.Item>
            <Form.Item
              label={this.translation(constant.Table_Name)}
              name="TableName"
              rules={[{ required: true, message: this.translation('Please select TableName!!') }]}
            >
              <Select
                style={{ width: 300 }}
                placeholder={this.translation('Table Name')}
                onChange={(e) => this.onTableNameChange(e)}
                disabled={editObject.TableName ? true : false}
              >
                {masterTableNames &&
                  Object.keys(masterTableNames)
                    .filter((fil) => {
                      return !(
                        masterList &&
                        masterList
                          .map((master) => {
                            return master.TableName;
                          })
                          .includes(fil)
                      );
                    })
                    .map((master, index) => {
                      return (
                        <Option key={index} value={master}>
                          {master}
                        </Option>
                      );
                    })}
              </Select>
            </Form.Item>

            <Form.Item
              label={this.translation(constant.Columns)}
              name="Columns"
              rules={[{ required: false, message: this.translation('Please select Columns!!') }]}
            >
              <Form
                ref={this.dynamicConfig}
                name="dynamic_form_nest_item"
                autoComplete="off"
                initialValues={dynamicEditObject || []}
              >
                <Form.List name="columns">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'title']}
                            rules={[
                              {
                                required: true,
                                message: this.translation('Input Title')
                              }
                            ]}
                          >
                            <Input
                              placeholder={this.translation(constant.Title)}
                              style={{ width: 150 }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'key']}
                            rules={[
                              {
                                required: true,
                                message: this.translation('Select key')
                              }
                            ]}
                          >
                            <Select
                              showArrow
                              showSearch
                              // defaultValue={k.key}
                              openOnFocus
                              style={{ width: 150 }}
                              onChange={(e) => this.onColumnKeyChange(e, 'key', index)}
                              placeholder={this.translation('Key')}
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                              }
                            >
                              {columnsList &&
                                Array.isArray(columnsList) &&
                                columnsList
                                  .filter((fil) => {
                                    return !(
                                      editObject &&
                                      editObject.columns &&
                                      editObject.columns
                                        .map((master) => {
                                          return master.key;
                                        })
                                        .includes(fil)
                                    );
                                  })
                                  .map((keyNames, index) => {
                                    return (
                                      <Option key={index} value={keyNames}>
                                        {keyNames}
                                      </Option>
                                    );
                                  })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'widget']}
                            rules={[
                              {
                                required: true,
                                message: this.translation('Select widget')
                              }
                            ]}
                          >
                            <Select
                              showArrow
                              showSearch
                              // defaultValue={k.widget}
                              openOnFocus
                              style={{ width: 150 }}
                              // onChange={e => this.handleFieldChange(e, "widget", index)}
                              placeholder={this.translation('Widget')}
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                              }
                            >
                              {['input', 'select'].map((widget, index) => {
                                return (
                                  <Option key={index} value={widget}>
                                    {this.translation(widget)}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'isMandatory']}
                            rules={[
                              {
                                required: true,
                                message: this.translation('Select isMandatory')
                              }
                            ]}
                          >
                            <Select
                              // defaultValue={k.isMandatory}
                              openOnFocus
                              style={{ width: 150 }}
                              // onChange={e => this.handleFieldChange(e, "isMandatory", index)}
                              placeholder={this.translation('Is Mandatory ?')}
                            >
                              <Option value={true}>{this.translation('true')}</Option>
                              <Option value={false}>{this.translation('false')}</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'disableOnUpdate']}
                            rules={[
                              {
                                required: true,
                                message: this.translation('Select disableOnUpdate')
                              }
                            ]}
                          >
                            <Select
                              openOnFocus
                              style={{ width: 150 }}
                              placeholder={this.translation('Is disableOnUpdate ?')}
                            >
                              <Option value={true}>{this.translation('true')}</Option>
                              <Option value={false}>{this.translation('false')}</Option>
                            </Select>
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          style={{ width: 150 }}
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {this.translation('Add Columns')}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form>
            </Form.Item>

            <Form.Item
              label={this.translation(constant.Unquie_Column_Relation)}
              name="uniqueness"
              rules={[{ required: true, message: this.translation('Please select Uniqueness!!') }]}
            >
              <Select
                style={{ width: 300 }}
                placeholder={this.translation('Uniqueness')}
                mode="multiple"
              >
                {unquieColumns && Array.isArray(unquieColumns)
                  ? unquieColumns.map((columns, index) => {
                      return (
                        <Option key={index} value={columns}>
                          {columns}
                        </Option>
                      );
                    })
                  : []}
              </Select>
            </Form.Item>

            <Form.Item
              label={this.translation('Read URL')}
              name="readURL"
              rules={[{ required: true, message: this.translation('Please input read URL!!') }]}
            >
              <Input
                style={{ width: 300, marginRight: '5px' }}
                placeholder={this.translation('Read API URL')}
              ></Input>
            </Form.Item>
            <Form.Item
              label={this.translation('Red Method')}
              name="readMethod"
              rules={[{ required: true, message: this.translation('Please select Read Method!!') }]}
            >
              <Select style={{ width: 300 }} placeholder={this.translation('Read API method')}>
                <Option value="POST">POST</Option>
                <Option value="GET">GET</Option>
                <Option value="PATCH">PATCH</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={this.translation('Create URL')}
              name="createURL"
              rules={[{ required: true, message: this.translation('Please input create URL!!') }]}
            >
              <Input
                style={{ width: 300, marginRight: '5px' }}
                placeholder={this.translation('Create API URL')}
              ></Input>
            </Form.Item>
            <Form.Item
              label={this.translation('Create Method')}
              name="createMethod"
              rules={[
                { required: true, message: this.translation('Please select Create Method!!') }
              ]}
            >
              <Select style={{ width: 300 }} placeholder={this.translation('Create API method')}>
                <Option value="POST">POST</Option>
                <Option value="GET">GET</Option>
                <Option value="PATCH">PATCH</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={this.translation('Update URL')}
              name="updateURL"
              rules={[{ required: true, message: this.translation('Please input Update URL!!') }]}
            >
              <Input
                style={{ width: 300, marginRight: '5px' }}
                placeholder={this.translation('Update API URL')}
              ></Input>
            </Form.Item>
            <Form.Item
              label={this.translation('Update Method')}
              name="updateMethod"
              rules={[
                { required: true, message: this.translation('Please select update Method!!') }
              ]}
            >
              <Select style={{ width: 300 }} placeholder={this.translation('Update API method')}>
                <Option value="POST">POST</Option>
                <Option value="GET">GET</Option>
                <Option value="PATCH">PATCH</Option>
              </Select>
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation(constant.Cancel)}
            </Button>
            <Button
              onClick={(e) => this._curdOperation(e, 'delete')}
              type="danger"
              style={{ marginRight: 8 }}
            >
              {this.translation(constant.Delete)}
            </Button>
            {editObject.Id === undefined ? (
              <Button onClick={(e) => this._curdOperation(e, 'create')} type="primary">
                {this.translation(constant.Create)}
              </Button>
            ) : (
              <Button onClick={(e) => this._curdOperation(e, 'update')} type="primary">
                {this.translation(constant.Update)}
              </Button>
            )}
          </DrawerFooter>
        </Drawer>
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(MasterConfiguration);
