import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { Row, Button, Form, Input, DatePicker, Collapse } from 'antd';

import SelectComponennt from '../Widgets/SelectComponent';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
let id = 0;

class DownTimePowderFeddingMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: cloneDeep(props.formObject) || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: ''
    };
  }

  componentDidMount() {
    this.setState({
      formObject: this.props.formObject,
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : []
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: cloneDeep(this.props.formObject),
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }

  addDownTime = () => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      [id]: {
        columns_downtime: formObject['equipmentBatch'].columns_downtime,
        datasource_downtime: {},
        columns_others: formObject['equipmentBatch'].columns_others,
        datasource_others: {}
      }
    };
    id = id + 1;
    this.props.downTimeData(formObject, false);
  };

  onDowntimeInputChange = (columnValue, columnKey, name) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource_downtime: {
          ...formObject[name].datasource_downtime,
          [columnKey]: columnValue
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onDowntimeDateChange = (date, columnKey, name) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource_downtime: {
          ...formObject[name].datasource_downtime,
          [columnKey]: {
            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onInputOthersChange = (columnValue, columnKey, name) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource_others: {
          ...formObject[name].datasource_others,
          [columnKey]: columnValue
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onOtherDateChange = (date, columnKey, name) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource_others: {
          ...formObject[name].datasource_others,
          [columnKey]: {
            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onHeaderInputChange = (columnValue, columnKey, name) => {
    let formObject = cloneDeep(this.props.formObject);

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        headerValue: {
          ...(formObject && formObject[name] && formObject[name].headerValue
            ? formObject[name].headerValue
            : {}),
          [columnKey]: columnValue
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onDateHeaderMainChange = (columnValue, columnKey, name) => {
    let formObject = cloneDeep(this.props.formObject);

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        headerValue: {
          ...(formObject && formObject[name] && formObject[name].headerValue
            ? formObject[name].headerValue
            : {}),
          [columnKey]: moment(columnValue).format('YYYY-MM-DD HH:mm:ss')
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  handleKeyPress = () => {};

  renderDowntimeSwitch(formObject, columns, name) {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].datasource_downtime &&
              formObject[name].datasource_downtime[columns.key]
                ? formObject[name].datasource_downtime[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onDowntimeInputChange(e.target.value, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name].datasource_downtime &&
              formObject[name].datasource_downtime[columns.key]
                ? formObject[name].datasource_downtime[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onDowntimeInputChange(e, columns.key, name)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')]
            }}
            showTime={{ format: 'HH:mm' }}
            value={
              formObject[name] &&
              formObject[name].datasource_downtime &&
              formObject[name].datasource_downtime[columns.key]
                ? [
                    moment(
                      formObject[name].datasource_downtime[columns.key]['startDate'],
                      dateFormat
                    ),
                    moment(formObject[name].datasource_downtime[columns.key]['endDate'], dateFormat)
                  ]
                : undefined
            }
            format="YYYY-MM-DD HH:mm"
            onChange={(e) => this.onDowntimeDateChange(e, columns.key, name)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].datasource_downtime &&
              formObject[name].datasource_downtime[columns.key]
                ? formObject[name].datasource_downtime[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onDowntimeInputChange(e.target.value, columns.key, name)}
          />
        );
    }
  }

  renderOthersSwitch(formObject, columns, name) {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].datasource_others &&
              formObject[name].datasource_others[columns.key]
                ? formObject[name].datasource_others[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputOthersChange(e.target.value, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name].datasource_others &&
              formObject[name].datasource_others[columns.key]
                ? formObject[name].datasource_others[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onInputOthersChange(e, columns.key, name)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')]
            }}
            showTime={{ format: 'HH:mm' }}
            value={
              formObject[name] &&
              formObject[name].datasource_others &&
              formObject[name].datasource_others[columns.key]
                ? [
                    moment(
                      formObject[name].datasource_others[columns.key]['startDate'],
                      dateFormat
                    ),
                    moment(formObject[name].datasource_others[columns.key]['endDate'], dateFormat)
                  ]
                : undefined
            }
            format="YYYY-MM-DD HH:mm"
            onChange={(e) => this.onOtherDateChange(e, columns.key, name)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].datasource_others &&
              formObject[name].datasource_others[columns.key]
                ? formObject[name].datasource_others[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputOthersChange(e.target.value, columns.key, name)}
          />
        );
    }
  }

  renderHeaderSwitch(formObject, columns, name) {
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onHeaderInputChange(e.target.value, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onHeaderInputChange(e, columns.key, name)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject[name] &&
              formObject[name]['headerValue'] &&
              formObject[name]['headerValue'][columns.key]
                ? moment(formObject[name]['headerValue'][columns.key], 'YYYY-MM-DD HH:mm:ss')
                : undefined
            }
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key, name)}
            style={{ width: 300 }}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
    }
  }

  render() {
    let { getFieldDecorator } = this.props.form;
    let { formObject } = this.props;
    return (
      <div style={{ marginTop: '30px', marginBottom: '30px', color: 'black', fontWeight: 'bold' }}>
        <Form className="batchUpdateForm1">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              boxSizing: 'border-box'
            }}
          >
            {formObject && formObject['equipmentBatch'] && formObject['equipmentBatch'].header
              ? formObject['equipmentBatch'].header.map((header, index) => {
                  return (
                    <div key={index} style={{ paddingRight: '2em' }}>
                      <Form.Item
                        required={false}
                        label={
                          <span style={{ marginLeft: header.marginLeft }}>
                            {header.displayName}
                          </span>
                        }
                      >
                        {getFieldDecorator(
                          header.key,
                          {}
                        )(
                          <div>{this.renderHeaderSwitch(formObject, header, 'equipmentBatch')}</div>
                        )}
                      </Form.Item>
                    </div>
                  );
                })
              : null}
          </div>

          <Row>
            <Button
              type="primary"
              className="saveButton"
              style={{ margin: '10px 0' }}
              onClick={this.addDownTime}
            >
              Add DownTime
            </Button>
          </Row>
          <Collapse bordered={false} className="subSectionCollapse">
            {formObject &&
              Object.keys(formObject).map((name) => {
                if (name !== 'equipmentBatch') {
                  let downTimeValue = parseInt(name) + 1;
                  return (
                    <Panel header={<span>DownTime {downTimeValue}</span>} key={name}>
                      <div
                        style={{
                          margin: '20px 0',
                          // padding: '40px',
                          border: '2px solid white',
                          marginBottom: '5px'
                        }}
                      >
                        <div style={{ border: '1px solid green' }}>
                          <div style={{ marginLeft: '10px' }}>Downtime</div>
                          <div
                            style={{
                              display: 'flex',
                              padding: '20px',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              margin: '10px 0px',
                              boxSizing: 'border-box'
                            }}
                          >
                            {formObject && formObject[name] && formObject[name].columns_downtime
                              ? formObject[name].columns_downtime.map((columns, index) => {
                                  return (
                                    <div key={index} style={{ paddingRight: '2em' }}>
                                      <Form.Item
                                        required={false}
                                        label={
                                          <span style={{ marginLeft: columns.marginLeft }}>
                                            {columns.displayName}
                                          </span>
                                        }
                                      >
                                        <div>
                                          {this.renderDowntimeSwitch(formObject, columns, name)}
                                        </div>
                                      </Form.Item>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                        <div style={{ border: '1px solid green' }}>
                          <div style={{ marginLeft: '10px' }}>Others</div>
                          <div
                            style={{
                              display: 'flex',
                              padding: '20px',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              margin: '10px 0px',
                              boxSizing: 'border-box'
                            }}
                          >
                            {formObject && formObject[name] && formObject[name].columns_others
                              ? formObject[name].columns_others.map((columns, index) => {
                                  return (
                                    <div key={index} style={{ paddingRight: '2em' }}>
                                      <Form.Item
                                        required={false}
                                        label={
                                          <span style={{ marginLeft: columns.marginLeft }}>
                                            {columns.displayName}
                                          </span>
                                        }
                                      >
                                        <div>
                                          {this.renderOthersSwitch(formObject, columns, name)}
                                        </div>
                                      </Form.Item>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </div>
                    </Panel>
                  );
                }
                return {};
              })}
          </Collapse>
        </Form>
      </div>
    );
  }
}

export default DownTimePowderFeddingMain;
