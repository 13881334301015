import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Table, Form, Input, Button, Row, Col } from 'antd';
import { Tabs } from 'antd';
import localStorage from '../../../utils/localStorage';
import { StyledDashboard, AddTableButton } from './styles';
import SelectComponent from './SelectComponent';
import { getTranslationCache } from '../../../selectors/language';

import './style.css';
import { PlusOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;
class MasterView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masterName: '',
      masterJSON: {},
      datasource: [],
      editObject: {},
      visible: false,
      activeKey: '1',
      translationCache: props.translationCache || []
    };
    this.hRef = React.createRef();
  }

  componentDidMount() {
    let masterName = this.props.match.params.mastername;
    this.getMasterData(masterName);
    this.setState({
      masterJSON: this.props.match.params.mastername
    });
  }

  componentDidUpdate(prevprops) {
    if (this.props.match.params.mastername !== prevprops.match.params.mastername) {
      this.getMasterData(this.props.match.params.mastername);
      this.setState({
        masterJSON: this.props.match.params.mastername
      });
    }
  }

  getDatasourceData = async (createAPIObject, type) => {
    let { editObject, masterJSON } = this.state;
    const accessToken = localStorage.get('accessToken');
    const SiteId = localStorage.get('currentSite');
    let header = {};
    if (type === 'list') {
      header = {
        method: createAPIObject.method,
        url: `${createAPIObject.url}?SiteId=${SiteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          MasterName: this.props.match.params.mastername,
          ActionType: 'read'
        }
      };
    } else if (type === 'create') {
      header = {
        method: 'POST',
        url: `${createAPIObject.url}?SiteId=${SiteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          MasterName: masterJSON.MasterName,
          ActionType: 'create',
          data: { ...editObject }
        }
      };
    } else if (type === 'update') {
      header = {
        method: createAPIObject.method,
        url: `${createAPIObject.url}?SiteId=${SiteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          MasterName: masterJSON.MasterName,
          ActionType: 'update',
          data: { ...editObject, SiteId }
        }
      };
    } else {
      header = {};
    }

    return axios(header)
      .then((response) => {
        return response.data;
      })
      .catch(function () {
        return [];
      });
  };

  getMasterData = async (masterName) => {
    let masterJSON = await this._getMasterData(masterName);
    if (
      masterJSON &&
      masterJSON[0] &&
      masterJSON[0].JSON &&
      masterJSON[0].JSON.api &&
      masterJSON[0].JSON.api.read
    ) {
      let datasource = await this.getDatasourceData(masterJSON[0].JSON.api.read, 'list');
      this.setState({
        masterJSON: masterJSON[0].JSON,
        masterName,
        datasource,
        activeKey: '1'
      });
    }
  };
  _getMasterData = (MasterName) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `/api/masterconfiguration/get?SiteId=${siteId}&MasterName=${MasterName}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(Object)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  openDrawer = (e, row) => {
    e.preventDefault();
    this.setState({
      editObject: row ? row : {},
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      activeKey: '1'
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
      editObject: {}
    });
  };

  _masterCreateObject = (e, apiObject) => {
    e.preventDefault();
    let { masterJSON } = this.state;
    this.hRef.current
      .validateFields()
      .then(async () => {
        await this.getDatasourceData(apiObject, 'create');
        let datasource = await this.getDatasourceData(masterJSON.api.read, 'list');
        this.setState({
          // visible: !visible,
          activeKey: '1',
          datasource
        });
        let masterName = this.props.match.params.mastername;
        this.getMasterData(masterName);
      })
      .catch(() => {});
  };

  _masterUpdateObject = (e, apiObject) => {
    e.preventDefault();
    let { visible, masterJSON } = this.state;
    this.hRef.current
      .validateFields()
      .then(async () => {
        await this.getDatasourceData(apiObject, 'update');
        let datasource = await this.getDatasourceData(masterJSON.api.read, 'list');
        this.setState({
          visible: !visible,
          datasource
        });
      })
      .catch(() => {});
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onInputChange = (e, key) => {
    // e.preventDefault()
    let { editObject } = this.state;
    editObject = {
      ...editObject,
      [key]: e
    };
    this.setState({
      editObject
    });
  };

  renderFormItems(formObject, editObject) {
    switch (formObject.widget) {
      case 'input':
        return (
          <Input
            disabled={
              formObject && formObject.disableOnUpdate && editObject
                ? //  && (editObject.Id || formObject.key === "Id")
                  formObject.disableOnUpdate
                : false
            }
            value={editObject && editObject[formObject.key] ? editObject[formObject.key] : ''}
            onChange={(e) => this.onInputChange(e.target.value, formObject.key)}
          />
        );

      case 'select':
        return (
          <SelectComponent
            value={editObject && editObject[formObject.key] ? editObject[formObject.key] : ''}
            column={formObject}
            handleFieldChange={this.onInputChange}
          />
        );
      default:
        return (
          <Input
            disabled={
              formObject &&
              formObject.disableOnUpdate &&
              editObject &&
              (editObject.Id || formObject.key === 'Id')
                ? formObject.disableOnUpdate
                : false
            }
            value={editObject && editObject[formObject.key] ? editObject[formObject.key] : ''}
            onChange={(e) => this.onInputChange(e.target.value, formObject.key)}
          />
        );
    }
  }

  render() {
    let { datasource, masterJSON, editObject, activeKey } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    return (
      <div>
        {masterJSON && masterJSON.MasterName ? (
          <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <AddTableButton
                type="primary"
                style={{ background: 'rgb(33, 73, 114)', border: 'rgb(33, 73, 114)' }}
                onClick={() => this.setState({ activeKey: '2', editObject: {} })}
              >
                <PlusOutlined />
                {this.translation('Add New')}
              </AddTableButton>
            </div>
            <Tabs
              type="card"
              defaultActiveKey="1"
              activeKey={activeKey}
              onChange={async (activeKey) => {
                if (activeKey === '2') {
                  this.setState({ activeKey, editObject: {} });
                }
                if (activeKey === '1') {
                  let masterName = this.props.match.params.mastername;
                  this.getMasterData(masterName);
                }
                this.setState({ activeKey });
              }}
            >
              <TabPane tab={this.translation('List')} key="1">
                <Table
                  className="masterTable"
                  columns={
                    masterJSON.columns &&
                    Array.isArray(masterJSON.columns) &&
                    masterJSON.columns.map((columnData) => {
                      return {
                        ...columnData,
                        dataIndex: columnData.key,
                        title: this.translation(columnData.title)
                      };
                    })
                  }
                  dataSource={datasource}
                  size="large"
                  pagination={
                    datasource.length > 10
                      ? {
                          pageSize: '10'
                        }
                      : false
                  }
                  locale={{ emptyText: this.translation('No Data') }}
                  onRow={(record) => ({
                    onClick: (e) => {
                      e.stopPropagation();
                      if (this.hRef.current) {
                        this.hRef.current.setFieldsValue(record);
                      }
                      this.setState({ activeKey: '2', editObject: record ? record : {} });
                    }
                  })}
                />
              </TabPane>
              <TabPane tab={this.translation('Details')} key="2">
                <div>
                  {activeKey && activeKey === '2' ? (
                    <>
                      <Form
                        {...formItemLayout}
                        layout="vertical"
                        ref={this.hRef}
                        initialValues={editObject}
                      >
                        <Row gutter={18}>
                          <Col span={16}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                boxSizing: 'border-box'
                              }}
                            >
                              {masterJSON &&
                                masterJSON.columns &&
                                Array.isArray(masterJSON.columns) &&
                                masterJSON.columns.map((masterColumns, index) => {
                                  return (
                                    <Col key={index} className="gutter-row" span={8}>
                                      <Form.Item
                                        key={index}
                                        required={false}
                                        className={'form'}
                                        label={
                                          <span
                                            style={{
                                              color: 'black !important'
                                            }}
                                          >
                                            {masterColumns.title}
                                          </span>
                                        }
                                      >
                                        <div>{this.renderFormItems(masterColumns, editObject)}</div>
                                      </Form.Item>
                                    </Col>
                                  );
                                })}
                            </div>
                          </Col>
                        </Row>

                        <Button onClick={this.onClose}>{this.translation('Cancel')}</Button>
                        {editObject && editObject.Id !== undefined ? (
                          <Button
                            style={{ marginLeft: '9px' }}
                            // className="SubmitButton"
                            onClick={(e) =>
                              this._masterUpdateObject(e, masterJSON.api.update, 'update')
                            }
                            type="primary"
                          >
                            {this.translation(`Update`)}
                          </Button>
                        ) : (
                          <Button
                            style={{ marginLeft: '9px' }}
                            // className="SubmitButton"
                            onClick={(e) =>
                              this._masterCreateObject(e, masterJSON.api.create, 'create')
                            }
                            type="primary"
                          >
                            {this.translation(`Create`)}
                          </Button>
                        )}
                      </Form>
                    </>
                  ) : null}
                </div>
              </TabPane>
            </Tabs>
          </StyledDashboard>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(MasterView);
