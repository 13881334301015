import { message } from 'antd';
import moment from 'moment';
import localStorage from '../../../utils/localStorage';

export async function selectDashboard(e) {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/dashboard/get?SiteId=${siteId}&Name=${e}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.message) {
        message.warning(response.message);
      } else if (Array.isArray(response)) {
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return data === undefined ? [] : data;
}

export async function getDashboardByName(e) {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/dashboard/get?SiteId=${siteId}&Name=${e}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      if (json) {
        return json;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return data === undefined ? [] : data;
}

export async function updateDashboard(panelData, dashboardName, dashboardId, GroupName) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/dashboard/update`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: dashboardName,
      Id: dashboardId,
      GroupName,
      json: panelData,
      ModifiedBy: 1,
      ModifiedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      SiteId: siteId
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      if (json.message) {
        return json.message;
      }
    })
    .catch(() => {
      //return [];
    });
  return data === undefined ? '' : data;
}

export async function insertDashboard(panelData, dashboardName, dashboardId, GroupName) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/dashboard/create`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      Name: dashboardName,
      Id: dashboardId,
      json: panelData,
      GroupName,
      CreatedBy: 1,
      CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      SiteId: siteId,
      Status: 'Active'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      if (json.message) {
        return json.message;
      }
    })
    .catch(() => {});

  return data === undefined ? [] : data;
}

export async function deleteDashboard(Id) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/dashboard/delete?SiteId=${siteId}&Id=${Id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      if (json.Data) {
        return json.Data;
      } else if (json.message) {
        return json.message;
      }
    })
    .catch(() => {
      // return [];
    });

  return data === undefined ? [] : data;
}
