import React, { Component } from 'react';
import moment from 'moment';
import { Form, message, Select, Button, DatePicker, Divider, Space, TimePicker, Input } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Highlighter from 'react-highlight-words';
import {
  ApartmentOutlined,
  PlusOutlined,
  PaperClipOutlined,
  SearchOutlined
} from '@ant-design/icons';
import axios from 'axios';
// import FailureCodesList from './FailureCodesList';
import { decode as base64_decode } from 'base-64';
import translation from '../../../Utils/translation';
import history from '../../../../../commons/history';
import TableView from '../../../Widgets/TableView';
import FileBase64 from '../../../Utils/FileUpload';
import HierarchyModal from '../../../Widgets/HierarchyModal';
// import TableSelect from '../../../Widgets/TableSelect';
import themeSettings from '../../../Utils/themeSettings.json';
import {
  getUsersList,
  getDeviceList,
  getSitesList,
  getAssetList,
  getHierarchyList,
  getFailureCodeList,
  submitMaster,
  getManufacturerList,
  _getDeviceTypesList,
  // _saveDeviceData,
  getMasterList,
  // _saveAssetData,
  // getMatsterItemList,
  _SaveManufaturerData,
  getParentDevicelist,
  getEquipmentMasterById
} from '../../../Utils/FormCalls';
import localStorage from '../../../../../utils/localStorage';
import { constant } from '../../../Utils/constants';
import { getUserProfile } from '../../../../../selectors/layout';
import { getTranslationCache } from '../../../../../selectors/language';
import FailureCodeDetails from '../../EquipmentRegister/FailureCodeMaster/FailureCodeDetails';

import {
  StyledBox,
  StyledBoxLabel,
  StyledButton,
  StyledComponent,
  StyledForm,
  StyledModal,
  StyledTable
  // StyledTabs
} from '../../../CSS/style';
// import { encode as base64_encode } from 'base-64';
import InputComponent from '../../../Widgets/InputComponent';
// import InputNumberComponent from '../../../Widgets/InputNumberComponent';

const { Option } = Select;
// const { TabPane } = Tabs;
const type = 'AssetDetail';
const urlList = {
  insert: '/api/masterAssetDetails/insert',
  getbyId: '/api/masterAssetDetails/getEquipmentsById',
  itemList: '/api/masterItems/list'
};
const fileData = new FormData();
const attachmentData = new FormData();
class EquipmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
      ItemdataSource: [],
      ItemSpecdataSource: [],
      Specifications: [],
      activeKey: '1',
      modalVisible: false,
      checked: false,
      displayTable: false,
      selectedTableData: [],
      tableData: [],
      hierarchyList: [],
      failureCodeList: [],
      deviceList: [],
      assetList: [],
      ManufacturerList: [],
      failureCodeId: this.props.failureCodeId,
      Id: [],
      viewItemModal: false,
      editObj: {},
      translationCache: props.translationCache || {},
      ViewManufacturerModal: false,
      ParentDeviceType: [],
      Itemdata: [],
      selectedRows: [],
      selectedRowKeys: [],
      selectedItem: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {}
    };
  }
  hRef = React.createRef();
  deviceRef = React.createRef();
  assetRef = React.createRef();
  ManufacturerRef = React.createRef();
  componentDidMount = async () => {
    this._getDataById();
    try {
      let hierarchyList = await getHierarchyList();
      this.setState({ hierarchyList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      let formObject = this.hRef.current.getFieldsValue();
      formObject = {
        ...formObject,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };
      this.hRef.current.setFieldsValue(formObject);
      this.setState({ sitesList: sitesList, formObject });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    let userList = await getUsersList();
    let failureCodeList = await getFailureCodeList();
    let deviceList = await getDeviceList();
    let assetList = await getAssetList();
    let ManufacturerList = await getManufacturerList();
    let siteList = await getSitesList();
    let deviceTypeList = await _getDeviceTypesList();
    let Itemdata = await getMasterList(urlList.itemList);

    this.setState({
      userList,
      failureCodeList: failureCodeList,
      deviceList: deviceList,
      ManufacturerList: ManufacturerList,
      siteList,
      assetList,
      Itemdata,
      deviceTypeList
    });
  };

  _getDataById = async (Equipment) => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/EquipmentDetails/');
    let formId = base64_decode(path[1]);
    if (formId !== 'new') {
      formObject = await getEquipmentMasterById(urlList.getbyId, base64_decode(path[1]), 'GET');
      _.cloneDeepWith(formObject, (value) => {
        return value && value !== undefined && value !== null
          ? {
              ...value,
              ...(value['InstallationDate']
                ? (value['InstallationDate'] = moment.utc(
                    value.InstallationDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ))
                : null),
              ...(value['UpdatedTime']
                ? (value['UpdatedTime'] = moment
                    .utc(value.UpdatedTime, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),
              ...(value['CreatedTime']
                ? (value['CreatedTime'] = moment
                    .utc(value.CreatedTime, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),
              ...(value['TotalDownTime']
                ? (value['TotalDownTime'] = moment.utc(
                    value.TotalDownTime,
                    'YYYY-MM-DD HH:mm'
                  )).local()
                : null)
            }
          : _.noop();
      });
      formObject = {
        ...formObject,
        Specifications: formObject && formObject.Specifications ? formObject.Specifications : [],
        SelectedDataTable: formObject && formObject.FailureCodes ? formObject.FailureCodes : [],
        ItemdataSource: formObject && formObject.Items ? formObject.Items : [],
        FailureCodes:
          formObject &&
          formObject.FailureCodes &&
          Array.isArray(formObject.FailureCodes) &&
          formObject.FailureCodes.map((item) => {
            return item.FailureCodeId;
          })
      };
    } else if (Equipment) {
      formObject = await getEquipmentMasterById(urlList.getbyId, Equipment, 'GET');
      _.cloneDeepWith(formObject, (value) => {
        return value && value !== undefined && value !== null
          ? {
              ...value,
              ...(value['InstallationDate']
                ? (value['InstallationDate'] = moment.utc(
                    value.InstallationDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ))
                : null),
              ...(value['UpdatedTime']
                ? (value['UpdatedTime'] = moment
                    .utc(value.UpdatedTime, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),
              ...(value['CreatedTime']
                ? (value['CreatedTime'] = moment
                    .utc(value.CreatedTime, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),
              ...(value['TotalDownTime']
                ? (value['TotalDownTime'] = moment.utc(
                    value.TotalDownTime,
                    'YYYY-MM-DD HH:mm'
                  )).local()
                : null)
            }
          : _.noop();
      });
      formObject = {
        ...formObject,
        SelectedDataTable: formObject && formObject.FailureCodes ? formObject.FailureCodes : [],
        ItemdataSource: formObject && formObject.Items ? formObject.Items : [],
        Specifications: formObject && formObject.Specifications ? formObject.Specifications : [],
        FailureCodes:
          formObject &&
          formObject.FailureCodes &&
          Array.isArray(formObject.FailureCodes) &&
          formObject.FailureCodes.map((item) => {
            return item.FailureCodeId;
          })
      };
    }

    if (this.hRef.current) {
      this.hRef.current.setFieldsValue(formObject);
    }
    let selectedRowKeys =
      formObject.Items && Array.isArray(formObject.Items)
        ? formObject.Items.map((item1) => {
            return item1.Id;
          })
        : [];
    this.setState({
      formObject,
      selectedRowKeys,
      Specifications: formObject.Specifications,
      ItemdataSource: formObject.Items,
      selectedRows: formObject.Items
    });
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject,
        ItemSpecdataSource:
          this.props.formObject && this.props.formObject.ItemSpecifications
            ? this.props.formObject.ItemSpecifications
            : []
      });
    }
    // if (
    //   this.props.formObject &&
    //   Object.keys(this.props.formObject).length === 0 &&
    //   Object.keys(this.state.formObject).length === 0
    // ) {
    //   this.onReset();
    // }
    // if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
    //   if (this.hRef.current) this.hRef.current.setFieldsValue(this.props.formObject);
    // }
    // if (prevProps.formObject !== this.props.formObject) {
    //   this.setState({
    //     formObject: this.props.formObject
    //   });
    // }
  };
  handleSelect = (value, mainIndex, subindex, keyName) => {
    let list = {};
    let TaskData = list;
    TaskData.map((item, index) => {
      if (index === mainIndex) {
        item[keyName][subindex]['TotalQuantity'] = value;
      }
      return {};
    });
  };
  fileupload = async (values, key, fileDataObject) => {
    let { datasource } = this.state;
    let formObject = this.hRef.current.getFieldsValue();
    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      Specifications: datasource,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    if (this.hRef.current) this.hRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, constant.search)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, constant.search)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, constant.Reset)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  locationFileupload = async (values, key, attachmentDataobj) => {
    let { editObj } = this.state;
    if (editObj[key]) {
      editObj[key].push(...values);
    } else {
      editObj[key] = values;
    }
    editObj = {
      ...editObj,
      [key]: editObj[key]
    };
    attachmentData.append('files', attachmentDataobj);
    if (this.assetRef.current) this.assetRef.current.setFieldsValue(editObj);
    this.setState({ editObj, attachmentData });
  };
  onFormsubmit = async (values) => {
    let { formObject, fileData, ItemdataSource } = this.state;
    let userId =
      this.props.userProfile && this.props.userProfile.user && this.props.userProfile.user.Id
        ? this.props.userProfile.user.Id
        : '';
    let specValidate =
      formObject && formObject.Specifications && Array.isArray(formObject.Specifications)
        ? formObject.Specifications.filter((spec) => {
            return spec.editable === true;
          })
        : [];

    if (specValidate && Array.isArray(specValidate) && specValidate.length === 0) {
      let Specifications =
        formObject &&
        formObject.Specifications &&
        Array.isArray(formObject.Specifications) &&
        formObject.Specifications.map((spec) => {
          delete spec.editable;
          return {
            ...spec
          };
        });

      let payload = {
        ...formObject,
        ...values,
        Items:
          ItemdataSource && ItemdataSource !== undefined && Array.isArray(ItemdataSource)
            ? ItemdataSource
            : [],
        Specifications,
        TimeSeries: true,
        DisplayCode: formObject.Equipment,
        ...(formObject && !formObject.Id ? { CreatedBy: userId } : {}),
        ...(formObject && formObject.Id ? { UpdatedBy: userId } : {})
      };
      let statusResponse = await submitMaster(urlList.insert, payload, fileData, type);
      if (statusResponse && statusResponse.message) {
        this._getDataById(statusResponse.DeviceId);
        message.success(statusResponse.message);
      } else if (statusResponse && statusResponse.Error) {
        message.error(statusResponse.Error);
      }
    } else {
      message.error('Please save specifications before submitting record');
    }
  };
  onReset = () => {
    if (this.hRef.current) this.hRef.current.resetFields();
  };
  // onFinishFailed = () => {
  //   message.error('Please enter mandatory fields');
  // };
  onFailureCodeClick = (data) => {
    let formObject = this.hRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      FailureCode: [...(formObject && formObject['FailureCode']), data]
    };

    this.hRef.current.setFieldsValue(formObject);
    this.setState({
      ...formObject
    });
  };
  onChangeEquipmentCategory = (data) => {
    let { deviceList } = this.state;
    let formObject = this.hRef.current.getFieldsValue();
    let selectedDevice = deviceList.filter((test) => test.DeviceCode === data);
    formObject = {
      ...formObject,
      EquipmentCategory: data,
      EquipmentCategoryDescription: selectedDevice[0].DeviceType
    };
    this.setState({
      modalVisible: false,
      formObject
    });
    this.hRef.current.setFieldsValue(formObject);
  };
  hierarchyDropdownChange = (value) => {
    let formObject = this.hRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Location: value.Code,
      LocationDescription: value.Name
    };

    this.hRef.current.setFieldsValue(formObject);
    this.setState({
      visible: false,
      formObject
    });
  };
  AssethierarchyDropdownChange = (value) => {
    let formObject = this.hRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Location: value.Code,
      LocationDescription: value.Name
    };
    this.hRef.current.setFieldsValue(formObject);
    this.setState({
      visible: false,
      editObj: formObject
    });
  };
  locationHierarchyDropdownChange = (value) => {
    let formObject = this.assetRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      ParentAsset: value.Code
    };

    this.assetRef.current.setFieldsValue(formObject);
    this.setState({
      visible: false,
      editObj: formObject
    });
  };
  parentNode = (parent) => {
    let formObject = this.hRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      // EquipmentCategory: data.Name,
      // EquipmentCategoryDescription: data.Code,
      Location: parent.Code,
      LocationDescription: parent.Name
    };
    this.hRef.current.setFieldsValue(formObject);
    this.setState({
      modalVisible: false,
      formObject
    });
  };
  disabledDate = (current) => {
    return current > moment().endOf('day');
  };
  onDrawerCancel = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Select Location');
    } else {
      this.setState({
        visible: false
      });
    }
  };
  onModalCancel = (e) => {
    let formObject = this.hRef.current.getFieldsValue();
    if (formObject.Location === undefined && e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Select Location');
    } else {
      this.setState({
        modalVisible: false
      });
    }
  };
  onModalOk = () => {
    message.error('Please Select Location');
    // this.setState({
    //   visible: false
    // });
    // let { formObject } = this.state;
    // formObject = {
    //     ...formObject,
    //     "FailureCodes": data
    // };
    // this.setState({
    //     selectedTableData: data,
    //     displayTable: true,
    //     Id: data.Id,
    //     formObject
    // });
  };
  getHierarchyLevels = (key) => {
    let { hierarchyList } = this.state;
    let payload = {
      HierarchyId:
        hierarchyList && Array.isArray(hierarchyList) && hierarchyList[0] && hierarchyList[0].Id
          ? hierarchyList[0].Id
          : null,
      Type: 'Asset'
    };
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/getHierarchyLevelsForAMM?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            treeData: data,
            [key]: true,
            formObject: this.hRef.current.getFieldsValue()
          });
        }
      })
      .catch(function () {});
  };
  handleChange = async (e, type) => {
    let formObject = this.hRef.current.getFieldsValue();

    formObject = {
      ...formObject
    };
    if (type === 'DeviceType') {
      let ParentDeviceType = await getParentDevicelist(e);
      this.setState({ ParentDeviceType });

      this.setState({
        formObject
      });
    }

    this.hRef.current.setFieldsValue(formObject);
    this.setState({ formObject });
  };
  EquipmentCategoryChange = (data, key) => {
    if (key === 'DeviceType') {
      let { deviceTypeList } = this.state;
      let formObject = this.hRef.current.getFieldsValue();
      let selectedDeviceType = deviceTypeList.filter((test) => test.Code === data);
      let selectedEquipment = selectedDeviceType[0];
      formObject = {
        ...formObject,
        EquipmentCategory: selectedEquipment.Code,
        EquipmentCategoryDescription: selectedEquipment.Description
      };
      this.setState({
        formObject
      });
      this.hRef.current.setFieldsValue(formObject);
    }
  };
  onFCModalSubmit = async () => {
    let failureCodeList = await getFailureCodeList();

    this.setState({
      failureCodeList,
      viewFCModal: false
    });
  };
  onChange = () => {
    let formObject = this.hRef.current.getFieldsValue();

    formObject = {
      ...formObject
    };
    this.hRef.current.setFieldsValue(formObject);
    this.setState({
      formObject
    });
  };
  onManufacturerClick = (data) => {
    let { ManufacturerList } = this.state;
    let formObject = this.hRef.current.getFieldsValue();
    let testdata = ManufacturerList.filter((test) => test.Id === data);
    formObject = {
      ...formObject,
      Manufacturer: data,
      ManufacturerDescription: testdata[0].Description
    };
    this.hRef.current.setFieldsValue(formObject);
    this.setState({
      formObject
    });
  };
  getfailureCodeId = (data) => {
    let formObject = this.hRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      FailureCode: data
    };
    this.hRef.current.setFieldsValue(formObject);
    this.setState({
      formObject
    });
  };
  oncloseManufaturer = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please verify fields');
    } else {
      this.setState({ ViewManufacturerModal: false });
    }
  };
  addNewManufaturer = () => {
    this.setState({ ViewManufacturerModal: true });
    if (this.ManufacturerRef.current) {
      this.ManufacturerRef.current.resetFields();
    }
  };
  onEquipmentClose = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please verify fields');
    } else {
      this.deviceRef.current.resetFields();
      this.setState({ viewEquipmentModal: false });
    }
  };
  onModalManufacturerFormsubmit = (e) => {
    e.preventDefault();
    this.ManufacturerRef.current
      .validateFields()
      .then(async (values) => {
        let ManufaturerReponse = await _SaveManufaturerData(values);

        if (ManufaturerReponse) {
          message.success(ManufaturerReponse.message);
          let ManufacturerList = await getManufacturerList();
          let testdata = ManufacturerList.filter(
            (test) =>
              test.Description === values.Description && test.Manufacturer === values.Manufacturer
          );
          let formObject = this.hRef.current.getFieldsValue();
          formObject = {
            ...formObject,
            Manufacturer: testdata[0].Id,
            ManufacturerDescription: testdata[0].Description
          };
          this.hRef.current.setFieldsValue(formObject);
          this.setState({ ViewManufacturerModal: false, ManufacturerList, formObject });

          if (this.ManufacturerRef.current) {
            this.ManufacturerRef.current.resetFields();
          }
        }
      })
      .catch(() => {});
  };

  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };

  addItem = () => {
    this.setState({
      viewItemModal: true
    });
  };
  onItemModalOk = () => {
    let { selectedRows } = this.state;
    this.setState({
      viewItemModal: false,
      ItemdataSource: [...selectedRows]
    });
  };
  onItemModalCancel = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please verify fields');
    } else {
      let { ItemdataSource } = this.state;
      this.setState({
        viewItemModal: false,
        selectedRowKeys:
          ItemdataSource && Array.isArray(ItemdataSource)
            ? ItemdataSource.map((item) => {
                return item.Id;
              })
            : []
      });
    }
  };

  onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  saveTableViewData = (Specifications) => {
    let formObject = this.hRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Specifications
    };
    this.setState({ formObject, Specifications });
  };
  onFCModalCancel = () => {
    this.setState({
      viewFCModal: false
    });
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/asset/get?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.hRef.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  render() {
    let {
      selectedRowKeys,
      sitesList,
      formObject,
      ItemdataSource,
      viewItemModal,
      displayTable,
      deviceList,
      Specifications,
      Itemdata,
      selectedTableData,
      failureCodeList,
      userList,
      ViewManufacturerModal,
      // parentLocation,
      // ItemSpecdataSource,
      // Itemdata,
      // viewEquipmentModal,
      ManufacturerList,
      deviceTypeList
    } = this.state;
    let { translationCache } = this.props;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    const modalTableColumns = [
      {
        title: translation(translationCache, constant.failureClass),
        key: 'FailureCode',
        dataIndex: 'FailureCode'
      },
      {
        title: translation(translationCache, constant.failureDescription),
        key: 'Description',
        dataIndex: 'Description'
      }
    ];

    let EquipmentColumns = [
      {
        title: translation(translationCache, constant.name),
        key: 'Name',
        dataIndex: 'Name',
        widget: 'input',
        required: true
      },
      {
        title: translation(translationCache, constant.value),
        key: 'Value',
        dataIndex: 'Value',
        widget: 'inputNumber',
        required: true
      },

      {
        title: translation(translationCache, constant.unitOfMeasure),
        key: 'UOM',
        dataIndex: 'UOM',
        widget: 'select',
        required: true,
        table: {
          url: `/api/masterconfiguration/getDropdown`,
          method: 'POST',
          payload: {
            TableName: 'appconfig."Site_Master_UnitOfMeasures"',
            ColumnName: 'Name'
          }
        }
      }
    ];
    let selectedItemcolumns = [
      {
        title: translation(translationCache, constant.item),
        key: 'Item',
        dataIndex: 'Item',
        widget: 'input',
        ...this.getColumnSearchProps('Item')
      },
      {
        title: translation(translationCache, constant.itemType),
        key: 'ItemType',
        dataIndex: 'ItemType',
        widget: 'input',
        ...this.getColumnSearchProps('ItemType')
      },
      {
        title: translation(translationCache, constant.itemDescription),
        key: 'Description',
        dataIndex: 'Description',
        widget: 'input',
        ...this.getColumnSearchProps('Description')
      }
    ];

    let Itemcolumns = [
      {
        title: translation(translationCache, constant.item),
        key: 'Item',
        dataIndex: 'Item',
        widget: 'input',
        ...this.getColumnSearchProps('Item')
      },
      {
        title: translation(translationCache, constant.itemType),
        key: 'ItemType',
        dataIndex: 'ItemType',
        widget: 'input',
        ...this.getColumnSearchProps('ItemType')
      },
      {
        title: translation(translationCache, constant.itemDescription),
        key: 'Description',
        dataIndex: 'Description',
        widget: 'input',
        ...this.getColumnSearchProps('Description')
      }
    ];
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange
    };
    return (
      <>
        <StyledComponent theme={themeSettings} style={{ padding: '0px 15px' }}>
          <>
            <StyledForm
              name="Equipment Master"
              theme={themeSettings}
              ref={this.hRef}
              onFinish={this.onFormsubmit}
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
              initialValues={formObject || {}}
              layout="horizontal"
              labelCol={{ flex: '130px' }}
              labelAlign="right"
              labelWrap
              validateMessages={validateMessages}
            >
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <StyledButton
                  style={{ marginRight: '5px' }}
                  theme={themeSettings}
                  htmlType="submit"
                >
                  <Link
                    to={{
                      pathname: `/rubus/AMM/EquipmentOverviewDetails/${formObject.Equipment}`
                    }}
                  >
                    {translation(translationCache, constant.overview)}
                  </Link>
                </StyledButton>
                <StyledButton
                  style={{ marginRight: '5px' }}
                  theme={themeSettings}
                  htmlType="submit"
                >
                  <Link to={{ pathname: `/rubus/AMM/CMDetails/bmV3` }}>
                    {translation(translationCache, constant.CM)}
                  </Link>
                </StyledButton>
                <StyledButton
                  style={{ marginRight: '5px' }}
                  theme={themeSettings}
                  htmlType="submit"
                >
                  <Link to={{ pathname: `/rubus/AMM/EquipmentTagDetails/bmV3` }}>
                    {translation(translationCache, constant.EquipmentTags)}
                  </Link>
                </StyledButton>
                <StyledButton theme={themeSettings} htmlType="submit">
                  {translation(translationCache, constant.submit)}
                </StyledButton>
              </div>
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item name={'Id'}></Form.Item>
                <StyledBoxLabel>
                  {translation(translationCache, constant.EquipmentDetails)}
                </StyledBoxLabel>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    marginTop: '40px'
                  }}
                  className="label"
                  label={translation(translationCache, constant.equipment)}
                  name="Equipment"
                  rules={[{ required: true }]}
                >
                  <InputComponent
                    style={{
                      width: '70%',
                      cursor: formObject && formObject.Id ? 'not-allowed' : null,
                      opacity: formObject && formObject.Id ? '0.8' : null
                    }}
                    placeholder={translation(this.props.translationCache, constant.inputEquipment)}
                    disabled={
                      formObject && formObject.EquipmentFlag && formObject.EquipmentFlag === true
                        ? true
                        : false
                    }
                  />
                </Form.Item>
                <Form.Item name="EquipmentFlag"></Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    marginTop: '40px'
                  }}
                  label={translation(translationCache, constant.equipmentDescription)}
                  name="EquipmentDescription"
                  rules={[{ required: true }]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    // maxLength={150}
                    // disabled
                    placeholder={translation(
                      this.props.translationCache,
                      constant.Inputequipmentdescription
                    )}
                    disabled={
                      formObject && formObject.EquipmentFlag && formObject.EquipmentFlag === true
                        ? true
                        : false
                    }
                  />
                </Form.Item>
                {/* <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%'
                  }}
                  label={translation(translationCache, constant.DisplayCode)}
                  name="DisplayCode"
                  rules={[{ required: true }]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    // maxLength={150}
                    // disabled
                    placeholder={translation(
                      this.props.translationCache,
                      constant.InputDisplayCode
                    )}
                    // disabled={
                    //   formObject && formObject.EquipmentFlag && formObject.EquipmentFlag === true
                    //     ? true
                    //     : false
                    // }
                  />
                </Form.Item> */}
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%'
                  }}
                  label={translation(translationCache, constant.equipmentCategory)}
                  name="EquipmentCategory"
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(
                      this.props.translationCache,
                      constant.SelectEquipmentCategory
                    )}
                    onChange={(e) => this.EquipmentCategoryChange(e, 'DeviceType')}
                    disabled={
                      formObject && formObject.EquipmentFlag && formObject.EquipmentFlag === true
                        ? true
                        : false
                    }
                  >
                    {deviceTypeList
                      ? deviceTypeList.map((deviceType, index) => (
                          <Option key={index} value={deviceType.Code}>
                            {translation(translationCache, deviceType.Name)}
                          </Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%'
                  }}
                  label={translation(translationCache, constant.equipmentCategoryDescription)}
                  name="EquipmentCategoryDescription"
                  // disabled={true}
                  rules={[{ required: true }]}
                >
                  <InputComponent
                    // maxLength={150}
                    style={{ width: '70%' }}
                    placeholder={translation(
                      this.props.translationCache,
                      constant.AutopopulatebasedonEquipmentCategory
                    )}
                    disabled={true}
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.location)}
                  name="Location"
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(this.props.translationCache, constant.SelectLocation)}
                    disabled={true}
                    suffixIcon={
                      <ApartmentOutlined
                        style={{
                          color: formObject && formObject.Id ? 'rgba(0,0,0,0.66)' : '#1a3b5c',
                          cursor: formObject && formObject.EquipmentFlag ? 'not-allowed' : null,
                          opacity: formObject && formObject.EquipmentFlag ? '0.3' : null
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (formObject && !formObject.Id) {
                            this.getHierarchyLevels('visible');
                          }
                        }}
                      />
                    }
                  >
                    {deviceList &&
                      Array.isArray(deviceList) &&
                      deviceList.map((device, index) => {
                        return (
                          <Option key={index} value={device.DeviceCode}>
                            {' '}
                            {device.DeviceCode}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%'
                  }}
                  label={translation(translationCache, constant.locationDescription)}
                  name="LocationDescription"
                  rules={[{ required: true }]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(
                      this.props.translationCache,
                      constant.AutopopulatebasedonLocation
                    )}
                    disabled={true}
                    // maxLength={150}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={<div>{translation(translationCache, constant.failureClass)}</div>}
                  name="FailureCodes"
                >
                  <Select
                    value={formObject.FailureCodes}
                    mode="multiple"
                    showArrow={true}
                    placeholder={translation(
                      this.props.translationCache,
                      constant.SelectFailureCode
                    )}
                    style={{ width: '70%' }}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: '8px 0'
                          }}
                        />
                        <Space
                          style={{
                            padding: '0 8px 4px'
                          }}
                        >
                          <StyledButton
                            theme={themeSettings}
                            onClick={() => {
                              this.setState({ viewFCModal: true });
                            }}
                          >
                            {translation(translationCache, constant.addFailureClass)}
                          </StyledButton>
                        </Space>
                      </>
                    )}
                  >
                    {failureCodeList &&
                      Array.isArray(failureCodeList) &&
                      failureCodeList.map((code, index) => {
                        return (
                          <Option key={index} value={code.Id}>
                            {' '}
                            {code.FailureCode}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%'
                  }}
                  label={translation(translationCache, constant.site)}
                  name="SiteId"
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(this.props.translationCache, constant.SelectSite)}
                    disabled={
                      formObject && formObject.EquipmentFlag && formObject.EquipmentFlag === true
                        ? true
                        : false
                    }
                    onChange={(e) => this.handleChange(e, 'SiteId')}
                  >
                    {sitesList &&
                      Array.isArray(sitesList) &&
                      sitesList.map((site, index) => {
                        return (
                          <Option key={index} value={site.Id}>
                            {' '}
                            {site.Name}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%'
                  }}
                  label={translation(translationCache, constant.TotalDowntime)}
                  name="TotalDownTime"
                >
                  <TimePicker
                    style={{ width: '70%' }}
                    placeholder={translation(
                      this.props.translationCache,
                      constant.InputTotalDowntime
                    )}
                    format={'HH:mm'}
                    defaultOpenValue={moment('00:00', 'HH:mm')}
                    onChange={(value) => this.handleChange(value, 'TotalDownTime')}
                  />
                </Form.Item>
                <Form.Item style={{ width: '100%', flex: '50%' }}>
                  <InputComponent style={{ display: 'none' }} />
                </Form.Item>
              </StyledBox>
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
                <StyledBoxLabel>
                  {' '}
                  {translation(translationCache, constant.EquipmentCatalogue)}
                </StyledBoxLabel>

                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                  label={translation(translationCache, constant.specificationNumber)}
                  name="SpecNumber"
                  rules={[
                    // { required: true},
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9 ]*$/)
                      // pattern:new RegExp(/[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤ヶ]+^[A-Za-z0-9 ]*$/u)
                      // pattern:new RegExp(/|[ぁ-ゔ]+|[ァ-ヴー]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤ヶ]+^[A-Za-z0-9 ]*$/u)
                      // pattern: new RegExp(
                      //   /^[一-龯ぁ-んァ-ンぁ-んァ-ン\wぁ-んァ-ンｧ-ﾝﾞﾟぁ-ゞ0-9A-Za-zァ-ヶｦ-ﾟ-_&一 ]*$/
                      // )
                    }
                  ]}
                >
                  <InputComponent
                    // maxLength={30}
                    style={{ width: '70%' }}
                    placeholder={translation(this.props.translationCache, constant.InputSpecNo)}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                  label={translation(translationCache, constant.usageCondition)}
                  name="UsageCondition"
                  rules={[
                    {
                      // pattern: new RegExp(/^[a-zA-Z ]*$/)
                      pattern: new RegExp(
                        /^[一-龯ぁ-んァ-ンぁ-んァ-ン\wぁ-んァ-ンｧ-ﾝﾞﾟぁ-ゞ0-9A-Za-zァ-ヶｦ-ﾟ-_&一 ]*$/
                      )
                    }
                  ]}
                >
                  <InputComponent
                    // maxLength={30}
                    style={{ width: '70%' }}
                    placeholder={translation(
                      this.props.translationCache,
                      constant.InputUsageCondition
                    )}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.serialNo)}
                  name="SerialNumber"
                  //  style={{ marginLeft: "26px" }}
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9 ]*$/)
                      // pattern: new RegExp(
                      //   /^[一-龯ぁ-んァ-ンぁ-んァ-ン\wぁ-んァ-ンｧ-ﾝﾞﾟぁ-ゞ0-9A-Za-zァ-ヶｦ-ﾟ-_&一 ]*$/
                      // )
                    }
                  ]}
                >
                  <InputComponent
                    // maxLength={30}
                    style={{ width: '70%' }}
                    placeholder={translation(this.props.translationCache, constant.InputSerialNo)}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.installationDate)}
                  name="InstallationDate"
                >
                  <DatePicker
                    inputReadOnly={true}
                    placeholder={translation(translationCache, constant.selectinstallationDate)}
                    style={{ width: '70%' }}
                    value={moment().format('YYYY-MM-DD HH:mm:ss')}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.manufacturer)}
                  name="Manufacturer"
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(
                      this.props.translationCache,
                      constant.SelectManufacturer
                    )}
                    onChange={(e) => this.onManufacturerClick(e)}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: '8px 0'
                          }}
                        />
                        <Space
                          style={{
                            padding: '0 8px 4px'
                          }}
                        >
                          <Button
                            type="dashed"
                            icon={<PlusOutlined />}
                            onClick={() => this.addNewManufaturer()}
                          >
                            {translation(translationCache, 'Add Manufacturer')}
                          </Button>
                        </Space>
                      </>
                    )}
                  >
                    {ManufacturerList &&
                      Array.isArray(ManufacturerList) &&
                      ManufacturerList.map((code, index) => {
                        return (
                          <Option key={index} value={code.Id}>
                            {code.Manufacturer}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.manufacturerDescription)}
                  name={'ManufacturerDescription'}
                >
                  <InputComponent
                    // maxLength={150}
                    style={{ width: '70%', color: 'black' }}
                    placeholder={translation(
                      this.props.translationCache,
                      constant.AutoPopulatebasedonManufacturer
                    )}
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.createdBy)}
                  name="CreatedBy"
                >
                  <Select
                    style={{ width: '70%' }}
                    value={formObject.CreatedBy}
                    placeholder={translation(this.props.translationCache, constant.CreatedBy)}
                    disabled={true}
                  >
                    {userList &&
                      Array.isArray(userList) &&
                      userList.map((user, index) => {
                        return (
                          <Option key={index} value={user.Id}>
                            {' '}
                            {user.FirstName}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.createdDate)}
                  name="CreatedTime"
                >
                  <DatePicker
                    placeholder={translation(translationCache, constant.createdDate)}
                    format="YYYY-MM-DD HH:mm:ss"
                    inputReadOnly={true}
                    style={{ width: '70%' }}
                    disabled={true}
                  />
                </Form.Item>

                {formObject && formObject.Id && formObject.Id !== null ? (
                  // <div>
                  <>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.modifiedBy)}
                      name="UpdatedBy"
                    >
                      <Select
                        style={{ width: '70%' }}
                        value={formObject.UpdatedBy}
                        placeholder={translation(this.props.translationCache, constant.UpdatedBy)}
                        disabled={true}
                      >
                        {userList &&
                          Array.isArray(userList) &&
                          userList.map((user, index) => {
                            return (
                              <Option key={index} value={user.Id}>
                                {' '}
                                {user.FirstName}{' '}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.modifiedDate)}
                      name="UpdatedTime"
                    >
                      <DatePicker
                        placeholder={translation(translationCache, constant.selectDate)}
                        format="YYYY-MM-DD HH:mm:ss"
                        inputReadOnly={true}
                        style={{ width: '70%' }}
                        defaultValue={moment()}
                        disabled={true}
                      />
                    </Form.Item>
                  </>
                ) : null}
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  name="Attachments"
                  // label={translation(translationCache, constant.attachments)}
                  label={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>
                        {translation(translationCache, constant.attachments)}
                        <PaperClipOutlined className="plusCircleOutlined" />
                      </span>
                    </div>
                  }
                >
                  <FileBase64
                    //  className={"formuploadInput"}
                    downloadClassName={'formdownloadInput'}
                    Attachments={formObject.Attachments}
                    fileUpload={this.fileupload}
                  ></FileBase64>
                </Form.Item>
                <Form.Item style={{ width: '100%', flex: '50%' }}>
                  <InputComponent style={{ display: 'none' }} />
                </Form.Item>
              </StyledBox>

              <StyledModal
                title={translation(translationCache, constant.addManufacturer)}
                open={ViewManufacturerModal}
                okText={'Submit'}
                onOk={(e) => this.onModalManufacturerFormsubmit(e)}
                onCancel={this.oncloseManufaturer}
              >
                <Form
                  layout="vertical"
                  ref={this.ManufacturerRef}
                  name="user_form"
                  onFinish={(e) => this.onModalManufacturerFormsubmit(e)}
                >
                  <Form.Item
                    style={{ width: '100%', flex: '50%' }}
                    label={translation(translationCache, constant.Manufacturer)}
                    name={'Manufacturer'}
                    placeholder={translation(
                      this.props.translationCache,
                      constant.PleaseenterManufacturer
                    )}
                    rules={[{ required: true }]}
                  >
                    <InputComponent
                      // maxLength={20}
                      placeholder={translation(
                        this.props.translationCache,
                        constant.PleaseenterManufacturer
                      )}
                      onChange={(e) => this.onChange(e)}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '100%', flex: '50%' }}
                    label={translation(translationCache, constant.Description)}
                    name={'Description'}
                    placeholder={translation(
                      this.props.translationCache,
                      constant.PleaseenterDescription
                    )}
                    rules={[
                      { required: true },
                      {
                        // pattern: new RegExp(/^[A-Za-z0-9 ]*$/)
                        // pattern: new RegExp(
                        //   /^[一-龯ぁ-んァ-ンぁ-んァ-ン\wぁ-んァ-ンｧ-ﾝﾞﾟぁ-ゞ0-9A-Za-zァ-ヶｦ-ﾟ-_&一 ]*$/
                        // )
                      }
                    ]}
                  >
                    <InputComponent
                      placeholder={translation(
                        this.props.translationCache,
                        constant.PleaseenterDescription
                      )}

                      //  maxLength={70}
                    />
                  </Form.Item>
                </Form>
              </StyledModal>
              <StyledModal
                open={this.state.modalVisible}
                width={800}
                closable
                title={translation(translationCache, constant.hierarchy)}
                onOk={this.onModalOk}
                onCancel={this.onModalCancel}
              >
                <HierarchyModal
                  value={this.state.deviceId}
                  treeData={this.state.treeData}
                  translationCache={this.props.translationCache}
                  Key="Location"
                  handleDropdownChange={this.hierarchyDropdownChange}
                  handleParentNode={this.parentNode}
                />
              </StyledModal>
              <StyledModal
                open={this.state.visible}
                width={800}
                closable
                title={translation(translationCache, constant.hierarchy)}
                onOk={this.onModalOk}
                onCancel={this.onDrawerCancel}
              >
                {' '}
                <HierarchyModal
                  value={this.state.deviceId}
                  treeData={this.state.treeData}
                  translationCache={this.props.translationCache}
                  language={this.props.language}
                  Key="Asset"
                  handleDropdownChange={this.AssethierarchyDropdownChange}
                  handleParentNode={this.parentNode}
                  deviceList={this.state.deviceList}
                />
              </StyledModal>

              {displayTable === true ? (
                <TableView form={{ columns: modalTableColumns, datasource: selectedTableData }} />
              ) : (
                ''
              )}

              <Form.Item name="FailureCodes" style={{ width: '100%', flex: '50%' }}></Form.Item>

              <StyledBox>
                <StyledBoxLabel> {translation(translationCache, constant.item)}</StyledBoxLabel>
                <StyledModal
                  open={viewItemModal}
                  width={800}
                  closable
                  title={translation(translationCache, constant.addItem)}
                  onOk={(e) => {
                    this.onItemModalOk(e);
                  }}
                  onCancel={this.onItemModalCancel}
                >
                  <StyledTable
                    theme={themeSettings}
                    rowSelection={rowSelection}
                    columns={Itemcolumns}
                    dataSource={Itemdata}
                  />
                </StyledModal>
                <div style={{ padding: '20px 10px' }}>
                  <StyledTable
                    theme={themeSettings}
                    columns={selectedItemcolumns}
                    dataSource={ItemdataSource}
                  />
                  <StyledButton
                    style={{ marginTop: '5px' }}
                    theme={themeSettings}
                    onClick={() => {
                      this.addItem();
                    }}
                  >
                    {translation(translationCache, constant.addItem)}
                  </StyledButton>
                </div>
              </StyledBox>

              <StyledBox>
                <StyledBoxLabel>
                  {translation(translationCache, constant.specifications)}
                </StyledBoxLabel>
                <div style={{ padding: '20px 10px' }}>
                  <TableView
                    form={{ columns: EquipmentColumns, datasource: Specifications }}
                    recordType={'Item Specifications'}
                    saveTableViewData={(e) => this.saveTableViewData(e, 'Specifications')}
                    showAddButton={true}
                  />
                </div>
              </StyledBox>

              <StyledModal
                visible={this.state.viewFCModal}
                width={800}
                closable
                footer={null}
                title={translation(this.props.translationCache, constant.AddFailureClass)}
                onOk={this.onFCModalOk}
                onCancel={this.onFCModalCancel}
              >
                <FailureCodeDetails
                  EWOModal={true}
                  viewFCModal={this.state.viewFCModal}
                  onFCModalSubmit={this.onFCModalSubmit}
                  onFCModalCancel={this.onFCModalCancel}
                  formObject={{}}
                  // getFailureCodeList={this.ongetFCList}
                />
              </StyledModal>
            </StyledForm>
          </>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(EquipmentDetails);
