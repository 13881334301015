import React, { Fragment } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';

import ReactEcharts from '../../Echarts/Echarts';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class Bar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      checked: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props);
    }
  }

  getData = async (props) => {
    let theme = this.props.selectedThemeing;
    console.log('Bar CurrentZone', this.props.currentTimeZone);

    this.requestCheck = false;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    let json = [];
    let { graphprops } = this.props;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (props.graphprops.graph === 'rangeBar' && props.graphprops.currentDateData !== undefined) {
        json = props.graphprops.currentDateData;
      } else {
        if (
          (graphprops && graphprops.dashboardType === 'dynamic') ||
          (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
          (graphprops &&
            (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
            this.props.selectionDate) ||
          (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
          (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
          (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
          (graphprops &&
            (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
            this.props.selectionDateRange) ||
          (graphprops &&
            (graphprops.dayMonthYearExists === 'true' ||
              graphprops.selection === 'dateMonthYearPicker') &&
            this.props.dayMonthYear) ||
          (graphprops &&
            graphprops.dashboardType === 'hierarchy' &&
            this.props.dropdownSelection) ||
          (graphprops && this.props.dashboardPayload) ||
          (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
        ) {
          json = await getChartDataWithSelection(
            graphprops,
            this.props.DeviceCode,
            this.props.selectionDate,
            this.props.selectionMonth,
            this.props.selectionWeek,
            this.props.shift,
            this.props.selectionDateRange,
            this.props.dayMonthYear,
            this.abortController,
            this.props.dropdownSelection,
            this.props.dashboardPayload,
            this.props.AssetCode,
            this.props.selectionDateLive,
            this.props.currentTimeZone
          );
          this.requestCheck = true;
        } else {
          json = await getChartDataWithoutSelection(
            graphprops,
            this.abortController,
            this.props.dropdownSelection,
            this.props.selectionDateLive,
            '',
            this.props.currentTimeZone
          );
          this.requestCheck = true;
        }
      }
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let dates = [];
      let values = [];
      let name = '';
      let tagnames = [];
      let seriesarray = [];
      let legendsdata = [];
      let showLabel = false;
      let showStack = false;
      let viewType = '';
      let { graphprops } = this.props;
      if (graphprops && graphprops.bar && graphprops.bar['showLabel']) {
        showLabel = graphprops.bar['showLabel'] || false;
      }
      if (graphprops && graphprops.bar && graphprops.bar['showStack']) {
        showStack = graphprops.bar['showStack'] || false;
      }
      if (graphprops && graphprops.bar && graphprops.bar['viewType']) {
        viewType = graphprops.bar['viewType'] || 'vertical';
      }
      let descriptionList = [];
      json &&
        Array.isArray(json) &&
        json.map((val) => {
          if (val.Description && val.Description !== name) {
            if (tagnames.indexOf(val.Description) === -1) {
              tagnames.push(val.Description);
              name = val.Description;
              legendsdata.push({
                name: val.Description,
                icon: 'roundRect',
                textStyle: {
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  fontSize: 16,
                  fontWeight: 'bold'
                }
              });
              descriptionList = [...descriptionList, val.Description];
            }
          }
          return {};
        });
      let yAxisData = [];
      if (
        this.props.graphprops &&
        this.props.graphprops[this.props.graphprops.graph] &&
        this.props.graphprops[this.props.graphprops.graph].YAxis
      ) {
        yAxisData = JSON.parse(this.props.graphprops[this.props.graphprops.graph].YAxis);
      } else {
        yAxisData = [];
      }

      let yaxis = yAxisData.map((value, index) => {
        let axis = {};
        axis.splitLine = {
          show: false,
          lineStyle: {
            color: '#504c4c'
          }
        };
        axis.triggerEvent = true;
        axis.axisLine = {
          show: true,
          lineStyle: {
            color:
              this.props.graphprops[this.props.graphprops.graph].colors[index] !== undefined
                ? value && value.tags && value.tags.length > 1
                  ? 'white'
                  : this.props.graphprops[this.props.graphprops.graph].colors[index]
                : 'white'
          }
        };
        axis.max = value.max;
        axis.min = value.min;
        axis.offset = value.offset;
        axis.interval = value.interval;
        axis.position = value.position;
        axis.name = value.name;
        axis.tags = value.tags;
        return axis;
      });
      yaxis.unshift({
        splitLine: {
          show: false,
          lineStyle: {
            color: '#504c4c'
          }
        },
        triggerEvent: true,
        axisLine: {
          show: true,
          lineStyle: {
            color:
              this.props.graphprops &&
              this.props.graphprops.bar &&
              this.props.graphprops.bar.Linecolor &&
              this.props.graphprops.bar.Linecolor.length > 0
                ? this.props.graphprops.bar.Linecolor[0]
                : 'white'
          }
        },
        axisLabel: { fontSize: 15 },
        tags: []
      });

      tagnames.map((tagval, tagindex) => {
        values = [];
        json.map((jsonval) => {
          if (tagnames[0] === jsonval.Description && tagindex === 0) {
            let date = {};
            if (
              this.props.graphprops.monthExists === 'true' ||
              this.props.graphprops.monthExists === true
            ) {
              date =
                graphprops && graphprops.type === 'live'
                  ? moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format('DD/MM/YYY')
                  : moment(jsonval.DateTime).tz(this.props.currentTimeZone).format('DD/MM/YYYY');
            } else {
              if (
                graphprops &&
                graphprops.bar &&
                graphprops.bar.xAxisLabel &&
                graphprops.bar.xAxisLabel === 'string'
              ) {
                date = jsonval.DateTime;
                // date = jsonval.xAxisDateFormat || '';
              } else {
                let format =
                  graphprops.bar && graphprops.bar.xAxisDateFormat
                    ? graphprops.bar.xAxisDateFormat
                    : 'YYYY-MM-DD HH:mm:ss';

                if (
                  this.props.graphprops &&
                  this.props.graphprops.selection &&
                  this.props.graphprops.selection === 'dateMonthYearPicker'
                ) {
                  if (this.props.dayMonthYear && this.props.dayMonthYear.selectionType === 'day') {
                    date =
                      graphprops && graphprops.type === 'summary'
                        ? moment.utc(jsonval.DateTime).format('HH:mm')
                        : moment
                            .utc(jsonval.DateTime)
                            .tz(this.props.currentTimeZone)
                            .format('HH:mm');
                  } else if (
                    this.props.dayMonthYear &&
                    this.props.dayMonthYear.selectionType === 'month'
                  ) {
                    date =
                      graphprops && graphprops.type === 'summary'
                        ? moment.utc(jsonval.DateTime).format('DD/MM')
                        : moment
                            .utc(jsonval.DateTime)
                            .tz(this.props.currentTimeZone)
                            .format('DD/MM');
                  } else if (
                    this.props.dayMonthYear &&
                    this.props.dayMonthYear.selectionType === 'year'
                  ) {
                    date =
                      graphprops && graphprops.type === 'summary'
                        ? moment.utc(jsonval.DateTime).format('MMMM')
                        : moment
                            .utc(jsonval.DateTime)
                            .tz(this.props.currentTimeZone)
                            .format('MMMM');
                  }
                } else {
                  date =
                    graphprops && graphprops.type === 'summary'
                      ? moment.utc(jsonval.DateTime).format(format)
                      : moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format(format);
                }
              }
            }
            if (
              graphprops &&
              graphprops[graphprops.graph] &&
              graphprops[graphprops.graph].showDescInYAxis &&
              graphprops[graphprops.graph].showDescInYAxis === 'Description'
            ) {
              dates.push(jsonval.Description);
            } else {
              dates.push(date);
            }
          }
          if (tagval === jsonval.Description) {
            if (
              graphprops &&
              graphprops[graphprops.graph] &&
              graphprops[graphprops.graph].showDescInYAxis &&
              graphprops[graphprops.graph].showDescInYAxis === 'Description'
            ) {
              values.push({
                value: jsonval.Value,
                name: jsonval.Description
              });
            } else {
              values.push(jsonval.Value);
            }
          }
          return {};
        });
        if (yaxis.length > 1) {
          yaxis.map((axis, axisindex) => {
            if (axis && axis.tags && axis.tags.indexOf(tagval) !== -1) {
              let linetags =
                props.graphprops && props.graphprops.bar ? props.graphprops.bar.tags : [];
              if (linetags && linetags.length > 0) {
                linetags &&
                  linetags.map((tag) => {
                    seriesarray.push({
                      name: legendsdata[tagindex]['name'],
                      data: values,
                      yAxisIndex: axisindex,
                      ...(showStack && showStack === 'true' ? { stack: 'total' } : {}),
                      label: {
                        show:
                          showLabel && (showLabel === 'true' || showLabel === true) ? true : false
                      },
                      markPoint: {
                        data: this.props.graphprops.minMax
                          ? [
                              { type: 'max', name: 'Max' },
                              { type: 'min', name: 'Min' }
                            ]
                          : null
                      },
                      type: legendsdata[tagindex]['name'] !== tag ? 'bar' : 'line',
                      axisLabel: { fontSize: 15 }
                    });
                    return {};
                  });
              } else {
                seriesarray.push({
                  name: legendsdata[tagindex]['name'],
                  data: values,
                  yAxisIndex: axisindex,
                  type: 'bar',
                  axisLabel: { fontSize: 15 }
                });
              }
            }
            return {};
          });
        } else {
          let linetags = props.graphprops && props.graphprops.bar ? props.graphprops.bar.tags : [];
          if (linetags && linetags.length > 0) {
            linetags &&
              linetags.map((tag) => {
                seriesarray.push({
                  name: legendsdata[tagindex]['name'],
                  data: values,
                  type: legendsdata[tagindex]['name'] !== tag ? 'bar' : 'line',
                  axisLabel: { fontSize: 15 }
                });
                return {};
              });
          } else {
            if (
              graphprops &&
              graphprops[graphprops.graph] &&
              graphprops[graphprops.graph].showDescInYAxis &&
              graphprops[graphprops.graph].showDescInYAxis === 'Description'
            ) {
              seriesarray = {
                axisLabel: { fontSize: 15 },
                type: 'bar',
                data: values
              };
            } else {
              seriesarray.push({
                name: legendsdata[tagindex]['name'],
                data: values,
                type: 'bar',
                axisLabel: { fontSize: 15 },
                ...(showStack && showStack === 'true' ? { stack: 'Total' } : {}),
                label: {
                  show: showLabel && (showLabel === 'true' || showLabel === true) ? true : false,
                  position: 'inside'
                }
              });
            }
          }
        }

        return {};
      });
      const option = cloneDeep(this.getOption());
      let series = await trendLabelTranslation(seriesarray, this.props.translationCache);
      if (
        this.props.graphprops &&
        this.props.graphprops.bar &&
        this.props.graphprops.bar.colors &&
        this.props.graphprops.bar.colors.length > 0
      ) {
        option.color = [...this.props.graphprops.bar.colors];
      }
      if (viewType === 'horizontal') {
        option.xAxis = [...yaxis];
        option.yAxis.data = descriptionList;
      } else {
        option.xAxis.data = [...dates];
        option.yAxis = [...yaxis];
      }
      option.series = series && Array.isArray(series) ? [...series] : series;
      option.legend = [...legendsdata];
      this.setState({
        option,
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;

    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '80%', width: '100%' }}
            className="react_for_echarts"
            theme="dark"
          />
        )}
      </Fragment>
    );
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      interval: this.props.graphprops.bar ? this.props.graphprops.bar['Interval'] : '',
      legend: {
        textStyle: { color: theme === 'lightTheme' ? 'black' : 'white' },
        data: [
          {
            name: 'test1',
            icon: 'roundRect'
          }
        ]
      },
      tooltip: {
        trigger: 'axis'
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            backgroundColor: theme === 'lightTheme' ? 'black' : 'black',
            title: 'download',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      xAxis: {
        type: 'category',
        data: this.props.graphprops,
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          },
          interval: 1
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
          // fontSize: 15,
        }
      },
      yAxis: {
        //type: "value",
        axisLine: {
          lineStyle: {
            // color: "white",
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          interval: 0,
          rotate: 90
        }
      },
      dataZoom: [
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,

          backgroundColor: '#9a9191',
          textStyle: {
            color: 'white'
          }
        },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ]
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(Bar);
