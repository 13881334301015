import React, { Component, Fragment } from 'react';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import { connect } from 'react-redux';
import { Button, message, Steps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';

import { getUserProfile } from '../../../selectors/layout';
import { StyledDashboard } from './styles';
import ColumnCustomization from './ColumnCustomization';
import ChildTableCustomization from './ChildTableCustomization';
import TableConfigCustomization from './TableConfigCustomization';
import ApiCustomization from './ApiCustomization';
import ChildTableApiCustomization from './ChildTableApiCustomization';
import WidgetCustomization from './WidgetCustomization';
import localStorage from '../../../utils/localStorage';
import './reportConfig.css';

const { Step } = Steps;
const uuid = require('uuid');

class ReportTypeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportType: props.reportType || '',
      reportList: [],
      editObj: props.editObj || {},
      currentTab: props.currentTab || '',
      reportObject: props.reportObject || {},
      userProfile: props.userProfile || {},
      currentStep: 0,
      disabled: false
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.editObj !== this.props.editObj) {
      this.setState({
        editObj: this.props.editObj,
        reportType: this.props.reportType,
        currentTab: this.props.currentTab,
        reportObject: this.props.reportObject || {},
        userProfile: this.props.userProfile
      });
    }
  }

  initalStep = () => {
    this.setState({ currentStep: 0 });
  };

  secondStep = () => {
    this.setState({ currentStep: 1 });
  };

  addColumn = () => {
    let { editObj } = this.state;
    if (editObj) {
      editObj = {
        ...editObj,
        columns: [
          ...(editObj['columns'] ? editObj['columns'] : []),
          {
            id: uuid(),
            title: 'New Column',
            dataIndex: '',
            key: '',
            width: '100',
            className: ''
          }
        ]
      };
    }
    // if (this.props.saveReportData === true) {
    //   if (editObj.JSON) {
    //     editObj = {
    //       ...editObj,
    //       columns: [
    //         ...(editObj && editObj.JSON && editObj.JSON['columns'] ? editObj.JSON['columns'] : []),
    //         {
    //           id: uuid(),
    //           title: 'New Column',
    //           dataIndex: '',
    //           key: '',
    //           width: '100',
    //           className: ''
    //         }
    //       ]
    //     };
    //   }
    // } else if (this.props.saveReportData === false) {
    //   if (editObj) {
    //     editObj = {
    //       ...editObj,
    //       columns: [
    //         ...(editObj['columns'] ? editObj['columns'] : []),
    //         {
    //           id: uuid(),
    //           title: 'New Column',
    //           dataIndex: '',
    //           key: '',
    //           width: '100',
    //           className: ''
    //         }
    //       ]
    //     };
    //   }
    // }

    this.setState({ editObj, disabled: false });
  };

  addChildTableColumn = () => {
    let { editObj } = this.state;
    if (editObj) {
      editObj = {
        ...editObj,
        childTable: [
          {
            columns: [
              ...(editObj &&
              editObj['childTable'] &&
              editObj['childTable'][0] &&
              editObj['childTable'][0]['columns']
                ? editObj['childTable'][0]['columns']
                : []),
              {
                id: uuid(),
                title: 'New Column',
                dataIndex: '',
                key: '',
                width: '100',
                className: ''
              }
            ]
          }
        ]
      };
    }

    this.setState({ editObj, currentStep: 1, disabled: false });
  };

  addTableConfiguration = () => {
    let { editObj } = this.state;

    if (editObj) {
      if (editObj['tableConfiguration']) {
        editObj = {
          ...editObj,
          tableConfiguration: editObj['tableConfiguration']
        };
      } else {
        editObj = {
          ...editObj,
          tableConfiguration: {
            ...(editObj['tableConfiguration'] ? editObj['tableConfiguration'] : []),
            bordered: 'true',
            horizontalScroll: 'true',
            tableSize: '10',
            noResultMessage: 'No Records',
            isCursorPointer: 'false',
            downloadXLXS: 'true',
            downloadExcelPayload: {
              portName: '',
              name: '',
              consumption: ''
            },
            style: {
              textAlign: 'center',
              fontWeight: 'normal',
              evenRowColor: 'rgb(251, 250, 250)',
              oddRowColor: '#fff'
            }
          }
        };
      }

      this.setState({ editObj, currentStep: 2, disabled: false });
    }
  };

  addApi = () => {
    let { editObj } = this.state;
    if (editObj) {
      if (editObj['api']) {
        editObj = {
          ...editObj,
          api: editObj['api']
        };
      } else {
        editObj = {
          ...editObj,
          api: {
            ...(editObj['api'] ? editObj['api'] : []),
            uri: 'uri',
            method: 'POST',
            payload: {},
            query: ''
          }
        };
      }
    }
    this.setState({ editObj, currentStep: 3, disabled: false });
  };

  addChildTableApi = () => {
    let { editObj } = this.state;
    if (editObj) {
      if (editObj && editObj['childTable'] && editObj['childTable'][0]['api']) {
        editObj = {
          ...editObj,
          childTable: [
            {
              columns: [
                {
                  ...(editObj['childTable'][0]['columns']
                    ? editObj['childTable'][0]['columns']
                    : [])
                }
              ],
              api: editObj['childTable'][0]['api']
            }
          ]
        };
      } else {
        editObj = {
          ...editObj,
          childTable: [
            {
              columns: [
                {
                  ...(editObj['childTable'] &&
                  editObj['childTable'][0] &&
                  editObj['childTable'][0]['columns']
                    ? editObj['childTable'][0]['columns']
                    : [])
                }
              ],
              api: {
                ...(editObj['childTable'] &&
                editObj['childTable'][0] &&
                editObj['childTable'][0]['api']
                  ? editObj['childTable'][0]['api']
                  : {}),
                uri: 'uri',
                method: 'POST',
                payload: {},
                query: ''
              }
            }
          ]
        };
      }
    }

    this.setState({ editObj, currentStep: 5, disabled: true });
  };

  addSelectionWidget = () => {
    let { editObj } = this.state;
    if (editObj) {
      if (editObj['selectionWidget']) {
        editObj = {
          ...editObj,
          selectionWidget: editObj['selectionWidget']
        };
      } else {
        editObj = {
          ...editObj,
          selectionWidget: {
            ...(editObj['selectionWidget'] ? editObj['selectionWidget'] : []),
            displayWidget: 'false',
            widget: 'month-picker',
            placeholder: '',
            format: 'YYYYY-MMMM',
            payload: {
              // date: "DDDD",
              // month: "MMMM",
              // year: "YYYY",
            }
          }
        };
      }
    }
    this.setState({ editObj, currentStep: 4, disabled: false });
  };

  jsonSaveObject = (columns, key) => {
    let { editObj } = this.state;

    if (editObj) {
      if (key === 'columns') {
        editObj = {
          ...editObj,
          columns: [...(columns ? columns : [])]
        };
      } else if (key === 'childTable') {
        editObj = {
          ...editObj,
          childTable: [
            {
              columns: [...(columns ? columns : [])]
            }
          ]
        };
      } else if (key === 'childTableApi') {
        editObj = {
          ...editObj,
          childTable: [
            {
              columns: [
                ...(editObj['childTable'][0]['columns'] ? editObj['childTable'][0]['columns'] : [])
              ],
              api: columns ? columns : {}
            }
          ]
        };
      } else {
        editObj = {
          ...editObj,
          [key]: {
            ...(columns ? columns : {})
          }
        };
      }
    }

    this.setState({ editObj });
  };

  generateJSON = () => {
    let { editObj } = this.state;
    copy(JSON.stringify(editObj));
    message.success('Copied to clipboard');
  };

  updateReportJSON = () => {
    let { editObj } = this.state;

    let payload = {
      JSON: editObj,
      tabName: this.props.tabName,
      reportType: this.props.reportType,
      reportName: this.props.reportName,
      createdBy: '',
      type: 'update'
    };

    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'POST',
      url: `/api/report/insertJSON?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(deviceTypeObject)
      .then(() => {
        message.success('ReportJSON Updated Successfully');
        this.props.updatefunction(this.props.reportObject);
      })
      .catch(function () {});
  };
  nextTab = (current) => {
    let { currentStep } = this.state;
    currentStep = current + 1;
    if (currentStep === 0) {
      this.addColumn();
    } else if (currentStep === 1) {
      this.addChildTableColumn();
    } else if (currentStep === 2) {
      this.addTableConfiguration();
    } else if (currentStep === 3) {
      this.addApi();
    } else if (currentStep === 4) {
      this.addSelectionWidget();
      // this.setState({ disabled: true });
    } else if (currentStep === 5) {
      this.addChildTableApi();
      this.setState({ disabled: true });
    }

    this.setState({ currentStep });
  };
  previousTab = (current) => {
    let { currentStep } = this.state;
    currentStep = current - 1;

    if (currentStep === 1) {
      this.addChildTableColumn();
    }
    if (currentStep === 2) {
      this.addTableConfiguration();
    } else if (currentStep === 3) {
      this.addApi();
    } else if (currentStep === 4) {
      this.addSelectionWidget();
    } else if (currentStep === 5) {
      this.addChildTableApi();
    }
    this.setState({ currentStep });
  };

  render() {
    const { editObj, currentTab, currentStep } = this.state;

    let steps = [
      {
        title: 'Columns',
        onClick: this.initalStep,
        content: (
          <div>
            <Button
              onClick={this.addColumn}
              title={'Add Column'}
              key={'Add Column'}
              type="primary"
              style={{ marginLeft: '5%', marginTop: '2%' }}
            >
              {' '}
              Add Columns
            </Button>
          </div>
        )
      },
      {
        title: 'ChildTable',
        onClick: this.secondStep,
        content: (
          <div>
            <Button
              onClick={this.addChildTableColumn}
              title={'Add ChildTable'}
              key={'Add ChildTable'}
              type="primary"
              style={{ marginLeft: '5%', marginTop: '2%' }}
            >
              <PlusOutlined /> Add ChildTable
            </Button>
          </div>
        )
      },

      {
        title: 'TableConfiguration',
        onClick: this.addTableConfiguration,
        content: this.addTableConfiguration
      },
      {
        title: 'Api',
        onClick: this.addApi,
        content: this.addApi
      },
      {
        title: 'SelectionWidget',
        onClick: this.addSelectionWidget,
        content: this.addSelectionWidget
      }
    ];
    if (
      editObj &&
      editObj['childTable'] &&
      editObj['childTable'][0] &&
      editObj['childTable'][0]['columns'].length > 0
    ) {
      steps.push({
        title: 'ChildTableApi',
        onClick: this.addChildTableApi,
        content: this.addChildTableApi
      });
    }

    let { disabled } = this.state;
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <div>
          {editObj && Object.keys.length > 0 ? (
            <Fragment>
              <Steps
                type="navigation"
                style={{ cursor: 'pointer' }}
                current={this.state.currentStep}
              >
                {steps &&
                  steps.map((item) => (
                    <Step key={item.title} title={item.title} onClick={item.onClick} />
                  ))}
              </Steps>
              <div className="configDiv" style={{ minHeight: '700px' }}>
                {steps && steps[currentStep].content}

                {editObj && editObj['columns'] && currentStep === 0 ? (
                  <div>
                    <ColumnCustomization
                      columnsProperties={editObj['columns']}
                      jsonSaveObject={this.jsonSaveObject}
                    />
                  </div>
                ) : null}
                {editObj && editObj['childTable'] && currentStep === 1 ? (
                  <div>
                    <ChildTableCustomization
                      columnsProperties={
                        editObj && editObj['childTable'] && editObj['childTable'][0].columns
                      }
                      jsonSaveObject={(data) => this.jsonSaveObject(data, 'childTable')}
                    />
                  </div>
                ) : null}
                {editObj && editObj['tableConfiguration'] && currentStep === 2 ? (
                  <div>
                    <TableConfigCustomization
                      tableProperties={editObj['tableConfiguration']}
                      jsonSaveObject={this.jsonSaveObject}
                    />
                  </div>
                ) : null}
                {editObj && editObj['api'] && currentStep === 3 ? (
                  <div>
                    <ApiCustomization
                      apiProperties={editObj['api']}
                      jsonSaveObject={this.jsonSaveObject}
                      currentTab={currentTab}
                    />
                  </div>
                ) : null}
                {editObj && editObj['selectionWidget'] && currentStep === 4 ? (
                  <div>
                    <WidgetCustomization
                      widgetProperties={editObj['selectionWidget']}
                      jsonSaveObject={this.jsonSaveObject}
                      currentTab={currentTab}
                    />
                  </div>
                ) : null}
                {editObj &&
                editObj['childTable'] &&
                editObj.childTable[0].api &&
                currentStep === 5 ? (
                  <div>
                    <ChildTableApiCustomization
                      apiProperties={
                        editObj && editObj['childTable'] && editObj['childTable'][0].api
                      }
                      jsonSaveObject={this.jsonSaveObject}
                    />
                  </div>
                ) : null}
              </div>
            </Fragment>
          ) : null}

          {currentStep === 0 ? (
            <div>
              <Button
                style={{ marginTop: '2%', marginLeft: '80%' }}
                type="primary"
                onClick={() => this.nextTab(currentStep)}
              >
                NEXT
              </Button>
            </div>
          ) : (
            <div>
              <Button
                style={{ marginTop: '2%', marginLeft: '70%' }}
                type="primary"
                onClick={() => this.previousTab(currentStep)}
              >
                PREVIOUS
              </Button>
              <Button
                disabled={disabled}
                style={{ marginTop: '2%', marginLeft: '2%' }}
                type="primary"
                onClick={() => this.nextTab(currentStep)}
              >
                NEXT
              </Button>
            </div>
          )}

          {(editObj && editObj['columns']) ||
          (editObj && editObj['childTable']) ||
          editObj['tableConfiguration'] ||
          editObj['api'] ||
          editObj['selectionWidget'] ? (
            <div>
              {currentTab === 'Generate JSON' ? (
                <Button type="primary" style={{ marginTop: '2px' }} onClick={this.generateJSON}>
                  Generate JSON
                </Button>
              ) : null}
              {currentTab === 'Edit Report JSON' ? (
                <Button style={{ marginTop: '2%' }} onClick={this.updateReportJSON} type="primary">
                  Save Report JSON
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});

export default connect(mapStateToProps)(ReportTypeComponent);
