export default {
  Rule_Name: 'Rule Name',
  Rule_Description: 'Rule Description',
  deviceRules: 'Equipment Rules',
  deviceTypeRules: 'Equipment Type Rules',
  nodeviceRules: 'Nodevice Rules',
  restApiRules: 'RestApi Rules',
  tableRules: 'Table Rules',
  type: 'Type',
  replicate: 'Replicate',
  add: 'Add',
  preventivemeasure: 'Preventive Measures',
  rootcauseanalysis: 'Possible Cause',
  recomandations: 'Recommendations',
  remove: 'Remove',
  addelseif: 'Add Other Condition',
  addelse: 'Add Another Condition',
  addrule: 'Add Rule',
  bodymessage: 'Remarks',
  createdBy: 'Created By',
  createdTime: 'Created Time',
  text: '',
  edit: 'Edit',
  delete: 'Delete',
  status: 'Notifications',
  //ph Are Place Holders
  phName: 'Please Enter Rule Name',
  phDescription: 'Please Enter Rule Description',
  device: ' Select Equipment',
  selectparameters: 'Select Parameter',
  selectoperator: 'Select Operator',
  value: 'Value',
  action: 'Communication Type',
  email: 'EMAIL',
  slack: 'SLACK',
  update: 'UPDATE',
  save: 'SAVE',
  cancel: 'CANCEL',
  selectCondition: 'Select Condition',
  selectDelay: 'Select Delay ',
  onchange: 'onChange',
  selecturl: 'Select Url',
  priority: 'Priority',
  selecttable: 'Select Table',
  selectcolumn: 'Select Coulmn',
  Enable: 'Click to Enable',
  Disable: 'Click to Disable',
  equipmentType: 'Equipment Type',
  members: 'Members',
  body: 'Body',
  equipmentTagIDandname: 'Equipment Tag ID and name',
  conditions: 'Conditions',
  sno: 'S.No',
  tagName: 'Tag Name',
  tagValue: 'Tag Value',
  exeTime: 'Execution Time',
  communicationType: 'CommunicationType',
  fdBack: 'Feedback',
  rootCause: 'Root Cause',
  timeStamp: 'TimeStamp',
  comment: 'Comment',
  Go_Back: 'Go Back',
  GET_DATA: 'Get Data'
};
