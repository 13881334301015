import React, { Component } from 'react';
import _ from 'lodash';
import { Form, message, Select, Tabs, DatePicker, Input, Button } from 'antd';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  submitWorkOrder,
  getJobPlansList,
  getJobPlanById,
  getWorkOrderName,
  // getLocationByAsset,
  getWorkTypes,
  getHierarchyList,
  getStatus,
  getWorkOrderById
} from '../../../Utils/FormCalls';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import TableSelect from '../../../Widgets/TableSelect';
// import HierarchyModal from '../../../Widgets/HierarchyModal';
import { Toolsform, MaterialForm } from './WorkOrderTasks';
import localStorage from '../../../../../utils/localStorage';
import { decode as base64_decode } from 'base-64';
import { StyledTable, StyledBox, StyledBoxLabel } from '../../../CSS/style';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import history from '../../../../../commons/history';
import themeSettings from '../../../Utils/themeSettings.json';
import WorkOrderJobPlanes from './WorkOrderJobPlanes';
import FailureCodeDetails from '../../EquipmentRegister/FailureCodeMaster/FailureCodeDetails';
import moment from 'moment';
import {
  StyledButton,
  StyledComponent,
  StyledForm,
  StyledSubForm,
  StyledTabs,
  StyledModal
} from '../../../CSS/style';
import { constant } from '../../../Utils/constants';
import { connect } from 'react-redux';
import FailureCodesList from './FailureCodesList';
import FileBase64 from '../../../Utils/FileUpload';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import translation from '../../../Utils/translation';
import InputComponent from '../../../Widgets/InputComponent';

const { Option } = Select;
const { TabPane } = Tabs;
const fileData = new FormData();
const { TextArea } = Input;
class WorkOrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      workTypesList: [],
      statusList: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},

      activeKey: '1',
      selectedJobPlan:
        props.formObject && props.formObject.JobPlanName ? props.formObject.JobPlanName : '',
      jobplanActivekey: '1',
      hierarchyList: [],
      visible: false,
      fileData: [],
      enableTask: true,
      viewFCModal: false,
      fileUploadList: {}
    };
  }
  workOrderRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    try {
      let hierarchyList = await getHierarchyList();
      this.setState({ hierarchyList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    let deviceList = await getDeviceList();
    this.setState({ deviceList });
    let organisationsList = await getOrganisationsList();
    this.setState({ organisationsList: organisationsList });
    let sitesList = await getSitesList();
    this.setState({ sitesList: sitesList });
    let JobPlansList = await getJobPlansList('Active');
    this.setState({ JobPlansList: JobPlansList });
    let workTypes = await getWorkTypes();
    this.setState({ workTypesList: workTypes });

    try {
      let statusList = await getStatus();
      this.setState({ statusList });
      let APPRStatus =
        statusList && Array.isArray(statusList)
          ? statusList.filter((status) => status.Status === 'APPR')
          : [];
      let defaultWorkType =
        workTypes &&
        Array.isArray(workTypes) &&
        workTypes.filter((wt) => wt.WorkType === 'Emergency Maintenance');
      let StatusId = APPRStatus && APPRStatus[0] ? APPRStatus[0].Id : undefined;
      let wt =
        defaultWorkType && Array.isArray(defaultWorkType) && defaultWorkType[0]
          ? defaultWorkType[0].Id
          : '';
      if (this.state.formObject && !this.state.formObject.Id) {
        this.handleChange(StatusId, 'StatusId');
        this.handleChange(wt, 'WorkTypeId');
      }
    } catch (error) {
      // message.warn(`${error.message}`);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.workOrderRef.current.setFieldsValue(this.props.formObject);
      // this.formRef.current.setFieldsValue(this.props.formObject.TaskData)
    }

    if (prevProps.formObject !== this.props.formObject) {
      if (this.props.formObject && !this.props.formObject.WorkOrder) {
        let workOrderName = await getWorkOrderName();
        this.handleChange(workOrderName['WorkOrder'], Object.keys(workOrderName)[0]);
      }
      this.setState({
        formObject: this.props.formObject
      });
    }
  };
  getHierarchyLevels = (key) => {
    let { hierarchyList } = this.state;
    const siteId = localStorage.get('currentSite');
    let payload = {
      HierarchyId:
        hierarchyList && Array.isArray(hierarchyList) && hierarchyList[0] && hierarchyList[0].Id
          ? hierarchyList[0].Id
          : null,
      Type: 'Device'
    };
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/getHierarchyLevelsForAMM?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    return axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            treeData: data,
            [key]: true,
            // visible: formObject && formObject.Id ? false : true,
            formObject: this.workOrderRef.current.getFieldsValue()
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // hierarchyDropdownChange = (value) => {
  //   let formObject = this.workOrderRef.current.getFieldsValue();
  //   formObject = {
  //     ...formObject,
  //     Device: value.Code,
  //     EquipmentDescription: value.Name
  //   };
  //   this.workOrderRef.current.setFieldsValue(formObject);
  //   this.setState({
  //     visible: false,
  //     formObject
  //   });
  // };
  onDrawerCancel = (e) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    if (formObject.Equipment === undefined && e.target.tagName === 'DIV') {
      e.preventDefault();
      return message.error(`Please Click On Cancel`);
    } else {
      this.setState({
        visible: false
      });
    }
  };
  onModalOk = () => {
    this.setState({
      visible: false
    });
  };
  parentNode = (parent) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Asset: parent.Code,
      AssetDescription: parent.Name
    };
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({
      visible: false,
      formObject
    });
  };
  remove = (mainindex, key) => {
    let formObject = this.workOrderRef.current.getFieldValue();
    formObject = {
      ...formObject,
      [key]:
        formObject &&
        formObject[key] &&
        formObject[key].filter((tool, index) => index !== mainindex)
    };
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ formObject });
  };
  // handleSelect = (value, mainIndex, subindex, keyName) => {
  //   // let list = this.formRef.current.getFieldValue("TaskData")
  //   let list = {};
  //   let TaskData = list;
  //   TaskData.map((item, index) => {
  //     if (index === mainIndex) {
  //       item[keyName][subindex]['TotalQuantity'] = value;
  //     }
  //     return {};
  //   });
  //   // this.formRef.current.setFieldsValue({ "TaskData": TaskData })
  // };
  _getDataById = async (Id) => {
    let { statusList } = this.state;
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/EWODetails/');
    let formId = Id ? Id : base64_decode(path[1]);
    if (formId !== 'new') {
      this.setState({
        enableTask: false
      });
      let data = await getWorkOrderById(formId, 'withId');
      formObject = data ? data : {};
      _.cloneDeepWith(formObject, (value) => {
        return value !== null
          ? {
              ...value,
              ...(value['ActualStart']
                ? (value['ActualStart'] = moment
                    .utc(value.ActualStart, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),
              ...(value['ActualEnd']
                ? (value['ActualEnd'] = moment.utc(value.ActualEnd, 'YYYY-MM-DD HH:mm:ss').local())
                : null),
              ...(value['RecordDate']
                ? (value['RecordDate'] = moment
                    .utc(value.RecordDate, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),

              ...(value['JobPlan'] &&
                value['JobPlan']['TaskData'] &&
                value['JobPlan']['TaskData'].map((item, i) => {
                  return value['JobPlan']['TaskData'][i]
                    ? (value['JobPlan']['TaskData'][i]['StartTime']
                        ? (value['JobPlan']['TaskData'][i]['StartTime'] = moment
                            .utc(
                              value['JobPlan']['TaskData'][i]['StartTime'],
                              'YYYY-MM-DD HH:mm:ss'
                            )
                            .local())
                        : null,
                      value['JobPlan']['TaskData'][i]['EndTime']
                        ? (value['JobPlan']['TaskData'][i]['EndTime'] = moment
                            .utc(value['JobPlan']['TaskData'][i]['EndTime'], 'YYYY-MM-DD HH:mm:ss')
                            .local())
                        : null)
                    : null;
                }))
            }
          : // _.cloneDeepWith(value["JobPlan"]['TaskData'], (value) => {
            //   return (value && value !== null && value !== undefined && value.StartTime) ||
            //     (value && value !== null && value !== undefined && value.EndTime) ||
            //     (value && value !== null && value !== undefined && value.MeasurementDate)
            //     ? {
            //         ...value,
            //         ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
            //         ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss')),
            //         ...(value['MeasurementDate'] = moment.utc(
            //           value.MeasurementDate,
            //           'YYYY-MM-DD HH:mm:ss'
            //         ))
            //       }
            //     : _.noop();
            // });
            _.noop();
      });
    } else if (Id) {
      let data = await getWorkOrderById(Id, 'withId');
      formObject = data ? data : {};
      _.cloneDeepWith(formObject, (value) => {
        return value !== null
          ? {
              ...value,
              ...(value['ActualStart']
                ? (value['ActualStart'] = moment
                    .utc(value.ActualStart, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),
              ...(value['ActualEnd']
                ? (value['ActualEnd'] = moment.utc(value.ActualEnd, 'YYYY-MM-DD HH:mm:ss').local())
                : null),
              ...(value['RecordDate']
                ? (value['RecordDate'] = moment
                    .utc(value.RecordDate, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),

              ...(value['JobPlan'] &&
                value['JobPlan']['TaskData'] &&
                value['JobPlan']['TaskData'].map((item, i) => {
                  return value['JobPlan']['TaskData'][i]
                    ? (value['JobPlan']['TaskData'][i]['StartTime']
                        ? (value['JobPlan']['TaskData'][i]['StartTime'] = moment
                            .utc(
                              value['JobPlan']['TaskData'][i]['StartTime'],
                              'YYYY-MM-DD HH:mm:ss'
                            )
                            .local())
                        : null,
                      value['JobPlan']['TaskData'][i]['EndTime']
                        ? (value['JobPlan']['TaskData'][i]['EndTime'] = moment
                            .utc(value['JobPlan']['TaskData'][i]['EndTime'], 'YYYY-MM-DD HH:mm:ss')
                            .local())
                        : null)
                    : null;
                }))
            }
          : // _.cloneDeepWith(value["JobPlan"]['TaskData'], (value) => {
            //   return (value && value !== null && value !== undefined && value.StartTime) ||
            //     (value && value !== null && value !== undefined && value.EndTime) ||
            //     (value && value !== null && value !== undefined && value.MeasurementDate)
            //     ? {
            //         ...value,
            //         ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
            //         ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss')),
            //         ...(value['MeasurementDate'] = moment.utc(
            //           value.MeasurementDate,
            //           'YYYY-MM-DD HH:mm:ss'
            //         ))
            //       }
            //     : _.noop();
            // });
            _.noop();
      });
    } else {
      if (formObject && !formObject.WorkOrder) {
        let workOrderName = await getWorkOrderName();
        this.handleChange(
          workOrderName && workOrderName['WorkOrder'] ? workOrderName['WorkOrder'] : '',
          workOrderName && Object.keys(workOrderName) && Array.isArray(Object.keys(workOrderName))
            ? Object.keys(workOrderName)[0]
            : ''
        );
        let WAPPRStatus =
          statusList && Array.isArray(statusList)
            ? statusList.filter((status) => status.Status === 'WAPPR')
            : [];
        let StatusId = WAPPRStatus && WAPPRStatus[0] ? WAPPRStatus[0].Id : undefined;
        this.handleChange(StatusId, 'StatusId');
        this.handleChange(6, 'WorkTypeId');
      }
      formObject = {
        ...formObject,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };
    }
    this.setState({
      formObject
    });
    // _.cloneDeepWith(formObject, (value) => {
    //   return value !== null
    //     ? {
    //         ...value,
    //         ...(value['ActualStart']
    //           ? (value['ActualStart'] = moment.utc(value.ActualStart, 'YYYY-MM-DD HH:mm:ss'))
    //           : null),
    //         ...(value['ActualEnd']
    //           ? (value['ActualEnd'] = moment.utc(value.ActualEnd, 'YYYY-MM-DD HH:mm:ss'))
    //           : null),
    //         ...(value['RecordDate']
    //           ? (value['RecordDate'] = moment.utc(value.RecordDate, 'YYYY-MM-DD HH:mm:ss'))
    //           : null),

    //         ...(value['JobPlan'] &&
    //           value['JobPlan']['TaskData'] &&
    //           value['JobPlan']['TaskData'].map((item, i) => {
    //             return value['JobPlan']['TaskData'][i]
    //               ? (value['JobPlan']['TaskData'][i]['StartTime']
    //                   ? (value['JobPlan']['TaskData'][i]['StartTime'] = moment
    //                       .utc(value['JobPlan']['TaskData'][i]['StartTime'], 'YYYY-MM-DD HH:mm:ss')
    //                       .local())
    //                   : null,
    //                 value['JobPlan']['TaskData'][i]['EndTime']
    //                   ? (value['JobPlan']['TaskData'][i]['EndTime'] = moment
    //                       .utc(value['JobPlan']['TaskData'][i]['EndTime'], 'YYYY-MM-DD HH:mm:ss')
    //                       .local())
    //                   : null)
    //               : null;
    //           }))
    //       }
    //     : // _.cloneDeepWith(value["JobPlan"]['TaskData'], (value) => {
    //       //   return (value && value !== null && value !== undefined && value.StartTime) ||
    //       //     (value && value !== null && value !== undefined && value.EndTime) ||
    //       //     (value && value !== null && value !== undefined && value.MeasurementDate)
    //       //     ? {
    //       //         ...value,
    //       //         ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
    //       //         ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss')),
    //       //         ...(value['MeasurementDate'] = moment.utc(
    //       //           value.MeasurementDate,
    //       //           'YYYY-MM-DD HH:mm:ss'
    //       //         ))
    //       //       }
    //       //     : _.noop();
    //       // });
    //       _.noop();
    // });
    if (formObject && this.workOrderRef.current) {
      this.workOrderRef.current.setFieldsValue(formObject);
    }
  };
  handleChange = async (value, key) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [key]: value
    };
    // if (key === 'Device') {
    //   let selectedDevice = deviceList.filter((item) => item.AliasCode === value);
    //   let location = await getLocationByAsset(selectedDevice[0].Asset);
    //   formObject = {
    //     ...formObject,
    //     [key]: value,
    //     EquipmentDescription: selectedDevice[0].Name,
    //     Asset: location && location.Name ? location.Name : '',
    //     AssetDescription: location && location.AssetCode ? location.AssetCode : ''
    //   };
    //   // this.workOrderRef.current.setFieldsValue({"LocationId":location["Name"]})
    // }
    if (this.workOrderRef && this.workOrderRef.current) {
      this.workOrderRef.current.setFieldsValue(formObject);
    }

    this.setState({ [key]: value, formObject });
  };

  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    let { fileUploadList } = this.state;

    // if (formObject && formObject[key]) {
    //   formObject[key].push(...values);
    // } else if (formObject[key] !== undefined) {
    //   formObject[key] = values;
    // } else {
    //   formObject[key].push({ [key]: values });
    // }

    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    let WO = [...(fileUploadList && fileUploadList.WO ? fileUploadList.WO : [])];
    WO.push({ fileDataObject });
    fileUploadList = {
      ...fileUploadList,
      WO
    };

    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileUploadList });
  };

  onFinish = async (values, key, fileData) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      [key]: {
        ...formObject[key],
        ...values
      }
    };
    this.setState({ formObject, fileData });
    this.workOrderRef.current.setFieldsValue(formObject);
  };
  onFormsubmit = async () => {
    let { fileUploadList } = this.state;

    let formObjectRef = this.workOrderRef.current.getFieldsValue();
    let formObject1 = {
      ...formObjectRef,
      RecordDate: formObjectRef.RecordDate
        ? moment(formObjectRef.RecordDate).utc().format('YYYY-MM-DD HH:mm:ss')
        : null,
      ActualStart: formObjectRef.ActualStart
        ? moment(formObjectRef.ActualStart).utc().format('YYYY-MM-DD HH:mm:ss')
        : null,
      ActualEnd: formObjectRef.ActualEnd
        ? moment(formObjectRef.ActualEnd).utc().format('YYYY-MM-DD HH:mm:ss')
        : null,
      Type: 'Emergency'
    };
    let statusResponse = await submitWorkOrder(formObject1, fileUploadList);
    if (statusResponse && statusResponse.message) {
      this._getDataById(statusResponse.Id);
      message.success('WorkOrder Submitted Successfully');
      this.onReset();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  onReset = () => {
    this.workOrderRef.current.resetFields();
  };
  // onFinishFailed = () => {
  //   message.error('Please enter mandatory fields');
  // };
  jobPlanOnChange = async (value) => {
    let jobplanData = await getJobPlanById(value, 'withoutId');
    let formObject =
      this.workOrderRef.current !== null && this.workOrderRef.current.getFieldsValue()
        ? this.workOrderRef.current.getFieldsValue()
        : {};
    _.cloneDeepWith(jobplanData['TaskData'], (value) => {
      return value !== null && value.ActualStart
        ? {
            ...value,
            ...(value['ActualStart'] = moment.utc(value.ActualStart, 'YYYY-MM-DD HH:mm:ss')),
            ...(value['ActualEnd'] = moment.utc(value.ActualEnd, 'YYYY-MM-DD HH:mm:ss'))
          }
        : _.noop();
    });
    formObject = { ...formObject, JobPlan: jobplanData };
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ selectedJobPlan: value, formObject });
  };
  handleSelect = (value, subindex, keyName, selectedObject) => {
    let formObject = this.workOrderRef.current.getFieldValue();
    let TaskData =
      this.workOrderRef.current.getFieldValue() &&
      this.workOrderRef.current.getFieldValue()[keyName]
        ? this.workOrderRef.current.getFieldValue()[keyName]
        : [];
    TaskData.map((item, index) => {
      if (index === subindex) {
        item['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
        item['Description'] = selectedObject[0]['Description'];
        // this.setState({
        //   view
        // })
      }
      return {};
    });
    formObject[keyName] = TaskData;
    this.setState({ formObject });
    this.workOrderRef.current.setFieldsValue(formObject);
  };
  // getDisabledHours = (e, key) => {
  //   let hours = [];
  //   let minutes = [];
  //   let StartDateTime = '';
  //   let EndDateTime = '';
  //   let StartDate = '';
  //   let EndDate = '';

  //   if (key === 'ActualStart') {
  //     StartDateTime = moment(e).format('YYYY-MM-DD HH:mm:ss');
  //     EndDateTime = moment
  //       .utc(
  //         this.workOrderRef.current.getFieldValue() &&
  //           this.workOrderRef.current.getFieldValue('ActualEnd'),
  //         'YYYY-MM-DD HH:mm:ss'
  //       )
  //       .local();

  //     StartDate = moment(e).format('YYYY-MM-DD');
  //     EndDate = moment
  //       .utc(
  //         this.workOrderRef.current.getFieldValue() &&
  //           this.workOrderRef.current.getFieldValue('ActualEnd')
  //       )
  //       .local()
  //       .format('YYYY-MM-DD HH:mm:ss');
  //   } else if (key === 'ActualEnd') {
  //     StartDateTime = moment
  //       .utc(
  //         this.workOrderRef.current.getFieldValue() &&
  //           this.workOrderRef.current.getFieldValue('ActualStart'),
  //         'YYYY-MM-DD HH:mm:ss'
  //       )
  //       .local();
  //     EndDateTime = moment(e).format('YYYY-MM-DD HH:mm:ss');

  //     StartDate = moment
  //       .utc(
  //         this.workOrderRef.current.getFieldValue() &&
  //           this.workOrderRef.current.getFieldValue('ActualStart')
  //       )
  //       .local()
  //       .format('YYYY-MM-DD HH:mm:ss');
  //     EndDate = moment(e).format('YYYY-MM-DD HH:mm:ss');
  //   }
  //   if (
  //     key === 'ActualStart' &&
  //     moment(StartDate).isSame(
  //       moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
  //     )
  //   ) {
  //     for (let i = 0; i <= 24; i++) {
  //       if (i > moment(EndDateTime).hours()) {
  //         hours.push(i);
  //       }
  //     }
  //     if (moment(StartDate).isSame(moment(EndDate))) {
  //       for (var i = 0; i <= 60; i++) {
  //         if (i > moment(EndDateTime).minutes() - 1) {
  //           minutes.push(i);
  //         }
  //       }
  //     }
  //     return {
  //       disabledHours: () => hours,
  //       disabledMinutes: () => minutes
  //     };
  //   } else if (
  //     key === 'ActualEnd' &&
  //     moment(StartDate).isSame(
  //       moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
  //     )
  //   ) {
  //     for (let i = 0; i < moment(StartDateTime).hours(); i++) {
  //       if (i < 25) {
  //         hours.push(i);
  //       }
  //     }

  //     if (moment(StartDate).isSame(moment(EndDate))) {
  //       for (let i = 0; i < moment(StartDateTime).minutes() + 1; i++) {
  //         if (i < 61) {
  //           minutes.push(i);
  //         }
  //       }
  //     }
  //     return {
  //       disabledHours: () => hours,
  //       disabledMinutes: () => minutes
  //     };
  //   }
  // };
  getDisabledHours = (e, key) => {
    let hours = [];
    let minutes = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';

    if (key === 'ActualStart') {
      StartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      EndDateTime = moment
        .utc(
          this.workOrderRef.current.getFieldValue() &&
            this.workOrderRef.current.getFieldValue('ActualEnd'),
          'YYYY-MM-DD HH:mm'
        )
        .local();

      StartDate = moment(e).format('YYYY-MM-DD');
      EndDate = moment
        .utc(
          this.workOrderRef.current.getFieldValue() &&
            this.workOrderRef.current.getFieldValue('ActualEnd')
        )
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'ActualEnd') {
      StartDateTime = moment
        .utc(
          this.workOrderRef.current.getFieldValue() &&
            this.workOrderRef.current.getFieldValue('ActualStart'),
          'YYYY-MM-DD HH:mm'
        )
        .local();
      EndDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      StartDate = moment
        .utc(
          this.workOrderRef.current.getFieldValue() &&
            this.workOrderRef.current.getFieldValue('ActualStart')
        )
        .local()
        .format('YYYY-MM-DD');
      EndDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'ActualStart' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (let i = 0; i <= 24; i++) {
        if (i > moment(EndDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var i = 0; i <= 60; i++) {
          if (i > moment(EndDateTime).minutes() - 1) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'ActualEnd' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (let i = 0; i < moment(StartDateTime).hours(); i++) {
        if (i < 25) {
          hours.push(i);
        }
      }

      if (moment(StartDate).isSame(moment(EndDate))) {
        for (let i = 0; i < moment(StartDateTime).minutes() + 1; i++) {
          if (i < 61) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };
  NestedSelectOnChange = (value, key) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      [key]: [...value]
    };
    this.setState({ formObject });
    this.workOrderRef.current.setFieldsValue(formObject);
  };
  fileAppend = (fileDataObject) => {
    fileData.append('files', fileDataObject);
    this.setState({ fileData });
  };
  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  handleTotalDownTime = (formObject1) => {
    let { formObject } = this.state;
    formObject = {
      ...(formObject ? formObject : {}),
      ...(formObject1 ? formObject1 : {})
    };
    this.setState({
      formObject
    });
    this.workOrderRef.current.setFieldsValue(formObject);
  };
  onFCModalSubmit = () => {
    this.setState({
      viewFCModal: false
    });
  };
  onFCModalCancel = () => {
    this.setState({
      viewFCModal: false
    });
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.workOrderRef.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  TableDropdownChange = (value, modal) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Device: value.AliasCode,
      Equipment: value.AliasCode,
      DeviceCode: value.DeviceCode,
      DisplayCode: value.DisplayCode,
      EquipmentDescription: value.Name,
      LocationId: value.Asset,
      LocationDescription: value.AssetDescription
    };
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      enableTask: false,
      [modal]: false
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, `Search`)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, `Reset`)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  datepickerchange = (date, dateString, key) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      [key]: date
    };
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ formObject });
  };
  render() {
    let {
      // deviceList,
      sitesList,
      formObject,
      selectedJobPlan,
      workTypesList,
      statusList
    } = this.state;
    let { translationCache } = this.props;
    const columns = [
      {
        title: translation(translationCache, 'Type'),
        dataIndex: 'Type',
        key: 'Type',
        render: (text) => <div>{translation(translationCache, text)}</div>
      },
      {
        title: translation(translationCache, 'FailueCode'),
        dataIndex: 'value',
        key: 'value'
      },
      {
        title: translation(translationCache, 'Description'),
        dataIndex: 'Description',
        key: 'Description'
      },
      {
        title: translation(translationCache, 'Remarks'),
        dataIndex: 'Remarks',
        key: 'Remarks'
      }
    ];
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '0px 30px', minHeight: window.innerHeight - 143 }}
        >
          <>
            <StyledForm
              ref={this.workOrderRef}
              name="EmergencyWorkOrder"
              theme={themeSettings}
              initialValues={formObject}
              onFinish={this.onFormsubmit}
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
              // onFinishFailed={this.onFinishFailed}
              layout="horizontal"
              labelCol={{ flex: '130px' }}
              labelAlign="right"
              labelWrap
              //wrapperCol={{ flex: 1 }}
              validateMessages={validateMessages}
            >
              {/* {formObject && formObject.Id ? (
                <div
                  style={{
                    color: 'white',
                    margin: '19px 37px',
                    fontSize: '17px',
                    fontWeight: 'bold',
                    marginBottom: '35px'
                  }}
                >
                  {formObject && formObject.SiteId ? (
                    <span>
                      <span style={{ color: '#ffb327' }}>
                        {this.getValueFromList(
                          sitesList,
                          'Id',
                          this.workOrderRef.current
                            ? this.workOrderRef.current.getFieldValue('SiteId')
                            : '',
                          'Name'
                        )}{' '}
                      </span>{' '}
                      (Site)
                      <span style={{ margin: '10px', color: 'white', fontSize: '17px' }}>
                        {' '}
                        /{' '}
                      </span>{' '}
                    </span>
                  ) : null}
                  {formObject && formObject.Asset ? (
                    <span>
                      <span style={{ color: '#ffb327' }}>
                        {' '}
                        {this.workOrderRef.current
                          ? this.workOrderRef.current.getFieldValue('Asset')
                          : null}
                      </span>{' '}
                      (Location)
                      <span style={{ margin: '10px', color: 'white' }}> / </span>
                    </span>
                  ) : null}
                  {formObject && formObject.Device ? (
                    <span>
                      <span style={{ color: '#ffb327' }}>
                        {' '}
                        {this.getValueFromList(
                          deviceList,
                          'DeviceCode',
                          this.workOrderRef.current
                            ? this.workOrderRef.current.getFieldValue('Device')
                            : '',
                          'Name'
                        )}
                      </span>{' '}
                      (Equipment)
                    </span>
                  ) : null}
                </div>
              ) : null} */}
              <div style={{ marginLeft: '93%' }}>
                <Form.Item>
                  <StyledButton theme={themeSettings} htmlType="submit">
                    {translation(translationCache, constant.submit)}
                  </StyledButton>
                </Form.Item>
              </div>
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
                <StyledBoxLabel>
                  {translation(translationCache, 'Trouble Reporting')}
                </StyledBoxLabel>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Form.Item name="Id"></Form.Item>
                  <Form.Item
                    label={translation(translationCache, constant.workOrder)}
                    name="WorkOrder"
                    style={{
                      width: '100%',
                      flex: '50%',
                      marginTop: '40px'
                    }}
                    rules={[{ required: true }]}
                  >
                    <InputComponent
                      // disabled={formObject && formObject.Id ? true : false}
                      placeholder={translation(translationCache, 'Enter WorkOrderName')}
                      style={{ width: '70%' }}
                      value={'WO-11'}
                      disabled={true}
                      onChange={(e) => this.handleChange(e.target.value, 'WorkOrder')}
                    />
                  </Form.Item>

                  <Form.Item
                    label={translation(translationCache, constant.WorkOrderdescription)}
                    name="Description"
                    style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                    rules={[{ required: true }]}
                  >
                    <InputComponent
                      //maxLength={150}
                      placeholder={translation(translationCache, 'Enter WorkOrderDescription')}
                      style={{ width: '70%' }}
                      onChange={(e) => this.handleChange(e.target.value, 'Description')}
                    />
                  </Form.Item>
                  <Form.Item name="Device"></Form.Item>
                  <Form.Item
                    style={{
                      width: '100%',
                      flex: '50%'
                    }}
                    label={translation(translationCache, constant.equipment)}
                    name="DisplayCode"
                    rules={[
                      { required: true },
                      {
                        // pattern: new RegExp(/^[^%!^ ]*$/)
                      }
                    ]}
                  >
                    <Input
                      //maxLength={150}
                      disabled={true}
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, 'Select Equipment')}
                      suffix={
                        <DownOutlined
                          style={{
                            color: '#1a3b5c',
                            cursor: formObject && formObject.Id ? 'not-allowed' : null,
                            opacity: formObject && formObject.Id ? '0.8' : null
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (formObject && !formObject.Id) {
                              this.getDeviceData('visible');
                            }
                          }}
                        />
                      }
                    />
                  </Form.Item>
                  <StyledModal
                    visible={this.state.visible}
                    width={800}
                    closable
                    title={translation(translationCache, 'Hierarchy')}
                    onOk={this.onModalOk}
                    onCancel={this.onDrawerCancel}
                  >
                    <TableSelect
                      data={this.state.tableData}
                      translationCache={this.props.translationCache}
                      key="Equipment"
                      columns={[
                        {
                          title: translation(translationCache, 'Equipment'),
                          dataIndex: 'DisplayCode',
                          key: 'DisplayCode',
                          ...this.getColumnSearchProps('DisplayCode')
                        },
                        {
                          title: translation(translationCache, 'Equipment Description'),
                          dataIndex: 'Name',
                          key: 'Name',
                          ...this.getColumnSearchProps('Name')
                        }
                      ]}
                      handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
                    />
                    {/* <HierarchyModal
                      value={this.state.deviceId}
                      treeData={this.state.treeData}
                      translationCache={this.props.translationCache}
                      language={this.props.language}
                      handleDropdownChange={this.hierarchyDropdownChange}
                      handleParentNode={this.parentNode}
                      deviceList={this.state.deviceList}
                    /> */}
                  </StyledModal>
                  <Form.Item
                    style={{
                      width: '100%',
                      flex: '50%'
                      // display: formObject && formObject.Id ? 'none' : true
                    }}
                    label={translation(translationCache, constant.equipmentDescription)}
                    name="EquipmentDescription"
                    rules={[{ required: true }]}
                  >
                    <InputComponent
                      //maxLength={150}
                      style={{ width: '70%' }}
                      placeholder={translation(
                        translationCache,
                        'Autopopulate based on equipment selection'
                      )}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      width: '100%',
                      flex: '50%'
                      // display: formObject && formObject.Id ? 'none' : true
                    }}
                    label={translation(translationCache, constant.location)}
                    name="LocationId"
                    rules={[{ required: true }]}
                  >
                    <InputComponent
                      placeholder={translation(
                        translationCache,
                        'Autopopulate based on equipment selection'
                      )}
                      disabled={true}
                      style={{ width: '70%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      width: '100%',
                      flex: '50%'
                      // display: formObject && formObject.Id ? 'none' : true
                    }}
                    label={translation(translationCache, constant.locationDescription)}
                    name="LocationDescription"
                    rules={[{ required: true }]}
                  >
                    <InputComponent
                      //maxLength={150}
                      placeholder={translation(
                        translationCache,
                        'Autopopulate based on equipment selection'
                      )}
                      disabled={true}
                      style={{ width: '70%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={translation(translationCache, constant.recordedDate)}
                    style={{ width: '100%', flex: '50%' }}
                    name={'RecordDate'}
                  >
                    <DatePicker
                      style={{ width: '70%' }}
                      format="YYYY-MM-DD HH:mm:ss"
                      placeholder={translation(translationCache, 'Select RecordDate')}
                      inputReadOnly={true}
                      showNow={false}
                      // onChange={() => {
                      //   // this.datepickerchange(date, dateString, 'RecordDate');
                      // }}
                      showTime={{
                        defaultValue: moment('00:00:00', 'HH:mm:ss')
                      }}
                      disabledDate={(d) =>
                        !d || d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="SiteId"
                    // disabled={formObject && formObject.Id ? true : false}
                    // rules={[{ required: true }]}
                    // style={{
                    //   width: '100%',
                    //   flex: '50%'
                    //   // display: formObject && formObject.Id ? 'none' : true
                    // }}
                  >
                    {/* <Select
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, 'Select Site')}
                      onChange={(value) => this.handleChange(value, 'SiteId')}
                    >
                      {sitesList &&
                        Array.isArray(sitesList) &&
                        sitesList.map((site, index) => {
                          return (
                            <Option key={index} value={site.Id}>
                              {' '}
                              {site.Name}{' '}
                            </Option>
                          );
                        })}
                    </Select> */}
                  </Form.Item>

                  <Form.Item
                    style={{ width: '100%', flex: '50%' }}
                    label={translation(translationCache, constant.workType)}
                    name="WorkTypeId"
                  >
                    <Select
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, 'Select Work Type')}
                      onChange={(value) => this.handleChange(value, 'WorkTypeId')}
                      disabled={true}
                    >
                      {workTypesList.map((workType, index) => {
                        return (
                          <Option key={index} value={workType.Id}>
                            {' '}
                            {translation(translationCache, workType.WorkType)}{' '}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ width: '100%', flex: '50%' }}
                    label={translation(translationCache, constant.actualStart)}
                    name={'ActualStart'}
                  >
                    <DatePicker
                      style={{ width: '70%' }}
                      format="YYYY-MM-DD HH:mm:ss"
                      placeholder={translation(translationCache, 'Select ActualStart')}
                      inputReadOnly={true}
                      showNow={false}
                      onChange={(date, dateString) => {
                        this.datepickerchange(date, dateString, 'ActualStart');
                      }}
                      showTime={true}
                      disabledDate={(d) =>
                        !d ||
                        d.isAfter(
                          this.workOrderRef.current.getFieldValue() &&
                            this.workOrderRef.current.getFieldValue('ActualEnd')
                            ? moment(this.workOrderRef.current.getFieldValue('ActualEnd'))
                                .add(1, 'day')
                                .format('YYYY-MM-DD')
                            : ''
                        )
                      }
                      disabledTime={(e) =>
                        this.getDisabledHours(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'ActualStart')
                      }
                      // disabledDate={(d) =>
                      //   //     this.workOrderRef.current.getFieldValue("ActualEnd")
                      //   // ? moment(this.workOrderRef.current.getFieldValue("ActualEnd")).add(1, "day").format("YYYY-MM-DD") : ""
                      //   //         ))
                      //   !d ||
                      //   d.isAfter(
                      //     this.workOrderRef.current.getFieldValue() &&
                      //       this.workOrderRef.current.getFieldValue().ActualEnd
                      //       ? moment(this.workOrderRef.current.getFieldValue().ActualEnd)
                      //           .add(1, 'day')
                      //           .format('YYYY-MM-DD')
                      //       : ''
                      //   ) ||
                      //   d.isBefore(
                      //     this.workOrderRef.current.getFieldValue() &&
                      //       this.workOrderRef.current.getFieldValue().ActualStart
                      //       ? moment(this.workOrderRef.current.getFieldValue().ActualStart).format(
                      //           'YYYY-MM-DD'
                      //         )
                      //       : ''
                      //   ) ||
                      //   d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                      // }
                      // disabledTime={(e) =>
                      //   this.getDisabledHours(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'ActualStart')
                      // }
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '100%', flex: '50%' }}
                    label={translation(translationCache, constant.actualEnd)}
                    name={'ActualEnd'}
                  >
                    <DatePicker
                      style={{ width: '70%' }}
                      format="YYYY-MM-DD HH:mm:ss"
                      showNow={false}
                      placeholder={translation(translationCache, 'Select ActualEnd')}
                      onChange={() => {
                        // this.datepickerchange(date, dateString, 'ActualEnd');
                      }}
                      disabled={
                        this.workOrderRef.current &&
                        this.workOrderRef.current.getFieldsValue() &&
                        this.workOrderRef.current.getFieldsValue()['ActualStart']
                          ? false
                          : true
                      }
                      inputReadOnly={true}
                      disabledDate={(d) =>
                        !d ||
                        d.isBefore(
                          this.workOrderRef.current &&
                            this.workOrderRef.current !== null &&
                            this.workOrderRef.current.getFieldValue() &&
                            this.workOrderRef.current.getFieldValue('ActualStart')
                            ? moment(
                                this.workOrderRef.current &&
                                  this.workOrderRef.current !== null &&
                                  this.workOrderRef.current.getFieldValue() &&
                                  this.workOrderRef.current.getFieldValue('ActualStart')
                              ).format('YYYY-MM-DD')
                            : ''
                        )
                      }
                      disabledTime={(e) =>
                        this.getDisabledHours(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'ActualEnd')
                      }
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: moment('23:59:59', 'HH:mm:ss')
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: '100%', flex: '50%' }}
                    label={translation(translationCache, constant.status)}
                    name="StatusId"
                  >
                    <Select
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, 'Select Status')}
                      onChange={(value) => this.handleChange(value, 'StatusId')}
                      disabled={true}
                    >
                      {statusList.map((status, index) => {
                        return (
                          <Option key={index} value={status.Id}>
                            {' '}
                            {status.Status}{' '}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{
                      width: '100%',
                      flex: '50%'
                      // display: formObject && formObject.Id ? 'none' : true
                    }}
                    label={translation(translationCache, constant.site)}
                    name="SiteId"
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, constant.SelectSite)}
                      disabled={formObject && formObject.Id ? true : false}
                      onChange={(e) => this.handleChange(e, 'SiteId')}
                    >
                      {sitesList &&
                        Array.isArray(sitesList) &&
                        sitesList.map((site, index) => {
                          return (
                            <Option key={index} value={site.Id}>
                              {' '}
                              {site.Name}{' '}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={translation(translationCache, constant.TotalDowntime)}
                    name={'TotalDowntime'}
                    rules={[
                      { required: false, message: 'Autopopulte based on Task Estimated Duration!' }
                    ]}
                    style={{ width: '100%', flex: '50%' }}
                  >
                    <InputComponent
                      disabled={true}
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, constant.autopopulateBasedOnTask)}
                      // onChange={(value) => this.handleChange(value, 'WorkOrderCreationTime')}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: '100%', flex: '50%' }}
                    name="Attachments"
                    label={translation(translationCache, constant.attachments)}
                  >
                    <FileBase64
                      className={'formuploadInput'}
                      downloadClassName={'formdownloadInput'}
                      Attachments={formObject.Attachments}
                      fileUpload={(values, key, fileDataObject) => {
                        this.fileupload(values, key, fileDataObject);
                      }}
                    ></FileBase64>{' '}
                  </Form.Item>
                  <Form.Item
                    label={translation(translationCache, constant.summary)}
                    name={'Summary'}
                    style={{ width: '100%', flex: '50%' }}
                  >
                    <TextArea
                      rows={3}
                      style={{ width: '70%' }}
                      placeholder={translation(translationCache, 'Enter Summary')}
                    />
                  </Form.Item>
                  <Form.Item style={{ width: '100%', flex: '50%' }}>
                    <InputComponent style={{ display: 'none' }} />
                  </Form.Item>
                </div>
              </StyledBox>
              <StyledBox>
                <StyledBoxLabel>{translation(translationCache, 'Failure Class')}</StyledBoxLabel>
                <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                  <div
                    style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: '5px' }}
                  >
                    <StyledButton
                      theme={themeSettings}
                      onClick={() => {
                        this.setState({ viewFCModal: true });
                      }}
                    >
                      {translation(translationCache, constant.addFailureClass)}
                    </StyledButton>
                  </div>
                  <Form.Item name="FailureCodes" style={{ width: '100%', flex: '50%' }}>
                    {selectedJobPlan === '' ? (
                      <FailureCodesList
                        NestedSelectOnChange={this.NestedSelectOnChange}
                        // onFinishFailed={this.onFinishFailed}
                        formObject={formObject['JobPlan']}
                        translationCache={this.props.translationCache}
                        text={
                          formObject &&
                          formObject['FailureCodes'] &&
                          formObject['FailureCodes'][0] &&
                          formObject['FailureCodes'][0]['value']
                            ? formObject['FailureCodes'][0]['value']
                            : ''
                        }
                      ></FailureCodesList>
                    ) : null}
                    {formObject &&
                    formObject['FailureCodes'] &&
                    Array.isArray(formObject['FailureCodes']) ? (
                      <StyledTable
                        theme={themeSettings}
                        style={{ padding: '20px 15px', border: '1px solid red !important' }}
                        columns={columns}
                        dataSource={
                          formObject['FailureCodes'] &&
                          formObject['FailureCodes'].filter((item) => {
                            return item.Type !== undefined && item.Type !== 'Failure Class';
                          })
                        }
                      />
                    ) : null}
                  </Form.Item>
                </div>
              </StyledBox>
              <StyledBox>
                {/* <StyledBoxLabel></StyledBoxLabel> */}
                <StyledSubForm theme={themeSettings}>
                  <StyledTabs
                    theme={themeSettings}
                    defaultActiveKey="1"
                    type="card"
                    size={'middle'}
                  >
                    {/* <TabPane tab="FailureCodes" key="1">
                      <Form.Item name="FailureCodes">
                        {selectedJobPlan === '' ? (
                          <FailureCodesList
                            NestedSelectOnChange={this.NestedSelectOnChange}
                            // onFinishFailed={this.onFinishFailed}
                            formObject={formObject['JobPlan']}
                            text={
                              formObject &&
                                formObject['FailureCodes'] &&
                                formObject['FailureCodes'][0] &&
                                formObject['FailureCodes'][0]['value']
                                ? formObject['FailureCodes'][0]['value']
                                : ''
                            }
                          ></FailureCodesList>
                        ) : null}
                        {formObject &&
                          formObject['FailureCodes'] &&
                          Array.isArray(formObject['FailureCodes']) ? (
                          <StyledTable
                            theme={themeSettings}
                            style={{ marginTop: '10px', border: '1px solid  red !important' }}
                            columns={columns}
                            dataSource={
                              formObject['FailureCodes'] &&
                              formObject['FailureCodes'].filter((item) => {
                                return item.Type !== undefined;
                              })
                            }
                          />
                        ) : null}
                      </Form.Item>
                    </TabPane> */}
                    <TabPane tab={translation(translationCache, constant.taskInformation)} key="2">
                      <Form.Item name="JobPlan">
                        {selectedJobPlan === '' ? (
                          <WorkOrderJobPlanes
                            addTask={this.state.enableTask}
                            Equipment={this.state.formObject}
                            handleTotalDownTime={this.handleTotalDownTime}
                            fileData={this.state.fileData}
                            onFinish={this.onFinish}
                            // onFinishFailed={this.onFinishFailed}
                            formObject={formObject['JobPlan']}
                            fileAppend={this.fileAppend}
                          ></WorkOrderJobPlanes>
                        ) : null}
                      </Form.Item>
                    </TabPane>
                    <TabPane tab={translation(translationCache, constant.material)} key="3">
                      <Form.Item>
                        <MaterialForm
                          formObject={
                            this.workOrderRef.current !== null
                              ? this.workOrderRef.current.getFieldsValue()
                              : {}
                          }
                          onMaterialChange={this.handleSelect}
                          onRemove={this.remove}
                        />
                      </Form.Item>
                    </TabPane>
                    <TabPane tab={translation(translationCache, constant.tools)} key="4">
                      <Form.Item>
                        <Toolsform
                          formObject={
                            this.workOrderRef.current !== null
                              ? this.workOrderRef.current.getFieldsValue()
                              : {}
                          }
                          onToolChange={this.handleSelect}
                          onRemove={this.remove}
                        />
                      </Form.Item>
                    </TabPane>
                    {/* <TabPane tab="Follow-up Work" key="4"> */}
                    {/* <FollowUpWork onFinish={this.onFinish} formObject={formObject["FollowUpWork"]}></FollowUpWork> */}
                    {/* </TabPane> */}
                    {/* <TabPane tab="Failure Report" key="6"> */}
                    {/* <FailureReport onFinish={this.onFinish} formObject={formObject["FailureReport"]}></FailureReport> */}
                    {/* </TabPane> */}
                  </StyledTabs>
                </StyledSubForm>
              </StyledBox>
              <StyledModal
                visible={this.state.viewFCModal}
                width={800}
                closable
                title={translation(translationCache, 'Add Failure Class')}
                footer={null}
                // onOk={this.onFCModalOk}
                onCancel={this.onFCModalCancel}
              >
                <FailureCodeDetails
                  EWOModal={true}
                  onFCModalSubmit={this.onFCModalSubmit}
                  onFCModalCancel={this.onFCModalCancel}
                  formObject={{}}
                  // getFailureCodeList={this.ongetFCList}
                />
              </StyledModal>
            </StyledForm>
          </>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkOrderForm);
