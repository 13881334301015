import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Form, Input } from 'antd';
import { UserOutlined, LockOutlined, AimOutlined } from '@ant-design/icons';

import localStorage from '../../../utils/localStorage';
import rubusLogo from '../../../images/rubus_new/Toki-Logo-1.jpg';
import config from '../../../constants/config.json';
import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';

import { HeaderLogo, Welcome, Add, LoginButton } from './styles';

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      translationCache: props.translationCache || [],
      userToken: props.userToken || ''
    };
  }

  UNSAFE_componentWillReceiveProps({ userToken }) {
    this.setState({ userToken });
  }

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { reset } = config;
    const userToken = localStorage.get('accessToken');
    if (
      userToken !== 'undefined' &&
      userToken !== 'null' &&
      userToken !== '' &&
      userToken !== undefined &&
      userToken !== null
    ) {
      return <Redirect to="/rubus/LandingPage" />;
    }

    const onFinish = (values) => {
      localStorage.set('loggedIn', true);
      this.props.actions.userLoginAuth(values);
    };

    // const onFinishFailed = () => {
    //   message.error(this.translation('Please enter valid username and password'));
    // };

    return (
      <section style={{ width: '100%' }}>
        <HeaderLogo>
          <img src={rubusLogo} alt="logo" style={{ height: '60px' }} />
        </HeaderLogo>

        <Welcome style={{ fontSize: '17px', color: '#444343' }}>
          {this.translation('Welcome back, sign in with your account')}
        </Welcome>
        {/* <WelcomeModule>{this.translation('Rubus Product')}</WelcomeModule> */}
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="siteName"
            wrapperCol={{ offset: 6, span: 16 }}
            rules={[{ required: true, message: this.translation('Please input your Site Name') }]}
          >
            <Input
              style={{ width: '380px' }}
              prefix={<AimOutlined />}
              placeholder={this.translation('SiteName')}
            />
          </Form.Item>
          <Form.Item
            name="identifier"
            wrapperCol={{ offset: 6, span: 16 }}
            rules={[
              {
                required: true,
                message: this.translation('Please input your username/email/mobilenumber!!')
              }
            ]}
          >
            <Input
              style={{ width: '380px' }}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={this.translation('Username/Email/Mobilenumber!!')}
            />
          </Form.Item>

          <Form.Item
            name="password"
            wrapperCol={{ offset: 6, span: 16 }}
            rules={[{ required: true, message: this.translation('Please input your password!') }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              style={{ width: '380px' }}
              placeholder={this.translation('Password')}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <LoginButton type="primary" htmlType="submit">
              {this.translation('Log in')}
            </LoginButton>
          </Form.Item>
        </Form>

        {reset ? (
          <Add>
            {this.translation('Forgot your password?')}{' '}
            <Link to={{ pathname: `/forget-password` }}>{this.translation('Reset password')}</Link>
          </Add>
        ) : (
          ''
        )}
      </section>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(LoginComponent);
