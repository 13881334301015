/* eslint-disable no-undef */
import localStorage from '../../../../../utils/localStorage';
import axios from 'axios';
import moment from 'moment';
import { message } from 'antd';

export async function getList(payload) {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    ...payload,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function deleteById(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = {
    ...payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getTaskList(type) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/masterTask/list?SiteId=${siteId}&Type=${type}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getTaskListByEquipment(type, equipmentId) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/masterTask/list?SiteId=${siteId}&Type=${type}&Equipment=${equipmentId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getTaskListByEquipmentandTaskType(type, equipmentId, tasktype) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/masterTask/list?SiteId=${siteId}&Type=${type}&Equipment=${equipmentId}&type=${tasktype}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function changeTaskStatus(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/masterTask/changestatus?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getTaskById(jobplanId, type) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GEt',
    url: `/api/masterTask/getById?Id=${jobplanId}&Type=${type}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function getTaskByIdFDM(jobplanId, type) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GEt',
    url: `/api/masterTask/getById?Id=${jobplanId}&Type=${type}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function getTaskById1(jobplanId) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/masterTask/list?SiteId=${siteId}&Id=${jobplanId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getWorkFlow(workFlowId) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GEt',
    url: `/api/masterWorkFlow/json?Id=${workFlowId}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  try {
    return axios(Object).then((response) => {
      return response.data;
    });
  } catch (e) {
    if (err.response.status === 400 || err.response.status === 500) {
      message.info(err.response.data.message);
    }
  }
}
export async function deleteTask(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/masterTask/delete?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function submitTask(payload, fileData) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const SiteId = localStorage.get('currentSite');
  let data = {
    url: `/api/masterTask/insert?SiteId=${SiteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: { ...payload, SiteId }
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `/api/masterWorkorder/saveFile?SiteId=${SiteId}&Id=${
            response.data.Id
          }&Type=${'Task'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      message.error(err.response.data.message);
    });
}
export function filterTaskByReference(TaskList, recordData) {
  let res = [];
  res = TaskList.filter((el) => {
    return (
      recordData &&
      !recordData.find((element) => {
        return element.TaskName === el.TaskName;
      })
    );
  });
  return res;
}

// ===========================Jobplan Api's=============================
export async function getJobPlansList(type) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/masterJobPlan/list?SiteId=${siteId}&Type=${type}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getJobPlansListByEquipment(type, Equipment) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/masterJobPlan/list?SiteId=${siteId}&Type=${type}&Equipment=${Equipment}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getJobPlansListByEquipmentandType(type, Equipment, JobPlanType) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/masterJobPlan/list?SiteId=${siteId}&Type=${type}&Equipment=${Equipment}&JobPlanType=${JobPlanType}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getJobPlanById(jobplanId, type) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GEt',
    url: `/api/masterJobPlan/getById?Id=${jobplanId}&Type=${type}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function submitJobPlan(payload, fileUploadList) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/masterJobPlan/insert?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };

  return axios(data)
    .then((response) => {
      if (
        fileUploadList &&
        fileUploadList.TaskData &&
        Array.isArray(fileUploadList.TaskData) &&
        fileUploadList.TaskData.length > 0
      ) {
        fileUploadList.TaskData.map((fileDataObject1) => {
          let fileData = new FormData();
          let { fileDataObject, TaskId } = fileDataObject1;
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `/api/masterWorkOrder/saveFile?SiteId=${siteId}&Id=${
                response.data.Id
              }&Type=${'JobPlan'}&TaskId=${TaskId}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: Authorization
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch(() => {});
          }
          return {};
        });
        return response.data;
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.response.status === 400 || error.response.status === 500) {
        message.info(error.response.data.message);
      }
      return error;
    });
}
export async function deleteJob(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/masterJobPlan/delete?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function changeJobPlanStatus(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/masterJobPlan/changestatus?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export function filterJobPlanByReference(TaskList, recordData) {
  let res = [];
  res = TaskList.filter((el) => {
    return !recordData.find((element) => {
      return element.TaskName === el.TaskName;
    });
  });
  return res;
}
// ===========================Tools,Materials,labor Api's===========================
export async function getToolsList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getList = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterName: 'Master_Tools',
      ActionType: 'read',
      Conditions: [
        {
          key: 'Status',
          value: 'Active'
        }
      ]
    }
  };
  return axios(getList);
}
export async function getLaborList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getList = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterName: 'Master_Labor',
      ActionType: 'read',
      Conditions: [
        {
          key: 'Status',
          value: 'Active'
        }
      ]
    }
  };
  return axios(getList);
}
export async function getMaterialsList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getList = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterName: 'Master_Materials',
      ActionType: 'read',
      Conditions: [
        {
          key: 'Status',
          value: 'Active'
        }
      ]
    }
  };
  return axios(getList);
}
export async function getItemsList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getList = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterName: 'Master_Items',
      ActionType: 'read',
      Conditions: [
        {
          key: 'Status',
          value: 'Active'
        }
      ]
    }
  };
  return axios(getList);
}
// export function checkAvailability(_, value, key, index, TaskData) {
//   if (typeof (TaskData[key][index]['AvailableQuantity']) === number) {
//     if (value !== undefined) {
//       if (value <= TaskData[key][index]['AvailableQuantity']) {
//         return Promise.resolve();
//       }
//       else {
//         return Promise.reject(new Error('Quantity should not be greater than AvailableQuantity!'));
//       }
//     }
//   }
//   else {
//     // return Promise.resolve();
//     return Promise.reject(new Error('accepts only zero'));
//   }
// }

export function checkAvailability(_, value, key, index, TaskData, validation) {
  if (TaskData[key] === undefined) {
    if (value !== undefined) {
      if (value === 0) {
        return Promise.resolve();
      } else if (
        validation &&
        validation['validateDecimal'] &&
        validation['validateDecimal'] === true &&
        value &&
        value % 1 !== 0
      ) {
        return Promise.reject(new Error('Quantity should not be decimal'));
      } else if (value > 0 || value < 0) {
        return Promise.reject(new Error('accepts only zero'));
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
      // return Promise.reject(new Error('accepts only zero'));
    }
  } else {
    if (value !== undefined) {
      if (
        validation &&
        validation['validateDecimal'] &&
        validation['validateDecimal'] === true &&
        value &&
        value % 1 !== 0
      ) {
        return Promise.reject(new Error('Available quantity should not be decimal'));
      } else {
        if (value <= TaskData[key][index]['AvailableQuantity'] && value > 0) {
          return Promise.resolve();
        }
        // else if (value < TaskData[key][index]['AvailableQuantity']) {
        //   return Promise.reject(new Error('Quantity should not be negative value!'));
        // }
        else if (value > TaskData[key][index]['AvailableQuantity'] && value > 0) {
          return Promise.reject(
            new Error('Quantity should not be greater than AvailableQuantity!')
          );
        }
      }
    } else {
      return Promise.resolve();
      // return Promise.reject(new Error('accepts only zero'));
    }
  }
}

export function checkDecimalValue(_, value, key, record, validation) {
  if (value !== undefined) {
    if (value === 0) {
      return Promise.resolve();
    } else if (
      validation &&
      validation['validateDecimal'] &&
      validation['validateDecimal'] === true &&
      value &&
      value % 1 !== 0
    ) {
      return Promise.reject(new Error('Value should not be decimal'));
    } else if (value < 0) {
      return Promise.reject(new Error('accepts only zero'));
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
}

export function checkLimit(value, key, record, validation) {
  if (value !== undefined) {
    if (
      value &&
      validation &&
      validation.validateField &&
      validation.operator &&
      validation.operator === 'less' &&
      record &&
      record[validation.validateField] &&
      parseInt(record[validation.validateField]) <= parseInt(value)
    ) {
      return Promise.reject(
        new Error(`${key} Should be ${validation.operator} than ${validation.validateField}`)
      );
    } else if (
      value &&
      validation &&
      validation.validateField &&
      validation.operator &&
      validation.operator === 'greater' &&
      record &&
      record[validation.validateField] &&
      parseInt(record[validation.validateField]) >= parseInt(value)
    ) {
      return Promise.reject(
        new Error(`${key} Should be ${validation.operator} than ${validation.validateField}`)
      );
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
}

export function check(value, key, validation) {
  if (value !== undefined) {
    if (
      validation &&
      validation['validateDecimal'] &&
      validation['validateDecimal'] === true &&
      value &&
      value % 1 !== 0
    ) {
      return Promise.reject(new Error('Available quantity should not be decimal'));
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
}
export function checkTask(_, value, key, index, form, validation) {
  if (form && form[key] === undefined) {
    if (value !== undefined) {
      if (value === 0) {
        return Promise.resolve();
      } else if (
        validation &&
        validation['validateDecimal'] &&
        validation['validateDecimal'] === true &&
        value &&
        value % 1 !== 0
      ) {
        return Promise.reject(new Error('Quantity should not be decimal'));
      } else if (value > 0 || value < 0) {
        return Promise.reject(new Error('accepts only zero'));
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
      // return Promise.reject(new Error('accepts only zero'));
    }
  } else {
    if (value !== undefined) {
      if (
        validation &&
        validation['validateDecimal'] &&
        validation['validateDecimal'] === true &&
        value &&
        value % 1 !== 0
      ) {
        return Promise.reject(new Error('Quantity should not be decimal'));
      } else {
        if (value <= form[key][index]['AvailableQuantity'] && value > 0) {
          return Promise.resolve();
        }
        // else if (value < TaskData[key][index]['AvailableQuantity']) {
        //   return Promise.reject(new Error('Quantity should not be negative value!'));
        // }
        else if (value > form[key][index]['AvailableQuantity'] && value > 0) {
          return Promise.reject(
            new Error('Quantity should not be greater than AvailableQuantity!')
          );
        } else {
          return Promise.resolve();
        }
      }
    } else {
      return Promise.resolve();
      // return Promise.reject(new Error('accepts only zero'));
    }
  }
}
// ===========================Workorder Api's===========================
export async function getWorkOrdersList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/masterWorkorder/list?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getWorkOrderById(workOrderId, type) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/masterWorkorder/getById?Id=${workOrderId}&Type=${type}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getConditionMonitorById(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/conditionalMonitoring/getById?Id=${Id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function deleteWorkOrder(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/masterWorkorder/delete?Id=${id}&SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function deleteConditionMonitor(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/conditionalMonitoring/delete?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function changeWorkOrderStatus(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/masterWorkOrder/changestatus?Id=${id}&SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getWorkOrderName() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/masterWorkorder/getId?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getInspectionName() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/inspectionconfig/getId?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function submitWorkOrder(payload, fileUploadList) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/masterWorkorder/insert?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (
        fileUploadList &&
        fileUploadList.WO &&
        Array.isArray(fileUploadList.WO) &&
        fileUploadList.WO.length > 0
      ) {
        fileUploadList.WO.map((fileDataObject1) => {
          let fileData = new FormData();
          let { fileDataObject } = fileDataObject1;
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `/api/masterWorkOrder/saveFile?SiteId=${siteId}&Id=${
                response.data.Id
              }&Type=${'MainWorkOrder'}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: Authorization
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch(() => {});
          }
          return {};
        });
      }
      if (
        fileUploadList &&
        fileUploadList.TaskData &&
        Array.isArray(fileUploadList.TaskData) &&
        fileUploadList.TaskData.length > 0
      ) {
        fileUploadList.TaskData.map((Tasks) => {
          let fileData = new FormData();
          let { fileDataObject, TaskId } = Tasks;
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `/api/masterWorkOrder/saveFile?SiteId=${siteId}&TaskId=${TaskId}&Id=${
                response.data.Id
              }&Type=${'WorkOrder'}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: Authorization
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch(() => {});
          }
          return {};
        });
      }
      if (
        fileUploadList &&
        fileUploadList.ActualTaskData &&
        Array.isArray(fileUploadList.ActualTaskData) &&
        fileUploadList.ActualTaskData.length > 0
      ) {
        fileUploadList.ActualTaskData.map((Tasks) => {
          let fileData = new FormData();
          let { fileDataObject, TaskId } = Tasks;
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `/api/masterWorkOrder/saveFile?SiteId=${siteId}&TaskId=${TaskId}&Id=${
                response.data.Id
              }&Type=${'WorkOrder'}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: Authorization
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch(() => {});
          }
          return {};
        });
      }
      if (
        fileUploadList &&
        fileUploadList.JobPlan &&
        Array.isArray(fileUploadList.JobPlan) &&
        fileUploadList.JobPlan.length > 0
      ) {
        fileUploadList.JobPlan.map((Tasks) => {
          let fileData = new FormData();
          let { fileDataObject, JobPlanId } = Tasks;
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `/api/masterWorkOrder/saveFile?SiteId=${siteId}&Id=${JobPlanId}&Type=${'JobPlan'}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: Authorization
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch(() => {});
          }
          return {};
        });
      }
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function submitConditionMonitor(payload, fileData) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `/api/conditionalMonitoring/insert?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `/api/masterWorkorder/saveFile?SiteId=${siteId}&Id=${response.data.Id}&Type=${'CM'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            if (err.response.status === 400 || err.response.status === 500) {
              message.info(err.response.data.message);
            }
          });
      } else {
        return response.data;
      }
    })

    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getEmergencyWorkOrdersList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  const Object = {
    method: 'GET',
    url: `/api/masterWorkorder/list?SiteId=${siteId}&Type=${'Emergency'}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getOverview(equipment) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  const Object = {
    method: 'GET',
    url: `/api/masterAssetDetails/getEquipmentOverviewDetailsById?SiteId=${siteId}&Equipment=${equipment}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getConditionMoitorList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  const Object = {
    method: 'GET',
    url: `/api/conditionalMonitoring/list?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getFieldDataWorkOrdersList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/masterWorkorder/list?SiteId=${siteId}&Type=${'ESchedule'}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
// here-cm feedback apis

export async function getRuleExecutionInfo(ruleId, value) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getRuleExecutionInfo = {
    method: 'POST',
    url: `/api/ruleEngine/getRuleStatus?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SourceType: 'CM',
      RuleId: ruleId,
      StartDate: moment(value[0]).format('YYYY-MM-DD HH:mm'),
      EndDate: moment(value[1]).format('YYYY-MM-DD HH:mm'),
      SiteId: siteId
    }
  };
  return axios(getRuleExecutionInfo)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        message.info(error.response.data.message);
      }
    });
}
export async function saveFeedback(feedBackInfo) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const saveFeedback = {
    method: 'POST',
    url: `/api/ruleEngine/saveFeedbackData?SiteId=${siteId}`,
    data: feedBackInfo,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(saveFeedback)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        message.info(error.response.data.message);
      }
    });
}
export async function getfeedBackInfo(ruleId) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  let Authorization = 'Bearer ' + token;
  const getRuleExecutionInfo = {
    method: 'GET',
    url: `/api/ruleEngine/getFeedbackList/${ruleId}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getRuleExecutionInfo)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        message.info(error.response.data.message);
      }
    });
}
export async function updateFeedBack(feedBackInfo) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getRuleExecutionInfo = {
    method: 'POST',
    url: `/api/ruleEngine/updateFeedback?SiteId=${siteId}`,
    data: feedBackInfo,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getRuleExecutionInfo)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        message.info(error.response.data.message);
      }
    });
}
// ===========================PM Api's===========================
export async function getPMList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/scheduler/list?SiteId=${siteId}&Type=${'PM'}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getPMByID(workOrderId) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/pm/getById?Id=${workOrderId}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  try {
    return axios(Object).then((response) => {
      return response.data;
    });
  } catch (e) {
    return [];
  }
}
export async function deletePM(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/pm/delete?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function submitPM(payload, fileData) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/pm/insert?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `/api/masterWorkorder/saveFile?SiteId=${siteId}&Id=${
            response.data.message
          }&Type=${'WorkOrder'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })

    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function changePMStatus(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/pm/changeStatus?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getWorkTypeId() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/pm/getId?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getWorkTypes() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { MasterName: 'Master_WorkTypes', ActionType: 'read' }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
// ===========================Workflow Api's ===========================
export async function getWorkFlows(WorkTypeId) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'eam."Site_Master_WorkFlows"',
      ColumnName: 'WorkFlowName',
      Conditions: [
        {
          key: 'Status',
          value: 'Active'
        },
        {
          key: 'WorkTypeId',
          value: WorkTypeId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getStatus() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { MasterName: 'Master_WorkOrder_Status', ActionType: 'read' }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getInspectionScheduleList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/scheduler/list?SiteId=${siteId}&Type=${'ESchedule'}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

// ===========================SR Api's===========================
export async function getSRList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/serviceReq/list?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function submitSR(payload, fileData) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/serviceReq/insert?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `/api/masterWorkorder/saveFile?SiteId=${siteId}&Id=${
            response.data.message
          }&Type=${'WorkOrder'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        message.error(error.response.data.message);
      }
    });
}
export async function submitScheduleInspection(payload, fileData) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/scheduler/insert?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `/api/masterWorkorder/saveFile?SiteId=${siteId}&Id=${
            response.data.message
          }&Type=${'WorkOrder'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        message.error(error.response.data.message);
      }
    });
}
export async function getServiceReqId() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/serviceReq/getId?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function changeSRStatus(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/serviceReq/changeStatus?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getSRByID(workOrderId) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/serviceReq/getById?Id=${workOrderId}&Type=withId&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  try {
    return axios(Object).then((response) => {
      return response.data;
    });
  } catch (e) {
    return {};
  }
}
export async function deleteSR(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/serviceReq/delete?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

// ===========================Dropdown Api's===========================
export async function getDeviceList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getDeviceList = {
    method: 'GET',
    url: `/api/device/get?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceList)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getParentDevicelist(device) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getDeviceList = {
    method: 'GET',
    url: `/api/device/get?SiteId=${siteId}&DeviceType=${device}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceList)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getAssetList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getAssetList = {
    method: 'GET',
    url: `/api/asset/get?SiteId=${siteId}&AssetType=${'location'}&ParentAsset=${null}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getAssetList)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function questionsListMaster() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const questionsListMaster = {
    method: 'POST',
    url: `/api/inspectionQuestions/master?action=read&SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(questionsListMaster)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getAnswersList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const Answers = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterName: 'Master_Answers',
      ActionType: 'read',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Answers)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getWorkPlanningList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const Answers = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterName: 'Site_Master_WorkPlanningNames',
      ActionType: 'read',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Answers)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getLocationByAsset(device) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getLocation = {
    method: 'GET',
    url: `/api/asset/get?SiteId=${siteId}&AssetCode=${device}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getLocation)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getOrganisationsList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getOrganisationsList = {
    method: 'GET',
    url: `/api/organisation/get`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getOrganisationsList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getSitesList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getSitesList = {
    method: 'GET',
    url: `/api/site/get?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getSitesList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getSkillTypeList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getequipmentList = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterName: 'Master_Skills',
      ActionType: 'read'
    }
  };
  return axios(getequipmentList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getequipmentList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getequipmentList = {
    method: 'POST',
    url: `/api/device/cache?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      Module: '',
      SiteId: siteId
    }
  };
  return axios(getequipmentList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getParameterList(DeviceAlias) {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getParameterList = {
    method: 'GET',
    url: `/api/parameter/list?ParameterCategory=Operational&SiteId=${siteId}&DeviceAlias=${DeviceAlias}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getParameterList)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getUomList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getUomList = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'appconfig."Site_Master_UnitOfMeasures"',
      ColumnName: 'Name'
    }
  };
  return axios(getUomList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getCmpointList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getCmpointList = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'eam."Site_Master_CBM_Types"',
      ColumnName: 'Name',
      conditionList: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getCmpointList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getwarningTypeList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getwarningTypeList = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'eam."Site_Master_WarningTypes"',
      ColumnName: 'WarningType',
      conditionList: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getwarningTypeList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getnotificationTypeList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getnotificationTypeList = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'eam."Site_Master_NotificationTypes"',
      ColumnName: 'NotificationType',
      conditionList: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getnotificationTypeList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getLocationList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getSitesList = {
    method: 'GET',
    url: `/api/location/list`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getSitesList);
}

export async function getItemTypeList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getItemTypeList = {
    method: 'GET',
    url: `/api/location/list`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getItemTypeList);
}
export async function getManufacturerList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterName: 'Master_Manufacturers',
      ActionType: 'read'
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getScannerId(EquipmentId) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  let headers = {
    method: 'POST',
    url: `/api/device/generateScannerId?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { DeviceCode: EquipmentId }
  };
  return axios(headers)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
}

export async function getManufacturerDescriptionList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `/api/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterName: 'Master_Manufacturers',
      ActionType: 'read'
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getUsersList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/user/get?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function workOrderStatus(payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `/api/masterWorkorder/status?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getQuestionList(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `/api/masterWorkorder/status`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getStatusList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'eam."Master_Status"',
      ColumnName: 'Status',
      Conditions: [
        {
          key: 'Type',
          value: 'JobPlan'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
///////hiiii
export async function getequipList() {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdow?SiteId=${siteId}n`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'appconfig."Site_Master_UnitOfMeasures"',
      ColumnName: 'Code'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getUOMList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'appconfig."Site_Master_UnitOfMeasures"',
      ColumnName: 'Code'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getFrequency() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'master."Site_Master_Frequency"',
      ColumnValue: 'Id',
      ColumnName: 'Frequency',
      Conditions: [
        {
          key: 'Module',
          value: 'EAM'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getStatusListPM() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'eam."Master_Status"',
      ColumnName: 'Status',
      ColumnValue: 'Id',
      Conditions: [
        {
          key: 'Type',
          value: 'PM'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getStatusListInspection() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'eam."Master_Status"',
      ColumnName: 'Status',

      Conditions: [
        {
          key: 'Type',
          value: 'InspectionCron'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getStatusListTask() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'eam."Master_Status"',
      ColumnName: 'Status',

      Conditions: [
        {
          key: 'Type',
          value: 'Task'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getitemList() {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'eam."Master_Status"',
      ColumnName: 'Status',
      Conditions: [
        {
          key: 'Type',
          value: 'Task'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getHierarchyList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let headers = {
    method: 'GET',
    url: `/api/hierarchymodule/mainHierarchyList?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(headers)
    .then(({ data }) => {
      if (Array.isArray(data)) {
        //this.setState({ datasource: data });
        return data;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
export async function getFailureCodeList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'Master_FailureCodes',
      ActionType: 'read'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function insertModalData(url, ActionType, payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let Object = {
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterName: url,
      ActionType: ActionType,
      data: payload
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function insertCause(payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let Object = {
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterName: 'Master_ProblemCodes',
      ActionType: 'create',
      data: payload
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function submitMaster(url, payload, fileData, type) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${url}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `/api/masterWorkorder/saveFile?SiteId=${siteId}&Id=${response.data.Id}&Type=${type}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        message.error(error.response.data.message);
      }
    });
}
export async function submitMasterFailureCode(url, payload, fileData, type) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${url}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `/api/asset/saveFile?SiteId=${siteId}&Id=${response.data.Id}&Type=${type}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            if (
              err &&
              err.response &&
              (err.response.status === 400 || err.response.status === 500)
            ) {
              message.info(err.response.data.message);
            }
          });
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}
export async function _getUOMist() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const uomObject = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'AssetDevice_UOM',
      ActionType: 'read'
    }
  };
  return axios(uomObject)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
}
export async function getMasterList(url) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Object = {};
  Object = {
    method: 'GET',
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function deleteMaster(url, id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?Id=${id}&SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function deleteEquipmentMaster(url, id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?SiteId=${siteId}&Id=${id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((err) => {
      message.error(err.response.data.message);
    });
}
export async function getMasterById(url, id, method) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: `${method}`,
    url: `${url}?Id=${id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getQRById(url, id, method) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: `${method}`,
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { ScannerId: id }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getEquipmentMasterById(url, id, method) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: `${method}`,
    url: `${url}?SiteId=${siteId}&Id=${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getMatsterItemList(Equipment) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/masterItems/get?SiteId=${siteId}&Equipment=${Equipment}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getMasterItemById(url, id) {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${url}?Id=${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getListQuestions() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Object = {};
  Object = {
    method: 'GET',
    url: `/api/inspectionQuestions/list?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getItemsMasterList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getDeviceList = {
    method: 'GET',
    url: `/api/device/get?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceList);
}

// Labor Master Api's
export async function getLaborMasterList() {
  const accessToken = localStorage.get('accessToken');
  let Object = {};
  // if(master === "AssetMasterHierarchy")
  // {
  Object = {
    method: 'POST',
    url: `/api/masterLabor/list?SiteId=1`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  // }

  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getProblemCodesList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'Master_ProblemCodes',
      ActionType: 'read',
      Conditions: null
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getCauseCodes() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'Master_CauseCodes',
      ActionType: 'read',
      Conditions: null
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getRemedyCodes() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'Master_RemedyCodes',
      ActionType: 'read',
      Conditions: null
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export const _getDeviceTypesList = () => {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/deviceType/get`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};
export const _saveDeviceData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/device/create?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      message.error('Equipment Already Exists');
    });
};
export const _SaveManufaturerData = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      data,
      MasterName: 'Master_Manufacturers',
      ActionType: 'create'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      message.error('Manufacturer already exists');
    });
};
export const _saveAssetData = (payload, fileData) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/asset/create?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `/api/asset/saveFile?SiteId=${siteId}&Id=${response.data.message}&Type=${'Asset'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })
    .catch(() => {});
};
export const saveQuestionData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');

  const Object = {
    method: 'POST',
    url: `/api/inspectionQuestions/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.error(err.response.data.message);
    });
};

export const getinspectionItemList = () => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'Master_Inspection_Items',
      ActionType: 'read'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};

export const saveAreaData = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export async function getinspectionAreaList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'Master_Inspection_Areas',
      ActionType: 'read'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
export const saveItemData = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getEquipmentRecord = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/inspectionQuestions/getById?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

// InspectionForm Api's
export async function getQuestionDeviceList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/device/get?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getInspectionQuestions(payload, Type) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/inspectionconfig/getInspectionQuestions?Type=${Type}&SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getInspectionData(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/inspection/getInspectionByEquipment?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export const InsertSeveiceReq = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/serviceReq/insert?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export async function getWorkOrderId() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/masterWorkorder/getId?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export const InsertWorkOrder = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterWorkorder/insert?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const submitInspection = (data, fileDataList, type) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/inspectionconfig/insertLogbookInspection?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      fileDataList &&
        Array.isArray(fileDataList) &&
        fileDataList.map((fileDataObject) => {
          let fileData = new FormData();
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `/api/masterWorkorder/saveFile?SiteId=${siteId}&Id=${response.data.Id}&Type=${type}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch(() => {});
          }
          return {};
        });

      return response.data;
    })
    .catch((error) => {
      if (error.response && (error.response.status === 400 || error.response.status === 500)) {
        message.info(error.response.data.message);
      }
    });
};

export async function getEquipmentHierarchyForInspection(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/inspectionconfig/inspectionHierarchy?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getDataSourceOfInspection(payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/inspectionconfig/cronInspectionList?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getDataSourceOfInspectionById(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/inspectionconfig/getByInspectionId?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getEquipmentHierarchy(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/inspection/hierarchy?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getscheduleInspectByID(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/scheduler/getById?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getAreasList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'Master_Inspection_Areas',
      ActionType: 'read',
      Conditions: null
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getItemsListForInspection() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterName: 'Master_Inspection_Items',
      ActionType: 'read',
      Conditions: null
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getWorkTypesEInspec(value) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getWorkTypes = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'eam."Master_WorkTypes"',
      ColumnName: 'WorkType',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        },
        {
          key: 'Type',
          value
        }
      ]
    }
  };
  return axios(getWorkTypes)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getWorkTypesWF() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getWorkTypes = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'eam."Master_WorkTypes"',
      ColumnName: 'WorkType',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getWorkTypes);
}
export async function getCommunication() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getRoles = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'master."Master_Channels"',
      ColumnName: 'ChannelName'
    }
  };
  return axios(getRoles);
}
export async function getRoles() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getRoles = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'base."Base_Roles"',
      ColumnName: 'Name',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getRoles);
}
export async function insertWF(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getRoles = {
    method: 'POST',
    url: `/api/masterWorkFlow/insert?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(getRoles)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function updateWF(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getRoles = {
    method: 'POST',
    url: `/api/masterWorkFlow/update?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(getRoles)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getWFList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;

  const getWFList = {
    method: 'GET',
    url: `/api/masterWorkFlow/workFlowList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getWFList);
}
export async function deleteWF(id) {
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const deleteWF = {
    method: 'POST',
    url: `/api/masterWorkFlow/delete?Id=${id}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(deleteWF);
}
export async function getStatusListWF() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getStatusList = {
    method: 'POST',
    url: `/api/masterconfiguration/getDropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'eam."Master_WorkOrder_Status"',
      ColumnName: 'Status'
    }
  };
  return axios(getStatusList);
}

export async function getWorkFlowById(Id) {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getWorkFlowById = {
    method: 'GET',
    url: `/api/masterWorkFlow/getById?Id=${Id}&SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getWorkFlowById)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function deleteDeviceType(deviceTypeId) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/deviceType/delete?Id=${deviceTypeId}&SiteId=${siteId}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function deleteTags(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/parameter/delete?Id=${id}&SiteId=${siteId}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      message.success('Successfully deleted');
      return response.data;
    })

    .catch((err) => {
      message.error(err.response.data.message);
    });
}
export async function getDeviceTypeList() {
  const accessToken = localStorage.get('accessToken');
  let Object = {};
  Object = {
    method: 'GET',
    url: `/api/deviceType/get`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function insertSkillType(payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let Object = {
    url: `/api/masterconfiguration/master?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterName: 'Master_Skills',
      ActionType: 'create',
      data: payload
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getTagsByEquipmentTypeId(id) {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/parameter/get?DeviceType=${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getDeviceTypeData(deviceCode) {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getDeviceTypeData = {
    method: 'GET',
    url: `/api/deviceType/get?Code=${deviceCode}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceTypeData)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getDeviceTypeById(Id) {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getDeviceTypeData = {
    method: 'GET',
    url: `/api/deviceType/get?Id=${Id}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceTypeData)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function tagCreateAPI(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `/api/parameter/create`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      message.success('Tag Saved');
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}

export async function tagUpdateAPI(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `/api/parameter/update?Id=${payload.Id}`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      message.success('Tag Updated');
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}

export async function getLandingPageCount(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const roleId = localStorage.get('roleId');
  let Authorization = 'Bearer ' + token;

  const getLandingPageCount = {
    method: 'POST',
    url: `/api/masterWorkorder/landingPageCount?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { RoleId: roleId, ...payload }
  };
  return axios(getLandingPageCount)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function getPendingList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const roleId = localStorage.get('roleId');
  let Authorization = 'Bearer ' + token;

  const getLandingPageCount = {
    method: 'POST',
    url: ` /api/masterWorkorder/roleBasedList?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { RoleId: roleId, Status: 'Pending' }
  };
  return axios(getLandingPageCount);
}
export async function updateStatus(payload, WfStatus) {
  const token = localStorage.get('accessToken');
  const roleId = localStorage.get('roleId');
  let Authorization = 'Bearer ' + token;

  const getLandingPageCount = {
    method: 'POST',
    url: `/api/masterWorkorder/status`,
    headers: {
      Authorization: Authorization
    },
    data: { RoleId: roleId, ...payload, WfStatus: WfStatus }
  };
  return axios(getLandingPageCount);
}
