import React from 'react';
import { Route } from 'react-router';

import {
  Profile,
  ChangePassword,
  Preferences,
  Translation,
  MasterConfiguration,
  LogoUpload,
  MenuBuilder,
  CraneManagement,
  TableConfigurationUpload,
  MasterView,
  Organisation,
  Site,
  Role,
  Users,
  Department,
  LandingPage,
  Hierarchy,
  NestedHierarchy,
  RoleBaseSelection,
  NotificationAlert,
  ConfigurationBulkUpload
} from '../pages';
import MasterMapping from '../pages/Settings/MasterMapping';
import CronReRun from '../pages/Reports/CronReRun';

export default class SettingsRouter extends React.Component {
  render() {
    return (
      <div>
        <Route path="/rubus/settingsPage/Profile" component={Profile} />
        <Route path="/rubus/settingsPage/change-password" component={ChangePassword} />
        <Route path="/rubus/settingsPage/translation" component={Translation} />
        <Route path="/rubus/settingsPage/preferences" component={Preferences} />
        <Route path="/rubus/settingsPage/logoUpload" component={LogoUpload} />
        <Route
          path="/rubus/settingsPage/TableConfigurationUpload"
          component={TableConfigurationUpload}
        />
        <Route path="/rubus/settingsPage/CraneManagement" component={CraneManagement} />

        <Route
          exact
          path="/rubus/settingsPage/UserManagement/Organisation"
          component={Organisation}
        />
        <Route exact path="/rubus/settingsPage/UserManagement/Site" component={Site} />
        <Route exact path="/rubus/settingsPage/UserManagement/:siteId/Roles" component={Role} />
        <Route exact path="/rubus/settingsPage/UserManagement/Users" component={Users} />
        <Route exact path="/rubus/settingsPage/UserManagement/Department" component={Department} />
        <Route
          exact
          path="/rubus/settingsPage/UserManagement/LandingPage"
          component={LandingPage}
        />

        <Route exact path={'/rubus/settingsPage/MenuConfiguration'} component={MenuBuilder} />
        <Route path="/rubus/settingsPage/Hierarchy/:hierarchyname" component={NestedHierarchy} />
        <Route path="/rubus/settingsPage/master/:mastername" component={MasterView} />
        <Route exact path={`/rubus/settingsPage/MasterMapping`} component={MasterMapping} />
        <Route
          exact
          path={'/rubus/settingsPage/MasterConfiguration'}
          component={MasterConfiguration}
        />
        <Route exact path="/rubus/settingsPage/Hierarchy" component={Hierarchy} />
        <Route exact path="/rubus/settingsPage/CronReRun" component={CronReRun} />
        <Route exact path="/rubus/settingsPage/RoleBaseHierarchy" component={RoleBaseSelection} />
        <Route exact path="/rubus/settingsPage/NotificationAlert" component={NotificationAlert} />
        <Route
          exact
          path="/rubus/settingsPage/ConfigurationBulkUpload"
          component={ConfigurationBulkUpload}
        />
      </div>
    );
  }
}
