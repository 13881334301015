import axios from 'axios';
import localStorage from '../../utils/localStorage';

export async function getProcessArea() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getProcessArea = {
    method: 'POST',
    url: `/api/hierarchymodule/drop-down?SiteId=${siteId} `,
    headers: {
      Authorization: Authorization
    },
    data: {
      Type: 'ProcessArea',
      ParentId: null
    }
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getShiftList() {
  const token = localStorage.get('accessToken');
  let siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getProcessArea = {
    method: 'POST',
    url: `/api/logbook/dropdown?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { tableName: 'master."Master_Shifts"', columnName: 'Shift', conditionList: [] }
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getWorkCenter(id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getWorkCenter = {
    method: 'POST',
    url: `/api/hierarchymodule/drop-down?SiteId=${siteId} `,
    headers: {
      Authorization: Authorization
    },
    data: {
      Type: 'WorkCenter',
      ParentId: id
    }
  };
  return axios(getWorkCenter)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getEquipment(id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getEquipment = {
    method: 'POST',
    url: `/api/hierarchymodule/drop-down?SiteId=${siteId} `,
    headers: {
      Authorization: Authorization
    },
    data: {
      Type: 'Device',
      ParentId: id
    }
  };
  return axios(getEquipment)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getAreaType() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getAreaType = {
    method: 'POST',
    url: `/api/downtime/failureDetails?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      Type: 'FailureArea',
      Module: 'DownTime'
    }
  };
  return axios(getAreaType)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getDescription(failureArea) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getDescription = {
    method: 'POST',
    url: `/api/downtime/failureDetails?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      Type: 'FailureReasons',
      FailureArea: failureArea,
      Module: 'DownTime'
    }
  };
  return axios(getDescription)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function saveDownTimelogs(param) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const saveDownTimelogs = {
    method: 'POST',
    url: `/api/downtime/create?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { ...param, SiteId: siteId, Status: 'Close' }
  };
  return axios(saveDownTimelogs)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getDownTimeRecords(Source) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getDownTimeRecords = {
    method: 'GET',
    url: `/api/downtime/get?SiteId=${siteId}&Source=${Source}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDownTimeRecords)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function editDownTimelogs(param) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const editDownTimelogs = {
    method: 'PATCH',
    url: `/api/downtime/update?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { ...param, SiteId: siteId, Status: 'Close' }
  };
  return axios(editDownTimelogs)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getShift() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getShift = {
    method: 'GET',
    url: `/api/downtimeLog/getShiftMaster?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getShift)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getSubEquipments(id) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getSubequipment = {
    method: 'GET',
    url: `https://sgt.bmecomosolution.com/api/downtimeLog/getSubequipment/${id}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getSubequipment)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function submitDowntimeAPI(data) {
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getAreaType = {
    method: 'POST',
    url: `/api/downtime/submit`,
    headers: {
      Authorization: Authorization
    },
    data
  };
  return axios(getAreaType)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getDepartmentDetailsAPI(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getAreaType = {
    method: 'POST',
    url: `/api/downtime/failureDetails?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: payload
  };
  return axios(getAreaType)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getEquipmentList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getEquipmentListPayload = {
    method: 'POST',
    url: `/api/device/get?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getEquipmentListPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
