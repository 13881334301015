import React from 'react';
import axios from 'axios';
import { Table } from 'antd';

import localStorage from '../../../../utils/localStorage';
import { StyledDashboard, StyledTable, lightTheme } from './styles.js';
import './style.css';
class MaximoNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maximoNotification: [],
      dataSource: []
    };
  }

  componentDidMount = () => {
    this.getMaximoList();
  };

  getMaximoList = () => {
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/notifications/get`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((response) => {
        this.setState({ maximoNotification: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let { maximoNotification } = this.state;
    let maximoCol = [
      {
        title: 'Created Time',
        dataIndex: 'CreatedTime',
        key: 'CreatedTime'
      },
      {
        title: 'Data Time',
        dataIndex: 'DataTime',
        key: 'DataTime'
      },
      {
        title: 'Device',
        dataIndex: 'Device',
        key: 'Device'
      },
      {
        title: 'Parameter Code',
        dataIndex: 'ParameterCode',
        key: 'ParameterCode'
      },
      {
        title: 'State',
        dataIndex: 'State',
        key: 'State'
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status'
      },
      {
        title: 'Tag Value',
        dataIndex: 'TagValue',
        key: 'TagValue'
      },
      {
        title: 'Maximo Response Date',
        dataIndex: 'MaximoResponseDate',
        key: 'MaximoResponseDate'
      }
    ];

    return (
      <StyledDashboard className="EAMForm" style={{ minHeight: window.innerHeight - 173 }}>
        {/* <Row>
          <Col style={{ marginTop: '2%' }}> */}
        <div style={{ marginTop: '2%' }}>
          <StyledTable theme={lightTheme}>
            <Table
              className="table"
              columns={maximoCol}
              size="large"
              pagination={
                maximoNotification.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : 1
              }
              dataSource={maximoNotification}
            ></Table>
          </StyledTable>
        </div>

        {/* </Col>
        </Row> */}
      </StyledDashboard>
    );
  }
}
export default MaximoNotification;
