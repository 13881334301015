import React, { Component } from 'react';
import { Input, message, Collapse, Button, Select, Checkbox, Form } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getTableMasters } from './ElogConfigCalls';

let { Panel } = Collapse;
let { Option } = Select;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  // width: 303,
  marginTop: 14,
  marginBottom: 14
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'lightgrey' : 'grey',
  ...draggableStyle
});

class HeaderCustomization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editObj: {},
      activeKey: [],
      dragDisabled: false,
      tableMaster: [],
      columnsProperties: props.columnsProperties || []
    };
    this.columns = {};
  }

  componentDidMount() {
    this._getTableMasters();
  }

  componentDidUpdate(prevprops) {
    if (this.props.columnsProperties !== prevprops.columnsProperties) {
      this.setState({
        columnsProperties: this.props.columnsProperties
      });
    }
  }

  _getTableMasters = async () => {
    let tableMaster = await getTableMasters();
    this.setState({ tableMaster });
  };

  onDragEnd = (result) => {
    const { columnsProperties } = this.state;
    if (!result.destination) {
      return;
    }

    const columnsPropertiess = reorder(
      columnsProperties,
      result.source.index,
      result.destination.index
    );
    this.setState({
      columnsProperties: columnsPropertiess
    });
    this.props.columnSaveObject(columnsProperties);
  };

  handleFieldChange(value, index, colName) {
    const { columnsProperties } = this.state;
    columnsProperties[index][colName] = value;
    this.setState({
      columnsProperties
    });
  }

  columnSave = () => {
    const { columnsProperties } = this.state;
    this.props.columnSaveObject(columnsProperties);
    message.success('Column saved');
    this.setState({
      activeKey: []
    });
  };

  deleteColumn = (e, index) => {
    let { columnsProperties } = this.state;
    columnsProperties = columnsProperties.filter(function (e, i) {
      return i !== index;
    });
    this.props.columnSaveObject(columnsProperties);
    message.success('Column Deleted');
    this.setState({
      activeKey: []
    });
  };

  callback = (key) => {
    if (key && key.length <= 1) {
      this.setState({
        activeKey: key
      });
    } else if (key.length > 1) {
      key = key[key.length - 1];
      this.setState({
        activeKey: [key]
      });
    }
  };

  render() {
    const { columnsProperties, dragDisabled, activeKey, tableMaster } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };

    return (
      <div style={{ margin: '50px' }}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {columnsProperties.map((item, index) => (
                  <Draggable
                    key={item.name}
                    draggableId={item.name}
                    index={index}
                    isDragDisabled={dragDisabled}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <Collapse bordered activeKey={activeKey} onChange={this.callback}>
                          <Panel header={item.name} key={index}>
                            <Form {...formItemLayout}>
                              <Form.Item label="Name :">
                                <Input
                                  autoFocus
                                  defaultValue={item.name}
                                  onChange={(e) =>
                                    this.handleFieldChange(e.target.value, index, 'name')
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Key :">
                                <Input
                                  defaultValue={item.key}
                                  onChange={(e) =>
                                    this.handleFieldChange(e.target.value, index, 'key')
                                  }
                                />
                              </Form.Item>
                              <Form.Item label="Widget :">
                                <Select
                                  style={{ width: '250px' }}
                                  defaultValue={item.widget}
                                  onSelect={(value) =>
                                    this.handleFieldChange(value, index, 'widget')
                                  }
                                >
                                  <Option value="input">Input</Option>
                                  <Option value="inputNumber">InputNumber</Option>
                                  <Option value="select">Select</Option>
                                  <Option value="timeRange">TimeRange</Option>
                                </Select>
                              </Form.Item>
                              {item.widget === 'inputNumber' ? (
                                <Form.Item label="Range :">
                                  <Input
                                    placeholder={'Min'}
                                    style={{ width: '80px' }}
                                    defaultValue={item.min}
                                    onChange={(e) =>
                                      this.handleFieldChange(e.target.value, index, 'min')
                                    }
                                  />
                                  <Input
                                    placeholder={'Max'}
                                    style={{ width: '80px', marginLeft: '5px' }}
                                    defaultValue={item.max}
                                    onChange={(e) =>
                                      this.handleFieldChange(e.target.value, index, 'max')
                                    }
                                  />
                                </Form.Item>
                              ) : null}
                              {item.widget === 'select' ? (
                                <Form.Item label="Table Name :">
                                  <Select
                                    style={{ width: '250px' }}
                                    defaultValue={item.table}
                                    onSelect={(value) =>
                                      this.handleFieldChange(value, index, 'table')
                                    }
                                  >
                                    {tableMaster &&
                                      tableMaster.map((table, index) => {
                                        return (
                                          <Option key={index} value={table.tableName}>
                                            {table.tableName}
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                </Form.Item>
                              ) : null}
                              <Form.Item label="Required :">
                                <Checkbox
                                  checked={item.required}
                                  onChange={(e) =>
                                    this.handleFieldChange(e.target.checked, index, 'required')
                                  }
                                />
                              </Form.Item>
                            </Form>

                            <Button
                              type="primary"
                              ghost
                              style={{ marginRight: '10px' }}
                              onClick={(e) => this.columnSave(e, index)}
                            >
                              Save
                            </Button>
                            <Button
                              type="danger"
                              onClick={(e) => this.deleteColumn(e, index)}
                              ghost
                            >
                              Delete
                            </Button>
                          </Panel>
                        </Collapse>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}
export default HeaderCustomization;
