import React, { Component } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import './style.css';
const { RangePicker, MonthPicker } = DatePicker;

class SelectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {},
      selectionWidget: props.selectionWidget || {},
      mode: ['month', 'month'],
      isOpen: false,
      monthValue: moment(),
      year: moment(),
      dateRangeValue: {
        startDate: moment().subtract('30', 'days').utc().format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().utc().format('YYYY-MM-DD HH:mm:ss')
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectionWidget !== prevProps.selectionWidget) {
      this.setState({ selectionWidget: this.props.selectionWidget, monthValue: moment() });
    }
  }

  onYearPicker = (year) => {
    let { selectionWidget } = this.state;

    if (selectionWidget.payload) {
      let payload = {};
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1 && key === 0) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment(year)
            .startOf('year')
            .utc()
            .format(payloadItem.format);
        } else if (key <= 1 && key === 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment(year)
            .endOf('years')
            .utc()
            .format(payloadItem.format);
        }
        if (payloadParams === 'year') {
          payload[`${payloadParams}`] = moment(year).utc().format('YYYY');
        }
        return {};
      });
      this.setState({ payload });
      this.props.selectionpayload(payload);
    }
  };

  onDataPickerChange = (value, dateString) => {
    let { selectionWidget } = this.state;
    if (selectionWidget.payload) {
      let payload = {};
      Object.keys(selectionWidget.payload).map((payloadParams) => {
        let payloadItem = selectionWidget.payload[payloadParams];
        if (selectionWidget.payload[payloadParams].add) {
          let { value, key } = selectionWidget.payload[payloadParams].add;
          payload[`${payloadParams}`] = moment(dateString)
            .add(value, key)
            .format(payloadItem.format);
        } else if (selectionWidget.payload[payloadParams].subtract) {
          let { value, key } = selectionWidget.payload[payloadParams].add;
          payload[`${payloadParams}`] = moment(dateString)
            .subtract(value, key)
            .format(payloadItem.format);
        } else {
          payload[`${payloadParams}`] = moment(dateString).format(payloadItem.format);
        }

        if (payloadParams === 'Date') {
          payload[`${payloadParams}`] = moment(dateString).format('YYYY-MM-DD');
        }
        return {};
      });
      this.setState({ payload });
      this.props.selectionpayload(payload);
    }
  };

  onOk = (dateString) => {
    let { selectionWidget } = this.state;
    if (selectionWidget.payload) {
      let payload = {};
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment(dateString[key]).format(payloadItem.format);
        }
        return {};
      });
      this.setState({ payload });
    }
  };

  disabledDate = (current) => {
    return current > moment().startOf('day');
  };

  onMonthPickerChange = (dateString) => {
    let { selectionWidget } = this.state;
    let month = moment(dateString).month();
    let year = moment(dateString).year();

    if (selectionWidget.payload) {
      let payload = {};
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1 && key === 0) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .startOf('month')
            .utc()
            .format(payloadItem.format);
        } else if (key <= 1 && key === 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .endOf('months')
            .utc()
            .format(payloadItem.format);
        }
        if (payloadParams === 'month') {
          payload[`${payloadParams}`] = moment(dateString).utc().format('MMMM');
        }
        if (payloadParams === 'year') {
          payload[`${payloadParams}`] = year;
        }
        return {};
      });
      this.setState({ payload, monthValue: dateString });
      this.props.selectionpayload(payload);
    }
  };

  onRangePickerChange = (dateString, dateString1) => {
    let startDate =
      dateString1 && dateString1[0]
        ? moment(dateString1[0]).utc().format('DD-MM-YYYY HH:mm:ss')
        : '';
    let endDate =
      dateString1 && dateString1[1]
        ? moment(dateString1[1]).utc().format('DD-MM-YYYY HH:mm:ss')
        : '';
    this.setState({
      payload: {
        startDate,
        endDate
      },
      dateRangeValue: dateString1
    });
    this.props.selectionpayload({ startDate, endDate });
  };
  onCalendarChange = (selectedRange) => {
    this.setState({
      selectedRange
    });
  };
  handlePanelChange = (dateString, mode) => {
    let { selectionWidget } = this.state;
    let startMonth = moment(dateString[0]).month();
    let startYear = moment(dateString[0]).year();
    let endMonth = moment(dateString[1]).month();
    let endYear = moment(dateString[1]).year();

    if (selectionWidget.payload) {
      let payload = {};
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1 && key === 0) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([startYear, startMonth])
            .startOf('month')
            .format(payloadItem.format);
        } else if (key <= 1 && key === 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([endYear, endMonth])
            .add('months', 1)
            .format(payloadItem.format);
        }
        return {};
      });
      this.setState({
        payload,
        mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
      });
    } else {
      this.setState({
        mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
      });
    }
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  disabledRangeDate = (current, selectedRange) => {
    if (selectedRange) {
      return (
        current > moment(selectedRange[0]).add('31', 'day') ||
        current < moment(selectedRange[0]) ||
        current > moment().endOf('day')
      );
    } else {
      return current > moment().endOf('day');
    }
  };

  render() {
    let { selectionWidget, mode, monthValue, dateRangeValue } = this.state;
    let { displayWidget, widget, displayDateFormat, displayTimeFormat, placeholder, format } =
      selectionWidget;

    let dateFormat = 'YYYY-MM-DD';
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          marginBottom: '15px'
        }}
      >
        {displayWidget && widget === 'date-picker' ? (
          <DatePicker
            onChange={this.onDataPickerChange}
            defaultValue={moment().subtract('day', 1)}
            placeholder={placeholder}
            disabledDate={this.disabledDate}
          />
        ) : null}
        {displayWidget && widget === 'dateRange-picker' ? (
          <RangePicker
            showTime={{ format: displayTimeFormat }}
            format={displayDateFormat}
            placeholder={placeholder}
            onChange={this.onRangePickerChange}
            onCalendarChange={this.onCalendarChange}
            onOk={this.onOk}
            defaultValue={[
              moment(dateRangeValue.startDate, dateFormat),
              moment(dateRangeValue.endDate, dateFormat)
            ]}
            // format={dateFormat}
            disabledDate={(current) => this.disabledRangeDate(current, this.state.selectedRange)}
            onOpenChange={(rangePickerOpen) => {
              this.setState({
                rangePickerOpen,
                selectedRange: undefined
              });
            }}
            ranges={{
              'Reset Filter': [moment().subtract('30', 'days'), moment()]
            }}
          />
        ) : null}
        {displayWidget && widget === 'month-picker' ? (
          <MonthPicker
            className="picker"
            placeholder="Select month"
            value={monthValue}
            format={format}
            disabledDate={this.disabledDate}
            onChange={this.onMonthPickerChange}
          />
        ) : null}
        {displayWidget && widget === 'monthRange-picker' ? (
          <RangePicker
            placeholder={placeholder}
            format={format}
            mode={mode}
            //   onChange={this.onMonthRangePickerChange}
            onPanelChange={this.handlePanelChange}
          />
        ) : null}
        {displayWidget && widget === 'year-picker' ? (
          <DatePicker
            value={this.state.year}
            open={this.state.isOpen}
            mode="year"
            placeholder="Select Year"
            format="YYYY"
            onOpenChange={(status) => {
              if (status) {
                this.setState({ isOpen: true });
              } else {
                this.setState({ isOpen: false });
              }
            }}
            onPanelChange={(v) => {
              this.setState({ isOpen: false, year: v });
              this.onYearPicker(v);
            }}
            // onChange={this.onYearPicker}
          />
        ) : null}
      </div>
    );
  }
}

export default SelectionComponent;
