/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import _ from 'lodash';
import {
  Form,
  message,
  Button,
  Select,
  DatePicker,
  Card,
  Drawer,
  Divider,
  Space,
  Empty,
  Pagination
} from 'antd';
import { UserOutlined, CalendarOutlined } from '@ant-design/icons';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  getTaskList,
  getTaskById,
  filterTaskByReference,
  getHierarchyList,
  getUOMList,
  getUsersList
} from '../../../Utils/FormCalls';
import { StyledForm, StyledButton, CardTitle } from '../../../CSS/style';
import localStorage from '../../../../../utils/localStorage';
import moment from 'moment';
import themeSettings from '../../../Utils/themeSettings.json';
import axios from 'axios';
import { connect } from 'react-redux';
import translation from '../../../Utils/translation';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
import InputComponent from '../../../Widgets/InputComponent';
const { Option } = Select;

class WorkOrderJobPlanesTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      recordData: props.formObject && props.formObject.TaskData ? props.formObject.TaskData : [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      workOrderStatus: props.workOrderStatus,
      fileData: this.props.fileData ? this.props.fileData : [],
      translationCache: props.translationCache || {},
      hierarchyList: [],
      UOMList: [],
      current: 1,
      pageSize: 10
    };
  }
  workOrderJobPlanRef = React.createRef();
  taskRef = React.createRef();

  componentDidMount = async () => {
    const { recordData } = this.state;
    this.workOrderJobPlanRef.current !== null &&
      this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
    try {
      let hierarchyList = await getHierarchyList();
      this.setState({ hierarchyList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let usersList = await getUsersList();
      this.setState({ usersList: usersList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let organisationsList = await getOrganisationsList();
      this.setState({ organisationsList: organisationsList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      this.setState({ sitesList: sitesList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let TaskList = await getTaskList('Active');
      TaskList = filterTaskByReference(TaskList, recordData);
      this.setState({ TaskList: TaskList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let UOMList = await getUOMList();
      this.setState({ UOMList: UOMList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.workOrderJobPlanRef.current !== null &&
        this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
      // this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject.TaskData)
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }

    if (prevProps.formObject !== this.props.formObject) {
      this.workOrderJobPlanRef.current !== null &&
        this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
      this.setState({
        formObject: this.props.formObject,
        recordData:
          this.props.formObject && this.props.formObject.TaskData
            ? this.props.formObject.TaskData
            : [],
        workOrderStatus: this.props.workOrderStatus ? this.props.workOrderStatus : null,
        fileData: this.props.fileData ? this.props.fileData : null
      });
    }
  }
  onPaginationChange = (current, pageSize) => {
    this.setState({ current, pageSize });
  };
  handleChange = (value, key) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [key]: value
    };

    // this.workOrderJobPlanRef.current.setFieldsValue(formObject)
    this.setState({ [key]: value, formObject });
  };

  onReset = () => {
    this.workOrderJobPlanRef.current.resetFields();
  };

  TaskOnChange = async (value) => {
    let TaskData = await getTaskById(value, 'withoutId');
    let { formObject } = this.state;
    let data = formObject && formObject.TaskData !== undefined ? formObject.TaskData : [];
    _.cloneDeepWith(TaskData, (value) => {
      return value !== null && value.StartTime
        ? {
            ...value,
            ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
            ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
          }
        : _.noop();
    });
    data.push(TaskData);
    formObject = {
      ...formObject,
      TaskData: data
    };
    this.setState({ selectedTask: value, formObject });
    //    this.setState({ formObject: { ...this.state.formObject, ["TaskData"]: data }, selectedTask: value })
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    // this.workOrderJobPlanRef.current.setFieldsValue({ "TaskData": data })
  };
  handleSelect = (value, mainIndex, subindex, keyName, selectedObject) => {
    let formObject = this.workOrderJobPlanRef.current.getFieldValue();
    let TaskData = formObject && formObject.TaskData;
    TaskData.map((item, index) => {
      if (index === mainIndex) {
        item[keyName][subindex]['Description'] = selectedObject[0]['Description'];
        item[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
      }
      return {};
    });
    formObject['TaskData'] = TaskData;
    this.setState({ formObject });
    this.workOrderJobPlanRef.current.setFieldsValue(TaskData);
  };
  datepickerchange = (date, dateString, index, key) => {
    // this.workOrderJobPlanRef.current.setFieldsValue(key, moment(dateString).format("YYYY-MM-DD HH:mm:ss"))
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    let EstimatedDuration = null;
    if (
      key &&
      key === 'EndTime' &&
      this.workOrderJobPlanRef.current.getFieldValue() &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime']
    ) {
      let startTime =
        this.workOrderJobPlanRef.current.getFieldValue() &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime'];
      let endTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    } else if (
      key &&
      key === 'StartTime' &&
      this.workOrderJobPlanRef.current.getFieldValue() &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime']
    ) {
      let endTime =
        this.workOrderJobPlanRef.current.getFieldValue() &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime'];
      let startTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    }
    formObject['TaskData'][index]['EstimatedDuration'] = EstimatedDuration;
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
  };
  getDisabledHours = (e, index, key) => {
    let hours = [];
    let minutes = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';

    if (key === 'StartTime') {
      StartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      EndDateTime = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();

      StartDate = moment(e).format('YYYY-MM-DD');
      EndDate = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime']
        )
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'EndTime') {
      StartDateTime = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();
      EndDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      StartDate = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime']
        )
        .local()
        .format('YYYY-MM-DD');
      EndDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'StartTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (var i = 0; i <= 24; i++) {
        if (i > moment(EndDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var j = 0; j <= 60; j++) {
          if (j > moment(EndDateTime).minutes() - 1) {
            minutes.push(j);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'EndTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (var k = 0; k < moment(StartDateTime).hours(); k++) {
        if (k < 25) {
          hours.push(k);
        }
      }

      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var K = 0; K < moment(StartDateTime).minutes() + 1; K++) {
          if (K < 61) {
            minutes.push(K);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };
  onFinish = () => {};

  disabledDate = (currentDay) => {
    return currentDay > moment().endOf('day');
  };
  disabledHours = () => {
    const hours = [];
    const currentHour = moment().hour();
    for (let i = currentHour + 1; i <= 24; i++) {
      hours.push(i);
    }
    return hours;
  };
  disabledMinutes = (selectedHour) => {
    const minutes = [];
    const currentMinute = moment().minute();
    if (selectedHour === moment().hour()) {
      for (let i = currentMinute; i <= 60; i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  disabledEndHours = () => {
    let hours = [];
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };
  getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  getHierarchyLevels = () => {
    let { hierarchyList } = this.state;
    let payload = {
      HierarchyId:
        hierarchyList && Array.isArray(hierarchyList) && hierarchyList[0] && hierarchyList[0].Id
          ? hierarchyList[0].Id
          : null,
      Type: 'Device'
    };
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/getHierarchyLevelsForAMM?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            treeData: data,
            visible: true,
            formObject: this.workOrderJobPlanRef.current.getFieldsValue()
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  hierarchyDropdownChange = (value) => {
    // let formObject = this.workOrderJobPlanRef.current.getFieldsValue()
    // formObject = {
    //     ...formObject,
    //     "Equipment": value.Code,
    //     "EquipmentDescription": value.Name,
    // }
    // this.workOrderJobPlanRef.current.setFieldsValue(formObject)
    let { formObject } = this.state;
    let list = this.workOrderJobPlanRef.current.getFieldValue('TaskData');
    let TaskData = list;
    TaskData.map((item) => {
      // if (index === mainindex) {
      item.Equipment = value.Code;
      // }
      return {};
    });
    formObject = {
      ...formObject,
      TaskData: TaskData
    };
    this.setState({
      visible: false,
      formObject
    });
    this.workOrderJobPlanRef.current.setFieldsValue({ TaskData: TaskData });
  };
  onClose = () => {
    this.setState({ open: false });
  };
  add3Dots = (text, limit) => {
    let dots = '...';
    if (text && text.length > limit) {
      text = text.substring(0, limit) + dots;
    }
    return text;
  };
  _editRecord = (id) => {
    // let { formObject } = this.state;
    // let selectedTask =
    //   formObject &&
    //   formObject['TaskData'] &&
    //   formObject['TaskData'].filter((item) => item.TaskName === id);
    this.setState({ selectedTask: id, open: true });
  };
  add = () => {
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    formObject.TaskData.push({ TaskName: 'New Task' });
    this.setState({ formObject });
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
  };
  render() {
    let {
      formObject,
      TaskList,
      workOrderStatus,
      selectedTask,
      // hierarchyList,
      // deviceList,
      UOMList,
      pageSize,
      current,
      translationCache,
      fileData,
      open
    } = this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    const IconText = ({ icon, text }) => (
      <Space>
        {React.createElement(icon)}
        {text}
      </Space>
    );

    const listData = formObject && formObject['TaskData'];
    return (
      <div>
        <>
          <StyledForm
            ref={this.workOrderJobPlanRef}
            name={'jobplan'}
            // onFinishFailed={() => this.props.onFinishFailed()}
            // className="collapse"
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            //wrapperCol={{ flex: 1 }}
            theme={themeSettings}
            validateMessages={validateMessages}
          >
            {/* {this.props.noJobplan === false ? (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  name="JobPlanName"
                  // style={{ width: '100%', flex: '50%' }}
                 ></Form.Item>
                <Form.Item
                  name="JobPlanDescription"
                  // style={{ width: '100%', flex: '50%' }}
                ></Form.Item>
              </div>
            ) : null}
             */}
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
              <Form.Item name="JobPlanName"></Form.Item>
              <Form.Item name="JobPlanDescription"></Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.addTask)}
                name="TaskName"
              >
                <Select
                  placeholder={translation(translationCache, 'Select Task')}
                  defaultValue={selectedTask}
                  onChange={(value) => this.TaskOnChange(value)}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: '8px 0'
                        }}
                      />
                      <Space
                        style={{
                          padding: '0 8px 4px'
                        }}
                      >
                        <Button onClick={() => this.add()}>
                          {translation(translationCache, constant.addNewTask)}{' '}
                        </Button>
                      </Space>
                    </>
                  )}
                  size="small"
                  style={{ width: '30%' }}
                >
                  {TaskList &&
                    Array.isArray(TaskList) &&
                    TaskList.map((task, index) => {
                      return (
                        <Option key={index} value={task.Id}>
                          {task.TaskName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item style={{ width: '100%', flex: '50%' }}>
                <InputComponent style={{ display: 'none' }} />
              </Form.Item>
            </div>

            <div style={{ margin: '0px 40px' }}>
              <Form.Item name="TaskData">
                {listData && Array.isArray(listData) && listData.length !== 0 ? (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {listData
                      .filter(
                        (e, index) =>
                          index >= pageSize * current - pageSize && index < pageSize * current
                      )
                      .map((list, index) => {
                        return (
                          <Card
                            key={list.TaskName}
                            onClick={() => this._editRecord(index)}
                            style={{ width: '410px', padding: '5px', margin: '5px' }}
                          >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <CardTitle theme={themeSettings}>
                                {this.add3Dots(list['TaskName'], 35)}
                              </CardTitle>
                              <div style={{ margin: '0px 0px 7px 0px' }}>
                                <IconText
                                  icon={UserOutlined}
                                  text={list['CreatedBy']}
                                  key="list-vertical-star-o"
                                />
                              </div>

                              <div style={{ margin: '0px 0px 7px 0px' }}>
                                <IconText
                                  icon={CalendarOutlined}
                                  text={moment(list['CreatedTime']).format('DD-MM-YYYY HH:mm:ss')}
                                  key="list-vertical-like-o"
                                />
                              </div>
                            </div>
                            {/* <Divider
                              style={{
                                borderTop: '1px solid #000',
                                height: '0px',
                                margin: '15px 0px'
                              }}
                            /> */}

                            {/* <CardFooterActions>
                              <Tooltip placement="top" key="Edit" title="Edit">
                                <EditOutlined
                                  style={{
                                    color: themeSettings.list.card.icon.color,
                                    marginRight: '8px'
                                  }}
                                  onClick={() => this._editRecord(list['TaskName'])}
                                />
                              </Tooltip>

                              <Divider type="vertical" />

                              <Popconfirm
                                key="1"
                                title="Are you sure you want to delete the record ?"
                                icon={<QuestionCircleOutlined style={{ color: '#d98e04' }} />}
                                // onConfirm={() => this._deleteRecord(list[deletee.byAttribute])}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Tooltip placement="top" key="Delete" title="Delete">
                                  <DeleteOutlined
                                    style={{
                                      color: themeSettings.list.card.icon.color,
                                      marginRight: '8px'
                                    }}
                                  />
                                </Tooltip>
                              </Popconfirm>

                              <Divider type="vertical" />
                            </CardFooterActions> */}
                          </Card>
                        );
                      })}
                  </div>
                ) : (
                  <Empty style={{ color: 'white' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}

                {listData && Array.isArray(listData) && listData.length !== 0 ? (
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '14px' }}>
                    <Pagination
                      current={current}
                      onChange={this.onPaginationChange}
                      total={listData.length}
                      pageSize={10}
                    />
                  </div>
                ) : null}
              </Form.Item>
            </div>
            <Drawer
              title="Edit Task"
              placement="right"
              width={'1200px'}
              closable
              onClose={() => this.onClose()}
              extra={
                <Space>
                  <Button onClick={() => this.onClose()}>Cancel</Button>
                  <StyledButton
                    theme={themeSettings}
                    onClick={() => {
                      this.setState({ open: false });
                      this.props.onFinish(
                        this.workOrderJobPlanRef.current.getFieldsValue(),
                        'JobPlan',
                        fileData
                      );
                    }}
                  >
                    Save
                  </StyledButton>
                </Space>
              }
              open={open}
            >
              <Form.List
                initialValue={formObject && formObject['TaskData'] ? formObject['TaskData'] : []}
                name="TaskData"
              >
                {(fields) => (
                  <>
                    {fields && Array.isArray(fields) && fields.length > 0
                      ? fields.map(({ key, name, ...restField }) => (
                          <div
                            key={key}
                            style={{ display: key === selectedTask ? 'block' : 'none' }}
                          >
                            <div key={key} style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.name)}
                                {...restField}
                                name={[name, 'TaskName']}
                                rules={[{ required: true }]}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Name')}
                                  style={{ width: '70%' }}
                                />
                              </Form.Item>

                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.description)}
                                {...restField}
                                name={[name, 'TaskDescription']}
                                rules={[{ required: true }]}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Description')}
                                  style={{ width: '70%' }}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.startTime)}
                                {...restField}
                                name={[name, 'StartTime']}
                              >
                                <DatePicker
                                  placeholder={translation(translationCache, constant.selectDate)}
                                  inputReadOnly={true}
                                  style={{ width: '70%' }}
                                  format="YYYY-MM-DD HH:mm:ss"
                                  // inputReadOnly={false}
                                  onChange={(date, dateString) => {
                                    this.datepickerchange(date, dateString, key, 'StartTime');
                                  }}
                                  showTime={{
                                    defaultValue: moment('00:00:00', 'HH:mm:ss')
                                  }}
                                  disabledDate={(d) =>
                                    !d ||
                                    d.isAfter(
                                      this.workOrderJobPlanRef.current.getFieldValue() &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ] &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ]['EndTime']
                                        ? moment(
                                            this.workOrderJobPlanRef.current.getFieldValue()
                                              .TaskData[key]['EndTime']
                                          )
                                            .add(1, 'day')
                                            .format('YYYY-MM-DD')
                                        : ''
                                    ) ||
                                    d.isBefore(
                                      this.workOrderJobPlanRef.current.getFieldValue() &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ] &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ]['StartTime']
                                        ? moment(
                                            this.workOrderJobPlanRef.current.getFieldValue()
                                              .TaskData[key]['StartTime']
                                          ).format('YYYY-MM-DD')
                                        : ''
                                    ) ||
                                    d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                                  }
                                  disabledTime={(e) =>
                                    this.getDisabledHours(
                                      moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                      key,
                                      'StartTime'
                                    )
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.endTime)}
                                {...restField}
                                name={[name, 'EndTime']}
                              >
                                <DatePicker
                                  placeholder={translation(translationCache, constant.selectDate)}
                                  inputReadOnly={true}
                                  style={{ width: '70%' }}
                                  format="YYYY-MM-DD HH:mm:ss"
                                  onChange={(date, dateString) => {
                                    this.datepickerchange(date, dateString, key, 'EndTime');
                                  }}
                                  // inputReadOnly={false}
                                  disabledDate={(d) =>
                                    !d ||
                                    d.isAfter(
                                      this.workOrderJobPlanRef.current &&
                                        this.workOrderJobPlanRef.current !== null &&
                                        this.workOrderJobPlanRef.current.getFieldValue() &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ] &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ]['EndTime']
                                        ? moment(
                                            this.workOrderJobPlanRef.current.getFieldValue() &&
                                              this.workOrderJobPlanRef.current.getFieldValue()
                                                .TaskData &&
                                              this.workOrderJobPlanRef.current.getFieldValue()
                                                .TaskData[key] &&
                                              this.workOrderJobPlanRef.current.getFieldValue()
                                                .TaskData[key]['EndTime']
                                          )
                                            .add(1, 'day')
                                            .format('YYYY-MM-DD')
                                        : ''
                                    ) ||
                                    d.isBefore(
                                      this.workOrderJobPlanRef.current &&
                                        this.workOrderJobPlanRef.current !== null &&
                                        this.workOrderJobPlanRef.current.getFieldValue() &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ] &&
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ]['StartTime']
                                        ? moment(
                                            this.workOrderJobPlanRef.current.getFieldValue() &&
                                              this.workOrderJobPlanRef.current.getFieldValue()
                                                .TaskData &&
                                              this.workOrderJobPlanRef.current.getFieldValue()
                                                .TaskData[key] &&
                                              this.workOrderJobPlanRef.current.getFieldValue()
                                                .TaskData[key]['StartTime']
                                          ).format('YYYY-MM-DD')
                                        : ''
                                    ) ||
                                    d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                                  }
                                  disabledTime={(e) =>
                                    this.getDisabledHours(
                                      moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                      key,
                                      'EndTime'
                                    )
                                  }
                                  showTime={{
                                    defaultValue: moment('00:00:00', 'HH:mm:ss')
                                  }}
                                />{' '}
                              </Form.Item>

                              {workOrderStatus && workOrderStatus === 'CLOSE' ? (
                                <>
                                  <Form.Item
                                    style={{ width: '100%', flex: '50%' }}
                                    label={translation(translationCache, constant.actualStartTime)}
                                    {...restField}
                                    name={[name, 'ActualStartTime']}
                                  >
                                    <DatePicker
                                      placeholder={translation(
                                        translationCache,
                                        constant.selectDate
                                      )}
                                      inputReadOnly={true}
                                      style={{ width: '70%' }}
                                      format="YYYY-MM-DD HH:mm:ss"
                                      showTime={{
                                        defaultValue: moment('00:00:00', 'HH:mm:ss')
                                      }}
                                      disabledDate={this.disabledDate}
                                      disabledHours={this.disabledHours}
                                      disabledMinutes={this.disabledMinutes}
                                    />{' '}
                                  </Form.Item>
                                  <Form.Item
                                    style={{ width: '100%', flex: '50%' }}
                                    label={translation(translationCache, constant.actualEndTime)}
                                    {...restField}
                                    name={[name, 'ActualEndTime']}
                                  >
                                    <DatePicker
                                      placeholder={translation(
                                        translationCache,
                                        constant.selectDate
                                      )}
                                      inputReadOnly={true}
                                      style={{ width: '70%' }}
                                      format="YYYY-MM-DD HH:mm:ss"
                                      showTime={{
                                        defaultValue: moment('00:00:00', 'HH:mm:ss')
                                      }}
                                      disabledDate={this.disabledDate}
                                      disabledHours={this.disabledEndHours}
                                      disabledMinutes={this.getDisabledMinutes}
                                    />{' '}
                                  </Form.Item>
                                </>
                              ) : null}
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.estimatedDuration)}
                                {...restField}
                                name={[name, 'EstimatedDuration']}
                              >
                                <InputComponent
                                  style={{ width: '70%' }}
                                  placeholder={translation(translationCache, 'EstimatedDuration')}
                                  onKeyPress={(event) => {
                                    if (/[a-z|| A-Z || -]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.UOM)}
                                name={[name, 'UOM']}
                              >
                                <Select
                                  style={{ width: '70%' }}
                                  showArrow
                                  showSearch
                                  openOnFocus
                                  placeholder={translation(translationCache, 'UOM')}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {UOMList &&
                                    Array.isArray(UOMList) &&
                                    UOMList.map((uom, index) => {
                                      return (
                                        <Option key={index} value={uom.Key}>
                                          {uom.Value}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                name={[name, 'Observation']}
                              ></Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                name={[name, 'PlannedSequence']}
                              ></Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                name={[name, 'Criteria']}
                              ></Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                name={[name, 'RecordBy']}
                              ></Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                name={[name, 'CheckedBy']}
                              ></Form.Item>
                              <Form.Item style={{ width: '100%', flex: '50%' }}></Form.Item>
                              <Form.Item style={{ width: '100%', flex: '50%' }}>
                                <InputComponent style={{ display: 'none' }} />
                              </Form.Item>
                            </div>
                          </div>
                        ))
                      : null}
                  </>
                )}
              </Form.List>
            </Drawer>
          </StyledForm>
        </>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkOrderJobPlanesTask);
