import React from 'react';
import axios from 'axios';
import { Row, Col, Badge } from 'antd';

import localStorage from '../../../../../utils/localStorage';
import history from '../../../../../commons/history';

import './landingpage.css';

const colStyle = {
  color: 'white',
  margin: '5px',
  padding: '5px',
  float: 'left',
  display: 'block',
  borderRadius: '10px',
  width: '6%',
  boxShadow: '5px 5px 30px #333333'
};

const titleStyle = {
  color: 'black',
  fontSize: '1em',
  fontWeight: 'bold',
  textAlign: 'center',
  // padding:'5px',
  borderBottom: '2px dotted black',
  marginBottom: '10px'
};

class TrainStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: {},
      activeCrane: []
    };
  }

  timeTicket = null;
  // signal = axios.CancelToken.source();
  requestDeviceCheck = true;
  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this._getdeviceList();

    this.timeTicket = setInterval(() => {
      if (this.requestDeviceCheck) {
        // Request Pending status check for Device Stauts
        this._getdeviceList();
      }
    }, 1 * 10000);
  }

  componentWillUnmount() {
    // this.signal.cancel('Api is being canceled');
    clearInterval(this.timeTicket);
  }

  _getdeviceList = () => {
    this.requestDeviceCheck = false;
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    if (!siteId) {
      siteId = 'test';
    }
    const headers = {
      method: 'GET',
      // url: `/api/landingPage/getTrainStatus`,
      url: `/api/landingPage/getDeviceStatus?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
      // cancelToken: this.signal.token,
    };
    axios(headers)
      .then((response) => {
        if (response.data) {
          this.setState({ deviceList: response.data });
          this.requestDeviceCheck = true;
        }
      })
      .catch(() => {
        return {};
      });
  };

  onCraneImageClick = (craneObject) => {
    //  history.push(`/rubus/singleHMI/dynamic/Train_Display_Unit/${craneObject.deviceCode}`);
    history.push(`/rubus/singleHMI/distinct/Train_Display_Unit/${craneObject.deviceCode}`);
  };

  render() {
    let { deviceList } = this.state;
    let colorStatus = {
      'No Data': '#808080',
      'Main Line': '#099c0c',
      Depot: '#3397da',
      Maintenance: '#ffc300'
    };
    return (
      <div style={{ backgroundColor: '#243a4f', minHeight: window.innerHeight - 135 }}>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          {colorStatus &&
            Object.entries(colorStatus).map(([key, value]) => {
              return (
                <div key={value}>
                  <Badge color={value} />
                  <span style={{ marginRight: '10px', color: 'white' }}>{key}</span>
                </div>
              );
            })}
        </div>
        <div style={{ marginBottom: '5px', marginLeft: '4%' }}></div>
        <Row className="">
          <Col span={24}>
            {deviceList &&
              Array.isArray(deviceList) &&
              deviceList.map((key, index) => {
                return (
                  <Col
                    key={index}
                    className="colRunning"
                    style={{
                      ...colStyle,
                      backgroundColor: key && key.color ? key.color : ''
                    }}
                    onClick={() => this.onCraneImageClick(key)}
                    width={'7%'}
                  >
                    <div style={titleStyle}>{key.deviceName} </div>
                    <div
                      className={'stop123'}
                      style={{ backgroundImage: key && key.url ? `url(${key.url})` : '' }}
                    ></div>
                  </Col>
                );
              })}
          </Col>
        </Row>
      </div>
    );
  }
}

export default TrainStatus;
