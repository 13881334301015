import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { Row, Form, Input, InputNumber, Collapse, message, DatePicker } from 'antd';
import TableView from '../Widgets/TableView';
import localStorage from '../../../../utils/localStorage';
import SelectComponennt from '../Widgets/SelectComponent';
import { dailyShift, morningShift, nightShift, defaultRange } from '../../Constants/index.js';

import 'antd/lib/date-picker/style/css';
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const newLocal = true;
class FormWithSubModelsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: props.formObject || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: '',
      equipmentName: '',
      BodyCount: '',
      batchArray: [],
      date: moment().format('YYYY-MM-DD'),
      equipmentList: [],
      shift: 'daily'
    };
  }

  componentDidMount() {
    this.setState({
      formObject: this.props.formObject
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: this.props.formObject,
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog,
        shift: this.props.formObject['mainHeaderValue']['Shift']
      });
    }
  }

  _getMandatoryStatus = () => {
    let { formObject } = this.props;
    let requiredStatus = false;
    if (formObject && formObject.mainHeader) {
      formObject.mainHeader.map((formHeader) => {
        let val = formObject.mainHeaderValue;
        if (formHeader && formHeader.key && formHeader.required === true) {
          if (val && val[formHeader.key]) {
            return {};
          } else {
            requiredStatus = true;
          }
        }
        return {};
      });
    }
    return requiredStatus;
  };

  onInputChange = (columnValue, columnKey, name, batchKey) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          [batchKey]: {
            ...(formObject &&
            formObject[name] &&
            formObject[name].datasource &&
            formObject[name].datasource[batchKey]
              ? formObject[name].datasource[batchKey]
              : {}),
            [columnKey]: columnValue
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onChangeRemarks = (value, name, key) => {
    let { formObject } = this.state;
    if (
      formObject &&
      formObject[name] &&
      formObject[name].datasource &&
      formObject[name].datasource.cause
    ) {
      formObject[name].datasource.cause[key] = value;
    }
    this.props.downTimeData(formObject, false);
  };

  handleKeyPress = () => {};

  onChange = (date, columnKey, name) => {
    let { formObject } = this.props;
    // let masterData = [
    //   {
    //     shift: "Morning Shift",
    //     startTime: "06:00:00",
    //     endTime: "17:59:59",
    //   },
    //   {
    //     shift: "Night Shift",
    //     startTime: "18:00:00",
    //     endTime: "05:59:59",
    //   },
    // ];
    // if (true) {
    //   let startTime = moment(date[0]).format("HH:mm:ss");
    //   let endTime = moment(date[1]).format("HH:mm:ss");
    //   let Shift =
    //     formObject &&
    //     formObject["mainHeaderValue"] &&
    //     formObject["mainHeaderValue"]["Shift"];

    //     masterData &&
    //     masterData.map((item) => {
    //       if (item.shift === Shift) {
    //         if (item.startTime <= startTime && endTime <= item.endTime) {
    //         } else {
    //         }
    //       }
    //     });
    // }

    formObject = {
      ...formObject,
      model: {
        ...(formObject && formObject.model ? formObject.model : {}),
        [name]: {
          ...(formObject && formObject.model && formObject.model[name]
            ? formObject.model[name]
            : {}),
          datasource: {
            ...(formObject &&
            formObject.model &&
            formObject.model[name] &&
            formObject.model[name].datasource
              ? formObject.model[name].datasource
              : {}),
            [columnKey]: {
              startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
              endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
            }
          }
        }
      }
    };

    this.props.downTimeData(formObject, false);
  };
  onOk = (date, columnKey, name) => {
    let { formObject } = this.state;
    let startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
    let endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
    if (date[0] !== null && date[1] !== null) {
      if (startDate2 === endDate2) {
        message.error('StartDateTime and EndDateTime should not be same');
        formObject = {
          ...formObject,
          model: {
            ...(formObject && formObject.model ? formObject.model : {}),
            [name]: {
              ...(formObject && formObject.model && formObject.model[name]
                ? formObject.model[name]
                : {}),
              datasource: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].datasource
                  ? formObject.model[name].datasource
                  : {}),
                [columnKey]: {}
              }
            }
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (startDate2 !== endDate2) {
        startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
        endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
        formObject = {
          ...formObject,
          model: {
            ...(formObject && formObject.model ? formObject.model : {}),
            [name]: {
              ...(formObject && formObject.model && formObject.model[name]
                ? formObject.model[name]
                : {}),
              datasource: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].datasource
                  ? formObject.model[name].datasource
                  : {}),
                [columnKey]: {
                  startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                  endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                }
              }
            }
          }
        };
        let masterData = [
          {
            shift: 'Morning Shift',
            startHour: '06',
            endHour: '17',
            startHour1: '06',
            endHour1: '23',
            startHour2: '00',
            endHour2: '05'
          },
          {
            shift: 'Night Shift',
            startHour: '18',
            endHour: '05',
            startHour1: '06',
            endHour1: '23',
            startHour2: '00',
            endHour2: '05'
          }
        ];
        if (newLocal) {
          let startTime = moment(date[0]).format('HH:mm:ss');
          let startHours = moment(date[0]).format('HH');

          let endHours = moment(date[1]).format('HH');
          let startDate = moment(date[0]).format('DD');
          let endDate = moment(date[1]).format('DD');
          let endTime = moment(date[1]).format('HH:mm:ss');
          let Shift =
            formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Shift'];
          let selectedDate =
            formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date'];
          let currentDate = moment(selectedDate).format('DD');
          masterData &&
            masterData.map((item) => {
              if (item.shift === Shift && item.shift === 'Morning Shift') {
                //  hours >= 6 && hours < 18)

                if (item.startHour <= startHours && endHours <= item.endHour) {
                  formObject = {
                    ...formObject,
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [name]: {
                        ...(formObject && formObject.model && formObject.model[name]
                          ? formObject.model[name]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[name] &&
                          formObject.model[name].datasource
                            ? formObject.model[name].datasource
                            : {}),
                          [columnKey]: {
                            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                          }
                        }
                      }
                    }
                  };
                } else {
                  message.error(
                    'Please Select Morning Shift From' +
                      item.startHour +
                      ':00' +
                      '~' +
                      item.endHour +
                      ':59'
                  );
                  formObject = {
                    ...formObject,
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [name]: {
                        ...(formObject && formObject.model && formObject.model[name]
                          ? formObject.model[name]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[name] &&
                          formObject.model[name].datasource
                            ? formObject.model[name].datasource
                            : {}),
                          [columnKey]: {}
                        }
                      }
                    }
                  };
                }
              } else if (
                item.shift === Shift &&
                item.shift === 'Night Shift' &&
                currentDate === startDate &&
                startDate !== endDate &&
                startTime !== endTime
              ) {
                //  hours >= 6 && hours < 18)
                if (item.startHour <= startHours && item.endHour >= endHours) {
                  formObject = {
                    ...formObject,
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [name]: {
                        ...(formObject && formObject.model && formObject.model[name]
                          ? formObject.model[name]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[name] &&
                          formObject.model[name].datasource
                            ? formObject.model[name].datasource
                            : {}),
                          [columnKey]: {
                            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                          }
                        }
                      }
                    }
                  };
                } else {
                  message.error(
                    'Please Select Night Shift From' +
                      item.startHour +
                      ':00' +
                      '~' +
                      item.endHour +
                      ':59'
                  );
                  formObject = cloneDeep(this.props.formObject);
                  formObject = {
                    ...formObject,
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [name]: {
                        ...(formObject && formObject.model && formObject.model[name]
                          ? formObject.model[name]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[name] &&
                          formObject.model[name].datasource
                            ? formObject.model[name].datasource
                            : {}),
                          [columnKey]: {}
                        }
                      }
                    }
                  };
                }
              } else if (
                item.shift === Shift &&
                item.shift === 'Night Shift' &&
                currentDate === startDate &&
                startDate === endDate
              ) {
                //  hours >= 6 && hours < 18)
                if (item.startHour <= startHours && item.endHour1 >= endHours) {
                  formObject = {
                    ...formObject,
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [name]: {
                        ...(formObject && formObject.model && formObject.model[name]
                          ? formObject.model[name]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[name] &&
                          formObject.model[name].datasource
                            ? formObject.model[name].datasource
                            : {}),
                          [columnKey]: {
                            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                          }
                        }
                      }
                    }
                  };
                } else {
                  message.error(
                    'Please Select Night Shift From' + item.startHour + '~' + item.endHour
                  );
                  formObject = cloneDeep(this.props.formObject);
                  formObject = {
                    ...formObject,
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [name]: {
                        ...(formObject && formObject.model && formObject.model[name]
                          ? formObject.model[name]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[name] &&
                          formObject.model[name].datasource
                            ? formObject.model[name].datasource
                            : {}),
                          [columnKey]: {}
                        }
                      }
                    }
                  };
                }
              } else if (
                item.shift === Shift &&
                item.shift === 'Night Shift' &&
                currentDate !== startDate &&
                startDate === endDate
              ) {
                //  hours >= 6 && hours < 18)

                if (item.startHour2 <= startHours && item.endHour >= endHours) {
                  formObject = {
                    ...formObject,
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [name]: {
                        ...(formObject && formObject.model && formObject.model[name]
                          ? formObject.model[name]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[name] &&
                          formObject.model[name].datasource
                            ? formObject.model[name].datasource
                            : {}),
                          [columnKey]: {
                            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                          }
                        }
                      }
                    }
                  };
                } else {
                  message.error(
                    'Please Select Night Shift From' + item.startHour + '~' + item.endHour
                  );
                  formObject = cloneDeep(this.props.formObject);
                  formObject = {
                    ...formObject,
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [name]: {
                        ...(formObject && formObject.model && formObject.model[name]
                          ? formObject.model[name]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[name] &&
                          formObject.model[name].datasource
                            ? formObject.model[name].datasource
                            : {}),
                          [columnKey]: {}
                        }
                      }
                    }
                  };
                }
              }
              return {};
            });
        }
        if (formObject && formObject['model']) {
          Object.keys(formObject['model']).map((modelItem) => {
            if (modelItem !== 'defaultData' && modelItem !== name) {
              let datasource =
                formObject['model'] &&
                formObject['model'][modelItem] &&
                formObject['model'][modelItem].datasource;

              if (datasource && datasource[columnKey] && datasource[columnKey]['endDate']) {
                let startDate1 = datasource && datasource[columnKey]['startDate'];
                let endDate1 = datasource && datasource[columnKey]['endDate'];
                if (startDate1 <= endDate2 && startDate2 <= endDate1) {
                  message.error('Dates Overlapping with' + startDate1 + '~' + endDate1);
                  formObject = cloneDeep(this.props.formObject);
                  formObject = {
                    ...formObject,
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [name]: {
                        ...(formObject && formObject.model && formObject.model[name]
                          ? formObject.model[name]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[name] &&
                          formObject.model[name].datasource
                            ? formObject.model[name].datasource
                            : {}),
                          [columnKey]: {}
                        }
                      }
                    }
                  };
                } else {
                  formObject = {
                    ...formObject,
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [name]: {
                        ...(formObject && formObject.model && formObject.model[name]
                          ? formObject.model[name]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[name] &&
                          formObject.model[name].datasource
                            ? formObject.model[name].datasource
                            : {}),
                          [columnKey]: {
                            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                          }
                        }
                      }
                    }
                  };
                }
              }
            }
            //  else {
            //   formObject = {
            //     ...formObject,
            //     model: {
            //       ...(formObject && formObject.model ? formObject.model : {}),
            //       [name]: {
            //         ...(formObject && formObject.model && formObject.model[name]
            //           ? formObject.model[name]
            //           : {}),
            //         datasource: {
            //           ...(formObject &&
            //           formObject.model &&
            //           formObject.model[name] &&
            //           formObject.model[name].datasource
            //             ? formObject.model[name].datasource
            //             : {}),
            //           [columnKey]: {
            //             startDate: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
            //             endDate: moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
            //           },
            //         },
            //       },
            //     },
            //   };
            // }
            return {};
          });
        }
        this.props.downTimeData(formObject, false);
      }
    } else {
      message.error('Please Input Start & End Times');
    }
    this.props.downTimeData(formObject, false);
  };
  onMainOk = (date, columnKey) => {
    let { formObject } = this.state;
    let startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm');
    let endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm');
    if (date[0] !== null && date[1] !== null) {
      if (startDate2 === endDate2) {
        message.error('StartDateTime and EndDateTime should not be same');
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: {
              // startDate: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
              // endDate: moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
            }
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (startDate2 !== endDate2) {
        startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm');
        endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm');
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: {
              startDate: moment(date[0]).format('YYYY-MM-DD HH:mm'),
              endDate: moment(date[1]).format('YYYY-MM-DD HH:mm')
            }
          }
        };

        let masterData = [
          {
            shift: 'Morning Shift',
            startHour: '06',
            endHour: '17',
            startHour1: '06',
            endHour1: '23',
            startHour2: '00',
            endHour2: '05'
          },
          {
            shift: 'Night Shift',
            startHour: '18',
            endHour: '05',
            startHour1: '06',
            endHour1: '23',
            startHour2: '00',
            endHour2: '05'
          }
        ];

        let startTime = moment(date[0]).format('HH:mm');
        let startHours = moment(date[0]).format('HH');

        let endHours = moment(date[1]).format('HH');
        let startDate = moment(date[0]).format('DD');
        let endDate = moment(date[1]).format('DD');
        let endTime = moment(date[1]).format('HH:mm');
        let Shift =
          formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Shift'];
        let selectedDate =
          formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date'];
        let currentDate = moment(selectedDate).format('DD');
        masterData &&
          masterData.map((item) => {
            if (item.shift === Shift && item.shift === 'Morning Shift') {
              //  hours >= 6 && hours < 18)
              if (item.startHour <= startHours && endHours <= item.endHour) {
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      startDate: moment(date[0]).format('YYYY-MM-DD HH:mm'),
                      endDate: moment(date[1]).format('YYYY-MM-DD HH:mm')
                    }
                  }
                };
                this.props.downTimeData(formObject, false);
              } else {
                message.error(
                  'Please Select Morning Shift From' + item.startHour + '~' + item.endHour
                );
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      // startDate: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
                      // endDate: moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
                    }
                  }
                };
              }
              this.props.downTimeData(formObject, false);
            } else if (
              item.shift === Shift &&
              item.shift === 'Night Shift' &&
              currentDate === startDate &&
              startDate !== endDate &&
              startTime !== endTime
            ) {
              //  hours >= 6 && hours < 18)
              if (item.startHour <= startHours && item.endHour >= endHours) {
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      startDate: moment(date[0]).format('YYYY-MM-DD HH:mm'),
                      endDate: moment(date[1]).format('YYYY-MM-DD HH:mm')
                    }
                  }
                };
              } else {
                message.error(
                  'Please Select Night Shift From' + item.startHour + '~' + item.endHour
                );
                formObject = cloneDeep(this.props.formObject);
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      // startDate: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
                      // endDate: moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
                    }
                  }
                };
              }
              this.props.downTimeData(formObject, false);
            } else if (
              item.shift === Shift &&
              item.shift === 'Night Shift' &&
              currentDate === startDate &&
              startDate === endDate
            ) {
              if (item.startHour <= startHours && item.endHour1 >= endHours) {
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      startDate: moment(date[0]).format('YYYY-MM-DD HH:mm'),
                      endDate: moment(date[1]).format('YYYY-MM-DD HH:mm')
                    }
                  }
                };
                this.props.downTimeData(formObject, false);
              } else {
                message.error(
                  'Please Select Night Shift From' + item.startHour + '~' + item.endHour
                );
                formObject = cloneDeep(this.props.formObject);
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      // startDate: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
                      // endDate: moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
                    }
                  }
                };
              }
            } else if (
              item.shift === Shift &&
              item.shift === 'Night Shift' &&
              currentDate !== startDate &&
              startDate === endDate
            ) {
              //  hours >= 6 && hours < 18)
              if (item.startHour2 <= startHours && item.endHour2 >= endHours) {
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      startDate: moment(date[0]).format('YYYY-MM-DD HH:mm'),
                      endDate: moment(date[1]).format('YYYY-MM-DD HH:mm')
                    }
                  }
                };
                this.props.downTimeData(formObject, false);
              } else {
                message.error(
                  'Please Select Night Shift From' + item.startHour + '~' + item.endHour
                );
                formObject = cloneDeep(this.props.formObject);
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      // startDate: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
                      // endDate: moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
                    }
                  }
                };
              }
            }
            return {};
          });
        this.props.downTimeData(formObject, false);
      }
      this.props.downTimeData(formObject, false);
    } else {
      message.error('Please Input Start & End Times');
    }
    this.props.downTimeData(formObject, false);
  };

  onMainChange = (date, columnKey) => {
    let { formObject } = this.props;
    formObject = {
      ...formObject,
      mainHeaderValue: {
        ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
        [columnKey]: {
          startDate: moment(date[0]).format('YYYY-MM-DD HH:mm'),
          endDate: moment(date[1]).format('YYYY-MM-DD HH:mm')
        }
      }
    };

    this.props.downTimeData(formObject, false);
  };

  onDateHeaderMainChange = (date, columnKey) => {
    let { formObject } = this.state;
    let formObjectNew = {};
    if (columnKey === 'Date') {
      //header
      let allAvailableMainHeader = [];
      allAvailableMainHeader = formObject.mainHeader.map((formMain) => {
        if (formMain && formMain.key) {
          return formMain.key;
        }
        return {};
      });

      if (allAvailableMainHeader && allAvailableMainHeader.indexOf('TimeSelection') > -1) {
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss'),
            TimeSelection: {}
          }
        };
      }

      //nested
      let datasourceNew = {};
      Object.keys(formObject.model).map((modelKey) => {
        let allAvailableColumns = [];
        if (formObject.model[modelKey].columns) {
          allAvailableColumns = formObject.model[modelKey].columns.map((colu) => {
            if (colu && colu.key) {
              return colu.key;
            }
            return {};
          });
        }
        if (
          formObject.model[modelKey].datasource &&
          allAvailableColumns &&
          (allAvailableColumns.indexOf('TimeSelection') > -1 ||
            allAvailableColumns.indexOf('Time Selection') > -1)
        ) {
          let datasourceNews = {};
          if (allAvailableColumns.indexOf('Time Selection') > -1) {
            datasourceNews = {
              ...formObject.model[modelKey].datasource,
              'Time Selection': {}
            };
          } else {
            datasourceNews = {
              ...formObject.model[modelKey].datasource,
              TimeSelection: {}
            };
          }

          datasourceNew[modelKey] = {
            ...formObject.model[modelKey],
            datasource: datasourceNews
          };
        } else {
          datasourceNew[modelKey] = formObject.model[modelKey];
        }

        // batchCodeNew ={
        //   ...(formObject.model && formObject.model[modelKey] &&  formObject.model[modelKey].datasource ?
        //        formObject.model[modelKey].datasource : {}),
        //   "TimeSelection": {}
        // }

        // datasourceNew[modelKey] = {
        //   ...(formObject.model[modelKey]),
        //   datasource: batchCodeNew
        // }
        return {};
      });
      formObjectNew = {
        ...formObject,
        mainHeaderValue: {
          ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
          [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
        model: datasourceNew
      };

      // formObjectNew = {
      //   ...formObject,
      //   mainHeaderValue: {
      //     ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
      //     [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
      //   },
      //   "model": datasourceNew
      // }
      this.props.downTimeData(formObjectNew);
    } else {
      formObject = {
        ...formObject,
        mainHeaderValue: {
          ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
          [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };
  inputNumberChange = (minValue, maxValue, value, key) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      (maxValue !== undefined || value === '')
    ) {
      this.onInputHeaderMainChange(value, key);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputHeaderMainChange(value, key);
    } else {
      this.onInputHeaderMainChange('', key);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  onInputHeaderMainChange = async (columnValue, columnKey) => {
    let { formObject } = this.state;
    if (columnValue < 0 && typeof columnValue === 'number') {
      message.error('Please Input Positive Values  Only');
    } else {
      if (
        columnKey === 'NumberOfModels' &&
        formObject &&
        formObject.model &&
        formObject.model.samples
      ) {
        let modelList = {};
        for (let i = 1; i <= columnValue; i++) {
          modelList['Model' + i] = {
            ...(formObject && formObject.model && formObject.model['defaultData']
              ? formObject.model['defaultData']
              : {})
          };
        }

        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue
          },
          model: {
            ...modelList,
            samples: {
              ...(formObject && formObject.model && formObject.model['samples']
                ? formObject.model['samples']
                : {})
            },
            default: {
              ...(formObject && formObject.model && formObject.model['defaultData']
                ? formObject.model['defaultData']
                : {})
            }
          }
        };

        this.props.downTimeData(formObject, false);
      } else if (columnKey === 'NumberOfModels') {
        let modelList = {};
        for (let i = 1; i <= columnValue; i++) {
          modelList['Model' + i] = {
            ...(formObject && formObject.model && formObject.model['defaultData']
              ? formObject.model['defaultData']
              : {})
          };
        }

        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue
          },
          model: {
            ...modelList,
            default: {
              ...(formObject && formObject.model && formObject.model['defaultData']
                ? formObject.model['defaultData']
                : {})
            }
          }
        };

        this.props.downTimeData(formObject, false);
      } else if (columnKey === 'ModelChange' && columnValue === 'No') {
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue,
            NumberOfModels: 1
          },
          model: {
            Model1: {
              ...(formObject && formObject.model && formObject.model['defaultData']
                ? formObject.model['defaultData']
                : {})
            },
            default:
              formObject && formObject.model && formObject.model['defaultData']
                ? formObject.model['defaultData']
                : {}
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (columnKey === 'Equipment') {
        // if (elog.logId === "108") {
        //   const accessToken = localStorage.get("accessToken");
        //   let obj = {
        //     method: "GET",
        //     url: `/api/logbook/getGlazelinelist `,
        //     headers: {
        //       Authorization: `Bearer ${accessToken}`,
        //     },
        //   };
        //   let data = await axios(obj);
        //   let barCount = data.data.filter((equip) => {
        //     return equip.Equipment === columnValue;
        //   });

        //   let count = barCount[0].BarCount;

        //   let barArray = [];
        //   for (let i = 1; i <= count; i++) {
        //     let obj = {
        //       bar: `Bar-${i}`,
        //       key: i,
        //     };
        //     barArray.push(obj);
        //   }
        //   formObject = {
        //     ...formObject,
        //     mainHeaderValue: {
        //       ...(formObject && formObject.mainHeaderValue
        //         ? formObject.mainHeaderValue
        //         : {}),
        //       [columnKey]: columnValue,
        //     },
        //     model: {
        //       ...(formObject && formObject.model ? formObject.model : {}),
        //       ["Digital Operations"]: {
        //         ...(formObject && formObject.model["Digital Operations"]
        //           ? formObject.model["Digital Operations"]
        //           : {}),
        //         "table": {
        //           ...(formObject &&
        //           formObject.model["Digital Operations"]["table"]
        //             ? formObject.model["Digital Operations"]["table"]
        //             : {}),
        //           "datasource": barArray,
        //         },
        //       },
        //     },
        //   };
        //  this.props.downTimeData(formObject,false);
        // } else {
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue
          },
          model: {
            ...(formObject && formObject.model ? formObject.model : {})
          }
        };
        this.props.downTimeData(formObject, false);
        // }
      } else if (columnKey === 'digital_Equipment') {
        const accessToken = localStorage.get('accessToken');
        let obj = {
          method: 'GET',
          url: `/api/logbook/getGlazelinelist `,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        };
        let data = await axios(obj);
        let barCount = data.data.filter((equip) => {
          return equip.Equipment === columnValue;
        });

        let count = barCount[0].BarCount;

        let barArray = [];
        for (let i = 1; i <= count; i++) {
          let obj = {
            bar: `Bar-${i}`,
            key: i
          };
          barArray.push(obj);
        }
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue
          },
          model: {
            ...(formObject && formObject.model ? formObject.model : {}),
            'Digital Operations': {
              ...(formObject && formObject.model['Digital Operations']
                ? formObject.model['Digital Operations']
                : {}),
              table: {
                ...(formObject && formObject.model['Digital Operations']['table']
                  ? formObject.model['Digital Operations']['table']
                  : {}),
                datasource: barArray
              }
            }
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (columnKey === 'ModalChanges') {
        let modelList = {};
        for (let i = 1; i <= columnValue; i++) {
          modelList['Model' + i] = {
            ...(formObject && formObject.model && formObject.model['defaultData']
              ? formObject.model['defaultData']
              : {})
          };
        }

        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue
          },
          model: {
            ...modelList,
            default: {
              ...(formObject && formObject.model && formObject.model['defaultData']
                ? formObject.model['defaultData']
                : {})
            }
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (columnKey === 'Polishing Line') {
        const accessToken = localStorage.get('accessToken');
        let obj = {
          method: 'POST',
          url: `/api/logbook/dropdownforcolumnaliasnames`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            tableName: 'master."Master_Machine"',
            columnName: columnValue
          }
        };

        let data = await axios(obj);

        for (var i in formObject.mainHeader) {
          if (formObject.mainHeader[i].key === 'Machine') {
            formObject.mainHeader[i].table = [...data.data];
            break; //Stop this loop, we found it!
          }
        }

        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (columnKey === 'Shift') {
        formObject &&
          formObject['model'] &&
          Object.keys(formObject['model']).map((key) => {
            if (
              key !== 'defaultData' &&
              formObject['model'] &&
              formObject['model'][key] &&
              formObject['model'][key].columns &&
              formObject['model'][key].datasource
            ) {
              Object.keys(
                formObject['model'] &&
                  formObject['model'][key] &&
                  formObject['model'][key].columns &&
                  formObject['model'][key].datasource
              ).map((item) => {
                if (item === 'TimeSelection') {
                  formObject = {
                    ...formObject,
                    mainHeaderValue: {
                      ...(formObject && formObject.mainHeaderValue
                        ? formObject.mainHeaderValue
                        : {}),
                      [columnKey]: columnValue
                    },
                    model: {
                      ...(formObject && formObject.model ? formObject.model : {}),
                      [key]: {
                        ...(formObject && formObject.model && formObject.model[key]
                          ? formObject.model[key]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject.model &&
                          formObject.model[key] &&
                          formObject.model[key].datasource
                            ? formObject.model[key].datasource
                            : {}),
                          TimeSelection: ''
                        }
                      }
                    }
                  };
                }
                return {};
              });
            } else {
              formObject = {
                ...formObject,
                mainHeaderValue: {
                  ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                  [columnKey]: columnValue
                }
              };
            }
            return {};
          });

        this.props.downTimeData(formObject, false);
      } else {
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue
          }
        };
        this.props.downTimeData(formObject, false);
      }
    }
  };

  onDateChange = (date, dateString) => {
    this.setState({
      date: dateString
    });
  };
  inputHeaderNumberChange = (minValue, maxValue, value, key, name) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      (maxValue !== undefined || value === '')
    ) {
      this.onHeaderInputChange(value, key, name);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onHeaderInputChange(value, key, name);
    } else {
      this.onHeaderInputChange('', key, name);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  onHeaderInputChange = (columnValue, columnKey, name) => {
    let { formObject } = this.props;

    // if (columnKey === 'TileSize') {

    //   // dependentSelection
    //   formObject = {
    //     ...formObject,
    //     model: {
    //       ...(formObject && formObject.model ? formObject.model : {}),
    //       [name]: {
    //         ['columns']: {
    //         ...(
    //           formObject.model[name] &&
    //           formObject.model[name]['columns'] &&
    //           Array.isArray(formObject.model[name]['columns'])
    //           ?
    //           (formObject.model[name]['columns']).map((col) => {
    //             if (col.dependentSelection && col.dependentSelection === columnKey) {
    //               return {
    //                 ...col,
    //                 "table": {
    //                   ...(col && col.table ? col.table : {}),
    //                   ["payload"]: {
    //                     ...(col && col.table && col.table['payload'] ? col.table['payload'] : {}),
    //                     ["conditionList"]: col && col.table && col.table['payload'].conditionList && Array.isArray(col.table['payload'].conditionList) ?
    //                       col.table['payload'].conditionList.map((condition) => {
    //                         if (condition && condition.referenceKey === columnKey) {
    //                           return {
    //                             ...condition,
    //                             "value": columnValue
    //                           }

    //                         } else {
    //                           return condition
    //                         }
    //                       })
    //                       : []
    //                   }
    //                 }
    //               }
    //             } else {
    //               return formObject.model[name]['columns']
    //             }
    //           })
    //           : {}
    //         )
    //         }
    //       }
    //     }
    //   }
    // }
    if (columnValue < 0 && typeof columnValue === 'number') {
      message.error('Please Input Positive Values  Only');
    } else {
      if (columnKey === 'Model_BatchCount') {
        let batchArray = [];
        for (let i = 1; i <= columnValue; i++) {
          batchArray.push({
            BatchName: 'Batch-' + i,
            key: i
          });
        }
        formObject = {
          ...formObject,
          model: {
            ...(formObject && formObject.model ? formObject.model : {}),
            [name]: {
              ...(formObject && formObject.model && formObject.model[name]
                ? formObject.model[name]
                : {}),
              datasource: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].datasource
                  ? formObject.model[name].datasource
                  : {}),
                [columnKey]: columnValue
              },
              table: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].table &&
                formObject.model[name].table
                  ? formObject.model[name].table
                  : {}),
                datasource: [...batchArray]
              }
            }
          }
        };
      } else if (columnKey === 'No of Head') {
        let batchArray = [];
        for (let i = 1; i <= columnValue; i++) {
          batchArray.push({
            key: i
          });
        }
        formObject = {
          ...formObject,
          model: {
            ...(formObject && formObject.model ? formObject.model : {}),
            [name]: {
              ...(formObject && formObject.model && formObject.model[name]
                ? formObject.model[name]
                : {}),
              datasource: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].datasource
                  ? formObject.model[name].datasource
                  : {}),
                [columnKey]: columnValue
              },
              table: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].table &&
                formObject.model[name].table
                  ? formObject.model[name].table
                  : {}),
                datasource: [...batchArray]
              }
            }
          }
        };
      } else if (columnKey === 'Typology') {
        let typologyList =
          formObject &&
          formObject.model &&
          formObject.model[name] &&
          formObject.model[name]['table'] &&
          formObject.model[name]['table']['datasource']
            ? formObject.model[name]['table']['datasource']
            : [];
        typologyList =
          typologyList &&
          Array.isArray(typologyList) &&
          typologyList.map((typology) => {
            return {
              ...typology,
              [columnKey]: columnValue
            };
          });

        formObject = {
          ...formObject,
          model: {
            ...(formObject && formObject.model ? formObject.model : {}),
            [name]: {
              ...(formObject && formObject.model && formObject.model[name]
                ? formObject.model[name]
                : {}),
              datasource: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].datasource
                  ? formObject.model[name].datasource
                  : {}),
                [columnKey]: columnValue
              },
              table: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].table
                  ? formObject.model[name].table
                  : {}),
                datasource: typologyList
              }
            }
          }
        };
      } else if (columnKey === 'Thermocouple_Changes') {
        let batchArray = [];
        for (let i = 1; i <= columnValue; i++) {
          batchArray.push({
            FormFields: 'ThermoCouple' + i,
            key: i
          });
        }
        formObject = {
          ...formObject,
          model: {
            ...(formObject && formObject.model ? formObject.model : {}),
            [name]: {
              ...(formObject && formObject.model && formObject.model[name]
                ? formObject.model[name]
                : {}),
              datasource: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].datasource
                  ? formObject.model[name].datasource
                  : {}),
                [columnKey]: columnValue
              },
              table: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].table &&
                formObject.model[name].table
                  ? formObject.model[name].table
                  : {}),
                datasource: [...batchArray]
              }
            }
          }
        };
      } else if (columnKey === 'burnerAirPressureChanges') {
        let batchArray = [];
        for (let i = 1; i <= columnValue; i++) {
          batchArray.push({
            TopFields: 'BurnerZone' + i,
            key: i
          });
        }
        formObject = {
          ...formObject,
          model: {
            ...(formObject && formObject.model ? formObject.model : {}),
            [name]: {
              ...(formObject && formObject.model && formObject.model[name]
                ? formObject.model[name]
                : {}),
              datasource: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].datasource
                  ? formObject.model[name].datasource
                  : {}),
                [columnKey]: columnValue
              },
              table: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].table &&
                formObject.model[name].table
                  ? formObject.model[name].table
                  : {}),
                datasource: [...batchArray]
              }
            }
          }
        };
      } else {
        formObject = {
          ...formObject,
          model: {
            ...(formObject && formObject.model ? formObject.model : {}),
            [name]: {
              ...(formObject && formObject.model && formObject.model[name]
                ? formObject.model[name]
                : {}),
              datasource: {
                ...(formObject &&
                formObject.model &&
                formObject.model[name] &&
                formObject.model[name].datasource
                  ? formObject.model[name].datasource
                  : {}),
                [columnKey]: columnValue
              }
            }
          }
        };
      }
      this.props.downTimeData(formObject, false);
    }
  };

  renderHeaderSwitch(formObject, columns, name) {
    const { shift } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject.model &&
              formObject.model[name] &&
              formObject.model[name].datasource &&
              formObject.model[name].datasource[columns.key]
                ? formObject.model[name].datasource[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onHeaderInputChange(e.target.value, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            //Marmini
            text={
              formObject &&
              formObject.model &&
              formObject.model[name] &&
              formObject.model[name].datasource &&
              formObject.model[name].datasource[columns.key]
                ? formObject.model[name].datasource[columns.key]
                : ''
            }
            datasource={
              formObject &&
              formObject.model &&
              formObject.model[name] &&
              formObject.model[name].datasource
                ? formObject.model[name].datasource
                : ''
            }
            //  datasource={
            //   formObject &&
            //     formObject.mainHeaderValue ? formObject.mainHeaderValue
            //     : ""
            // }
            handleFieldChange={(e) => this.onHeaderInputChange(e, columns.key, name)}
            // handleKeyPress={this.handleKeyPress}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject &&
              formObject.model &&
              formObject.model[name] &&
              formObject.model[name].datasource &&
              (formObject.model[name].datasource[columns.key] ||
                formObject.model[name].datasource[columns.key] === 0)
                ? formObject.model[name].datasource[columns.key]
                : ''
            }
            step={columns.step ? columns.step : 1}
            // min={columns.min !== undefined ? columns.min : 0}
            // max={columns.max !== undefined ? columns.max : 1000000}
            placeholder={columns.placeHolder}
            // onChange={(e) => this.onHeaderInputChange(e, columns.key, name)}
            onBlur={(e) =>
              this.inputHeaderNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                name
              )
            }
            onChange={(e) => this.onHeaderInputChange(e, columns.key, name)}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            // ranges={{
            //   Today: [moment(), moment()],
            //   "This Month": [
            //     moment().startOf("month"),
            //     moment().endOf("month"),
            //   ],
            // }}
            allowClear={false}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            placeholder={['Start Time', 'End Time']}
            value={
              formObject &&
              formObject.model &&
              formObject.model[name] &&
              formObject.model[name].datasource &&
              formObject.model[name].datasource[columns.key] &&
              formObject.model[name].datasource[columns.key]['startDate'] &&
              formObject.model[name].datasource[columns.key]['endDate']
                ? [
                    moment(formObject.model[name].datasource[columns.key]['startDate'], dateFormat),
                    moment(formObject.model[name].datasource[columns.key]['endDate'], dateFormat)
                  ]
                : null
            }
            format="YYYY-MM-DD HH:mm"
            // onChange={(e) => this.onChange(e, columns.key, name)}
            // disabledTime={(e) => this.disabledTimeRange(
            //     {
            //         ...(
            //             formObject ? formObject : {}
            //         )
            //     },
            //     e
            // )}
            disabledTime={(e) => this.disabledRangeTime(e)}
            onOk={(e) => this.onOk(e, columns.key, name)}
            disabledDate={(e) =>
              this.disabledDateRange(
                {
                  ...(formObject ? formObject : {})
                },
                e
              )
            }
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject.model &&
              formObject.model[name] &&
              formObject.model[name].datasource &&
              formObject.model[name].datasource[columns.key]
                ? formObject.model[name].datasource[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onHeaderInputChange(e.target.value, columns.key, name)}
          />
        );
    }
  }
  disabledDateRange = (formObject, current) => {
    if (
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date'] &&
      formObject['mainHeaderValue']['Shift'] === 'Morning Shift'
    ) {
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).endOf('day') || current < moment(selectedDate).startOf('day')
      );
    } else if (
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date'] &&
      formObject['mainHeaderValue']['Shift'] === 'Night Shift'
    ) {
      //alert("1")
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).add(1, 'day').endOf('day') ||
        current < moment(selectedDate).startOf('day')
      );
    }
    //return current > moment().add(1, 'days').endOf("day") || current < moment().startOf("day");
    return current || current > moment().endOf('day') || current < moment().startOf('day');
  };

  renderMainSwitch(formObject, columns) {
    const { shift } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key)}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject &&
              formObject.mainHeaderValue &&
              (formObject.mainHeaderValue[columns.key] ||
                formObject.mainHeaderValue[columns.key] === 0)
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            step={columns.step ? columns.step : 1}
            // min={columns.min !== undefined ? columns.min : 0}
            // max={columns.max !== undefined ? columns.max : 1000000}
            placeholder={columns.placeHolder}
            // onChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
            onBlur={(e) =>
              this.inputNumberChange(columns.min, columns.max, e.target.value, columns.key)
            }
            onChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            allowClear={false}
            showTime={columns.showTime ? columns.showTime : false}
            format={
              columns.showTime && columns.showTime === true ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
            }
            value={
              formObject &&
              formObject['mainHeaderValue'] &&
              formObject['mainHeaderValue'][columns.key]
                ? moment(formObject['mainHeaderValue'][columns.key], 'YYYY-MM-DD HH:mm:ss')
                : null
            }
            showNow={false}
            disabledTime={(e) => this.disabledRangeTime(e)}
            disabledDate={(e) =>
              this.disabledDatePicker(
                {
                  ...(formObject ? formObject : {})
                },
                e,
                columns.showTime
              )
            }
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key)}
            style={{ width: 300 }}
          />
        );
      case 'date-pickerTime':
        return (
          <DatePicker
            allowClear={false}
            showTime={true}
            format={'YYYY-MM-DD HH:mm'}
            value={
              formObject &&
              formObject['mainHeaderValue'] &&
              formObject['mainHeaderValue'][columns.key]
                ? moment(formObject['mainHeaderValue'][columns.key], 'YYYY-MM-DD HH:mm')
                : null
            }
            disabledDate={(e) =>
              this.disabledDatePicker(
                {
                  ...(formObject ? formObject : {})
                },
                e
              )
            }
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key)}
            style={{ width: 300 }}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            handleFieldChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
            handleKeyPress={this.handleKeyPress}
          />
        );

      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            // ranges={{
            //   Today: [moment(), moment()],
            //   "This Month": [
            //     moment().startOf("month"),
            //     moment().endOf("month"),
            //   ],
            // }}
            allowClear={false}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            format="YYYY-MM-DD HH:mm"
            disabledDate={(e) =>
              this.disabledDateRange(
                {
                  ...(formObject ? formObject : {})
                },
                e
              )
            }
            value={
              formObject &&
              formObject.mainHeaderValue &&
              formObject.mainHeaderValue &&
              formObject.mainHeaderValue[columns.key] &&
              formObject.mainHeaderValue[columns.key]['startDate'] &&
              formObject.mainHeaderValue[columns.key]['endDate']
                ? [
                    moment(formObject.mainHeaderValue[columns.key]['startDate'], dateFormat),
                    moment(formObject.mainHeaderValue[columns.key]['endDate'], dateFormat)
                  ]
                : null
            }
            disabledTime={(e) => this.disabledRangeTime(e)}
            onOk={(e) => this.onMainOk(e, columns.key)}
            // onChange={(e) => this.onMainChange(e, columns.key)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key)}
          />
        );
    }
  }

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };
  disabledDatePicker = (formObject, current, showTime) => {
    if (
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date'] &&
      showTime
    ) {
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).add(1, 'day').endOf('day') ||
        current < moment(selectedDate).startOf('day')
      );
    } else if (!showTime) {
      return current > moment().endOf('day');
    }
    //return current > moment().add(1, 'days').endOf("day") || current < moment().startOf("day");
    return current || current > moment().endOf('day') || current < moment().startOf('day');
  };
  saveTableViewData = (name, e) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      model: {
        ...(formObject && formObject.model ? formObject.model : {}),
        [name]: {
          ...(formObject && formObject.model && formObject.model[name]
            ? formObject.model[name]
            : {}),
          table: {
            ...(formObject &&
            formObject.model &&
            formObject.model[name] &&
            formObject.model[name].table
              ? formObject.model[name].table
              : {}),
            datasource: [...e]
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };
  dateRanges = (shift) => {
    switch (shift) {
      case 'daily': {
        return dailyShift;
      }
      case 'Morning Shift': {
        return morningShift;
      }
      case 'Night Shift': {
        return nightShift;
      }
      default:
        return defaultRange;
    }
  };
  disabledRangeTime = (current) => {
    let { shift, formObject } = this.state;
    if (
      current !== null &&
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date']
    ) {
      let selectedDate = moment(
        formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date']
      ).format('YYYY-MM-DD');
      let currentDate = moment(current).format('YYYY-MM-DD');
      let range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
      };
      if (shift === 'Morning Shift' && selectedDate === currentDate) {
        return {
          // disabledHours: () => range(18, 18),
          disabledHours: () => [0, 1, 2, 3, 4, 5, 18, 19, 20, 21, 22, 23],
          disabledMinutes: () => range(60, 60)
          // disabledSeconds: () => [55, 56],
        };
      } else if (shift === 'Night Shift' && selectedDate === currentDate) {
        return {
          disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
          disabledMinutes: () => range(60, 60)
        };
      } else if (shift === 'Night Shift' && selectedDate !== currentDate) {
        return {
          disabledHours: () => [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
          disabledMinutes: () => range(60, 60)
        };
      } else if (selectedDate === currentDate) {
        return {
          disabledHours: () => [0, 1, 2, 3, 4, 5],
          disabledMinutes: () => range(60, 60)
        };
      } else if (selectedDate !== currentDate) {
        return {
          disabledHours: () => [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
        };
      }
    }

    return {
      disabledHours: () => [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
      ]
    };
  };

  render() {
    let { formObject } = this.state;
    return (
      <div style={{ marginTop: '30px', marginBottom: '20px', color: 'black' }}>
        <Form className="formCustomLabel" layout="vertical">
          <div
            style={{
              ...(this.props.status === 'Completed'
                ? {
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }
                : {})
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                boxSizing: 'border-box'
              }}
            >
              {formObject && formObject.mainHeader
                ? formObject.mainHeader.map((mainHeaderKey, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          paddingRight: '2em',
                          ...(this.props.status === 'Completed'
                            ? {
                                opacity: newLocal ? 0.75 : 1,
                                pointerEvents: newLocal ? 'none' : 'initial'
                              }
                            : {})
                        }}
                      >
                        <Form.Item
                          required={false}
                          label={
                            <span style={{ marginLeft: mainHeaderKey.marginLeft }}>
                              {mainHeaderKey.displayName}
                            </span>
                          }
                        >
                          <div>{this.renderMainSwitch(formObject, mainHeaderKey)}</div>
                        </Form.Item>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          {formObject && formObject.model && Object.keys(formObject.model).length >= 1 ? (
            <Row style={{ marginTop: '20px' }}>
              <Collapse bordered={false} className="subSectionCollapse">
                {formObject &&
                  formObject.model &&
                  Object.keys(formObject.model).map((name) => {
                    if (name !== 'defaultData') {
                      return (
                        <Panel header={name} key={name}>
                          <div
                            style={{
                              margin: '20px 10px'
                            }}
                          >
                            <div
                              style={{
                                margin: '0px 20px',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                boxSizing: 'border-box',
                                ...(this.props.status === 'Completed'
                                  ? {
                                      opacity: newLocal ? 0.75 : 1,
                                      pointerEvents: newLocal ? 'none' : 'initial'
                                    }
                                  : {})
                              }}
                            >
                              {formObject &&
                              formObject['model'] &&
                              formObject['model'][name] &&
                              formObject['model'][name].columns
                                ? formObject['model'][name].columns.map((columns, index) => {
                                    if (columns && !columns.hide) {
                                      return (
                                        <div key={index} style={{ paddingRight: '2em' }}>
                                          <Form.Item
                                            required={false}
                                            label={
                                              <span
                                                style={{
                                                  marginLeft: columns.marginLeft
                                                }}
                                              >
                                                {columns.displayName}
                                              </span>
                                            }
                                          >
                                            <div>
                                              {this.renderHeaderSwitch(formObject, columns, name)}
                                            </div>
                                          </Form.Item>
                                        </div>
                                      );
                                    }
                                    return {};
                                  })
                                : null}
                            </div>
                            <div
                              style={{
                                ...(this.props.status === 'Completed'
                                  ? {
                                      opacity: newLocal ? 0.75 : 1,
                                      pointerEvents: newLocal ? 'none' : 'initial'
                                    }
                                  : {})
                              }}
                            >
                              {formObject &&
                              formObject['model'] &&
                              formObject['model'][name] &&
                              formObject['model'][name].table &&
                              formObject['model'][name].table.columns ? (
                                <TableView
                                  form={formObject['model'][name].table}
                                  mainHeaderValue={
                                    formObject && formObject.mainHeaderValue
                                      ? formObject.mainHeaderValue
                                      : {}
                                  }
                                  subHeaderValue={
                                    formObject &&
                                    formObject['model'] &&
                                    formObject['model'][name] &&
                                    formObject['model'][name].datasource
                                      ? formObject['model'][name].datasource
                                      : {}
                                  }
                                  recordType={'FormSubSectionTable'}
                                  saveTableViewData={(e) => this.saveTableViewData(name, e)}
                                />
                              ) : null}
                            </div>
                          </div>
                        </Panel>
                      );
                    }
                    return {};
                  })}
              </Collapse>
            </Row>
          ) : null}
        </Form>
      </div>
    );
  }
}

export default FormWithSubModelsMain;
