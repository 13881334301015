import React, { Component } from 'react';
import MonacoEditor from 'react-monaco-editor';
import { Drawer, Input, Form, Row, Col, Select, message, Collapse, Upload, Progress } from 'antd';
import { PlusOutlined, CaretRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import localStorage from '../../../utils/localStorage';
import { getTranslationCache } from '../../../selectors/language';
import { constant } from './constants';
import { getHMICalls } from '../hmicalls';
import { getUserDetails, getUserProfile } from '../../../selectors/layout';
import {
  StyledButton,
  StyledComponent,
  StyledContentRight,
  StyledTable
} from '../../../commons/styles/layout';

const customPanel = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'
};
const { Panel } = Collapse;
const { Option } = Select;

class HmiConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      theme: 'vs-dark',
      editObj: {},
      datasource: [],
      userId: props.userDeatils,
      userProfile: props.userProfile,
      translationCache: props.translationCache || [],
      editEditor: {},
      imageDetails: {},
      imageUrl: '',
      imagebaseUrl: '',
      defaultFileList: [],
      progress: 0,
      loading: false,
      enable: false
    };
    this.hmiRef = React.createRef();
    this.editRef = React.createRef();
  }

  onClose = () => {
    if (this.hmiRef.current) {
      this.hmiRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {},
      imageUrl: '',
      imagebaseUrl: '',
      defaultFileList: []
    });
  };

  addNewHmi = () => {
    if (this.hmiRef.current) {
      this.hmiRef.current.resetFields();
    }
    this.setState({
      visible: true,
      editObj: {},
      listType: 'save',
      editEditor: {}
    });
  };

  componentDidMount() {
    this._getHmiList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.UserDetails !== this.props.UserDetails) {
      this.setState({
        userId: this.props.UserDetails
      });
    }
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  }

  _getHmiList = async () => {
    const siteId = localStorage.get('currentSite');
    let payload = {
      method: 'GET',
      url: `/api/hmi/get?SiteId=${siteId}`
    };
    let datasource = await getHMICalls(payload);
    if (datasource && datasource.message) {
      message.info(datasource.message);
    } else {
      this.setState({ datasource });
    }
  };

  editorDidMount = (editor) => {
    this.editor = editor;
  };

  hmiOperation = async (operation, userId) => {
    let { imageDetails, editObj } = this.state;

    let datasource = undefined;
    const siteId = localStorage.get('currentSite');

    if (operation === 'save') {
      this.hmiRef.current.validateFields().then(async (values) => {
        const model = this.monaco.editor.getModel();
        const value = model.getValue();
        if (typeof JSON.parse(value) !== 'string') {
          let payload = {
            method: 'POST',
            url: `/api/hmi/create?SiteId=${siteId}`,
            data: {
              Name: values.Name,
              Type: values.Type,
              ...imageDetails,
              JSON: JSON.parse(value),
              CreatedBy: userId,
              SiteId: siteId
            }
          };
          datasource = await getHMICalls(payload);
          if (datasource && datasource.message) {
            if (datasource.message === 'Error in API') {
              message.error(datasource.message);
            } else {
              message.success(datasource.message);
              this.setState({
                visible: false
              });
              this.hmiRef.current.resetFields();
              this._getHmiList('Update');
            }
          } else {
            this.setState({
              visible: false,
              imageUrl: '',
              defaultFileList: []
            });
            this.hmiRef.current.resetFields();
            this._getHmiList('Update');
          }
        } else {
          alert('Error');
        }
      });
    } else if (operation === 'update') {
      this.hmiRef.current
        .validateFields()
        .then(async (values) => {
          const model = this.monaco.editor.getModel();
          const value = model.getValue();
          if (typeof JSON.parse(value) !== 'string') {
            let payload = {
              method: 'PATCH',
              url: `/api/hmi/update?SiteId=${siteId}`,
              data: {
                Name: values.Name,
                Type: values.Type,
                JSON: JSON.parse(value),
                UpdatedBy: userId,
                ...imageDetails,
                Id: editObj.Id,
                SiteId: siteId
              }
            };
            datasource = await getHMICalls(payload);
            if (datasource && datasource.message) {
              if (datasource.message === 'Error in API') {
                message.error(datasource.message);
              } else {
                message.success(datasource.message);

                if (this.hmiRef.current) {
                  this.hmiRef.current.resetFields();
                }
                this.setState({
                  visible: false,
                  editObj: {},
                  editEditor: {},
                  imageUrl: '',
                  defaultFileList: []
                });
                this._getHmiList('Update');
              }
            } else {
              this.setState({
                visible: false
              });
              this.hmiRef.current.resetFields();
              this._getHmiList('Update');
            }
          }
        })
        .catch(() => {});
    } else if (operation === 'delete') {
      this.hmiRef.current
        .validateFields()
        .then(async () => {
          let payload = {
            method: 'DELETE',
            url: `/api/hmi/delete?SiteId=${siteId}&Id=${this.state.editObj.Id}`
          };
          datasource = await getHMICalls(payload);
          if (datasource && datasource.message) {
            if (datasource.message === 'Error in API') {
              message.error(datasource.message);
            } else {
              message.success(datasource.message);
              this.setState({
                visible: false
              });
              this.hmiRef.current.resetFields();
              this._getHmiList('Uodate');
            }
          } else {
            this.setState({
              visible: false
            });
            this.hmiRef.current.resetFields();
            this._getHmiList('Update');
          }
        })
        .catch(() => {});
    }
  };
  uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const fmData = new FormData();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setTimeout(() => this.setState({ progress: 0 }), 1000);
        this.setState({ progress: percent });
        if (percent === 100) {
          setTimeout(() => this.setState({ progress: 0 }), 1000);
        }
        this.setState({ progress: (event.loaded / event.total) * 100 });
      }
    };
    fmData.append('file', file);
    try {
      const res = await axios.post(`/api/hmi/svgupload?SiteId=${siteId}`, fmData, config);
      onSuccess('Ok');
      let imageDetails = res.data;
      this.setState({ imageDetails, enable: true });
    } catch (err) {
      message.error('Error occured while uploading');
      this.setState({ loading: false, enable: false });
      onError({ err });
    }
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  handleOnChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, (url) => {
        this.setState({ loading: false, imagebaseUrl: url });
        // setImageUrl(url);
      });
    }
  };
  beforeUpload = (file) => {
    const isSvg = file.type === 'image/svg+xml';
    if (!isSvg) {
      message.error('You can only upload SVG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isSvg && isLt2M;
  };

  render() {
    let userDeatils = this.props.UserDetails;
    const {
      editObj,
      translationCache,
      datasource,
      userProfile,
      editEditor,
      defaultFileList,
      progress,
      imageUrl,
      loading,
      imagebaseUrl
      // enable
    } = this.state;
    const userId = userDeatils && userDeatils.Id;
    const options = {
      selectOnLineNumbers: true,
      roundedSelection: false,
      readOnly: false,
      cursorStyle: 'line',
      automaticLayout: false,
      lineDecorationsWidth: 0
    };
    let permissionsArray = [];
    const siteId = localStorage.get('currentSite');
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][siteId] &&
      userProfile['belongsTo']['sites'][siteId]['role'] &&
      userProfile['belongsTo']['sites'][siteId]['role']['Permission']
        .filter((item) => item.resource === 'hmi')
        .map((name) => permissionsArray.push(name.action));

    const columns = [
      {
        title:
          translationCache && translationCache[`${constant.HMI_Name}`]
            ? translationCache[`${constant.HMI_Name}`]
            : `${constant.HMI_Name}`,
        dataIndex: 'Name',
        key: 'Name',
        width: '50%',
        render: (text, record) => {
          return (
            <Link
              style={{ color: '#173049' }}
              to={{ pathname: `/rubus/singleHMI/static/${record.Name}` }}
            >
              {text}
            </Link>
          );
        }
      },
      {
        title:
          translationCache && translationCache[`${constant.HMI_Type}`]
            ? translationCache[`${constant.HMI_Type}`]
            : `${constant.HMI_Type}`,
        dataIndex: 'Type',
        key: 'Type'
      },
      {
        title:
          translationCache && translationCache[`${constant.File_Name}`]
            ? translationCache[`${constant.File_Name}`]
            : `${constant.File_Name}`,
        dataIndex: 'FileName',
        key: 'FileName'
      }
      // ,
      // {
      //   title:
      //     translationCache && translationCache[`${constant.Path}`]
      //       ? translationCache[`${constant.Path}`]
      //       : `${constant.path}`,
      //   dataIndex: 'path',
      //   key: 'path'
      // }
    ];
    return (
      <StyledComponent style={{ minHeight: window.innerHeight - 69 }}>
        <StyledContentRight>
          <StyledButton
            disabled={!permissionsArray.includes('create') ? true : false}
            onClick={this.addNewHmi}
          >
            {' '}
            <PlusOutlined />{' '}
            {translationCache && translationCache[`${constant.New_HMI}`]
              ? translationCache[`${constant.New_HMI}`]
              : `${constant.New_HMI}`}
          </StyledButton>
        </StyledContentRight>
        <Drawer
          title="Create a new HMI configuration"
          width={720}
          closable
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            layout="vertical"
            ref={this.hmiRef}
            hideRequiredMark
            initialValues={editObj || {}}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={18}>
                <Form.Item
                  label="HMI Name :"
                  name="Name"
                  rules={[{ required: true, message: 'Enter HMI Name' }]}
                >
                  <Input
                    placeholder="Please enter HMI Name"
                    disabled={!editObj.Name ? false : true}
                  />
                </Form.Item>
                <Form.Item
                  label="HMI Type :"
                  name="Type"
                  rules={[{ required: true, message: 'Please enter HMI Type' }]}
                >
                  <Select placeholder="Select HMI Type" disabled={!editObj.Type ? false : true}>
                    <Option value="static">Static</Option>
                    <Option value="dynamic">Dynamic</Option>
                    <Option value="distinct">Distinct</Option>
                    <Option value="hierarchy">Hierarchy</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Upload" name="fileList">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    // onRemove={false}
                    accept="image/svg+xml"
                    customRequest={this.uploadImage}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleOnChange}
                    defaultFileList={defaultFileList}
                    onProgress={({ percent }) => {
                      if (percent === 100) {
                        setTimeout(() => this.setState({ percent: 0 }), 1000);
                      }
                      return this.setState({ percent: Math.floor(percent) });
                    }}
                  >
                    {imagebaseUrl ? (
                      <img
                        src={imagebaseUrl}
                        alt="svg"
                        style={{
                          width: '100%'
                        }}
                      />
                    ) : imageUrl ? (
                      <img
                        src={`https://sgt.bmecomosolution.com${imageUrl}`}
                        alt="svg"
                        style={{
                          width: '100%'
                        }}
                      />
                    ) : (
                      <div>
                        {loading ? <LoadingOutlined /> : <PlusOutlined />}
                        <div
                          style={{
                            marginTop: 8
                          }}
                        >
                          Upload
                        </div>
                      </div>
                    )}
                  </Upload>
                  {progress > 0 ? <Progress percent={progress} /> : null}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div>Enter HMI JSON</div>
          <Collapse
            bordered={false}
            style={{ marginTop: '20px' }}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0}></CaretRightOutlined>
            )}
          >
            <Panel key="1" header={'JSON'} style={customPanel}>
              <MonacoEditor
                width="1000"
                height="500"
                language="json"
                theme="vs-dark"
                defaultValue={JSON.stringify(editEditor)}
                options={options}
                ref={(el) => (this.monaco = el)}
                value={JSON.stringify(editEditor, null, '\t')}
                editorWillMount={this.editorWillMount}
                editorDidMount={this.editorDidMount}
              />
              {this.state.listType === 'save' ? (
                <StyledButton
                  type="primary"
                  // disabled={!enable ? true : false}
                  shape="round"
                  onClick={() => this.hmiOperation('save', userId)}
                  style={{ marginRight: '10px', marginTop: '10px', color: 'white !important' }}
                >
                  Save
                </StyledButton>
              ) : (
                <StyledButton
                  // disabled={!permissionsArray.includes('update') ? true : !enable ? true : false}
                  type="primary"
                  shape="round"
                  onClick={() => this.hmiOperation('update', userId)}
                  style={{ marginTop: '10px', color: 'white !important' }}
                >
                  Update
                </StyledButton>
              )}

              {
                <StyledButton
                  type="primary"
                  shape="round"
                  // disabled={!permissionsArray.includes('delete') ? true : !enable ? true : false}
                  onClick={() => this.hmiOperation('delete', userId)}
                  style={{
                    marginTop: '10px',
                    marginLeft: '5px',
                    color: 'white !important'
                  }}
                >
                  Delete
                </StyledButton>
              }
              {
                <StyledButton
                  type="primary"
                  shape="round"
                  disabled={!permissionsArray.includes('delete') ? true : false}
                  onClick={this.onClose}
                  style={{
                    marginTop: '10px',
                    marginLeft: '5px',
                    color: 'white !important'
                  }}
                >
                  Close
                </StyledButton>
              }
            </Panel>
          </Collapse>
        </Drawer>
        <StyledTable
          columns={columns}
          dataSource={datasource}
          onRow={(record) => {
            return {
              onClick: () => {
                this.setState({
                  visible: true,
                  editObj: record,
                  imageDetails: { FileName: record.FileName, path: '' },
                  imageUrl: record.Url,
                  listType: 'update',
                  editEditor: record['JSON'],
                  enable: true
                });
              }
            };
          }}
        />
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  UserDetails: getUserDetails(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(HmiConfiguration);
