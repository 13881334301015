/**
 * @author Anvesh
 * @description This Component is mainly for Creating Equipment  MASTER PARTS
 * @View Table with crud operations of PARTS
 */
import React, { Component } from 'react';

import {
  Form,
  Select,
  Input,
  Button,
  Row,
  Col,
  Divider,
  message,
  Upload,
  Collapse,
  Popconfirm
} from 'antd';
import {
  DownloadOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import localStorage from '../../../utils/localStorage';
import { SpecForm, SupplierForm } from './PartSpecification2';
import {
  getPartMasterRecord,
  getDeviceType,
  createPartMaster,
  getFileName,
  getParameterTypes
} from '../EquipmentMastersComponents/ApiCall';
import { getSubDeviceList } from '../EquipmentSelection/ApiCalls';
import '../EquipmentSelection/style.css';
import { StyledApplication } from '../style';

const ButtonGroup = Button.Group;
const { Option } = Select;

const { Panel } = Collapse;
class PartsMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceTypeList: [],
      deviceList: [],
      subDeviceList: [],
      device: undefined,
      deviceCode: undefined,
      deviceType: undefined,
      recordData: [],
      paramTypes: [],
      fileName: ''
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    try {
      let deviceType = await getDeviceType();
      this.setState({ deviceTypeList: deviceType.data });
      let paramTypes = await getParameterTypes();
      this.setState({ paramTypes: paramTypes.data });
      let file = await getFileName();
      this.setState({ fileName: file.data.file });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.UserDetails !== this.props.UserDetails) {
      this.setState({
        userId: this.props.UserDetails
      });
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  handelDeviceType = async (value) => {
    this.setState({ deviceType: value });
    try {
      let deviceList = await getSubDeviceList(value, 'Primary');
      this.setState({
        deviceList: deviceList.data,
        device: undefined,
        subDeviceList: [],
        deviceCode: undefined,
        recordData: []
      });
    } catch (err) {
      return {};
    }
  };
  handelDevice = async (value) => {
    this.setState({ device: value });
    try {
      let deviceList = await getSubDeviceList(this.state.deviceType, 'Secondary', value);
      this.setState({ subDeviceList: deviceList.data, deviceCode: undefined });
    } catch (err) {
      return {};
    }
  };

  handleSubDevice = async (value) => {
    this.setState({ deviceCode: value });
    try {
      let record = await getPartMasterRecord(value, this.state.deviceType);
      let recordDat = record.data && record.data.Parts;
      this.setState({ recordData: recordDat });
      this.onReset();
    } catch (err) {
      message.warn(`${err.message}`);
    }
  };
  onFinish = async (values) => {
    let { deviceCode, deviceType } = this.state;
    if (values.Parts.length > 0) {
      try {
        await createPartMaster('Part', deviceCode, values, deviceType);
        this.setState({
          DeviceId: undefined,
          deviceCode: undefined,
          device: undefined,
          deviceType: undefined,
          recordData: []
        });
        this.onReset();
      } catch (err) {
        message.warn(`${err.message}`);
      }
    } else {
      message.warn('Please Add Atleast one Specification');
    }
  };

  onReset = () => {
    this.formRef.current.resetFields();
  };

  render() {
    let {
      deviceTypeList,
      deviceList,
      subDeviceList,
      deviceCode,
      deviceType,
      recordData,
      device,
      fileName
    } = this.state;
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const siteId = localStorage.get('currentSite');
    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 170 }}>
        <div style={{ display: 'block', marginBottom: '3%' }}>
          <ButtonGroup style={{ float: 'right' }}>
            <Button
              block
              type="primary"
              icon={<DownloadOutlined />}
              href={`https://sgt.bmecomosolution.com/api/sample/EquipmentParts_sample.xlsx`}
            >
              {' '}
              Template{' '}
            </Button>
            <Button
              block
              type="primary"
              icon={<DownloadOutlined />}
              href={`https://sgt.bmecomosolution.com/api/sample/${fileName}`}
            >
              {' '}
              Download{' '}
            </Button>
            <Upload
              name="file"
              action={`/api/parts/upload`}
              accept="application/vnd.ms-excel"
              multiple="false"
              headers={{ Authorization: Authorization }}
            >
              <Button block type="primary" icon={<UploadOutlined />}>
                {' '}
                Upload
              </Button>{' '}
            </Upload>
          </ButtonGroup>
        </div>

        <Form className="headerDiv" layout="vertical">
          <Row>
            <Col span={5} offset={1}>
              <Form.Item label="Select Equipment Category">
                <Select
                  placeholder="Select Equipment Category"
                  style={{ width: '300px' }}
                  value={deviceType}
                  onChange={this.handelDeviceType}
                >
                  {deviceTypeList.map((param, index) => {
                    return (
                      <Option key={index} value={param.Id}>
                        {' '}
                        {param.Name}{' '}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5} offset={1}>
              <Form.Item label="Select Equipment ">
                <Select
                  placeholder="Select Equipment "
                  style={{ width: '300px' }}
                  value={device}
                  onChange={this.handelDevice}
                >
                  {deviceList.map((param, index) => {
                    return (
                      <Option key={index} value={param.Id}>
                        {' '}
                        {param.Name}{' '}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5} offset={1}>
              <Form.Item label="Select Sub Equipment">
                <Select
                  placeholder="Select Equipment"
                  style={{ width: '300px' }}
                  value={deviceCode}
                  onChange={this.handleSubDevice}
                >
                  {subDeviceList.map((param, index) => {
                    return (
                      <Option key={index} value={param.Id}>
                        {' '}
                        {param.Name}{' '}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {deviceCode !== undefined ? (
          <Form
            ref={this.formRef}
            name="dynamic_form_nest_item"
            layout="vertical"
            className="dynamicForm"
            onFinish={this.onFinish}
          >
            <Divider>Parts Details</Divider>
            <ButtonGroup style={{ float: 'right' }}>
              <Button
                block
                type="primary"
                icon={<DownloadOutlined />}
                href={`https://sgt.bmecomosolution.com/api/sample/EquipmentParts_sample.xlsx`}
              >
                {' '}
                Template{' '}
              </Button>
              {/* <Button block  type="primary" icon={<DownloadOutlined />} href={` https://sgt.bmecomosolution.com/api/sample/${fileName}`} > Download </Button> */}
              <Upload
                name="file"
                action={`/api/parts/partlevelUpload?DeviceTypeId=${deviceType}&DeviceId=${deviceCode}&SiteId=${siteId}`}
                accept="application/vnd.ms-excel"
                multiple="false"
                headers={{ Authorization: Authorization }}
              >
                <Button block type="primary" icon={<UploadOutlined />}>
                  {' '}
                  Upload
                </Button>{' '}
              </Upload>
            </ButtonGroup>
            <Form.List initialValue={recordData} name="Parts">
              {(fields, { add, remove }) => (
                <>
                  <Form.Item>
                    <Button
                      type="dashed"
                      className="submitBtn"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      {' '}
                      Add Parts{' '}
                    </Button>
                  </Form.Item>

                  {fields.map(({ key, name, ...restField }) => (
                    <Collapse key={key} accordion defaultActiveKey={['0']}>
                      <Panel header={`Part ${key + 1}`} key={key}>
                        <div>
                          {' '}
                          <Row>
                            <Col span={4} offset={1}>
                              <Form.Item
                                label="Name"
                                {...restField}
                                name={[name, 'Name']}
                                rules={[{ required: true, message: 'Please Provide Name' }]}
                              >
                                <Input placeholder="Name" style={{ width: '90%' }} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label="OEM Number"
                                {...restField}
                                name={[name, 'OEMPartNumber']}
                              >
                                <Input placeholder="OEM Number" style={{ width: '90%' }} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label="Quantity"
                                {...restField}
                                name={[name, 'Quantity']}
                                rules={[{ required: true, message: 'Please Provide Quantity' }]}
                              >
                                <Input placeholder="Quantity" style={{ width: '90%' }} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label="Description"
                                {...restField}
                                name={[name, 'Description']}
                                rules={[{ required: true, message: 'Please Provide Description' }]}
                              >
                                <Input placeholder="Description" style={{ width: '90%' }} />
                              </Form.Item>
                            </Col>

                            <Col span={4}>
                              <Form.Item
                                label="Maintained Part"
                                {...restField}
                                name={[name, 'MaintainedPart']}
                                rules={[
                                  { required: true, message: 'Please Select Maintained Type' }
                                ]}
                              >
                                <Select style={{ width: '90%' }} placeholder="Maintained part">
                                  <option value={true}>True</option>
                                  <option value={false}>False</option>
                                </Select>
                              </Form.Item>
                            </Col>
                            {fields.length > 1 ? (
                              <Col span={1}>
                                <Popconfirm
                                  title="Are you sure that you want to delete the part？"
                                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                  onConfirm={() => remove(name)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button
                                    type="danger"
                                    className="deleteBtn dynamic-delete-button"
                                    shape="round"
                                    icon={<DeleteOutlined />}
                                  >
                                    {' '}
                                    Remove Part{' '}
                                  </Button>
                                </Popconfirm>

                                {/* <DeleteOutlined className="deleteIcon" onClick={() => remove(name)} /> */}
                              </Col>
                            ) : null}
                          </Row>
                          <Row>
                            {' '}
                            <Col span={18}>
                              <Form.Item>
                                <SpecForm fieldKey={name} deviceType={this.state.deviceType} />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item>
                                <SupplierForm fieldKey={name} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Panel>
                    </Collapse>
                  ))}
                </>
              )}
            </Form.List>

            <Form.Item>
              <Button
                type="primary"
                className="submitBtn"
                style={{ marginTop: '10px', marginBottom: '10px' }}
                htmlType="submit"
              >
                Submits
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </StyledApplication>
    );
  }
}

export default PartsMaster;
