import React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Table,
  message,
  Button,
  Drawer,
  Input,
  Col,
  Row,
  Popconfirm,
  Upload,
  Form,
  Select
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  DownloadOutlined,
  UploadOutlined,
  PlusOutlined
} from '@ant-design/icons';

import localStorage from '../../../utils/localStorage';
import {
  _getTranslationMasterData,
  _saveTranslation,
  _getCurrentLanguages
} from './translationCalls';
import { getTranslationCache } from '../.././../selectors/language';
import { AddTableButton, DrawerFooter } from './styles';
import { constant } from '../Constants';
import '../../../utils/css/table.css';

const FormItem = Form.Item;
const { Option } = Select;

class Translation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataSource: [],
      editObject: {},
      searchText: '',
      languageList: [],
      translationCache: props.translationCache || [],
      columns: [],
      fileProps: {}
    };
  }

  translationRef = React.createRef();
  componentDidMount() {
    this._getTranslationList();
    this._getCurrentLanguage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  _getTranslationList = async () => {
    let dataSource = await _getTranslationMasterData();
    this.setState({
      dataSource
    });
  };

  _getCurrentLanguage = async () => {
    let columnsAddition = [];
    let languageList = [];
    languageList = await _getCurrentLanguages();
    columnsAddition =
      languageList &&
      Array.isArray(languageList) &&
      languageList.map((language) => {
        return {
          title: this.translation(language),
          dataIndex: language,
          key: language,
          width: '15%',
          ...this.getColumnSearchProps(language)
        };
      });
    let columns = [
      {
        title: this.translation(constant.Keyword),
        dataIndex: 'Keyword',
        key: 'Keyword',
        width: '15%',
        ...this.getColumnSearchProps('Keyword')
      },
      ...(columnsAddition && Array.isArray(columnsAddition) ? columnsAddition : []),
      {
        title: this.translation('Action'),
        dataIndex: '',
        key: '',
        width: '15%',
        render: (text, record) => (
          <div>
            <button type="button" className="ant-btn" onClick={() => this.editTranslation(record)}>
              <EditOutlined />
            </button>
            <Popconfirm
              title={this.translation('Do you want to delete ?')}
              onConfirm={() => {
                this.deleteTranslation(record);
              }}
              onCancel={this.cancel}
              okText={this.translation(constant.yes)}
              cancelText={this.translation(constant.no)}
            >
              <button type="button" className="ant-btn" style={{ marginLeft: '10px' }}>
                <DeleteOutlined />
              </button>
            </Popconfirm>
          </div>
        )
      }
    ];
    this.setState({ languageList, columns });
  };

  editTranslation = (editObject) => {
    if (this.translationRef.current) {
      this.translationRef.current.setFieldsValue(editObject);
    }
    this.setState({ editObject, visible: true });
  };
  OpenDrawer = () => {
    if (this.translationRef.current) {
      this.translationRef.current.resetFields();
    }
    this.setState({
      visible: true,
      editObject: {}
    });
  };

  onClose = () => {
    if (this.translationRef.current) {
      this.translationRef.current.resetFields();
    }
    this.setState({ editObject: {}, visible: false });
  };

  saveTranslation = (e) => {
    const SiteId = localStorage.get('currentSite');
    e.preventDefault();
    this.translationRef.current
      .validateFields()
      .then(async (values) => {
        let saveTranslation = {
          ...values,
          SiteId,
          CreatedBy: '1'
        };
        this._curdTranslationFn(saveTranslation, 'create', 'POST');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateTranslation = (e) => {
    let { editObject } = this.state;
    const SiteId = localStorage.get('currentSite');
    e.preventDefault();
    this.translationRef.current
      .validateFields()
      .then(async (values) => {
        let updateTranslation = {
          ...values,
          SiteId,
          Id: editObject.Id,
          CreatedBy: '1',
          ModifiedBy: '1'
        };
        this._curdTranslationFn(updateTranslation, 'update', 'PATCH');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _curdTranslationFn = async (payload, apiURL, method) => {
    let responseData = await _saveTranslation(payload, apiURL, method);
    if (responseData && responseData.message) {
      this._getTranslationList();
      this.setState({ visible: false, editObject: {} });
      if (this.translationRef.current) {
        this.translationRef.current.resetFields();
      }
      message.success(responseData.message);
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(`Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            this.setState({ searchText: '' });
            this.handleSearch([''], confirm);
          }}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handleChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      this._getTranslationList();
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  fileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        filename: file.name
      });
    };
  };

  deleteTranslation = (e) => {
    const SiteId = localStorage.get('currentSite');
    this.translationRef.current.validateFields().then(async () => {
      let deleteTranslation = {
        Keyword: e.Keyword,
        SiteId
      };
      this._curdTranslationFn(deleteTranslation, 'delete', 'POST');
      if (this.translationRef.current) {
        this.translationRef.current.resetFields();
      }
      this.setState({ visible: false });
      await _getTranslationMasterData();
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  renderFormSwitch(renderFormItems) {
    let { disableOnUpdate } = renderFormItems;
    switch (renderFormItems.Widget) {
      case 'Select':
        return (
          <Select
            style={{ width: 350 }}
            placeholder={this.translation(`Select ${renderFormItems.Key}`)}
            onChange={(e) =>
              this.translationRef.current.setFieldsValue({ [renderFormItems.Key]: e })
            }
          >
            {renderFormItems.Options &&
              renderFormItems.Options.map((option, index) => {
                if (
                  renderFormItems.OptionsSettings &&
                  renderFormItems.OptionsSettings.key &&
                  renderFormItems.OptionsSettings.value
                ) {
                  return (
                    <Option key={index} value={option[renderFormItems.OptionsSettings.value]}>
                      {option[renderFormItems.OptionsSettings.key]}
                    </Option>
                  );
                }
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
          </Select>
        );

      default:
        return (
          <Input
            style={{ width: 350 }}
            placeholder={this.translation(`Enter ${renderFormItems.Key}`)}
            disabled={disableOnUpdate && disableOnUpdate === true ? true : false}
          />
        );
    }
  }
  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  render() {
    const { dataSource, editObject, languageList, columns } = this.state;
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const props = {
      name: 'file',
      file: this.state.filename,
      method: 'POST',
      action: '/api/language/bulk',
      headers: {
        Authorization: Authorization
      },
      data: {
        file: this.state.filename
      },
      onChange: this.handleChange
    };

    let formStructure = [
      {
        DisplayName: 'Keyword',
        Key: 'Keyword',
        Widget: 'Input'
      }
    ];

    return (
      <div
        style={{
          flexDirection: 'row',
          justifyContent: 'space-evenly'
        }}
      >
        <AddTableButton type="primary" onClick={(e) => this.OpenDrawer(e, {})}>
          <PlusOutlined /> {this.translation(constant.Add)}
        </AddTableButton>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: '5px' }}>
          <Button type="primary" className="ant-btn" style={{ marginLeft: '5px' }}>
            <DownloadOutlined />
            <a
              href={`https://sgt.bmecomosolution.com/api/sample/sample.csv`}
              style={{ color: 'white' }}
            >
              {' '}
              {this.translation(constant.Default_Template)}
            </a>
          </Button>
          <Upload
            name="file"
            accept="csv/xml"
            multiple="false"
            beforeUpload={this.fileUpload}
            {...props}
          >
            <Button type="primary" className="ant-btn">
              <UploadOutlined />
              {this.translation(constant.Click_to_Upload)}
            </Button>
          </Upload>
        </div>
        <Drawer
          title={
            !editObject.Keyword
              ? this.translation(constant.Create_Translation_Words)
              : this.translation(constant.Update_Translation_Words)
          }
          placement="right"
          closable
          width={500}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Form
            layout="vertical"
            ref={this.translationRef}
            name="user_form"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={editObject || {}}
            onFinishFailed={this.onFinishFailed}
          >
            {formStructure &&
              Array.isArray(formStructure) &&
              formStructure.map((formItem, index) => {
                return (
                  <Form.Item
                    key={index}
                    name={formItem.Key}
                    label={this.translation(formItem.DisplayName)}
                  >
                    {this.renderFormSwitch(formItem)}
                  </Form.Item>
                );
              })}
            {languageList &&
              Array.isArray(languageList) &&
              languageList.map((language, index) => {
                return (
                  <Row key={index}>
                    <Col span={24} key={index}>
                      <FormItem name={language} label={this.translation(language)}>
                        <Input
                          style={{ width: 350 }}
                          placeholder={this.translation(`Enter ${language}`)}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                );
              })}
          </Form>

          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            {!editObject.Keyword ? (
              <Button type="primary" onClick={this.saveTranslation}>
                {this.translation('Create')}
              </Button>
            ) : (
              <Button type="primary" onClick={this.updateTranslation}>
                {this.translation('Update')}
              </Button>
            )}
          </DrawerFooter>
        </Drawer>
        <Table className="basictable" columns={columns} dataSource={dataSource} />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Translation);
