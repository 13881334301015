import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Input, Button, message, Select, Modal, Form } from 'antd';

import history from '../../../commons/history';
import localstorage from '../../../utils/localStorage';
import ConfigurationModalContent from './ConfigurationModalContent';
import DashboardLevelConfig from './DashboardLevelConfig';
import { getDashboardByName } from './DashboardCalls';
import { getUserProfile } from '../../../selectors/layout';
import { insertDashboard, updateDashboard } from './DashboardCalls';
import {
  Title,
  DashboardTheme,
  AddPanelBtn,
  PanelDiv,
  DeletePanelDiv,
  EditDashboardDiv,
  AddConfigurationDiv
} from '../CSS/style';

import './CSS/NewDashboard.css';
import 'react-grid-layout/css/styles.css';
import { DownloadOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';

const Option = Select.Option;

const ResponsiveReactGridLayout = WidthProvider(Responsive);
class NewDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panels: [],
      visible: false,
      editData: [],
      dashboardName: '',
      id: '',
      mode: 'insert',
      dashboardType: 'static',
      dateExists: 'false',
      dateRangeExists: 'false',
      monthExists: 'false',
      shiftExists: 'false',
      treeExists: 'false',
      weekExists: 'false',
      dayMonthYearExists: 'false',
      apiPayload: {},
      treePayload: {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      hierarchyLevels: [],
      GroupName: '',
      isModalOpen: false
    };
    this.counter = 0;
  }
  componentDidUpdate = (prevProps) => {
    let siteId = localstorage.get('currentSite');
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile,
        hierarchyLevels:
          this.props.userProfile && this.props.userProfile.belongsTo.sites[siteId]
            ? this.props.userProfile.belongsTo.sites[siteId].hierarchy
            : []
      });
    }
  };

  componentDidMount() {
    let dashboardName = this.props.history.location;
    if (dashboardName && dashboardName.state && dashboardName.state !== 'new') {
      this.getDashboardDataName(dashboardName.state);
    }
  }

  getDashboardDataName = async (dashboardName) => {
    let dashboardObject = await getDashboardByName(dashboardName);
    let dashboardData = dashboardObject && dashboardObject[0] ? dashboardObject[0] : {};
    this.setState({
      mode: 'update',
      dashboardData,
      dashboardName: dashboardData && dashboardData.Name ? dashboardData.Name : '',
      dashboardId: dashboardData && dashboardData.Id ? dashboardData.Id : '',
      dashboardType:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].dashboardType
          ? dashboardData.json[0].dashboardType
          : 'static',
      hierarchyViewType:
        dashboardData.json && dashboardData.json[0] && dashboardData.json[0].hierarchyViewType
          ? dashboardData.json[0].hierarchyViewType
          : '',
      panels: dashboardData && dashboardData.json ? dashboardData.json : [],
      GroupName: dashboardData && dashboardData.GroupName ? dashboardData.GroupName : '',
      dateExists:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].dateExists
          ? dashboardData.json[0].dateExists
          : 'false',
      dateRangeExists:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].dateRangeExists
          ? dashboardData.json[0].dateRangeExists
          : 'false',
      monthExists:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].monthExists
          ? dashboardData.json[0].monthExists
          : 'false',
      shiftExists:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].shiftExists
          ? dashboardData.json[0].shiftExists
          : 'false',
      treeExists:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].treeExists
          ? dashboardData.json[0].treeExists
          : 'false',
      weekExists:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].weekExists
          ? dashboardData.json[0].weekExists
          : 'false',
      dayMonthYearExists:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].dayMonthYearExists
          ? dashboardData.json[0].dayMonthYearExists
          : 'false',
      apiPayload:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].apiPayload
          ? dashboardData.json[0].apiPayload
          : {},
      treePayload:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].treePayload
          ? dashboardData.json[0].treePayload
          : {}
    });
  };

  showModals = () => {
    this.setState({ isModalOpen: true });
  };
  handleOks = () => {
    this.setState({ isModalOpen: false });
  };
  handleCancels = () => {
    this.setState({
      isModalOpen: false,
      dashboardType: 'static',
      dateExists: 'false',
      dateRangeExists: 'false',
      monthExists: 'false',
      shiftExists: 'false',
      treeExists: 'false',
      weekExists: 'false',
      dayMonthYearExists: 'false'
    });
  };

  enterURL = (e) => {
    let { apiPayload } = this.state;
    this.setState({
      apiPayload: {
        url: e.target.value,
        method: apiPayload && apiPayload.method ? apiPayload.method : '',
        ...(apiPayload && apiPayload.method === 'POST' ? { payload: apiPayload.payload } : {})
      }
    });
  };

  selectMethod = (value) => {
    let { apiPayload } = this.state;
    this.setState({
      apiPayload: {
        url: apiPayload && apiPayload.url ? apiPayload.url : '',
        method: value,
        ...(apiPayload && apiPayload.method === 'POST' ? { payload: apiPayload.payload } : {})
      }
    });
  };

  selectPayload = (e) => {
    let { apiPayload } = this.state;
    this.setState({
      apiPayload: {
        url: apiPayload && apiPayload.url ? apiPayload.url : '',
        method: apiPayload && apiPayload.method ? apiPayload.method : 'GET',
        payload: JSON.parse(e.target.value)
      }
    });
  };

  enterTreeURL = (e) => {
    let { treePayload } = this.state;
    this.setState({
      treePayload: {
        url: e.target.value,
        method: treePayload && treePayload.method ? treePayload.method : '',
        ...(treePayload && treePayload.method === 'POST' ? { payload: treePayload.payload } : {})
      }
    });
  };

  selectTreeMethod = (value) => {
    let { treePayload } = this.state;
    this.setState({
      treePayload: {
        url: treePayload && treePayload.url ? treePayload.url : '',
        method: value,
        ...(treePayload && treePayload.method === 'POST' ? { payload: treePayload.payload } : {})
      }
    });
  };

  selectTreePayload = (e) => {
    let { treePayload } = this.state;
    this.setState({
      treePayload: {
        url: treePayload && treePayload.url ? treePayload.url : '',
        method: treePayload && treePayload.method ? treePayload.method : 'GET',
        payload: JSON.parse(e.target.value)
      }
    });
  };
  render() {
    let {
      dashboardName,
      dashboardType,
      panels,
      apiPayload,
      dateExists,
      treePayload,
      dateRangeExists,
      monthExists,
      weekExists,
      dayMonthYearExists,
      shiftExists,
      treeExists,
      GroupName,
      hierarchyViewType
    } = this.state;
    return (
      <EditDashboardDiv>
        <div style={{ padding: '10px', marginTop: '64px' }}>
          <Button
            style={{ float: 'right' }}
            type="danger"
            shape="round"
            onClick={this.saveDashboard}
          >
            <SaveOutlined />
            Save Dashboard
          </Button>
          <Input
            style={{ width: '150px', float: 'right' }}
            type="text"
            value={dashboardName}
            placeholder="Enter Dashboard Name"
            onChange={(e) => {
              this.setState({ dashboardName: e.target.value });
            }}
          />
          <Input
            style={{ width: '150px', float: 'right' }}
            type="text"
            value={GroupName}
            placeholder="Enter Group Name"
            onChange={(e) => {
              this.setState({ GroupName: e.target.value });
            }}
          />
          <AddPanelBtn type="danger" shape="round" onClick={this.addPanel}>
            <PlusCircleOutlined />
            Add Panel
          </AddPanelBtn>
          <label className="fileContainer">
            <i
              aria-label="icon: plus-circle"
              className="anticon anticon-plus-circle"
              style={{ marginLeft: '10px' }}
            >
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="import"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M888.3 757.4h-53.8c-4.2 0-7.7 3.5-7.7 7.7v61.8H197.1V197.1h629.8v61.8c0 4.2 3.5 7.7 7.7 7.7h53.8c4.2 0 7.7-3.4 7.7-7.7V158.7c0-17-13.7-30.7-30.7-30.7H158.7c-17 0-30.7 13.7-30.7 30.7v706.6c0 17 13.7 30.7 30.7 30.7h706.6c17 0 30.7-13.7 30.7-30.7V765.1c0-4.3-3.5-7.7-7.7-7.7zM902 476H588v-76c0-6.7-7.8-10.5-13-6.3l-141.9 112a8 8 0 000 12.6l141.9 112c5.3 4.2 13 .4 13-6.3v-76h314c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"></path>
              </svg>
            </i>
            <span style={{ marginLeft: '8px' }}>import</span>
            <input
              type="file"
              onChange={(e) => this.handleChangeFile(e.target.files)}
              accept=".json"
            />
          </label>
          <div style={{ marginLeft: 20, float: 'left' }}>
            <Button type="danger" shape="round" onClick={this.showModals}>
              <PlusCircleOutlined />
              Headers
            </Button>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ color: 'white' }}>New dashboard creation</h3>
          </div>
        </div>
        <Modal
          title="Header Selection"
          bodyStyle={{ height: 700 }}
          width={1000}
          open={this.state.isModalOpen}
          okText="Update"
          onOk={this.handleOks}
          onCancel={this.handleCancels}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 8 }}>
            <Form.Item label="DashboardType">
              <DashboardTheme
                onChange={(e) => {
                  this.setState({
                    dashboardType: e,
                    apiPayload: e && e === 'static' ? {} : apiPayload
                  });
                }}
                value={dashboardType}
              >
                <Option value="static">Static</Option>
                <Option value="dynamic">Dynamic</Option>
                <Option value="hierarchy">Hierarchy</Option>
              </DashboardTheme>
              {this.state.dashboardType === 'hierarchy' ? (
                <Select
                  style={{ width: '200px' }}
                  value={hierarchyViewType ? hierarchyViewType : ''}
                  placeholder={'Select Hierarchy ViewType'}
                  onChange={(e) => {
                    this.setState({ hierarchyViewType: e });
                  }}
                >
                  <Option value="Tree">Tree</Option>
                  <Option value="Select">Select</Option>
                </Select>
              ) : null}
              {this.state.dashboardType === 'dynamic' ? (
                <div>
                  <Select
                    style={{ width: '200px' }}
                    value={apiPayload && apiPayload.method ? apiPayload.method : undefined}
                    placeholder={'Select Method'}
                    onChange={this.selectMethod}
                  >
                    <Option value="GET">GET</Option>
                    <Option value="POST">POST</Option>
                  </Select>
                  <Input
                    style={{ width: '300px' }}
                    value={apiPayload && apiPayload.url ? apiPayload.url : ''}
                    placeholder="Enter URL"
                    onChange={this.enterURL}
                  />
                  {apiPayload && apiPayload.method && apiPayload.method === 'POST' ? (
                    <Input.TextArea
                      style={{ width: '300px' }}
                      defaultValue={
                        apiPayload &&
                        apiPayload.payload &&
                        Object.keys(apiPayload.payload) &&
                        Object.keys(apiPayload.payload).length > 0 &&
                        JSON.stringify(apiPayload.payload)
                          ? JSON.stringify(apiPayload.payload)
                          : ''
                      }
                      placeholder="Enter payload"
                      onBlur={this.selectPayload}
                    />
                  ) : null}
                </div>
              ) : null}
            </Form.Item>

            <Form.Item label="Date Selection">
              <DashboardTheme
                onChange={(e) => {
                  this.setState({ dateExists: e });
                }}
                value={dateExists}
              >
                <Option value="true">true</Option>
                <Option value="false">false</Option>
              </DashboardTheme>
            </Form.Item>

            <Form.Item label="DateRange Selection">
              <DashboardTheme
                onChange={(e) => {
                  this.setState({ dateRangeExists: e });
                }}
                value={dateRangeExists}
              >
                <Option value="true">true</Option>
                <Option value="false">false</Option>
              </DashboardTheme>
            </Form.Item>

            <Form.Item label="Month Selection">
              <DashboardTheme
                onChange={(e) => {
                  this.setState({ monthExists: e });
                }}
                value={monthExists}
              >
                <Option value="true">true</Option>
                <Option value="false">false</Option>
              </DashboardTheme>
            </Form.Item>

            <Form.Item label="Week Selection">
              <DashboardTheme
                onChange={(e) => {
                  this.setState({ weekExists: e });
                }}
                value={weekExists}
              >
                <Option value="true">true</Option>
                <Option value="false">false</Option>
              </DashboardTheme>
            </Form.Item>

            <Form.Item label="DayMonthYear Selection">
              <DashboardTheme
                onChange={(e) => {
                  this.setState({ dayMonthYearExists: e });
                }}
                value={dayMonthYearExists}
              >
                <Option value="true">true</Option>
                <Option value="false">false</Option>
              </DashboardTheme>
            </Form.Item>

            <Form.Item label="Shift Selection">
              <DashboardTheme
                onChange={(e) => {
                  this.setState({ shiftExists: e });
                }}
                value={shiftExists}
              >
                <Option value="true">true</Option>
                <Option value="false">false</Option>
              </DashboardTheme>
            </Form.Item>

            <Form.Item label="Tree Selection">
              <DashboardTheme
                onChange={(e) => {
                  this.setState({ treeExists: e, treePayload: {} });
                }}
                value={treeExists}
              >
                <Option value="true">true</Option>
                <Option value="false">false</Option>
              </DashboardTheme>
              {treeExists === 'true' ? (
                <div>
                  <Select
                    style={{ width: '200px' }}
                    value={treePayload && treePayload.method ? treePayload.method : undefined}
                    placeholder={'Select Method'}
                    onChange={this.selectTreeMethod}
                  >
                    <Option value="GET">GET</Option>
                    <Option value="POST">POST</Option>
                  </Select>
                  <Input
                    style={{ width: '300px' }}
                    value={treePayload && treePayload.url ? treePayload.url : ''}
                    placeholder="Enter URL"
                    onChange={this.enterTreeURL}
                  />
                  {treePayload && treePayload.method && treePayload.method === 'POST' ? (
                    <Input.TextArea
                      style={{ width: '300px' }}
                      defaultValue={
                        treePayload &&
                        treePayload.payload &&
                        Object.keys(treePayload.payload) &&
                        Object.keys(treePayload.payload).length > 0 &&
                        JSON.stringify(treePayload.payload)
                          ? JSON.stringify(treePayload.payload)
                          : ''
                      }
                      placeholder="Enter payload"
                      onBlur={this.selectTreePayload}
                    />
                  ) : null}
                </div>
              ) : null}
            </Form.Item>
          </Form>
        </Modal>
        <div>{panels.length > 0 ? this.createPanels() : ''}</div>
        <ConfigurationModalContent
          DeviceData={this.props.DeviceData}
          AssetData={this.props.AssetData}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          editData={this.state.editData}
        />
        <DashboardLevelConfig />
      </EditDashboardDiv>
    );
  }

  handleChangeFile = (e) => {
    let context = this;
    var reader = new FileReader();
    reader.readAsText(e[0]);
    reader.onloadend = function () {
      let fileData = JSON.parse(reader.result);
      context.setState({ panels: fileData.json });
    };
  };

  setTitle = (e) => {
    this.setState({ title: e.target.value, id: this.state.id });
  };

  redirect() {
    let { dashboardName } = this.state;
    this.props.history.push(
      dashboardName.length === 0 ? '/dashboard/' : '/dashboard/' + dashboardName
    );
  }

  addPanel = () => {
    this.counter = this.state.panels.length + 1;
    this.state.panels.push({
      id: this.counter,
      title: 'new panel',
      graph: 'guage',
      source: 'mqtt',
      query: '',
      query1: '',
      query2: '',
      position: { x: 0, y: 0, w: 4, h: 6 }
    });

    this.setState({ panels: [...this.state.panels] });
  };

  createPanels = () => {
    return (
      <ResponsiveReactGridLayout
        className="layout"
        draggableHandle=".headerdrag"
        //key={new Date().getTime()}
        onResizeStop={(data) => this.updatePosition(data)}
        onDragStop={(data) => {
          this.updatePosition(data);
        }}
        rowHeight={30}
      >
        {this.getPanels()}
      </ResponsiveReactGridLayout>
    );
  };

  updatePosition = (data) => {
    this.state.panels.map((v, i) => {
      data[i]['id'] = v.id;
      return {};
    });
    data.map((value1) => {
      this.state.panels.map((value2) => {
        if (value1.id === value2.id) {
          value2.position.x = value1.x;
          value2.position.y = value1.y;
          value2.position.w = value1.w;
          value2.position.h = value1.h;
        }
        return {};
      });
      return {};
    });

    this.setState({ panels: [...this.state.panels] });
  };

  getPanels = () => {
    let { panels } = this.state;
    let data =
      panels &&
      Array.isArray(panels) &&
      panels.map((value, index) => {
        const { x, y, w, h } = value && value.position;
        return (
          <PanelDiv
            key={index}
            data-grid={{
              x,
              y,
              w,
              h,
              isResizable: true
            }}
          >
            <div className="headerdrag">
              <Title>{value.title}</Title>
            </div>

            <AddConfigurationDiv>
              <Button type="primary" shape="round" onClick={() => this.editConfiguration(value)}>
                <PlusCircleOutlined />
                Edit Configuration
              </Button>
            </AddConfigurationDiv>
            <DeletePanelDiv>
              <Button type="danger" shape="round" onClick={() => this.deletePanel(value.id)}>
                <DownloadOutlined />
                Delete Panel
              </Button>
            </DeletePanelDiv>
          </PanelDiv>
        );
      });

    return data;
  };

  deletePanel = (id) => {
    this.state.panels.map((value, index) => {
      if (id === value.id) {
        this.state.panels.splice(index, 1);
      }
      return {};
    });
    let panels = this.state.panels.map((value, index) => {
      value.id = index + 1;
      return value;
    });

    this.setState({
      panels: [...panels],
      id: ''
    });
  };

  editConfiguration(value) {
    let { panels } = this.state;
    let editdata =
      panels &&
      Array.isArray(panels) &&
      panels.filter((filterData) => {
        return value.id === filterData.id;
      });
    this.setState({ id: value.id });
    this.showModal(editdata);
  }

  showModal = (value) => {
    this.setState({
      visible: true,
      editData: [...value]
    });
  };

  handleOk = (config) => {
    let { panels, id } = this.state;
    try {
      let panelObject = {};
      let panelData = panels.map((value) => {
        if (value.id === id) {
          panelObject = { ...value, ...config };
          return panelObject;
        } else {
          return value;
        }
      });
      this.setState({
        visible: false,
        panels: panelData,
        id: ''
      });
    } catch (e) {
      message.error('Configuration Error!');
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  saveDashboard = async () => {
    let {
      dashboardName,
      dashboardType,
      dateRangeExists,
      dateExists,
      monthExists,
      shiftExists,
      treeExists,
      weekExists,
      dayMonthYearExists,
      panels,
      apiPayload,
      treePayload,
      GroupName,
      hierarchyViewType
    } = this.state;
    if (dashboardName.trim().length !== 0) {
      panels.map((value) => {
        if (value.queryData && Array.isArray(value.queryData) && value.queryData.length === 0) {
          delete value.query1;
        }
        value.dashboardName = dashboardName;
        value.dateExists = dateExists;
        value.dateRangeExists = dateRangeExists;
        value.monthExists = monthExists;
        value.shiftExists = shiftExists;
        value.treeExists = treeExists;
        value.weekExists = weekExists;
        value.dayMonthYearExists = dayMonthYearExists;
        value.dashboardType = dashboardType;
        value.apiPayload = apiPayload;
        value.hierarchyViewType = hierarchyViewType;
        value.treePayload = treePayload;
        return {};
      });
      if (this.state.mode === 'insert') {
        let msg = await insertDashboard(panels, dashboardName, this.state.dashboardId, GroupName);
        message.info(msg);
        history.push('/rubus/dashboard');
        this.setState({
          panels: [],
          visible: false,
          editData: [],
          dashboardName: '',
          mode: this.state.mode,
          id: '',
          apiPayload: {},
          hierarchyViewType: '',
          treePayload: {}
        });
      } else {
        let msg = await updateDashboard(panels, dashboardName, this.state.dashboardId, GroupName);
        message.success(msg);
        history.push('/rubus/dashboard');
        this.setState({
          panels: [],
          visible: false,
          editData: [],
          dashboardName: '',
          mode: this.state.mode,
          id: '',
          apiPayload: {},
          hierarchyViewType: '',
          treePayload: {}
        });
      }
    } else {
      alert('Enter Dashboard Name');
    }
  };
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});

export default connect(mapStateToProps)(NewDashboard);
