import React, { Component } from 'react';
import _ from 'lodash';
import cloneDeep from 'lodash.clonedeep';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Table, Tag, TreeSelect, message } from 'antd';
import styled from 'styled-components';

import SelectionComponent from './SelectionComponent';
import SelectComponennt from './SelectComponent';
import {
  getDataSourceFromBackend,
  getReportNametoDownload,
  getPdfNametoDownload,
  getReportNametoDownloadMultiVaraint
} from './ReportCalls';
import { getTranslationCache } from '../../selectors/language';
import { getTranslatedDatasource } from './ReportAdapter';

import './style.css';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
const { SHOW_PARENT } = TreeSelect;
export const ChildTablesGroup = styled.div`
  background-color: #f1f1f1;
  width: 100%;
  // margin: 20px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
`;
class ReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns || [],
      currentDatasource: props.currentDatasource || [],
      reportName: '',
      childTable: props.childTable || [],
      childDatasource: [],
      nestedChildColumns: props.nestedChildColumns || [],
      weekSummaryDatasource: [],
      initalRender: {
        initalRender: true,
        optionList: []
        // columns:props.columns
      },
      tableConfiguration: {
        bordered:
          props.tableConfiguration && props.tableConfiguration.bordered
            ? props.tableConfiguration.bordered
            : true,
        horizontalScroll: props.tableConfiguration
          ? props.tableConfiguration.horizontalScroll
          : false,
        tableSize: props.tableConfiguration ? props.tableConfiguration.tableSize : 'default',
        noResultMessage: props.tableConfiguration
          ? props.tableConfiguration.noResultMessage
          : 'No Records',
        isCursorPointer: props.tableConfiguration
          ? props.tableConfiguration.isCursorPointer
          : false,
        title: props.tableConfiguration ? props.tableConfiguration.title : '',
        pageSize: props.tableConfiguration ? props.tableConfiguration.pageSize : '10',
        downloadXLXS: props.tableConfiguration ? props.tableConfiguration.downloadXLXS : false,
        downloadExcelPayload: props.tableConfiguration
          ? props.tableConfiguration.downloadExcelPayload
          : {},
        downloadPdf: props.tableConfiguration ? props.tableConfiguration.downloadPdf : false,
        downloadPdfPayload: props.tableConfiguration
          ? props.tableConfiguration.downloadPdfPayload
          : {},
        columnFilter: props.tableConfiguration ? props.tableConfiguration.columnFilter : false,
        headerSelection: props.tableConfiguration ? props.tableConfiguration.headerSelection : [],
        style: {
          textAlign:
            props.tableConfiguration &&
            props.tableConfiguration.style &&
            props.tableConfiguration.style.textAlign
              ? props.tableConfiguration.style.textAlign
              : 'center',
          // "fontWeight": (props.tableConfiguration && props.tableConfiguration.style && props.tableConfiguration.style.fontWeight) ? props.tableConfiguration.style.fontWeight : "normal",
          evenRowColor:
            props.tableConfiguration &&
            props.tableConfiguration.style &&
            props.tableConfiguration.style.evenRowColor
              ? props.tableConfiguration.style.evenRowColor
              : 'rgb(251, 250, 250)',
          oldRowColor:
            props.tableConfiguration &&
            props.tableConfiguration.style &&
            props.tableConfiguration.style.oldRowColor
              ? props.tableConfiguration.style.oldRowColor
              : '#fff'
        },
        showStatus:
          props.tableConfiguration && props.tableConfiguration.showStatus
            ? props.tableConfiguration.showStatus
            : false
      },
      api: props.api || [],
      assetCode: props.AssetCode || '',
      selectionWidget: props.selectionWidget || {},
      value: [],
      treeData: [],
      summaryValue: [],
      selectionObject: {
        // startDate: moment()
        //   .startOf("month").utc()
        //   .format("YYYY-MM-DD HH:mm:ss"),
        // endDate: moment()
        //   .endOf("months").utc()
        //   .format("YYYY-MM-DD HH:mm:ss"),
        Month: moment().format('MMMM'),
        Year: moment().year()
      },
      selectedDevice: [],
      translationCache: props.translationCache || [],
      TagsList: [],
      filteredInfo: null
    };
  }
  timeTicket = null;

  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this._getDataSource();
    let refreshTime = '';
    if (this.props.tableConfiguration && this.props.tableConfiguration.pageRefreshFrequency) {
      refreshTime = this.props.tableConfiguration.pageRefreshFrequency;
    } else {
      refreshTime = 1440; // Default one day
    }
    this.timeTicket = setInterval(() => {
      this._getDataSource();
    }, refreshTime * 60000);
  }

  componentWillUnmount() {
    clearInterval(this.timeTicket);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      selectionWidget: nextProps.selectionWidget,
      filteredInfo: null
      // columns: [],
      //currentDatasource: []
    });
    // this._getDataSource()
  }

  componentDidUpdate({ api, language, reportName }) {
    if (api !== this.props.api || reportName !== this.props.reportName) {
      this._getDataSource();
      this.setState({
        // columns: [],
        // currentDatasource: []
      });
    }
    if (language !== this.props.language) {
      this._getDataSource();
    }
  }

  _generateTreeData = (columns) => {
    let treeData = [];
    let value = [];
    treeData =
      columns &&
      Array.isArray(columns) &&
      columns.map((columnData) => {
        value.push(columnData.key);
        return {
          ...columnData,
          children: [],
          value: columnData.key
        };
      });
    let summaryValue = value.filter(
      (e) => e !== 'Date' && e !== 'Total Consumption With Losses' && e !== 'Total Cost per KWH'
    );
    return {
      treeData,
      value,
      summaryValue: ['Summary', ...summaryValue]
    };
  };

  _getDataSource = async () => {
    let summaryTable = [];
    let api = cloneDeep({
      ...this.props.api,
      payload: {
        ...(this.props.api && this.props.api.payload ? this.props.api.payload : {}),
        AssetCode: this.props.AssetCode
      }
    });
    let columns = cloneDeep(this.props.columns);
    let multiVaraintColumns = cloneDeep(
      this.props.nestedChildColumns ? this.props.nestedChildColumns : []
    );
    columns = await getTranslatedDatasource(columns, this.props.translationCache);
    let { selectionWidget, childTable, weekSummaryTable, tableConfiguration } = this.props;
    summaryTable = childTable && childTable[0] && childTable[0].columns;
    if (childTable && childTable[0] && childTable[0].columns) {
      summaryTable = await getTranslatedDatasource(
        childTable[0].columns,
        this.props.translationCache
      );
    }
    if (api && api.payload && api.payload.isEquipment && api.payload.isEquipment === 'no') {
      columns = await this.columnValidation(columns, 'no');
    } else if (api && api.payload && api.payload.isEquipment && api.payload.isEquipment === 'yes') {
      columns = await this.columnValidation(columns, 'yes');
    } else {
      columns = await this.columnValidation(columns, 'yes');
    }
    let treeDataNew = await this._generateTreeData(columns);
    this.setState({
      columns,
      treeData: treeDataNew.treeData,
      value: treeDataNew.value,
      summaryValue: treeDataNew.summaryValue
    });

    let month = moment().month();
    let year = moment().year();
    let payload = {};

    if (selectionWidget && selectionWidget.payload) {
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1 && key === 0) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .startOf('month')
            .utc()
            .format(payloadItem.format);
          if (selectionWidget.widget === 'year-picker') {
            payload[`${payloadParams}`] = moment([year])
              .startOf('year')
              .utc()
              .format(payloadItem.format);
          }
          if (selectionWidget.widget === 'dateRange-picker') {
            payload[`${payloadParams}`] = moment().subtract(30, 'days').format(payloadItem.format);
          }
        } else if (key <= 1 && key === 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .endOf('months')
            .utc()
            .format(payloadItem.format);
          if (selectionWidget.widget === 'year-picker') {
            payload[`${payloadParams}`] = moment([year])
              .endOf('years')
              .utc()
              .format(payloadItem.format);
          }
          if (selectionWidget.widget === 'dateRange-picker') {
            payload[`${payloadParams}`] = moment().format(payloadItem.format);
          }
        }
        if (payloadParams === 'month') {
          payload[`${payloadParams}`] = moment().format('MMMM');
        }
        if (payloadParams === 'year') {
          // let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = year;
          // payload['startDate'] = moment([year]).startOf('year').format(payloadItem.format)
          // payload['endDate'] = moment([year]).endOf('years').format(payloadItem.format)
        }
        if (payloadParams === 'Date') {
          payload[`${payloadParams}`] = moment().subtract('day', 1).format('YYYY-MM-DD');
        }
        return {};
      });
    }
    let TagsList =
      columns &&
      Array.isArray(columns) &&
      columns.map((columnsData) => {
        return columnsData.key;
      });
    if (api) {
      if (
        tableConfiguration &&
        tableConfiguration.columnFilter &&
        tableConfiguration.columnFilter === 'true'
      ) {
        // columnFilter is true
        api.payload = {
          ...(api && api.payload ? api.payload : {}),
          ...payload,
          ...this.state.selectionObject,
          TagsList: TagsList.filter(
            (e) =>
              e !== 'Date' && e !== 'Total Consumption With Losses' && e !== 'Total Cost per KWH'
          )
        };
      } else {
        api.payload = {
          ...(api && api.payload ? api.payload : {}),
          ...payload,
          //...this.state.selectionObject,
          deviceId: this.state.selectedDevice
        };
      }
    }

    let childDatasource = [];
    if (childTable && childTable[0] && childTable[0].api) {
      if (
        tableConfiguration &&
        tableConfiguration.columnFilter &&
        tableConfiguration.columnFilter === 'true'
      ) {
        childTable[0].api.payload = {
          ...childTable[0].api.payload,
          ...payload,
          ...this.state.selectionObject,
          TagsList: TagsList.filter(
            (e) =>
              e !== 'Date' && e !== 'Total Consumption With Losses' && e !== 'Total Cost per KWH'
          )
        };
      } else {
        childTable[0].api.payload = {
          ...childTable[0].api.payload,
          ...payload,
          ...this.state.selectionObject
        };
      }

      childDatasource = await getDataSourceFromBackend(childTable[0].api);
      this.setState({ childDatasource });
    }
    //API
    let weekSummaryDatasource = [];
    if (weekSummaryTable && weekSummaryTable.api) {
      if (
        tableConfiguration &&
        tableConfiguration.columnFilter &&
        tableConfiguration.columnFilter === 'true'
      ) {
        weekSummaryTable.api.payload = {
          ...weekSummaryTable.api.payload,
          ...payload,
          ...this.state.selectionObject,
          TagsList: TagsList.filter(
            (e) =>
              e !== 'Date' && e !== 'Total Consumption With Losses' && e !== 'Total Cost per KWH'
          )
        };
      } else {
        weekSummaryTable.api.payload = {
          ...weekSummaryTable.api.payload,
          ...payload,
          ...this.state.selectionObject
        };
      }

      weekSummaryDatasource = await getDataSourceFromBackend(weekSummaryTable.api);
      this.setState({ weekSummaryDatasource });
    }

    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    let currentDatasource = await getDataSourceFromBackend(api);
    if (currentDatasource && Array.isArray(currentDatasource)) {
      columns &&
        Array.isArray(columns) &&
        columns.map((columnsObject, i) => {
          if (columnsObject && columnsObject.filter && columnsObject.filter === true) {
            let filterOptions = currentDatasource
              .map((datasource) => {
                if (datasource[columns[i].key] && datasource[columns[i].key] !== null) {
                  return {
                    text: datasource[columns[i].key],
                    value: datasource[columns[i].key]
                  };
                }
                return {};
              })
              .filter(function (element) {
                return element !== undefined;
              });
            columns[i] = {
              ...columnsObject,
              filters: _.uniqBy(filterOptions, function (e) {
                return e.text;
              }),
              filteredValue:
                filteredInfo && filteredInfo[columns[i].key] ? filteredInfo[columns[i].key] : null,
              onFilter: (value, record) => record[columns[i].key].includes(value),
              ellipsis: true
            };
          }
          if (columnsObject && columnsObject.key === 'Action') {
            columns[i] = {
              ...columnsObject,
              render: () => (
                <a href="/#" target="_blank">
                  action
                </a>
              )
            };
          }
          return {};
        });
    }
    let reportName = '';
    let pdfName = '';
    if (
      this.props.tableConfiguration &&
      this.props.tableConfiguration.downloadXLXS &&
      (this.props.tableConfiguration.downloadXLXS === true ||
        this.props.tableConfiguration.downloadXLXS === 'true')
    ) {
      if (
        this.props.tableConfiguration.downloadExcelPayload &&
        this.props.tableConfiguration.downloadExcelPayload.summaryType &&
        (this.props.tableConfiguration.downloadExcelPayload.summaryType === 'true' ||
          this.props.tableConfiguration.downloadExcelPayload.summaryType === true) &&
        childTable &&
        childTable[0] &&
        childTable[0].columns
      ) {
        reportName = await getReportNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadExcelPayload,
          childTable[0].columns,
          childDatasource,
          weekSummaryDatasource
        );
      } else if (this.props.nestedChildColumns) {
        reportName = await getReportNametoDownloadMultiVaraint(
          columns,
          currentDatasource,
          multiVaraintColumns,
          this.props.tableConfiguration.downloadExcelPayload
        );
      } else {
        reportName = await getReportNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadExcelPayload
        );
      }
      this.setState({
        currentDatasource,
        summaryTable,
        reportName: reportName && reportName.file ? reportName.file : '',
        api
      });
    } else {
      this.setState({
        currentDatasource,
        summaryTable,
        TagsList,
        api
      });
    }
    if (
      this.props.tableConfiguration &&
      this.props.tableConfiguration.downloadPdf &&
      (this.props.tableConfiguration.downloadPdf === true ||
        this.props.tableConfiguration.downloadPdf === 'true')
    ) {
      if (
        this.props.tableConfiguration.downloadPdfPayload &&
        this.props.tableConfiguration.downloadPdfPayload.summaryType &&
        (this.props.tableConfiguration.downloadPdfPayload.summaryType === 'true' ||
          this.props.tableConfiguration.downloadPdfPayload.summaryType === true) &&
        childTable &&
        childTable[0] &&
        childTable[0].columns
      ) {
        pdfName = await getPdfNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadPdfPayload,
          childTable[0].columns,
          childDatasource,
          weekSummaryDatasource
        );
      } else {
        pdfName = await getPdfNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadPdfPayload
        );
      }
      this.setState({
        currentDatasource,
        summaryTable,
        pdfName: pdfName && pdfName.file ? pdfName.file : '',
        api
      });
    } else {
      this.setState({
        currentDatasource,
        summaryTable,
        TagsList,
        api
      });
    }
  };

  columnValidation = (columnsData, columnValue) => {
    let newObj =
      columnsData &&
      Array.isArray(columnsData) &&
      columnsData.filter((columns) => {
        if (columns && !columns.noEquipment && !columns.yesEquipment) {
          return columns;
        }
        if (columnValue === 'no') {
          return columns && columns.noEquipment === true;
        } else if (columnValue === 'yes') {
          return columns && columns.yesEquipment === true;
        }
        return {};
      });
    return newObj;
  };

  selectionpayload = async (selectionpayload) => {
    let api = cloneDeep(this.props.api);
    let weekSummaryTable = cloneDeep(this.props.weekSummaryTable);
    let { childTable, tableConfiguration } = this.props;
    let columns = cloneDeep(this.state.columns);
    let TagsList =
      columns &&
      Array.isArray(columns) &&
      columns.map((columnsData) => {
        return columnsData.key;
      });

    if (
      tableConfiguration &&
      tableConfiguration.columnFilter &&
      tableConfiguration.columnFilter === 'true'
    ) {
      api.payload = {
        ...api.payload,
        ...selectionpayload,
        TagsList: TagsList.filter(
          (e) => e !== 'Date' && e !== 'Total Consumption With Losses' && e !== 'Total Cost per KWH'
        )
      };
    } else {
      api.payload = {
        ...api.payload,
        ...selectionpayload
      };
    }

    let childDatasource = [];
    if (childTable && childTable[0] && childTable[0].api) {
      if (
        tableConfiguration &&
        tableConfiguration.columnFilter &&
        tableConfiguration.columnFilter === 'true'
      ) {
        childTable[0].api.payload = {
          ...childTable[0].api.payload,
          ...selectionpayload,
          TagsList: TagsList.filter(
            (e) =>
              e !== 'Date' && e !== 'Total Consumption With Losses' && e !== 'Total Cost per KWH'
          )
        };
      } else {
        childTable[0].api.payload = {
          ...childTable[0].api.payload,
          ...selectionpayload
        };
      }

      childDatasource = await getDataSourceFromBackend(childTable[0].api);
      this.setState({ childDatasource });
    }
    //API
    let weekSummaryDatasource = [];
    if (weekSummaryTable && weekSummaryTable.api) {
      if (
        tableConfiguration &&
        tableConfiguration.columnFilter &&
        tableConfiguration.columnFilter === 'true'
      ) {
        weekSummaryTable.api.payload = {
          ...weekSummaryTable.api.payload,
          ...selectionpayload,
          TagsList: weekSummaryTable
            ? TagsList.filter(
                (e) =>
                  e !== 'Date' &&
                  e !== 'Total Consumption With Losses' &&
                  e !== 'Total Cost per KWH'
              )
            : []
        };
      } else {
        weekSummaryTable.api.payload = {
          ...weekSummaryTable.api.payload,
          ...selectionpayload
        };
      }

      weekSummaryDatasource = await getDataSourceFromBackend(weekSummaryTable.api);
      this.setState({ weekSummaryDatasource });
    }
    let currentDatasource = await getDataSourceFromBackend(api);
    let multiVaraintColumns = cloneDeep(this.props.nestedChildColumns);
    if (currentDatasource && Array.isArray(currentDatasource)) {
      columns &&
        Array.isArray(columns) &&
        columns.map((columnsObject, i) => {
          if (columnsObject && columnsObject.filter && columnsObject.filter === true) {
            let filterOptions = currentDatasource
              .map((datasource) => {
                if (datasource[columns[i].key] && datasource[columns[i].key] !== null) {
                  return {
                    text: datasource[columns[i].key],
                    value: datasource[columns[i].key]
                  };
                }
                return {};
              })
              .filter(function (element) {
                return element !== undefined;
              });
            columns[i] = {
              ...columnsObject,
              filters: _.uniqBy(filterOptions, function (e) {
                return e.text;
              })
            };
          } else if (columnsObject && columnsObject.key === 'Action') {
            columns[i] = {
              ...columnsObject,
              render: () => (
                <a href="/#" target="_blank">
                  action
                </a>
              )
            };
          }
          return {};
        });
    }
    if (
      this.props.tableConfiguration &&
      this.props.tableConfiguration.downloadXLXS &&
      (this.props.tableConfiguration.downloadXLXS === true ||
        this.props.tableConfiguration.downloadXLXS === 'true')
    ) {
      let reportName = '';
      if (
        this.props.tableConfiguration.downloadExcelPayload &&
        this.props.tableConfiguration.downloadExcelPayload.summaryType &&
        this.props.tableConfiguration.downloadExcelPayload.summaryType === 'true' &&
        childTable &&
        childTable[0] &&
        childTable[0].columns
      ) {
        reportName = await getReportNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadExcelPayload,
          childTable[0].columns,
          childDatasource,
          weekSummaryDatasource
        );
      } else if (this.props.nestedChildColumns) {
        reportName = await getReportNametoDownloadMultiVaraint(
          columns,
          currentDatasource,
          multiVaraintColumns,
          this.props.tableConfiguration.downloadExcelPayload
        );
      } else {
        reportName = await getReportNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadExcelPayload
        );
      }
      this.setState({
        currentDatasource,
        selectionObject: selectionpayload,
        reportName: reportName && reportName.file ? reportName.file : '',
        columns
      });
    } else {
      this.setState({
        currentDatasource,
        weekSummaryDatasource,
        selectionObject: selectionpayload,
        columns,
        api
      });
    }
    if (
      this.props.tableConfiguration &&
      this.props.tableConfiguration.downloadPdf &&
      (this.props.tableConfiguration.downloadPdf === true ||
        this.props.tableConfiguration.downloadPdf === 'true')
    ) {
      let pdfName = '';
      if (
        this.props.tableConfiguration.downloadPdfPayload &&
        this.props.tableConfiguration.downloadPdfPayload.summaryType &&
        this.props.tableConfiguration.downloadPdfPayload.summaryType === 'true' &&
        childTable &&
        childTable[0] &&
        childTable[0].columns
      ) {
        pdfName = await getPdfNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadPdfPayload,
          childTable[0].columns,
          childDatasource,
          weekSummaryDatasource
        );
      } else {
        pdfName = await getPdfNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadPdfPayload
        );
      }
      this.setState({
        currentDatasource,
        selectionObject: selectionpayload,
        pdfName: pdfName && pdfName.file ? pdfName.file : ''
      });
    } else {
      this.setState({
        currentDatasource,
        weekSummaryDatasource,
        selectionObject: selectionpayload,
        api
      });
    }
  };

  onTagChange = async (TagsList) => {
    let api = cloneDeep(this.props.api);
    let { childTable, tableConfiguration } = this.props;
    let weekSummaryTable = this.props.weekSummaryTable;
    let columns = cloneDeep(this.state.columns);

    if (
      tableConfiguration &&
      tableConfiguration.columnFilter &&
      tableConfiguration.columnFilter === 'true'
    ) {
      api.payload = {
        ...api.payload,
        ...this.state.selectionObject,
        TagsList: TagsList.filter(
          (e) => e !== 'Date' && e !== 'Total Consumption With Losses' && e !== 'Total Cost per KWH'
        )
      };
    } else {
      api.payload = {
        ...api.payload,
        ...this.state.selectionObject
      };
    }

    let childDatasource = [];
    if (childTable && childTable[0] && childTable[0].api) {
      if (
        tableConfiguration &&
        tableConfiguration.columnFilter &&
        tableConfiguration.columnFilter === 'true'
      ) {
        childTable[0].api.payload = {
          ...childTable[0].api.payload,
          ...this.state.selectionObject,
          TagsList: TagsList.filter(
            (e) =>
              e !== 'Date' && e !== 'Total Consumption With Losses' && e !== 'Total Cost per KWH'
          )
        };
      } else {
        childTable[0].api.payload = {
          ...childTable[0].api.payload,
          ...this.state.selectionObject
        };
      }

      childDatasource = await getDataSourceFromBackend(childTable[0].api);
      this.setState({ childDatasource, TagsList });
    }

    //API
    let weekSummaryDatasource = [];
    if (weekSummaryTable && weekSummaryTable.api) {
      if (
        tableConfiguration &&
        tableConfiguration.columnFilter &&
        tableConfiguration.columnFilter === 'true'
      ) {
        weekSummaryTable.api.payload = {
          ...weekSummaryTable.api.payload,
          ...this.state.selectionObject,
          TagsList: TagsList.filter(
            (e) =>
              e !== 'Date' && e !== 'Total Consumption With Losses' && e !== 'Total Cost per KWH'
          )
        };
      } else {
        weekSummaryTable.api.payload = {
          ...weekSummaryTable.api.payload,
          ...this.state.selectionObject
        };
      }

      weekSummaryDatasource = await getDataSourceFromBackend(weekSummaryTable.api);
      this.setState({ weekSummaryDatasource });
    }
    let multiVaraintColumns = cloneDeep(this.props.nestedChildColumns);
    let currentDatasource = await getDataSourceFromBackend(api);

    if (
      this.props.tableConfiguration &&
      this.props.tableConfiguration.downloadXLXS &&
      (this.props.tableConfiguration.downloadXLXS === true ||
        this.props.tableConfiguration.downloadXLXS === 'true')
    ) {
      let reportName = '';
      if (
        this.props.tableConfiguration.downloadExcelPayload &&
        this.props.tableConfiguration.downloadExcelPayload.summaryType &&
        this.props.tableConfiguration.downloadExcelPayload.summaryType === 'true' &&
        childTable &&
        childTable[0] &&
        childTable[0].columns
      ) {
        reportName = await getReportNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadExcelPayload,
          childTable[0].columns,
          childDatasource,
          weekSummaryDatasource
        );
      } else if (this.props.nestedChildColumns) {
        reportName = await getReportNametoDownloadMultiVaraint(
          columns,
          currentDatasource,
          multiVaraintColumns,
          this.props.tableConfiguration.downloadExcelPayload
        );
      } else {
        reportName = await getReportNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadExcelPayload
        );
      }
      this.setState({
        currentDatasource,
        reportName: reportName && reportName.file ? reportName.file : ''
      });
    } else {
      this.setState({
        currentDatasource
      });
    }
    if (
      this.props.tableConfiguration &&
      this.props.tableConfiguration.downloadPdf &&
      (this.props.tableConfiguration.downloadPdf === true ||
        this.props.tableConfiguration.downloadPdf === 'true')
    ) {
      let pdfName = '';
      if (
        this.props.tableConfiguration.downloadPdfPayload &&
        this.props.tableConfiguration.downloadPdfPayload.summaryType &&
        this.props.tableConfiguration.downloadPdfPayload.summaryType === 'true' &&
        childTable &&
        childTable[0] &&
        childTable[0].columns
      ) {
        pdfName = await getPdfNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadPdfPayload,
          childTable[0].columns,
          childDatasource,
          weekSummaryDatasource
        );
      } else {
        pdfName = await getPdfNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadPdfPayload
        );
      }
      this.setState({
        currentDatasource,
        pdfName: pdfName && pdfName.file ? pdfName.file : ''
      });
    } else {
      this.setState({
        currentDatasource
      });
    }
  };

  onSelectionChange = async (TagsList) => {
    let api = cloneDeep(this.props.api);
    let { childTable, weekSummaryTable } = this.props;
    let columns = cloneDeep(this.state.columns);
    let { selectionWidget } = this.props;

    let month = moment().month();
    let year = moment().year();
    let payload = {};

    if (selectionWidget && selectionWidget.payload) {
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1 && key === 0) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .startOf('month')
            .format(payloadItem.format);
        } else if (key <= 1 && key === 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .endOf('months')
            .format(payloadItem.format);
        }
        if (payloadParams === 'month') {
          payload[`${payloadParams}`] = moment().format('MMMM');
        }
        if (payloadParams === 'year') {
          payload[`${payloadParams}`] = year;
        }
        if (payloadParams === 'Date') {
          payload[`${payloadParams}`] = moment().subtract('day', 1).format('YYYY-MM-DD');
        }
        return {};
      });
    }
    api.payload = {
      ...api.payload,
      ...payload,
      ...TagsList
    };
    let childDatasource = [];
    if (childTable && childTable[0] && childTable[0].api) {
      childTable[0].api.payload = {
        ...childTable[0].api.payload,
        ...payload,
        ...TagsList
      };
      childDatasource = await getDataSourceFromBackend(childTable[0].api);
      this.setState({ childDatasource });
    }
    //API
    let weekSummaryDatasource = [];
    if (weekSummaryTable && weekSummaryTable.api) {
      weekSummaryTable.api.payload = {
        ...weekSummaryTable.api.payload,
        ...payload,
        ...TagsList
      };
      weekSummaryDatasource = await getDataSourceFromBackend(weekSummaryTable.api);
      this.setState({ weekSummaryDatasource });
    }
    let currentDatasource = await getDataSourceFromBackend(api);
    if (currentDatasource && Array.isArray(currentDatasource)) {
      columns &&
        Array.isArray(columns) &&
        columns.map((columnsObject, i) => {
          if (columnsObject && columnsObject.filter && columnsObject.filter === true) {
            columns[i] = {
              ...columnsObject,
              filters: currentDatasource
                .map((datasource) => {
                  if (datasource[columns[i].key] && datasource[columns[i].key] !== null) {
                    return {
                      text: datasource[columns[i].key],
                      value: datasource[columns[i].key]
                    };
                  }
                  return {};
                })
                .filter(function (element) {
                  return element !== undefined;
                })
            };
          }
          // else if (columnsObject && columnsObject.key === 'Action') {
          //   columns[i] = {
          //     ...columnsObject,
          //     render: () => <a>action</a>,
          //   }
          // }
          return {};
        });
    }
    if (
      this.props.tableConfiguration &&
      this.props.tableConfiguration.downloadXLXS &&
      (this.props.tableConfiguration.downloadXLXS === true ||
        this.props.tableConfiguration.downloadXLXS === 'true')
    ) {
      let reportName = '';
      if (
        this.props.tableConfiguration.downloadExcelPayload &&
        this.props.tableConfiguration.downloadExcelPayload.summaryType &&
        this.props.tableConfiguration.downloadExcelPayload.summaryType === 'true' &&
        childTable &&
        childTable[0] &&
        childTable[0].columns
      ) {
        reportName = await getReportNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadExcelPayload,
          childTable[0].columns,
          childDatasource,
          weekSummaryDatasource
        );
      } else if (this.props.nestedChildColumns) {
        let multiVaraintColumns = cloneDeep(this.props.nestedChildColumns);
        reportName = await getReportNametoDownloadMultiVaraint(
          columns,
          currentDatasource,
          multiVaraintColumns,
          this.props.tableConfiguration.downloadExcelPayload
        );
      } else {
        reportName = await getReportNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadExcelPayload
        );
      }
      this.setState({
        currentDatasource,
        reportName: reportName && reportName.file ? reportName.file : '',
        columns
      });
    } else {
      this.setState({
        currentDatasource,
        columns
      });
    }
    if (
      this.props.tableConfiguration &&
      this.props.tableConfiguration.downloadPdf &&
      (this.props.tableConfiguration.downloadPdf === true ||
        this.props.tableConfiguration.downloadPdf === 'true')
    ) {
      let pdfName = '';
      if (
        this.props.tableConfiguration.downloadPdfPayload &&
        this.props.tableConfiguration.downloadPdfPayload.summaryType &&
        this.props.tableConfiguration.downloadPdfPayload.summaryType === 'true' &&
        childTable &&
        childTable[0] &&
        childTable[0].columns
      ) {
        pdfName = await getPdfNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadPdfPayload,
          childTable[0].columns,
          childDatasource,
          weekSummaryDatasource
        );
      } else {
        pdfName = await getPdfNametoDownload(
          columns,
          currentDatasource,
          this.props.tableConfiguration.downloadPdfPayload
        );
      }
      this.setState({
        currentDatasource,
        pdfName: pdfName && pdfName.file ? pdfName.file : ''
      });
    } else {
      this.setState({
        currentDatasource
      });
    }
  };

  onChange = async (value) => {
    await this.onTagChange(value);
    value = ['Date', ...value, 'Total'];
    let summaryValue = ['Summary', ...value, 'Total'];
    this.setState({ value, summaryValue });
  };

  onInputChange = async (columnValue, columnkey, header, index, initalRender) => {
    this.setState({
      selectedDevice: columnValue
    });
    let { tableConfiguration, columns } = this.state;
    let newObject = {
      ...tableConfiguration.headerSelection[index],
      defaultValue: columnValue
      // defaultDevice: columnkey
    };
    delete tableConfiguration.headerSelection[index];
    tableConfiguration.headerSelection = tableConfiguration.headerSelection.filter((header) => {
      return header;
    });
    let { headerSelection } = tableConfiguration;
    if (headerSelection) {
      tableConfiguration = {
        ...tableConfiguration,
        headerSelection: [...tableConfiguration.headerSelection, newObject]
      };
      let TagsList = {};
      tableConfiguration &&
        tableConfiguration.headerSelection &&
        Array.isArray(tableConfiguration.headerSelection) &&
        tableConfiguration.headerSelection.map((header) => {
          if (header) {
            TagsList = {
              ...TagsList,
              [header.key]: header.defaultValue
            };
          }
          return {};
        });
      await this.onSelectionChange(TagsList);

      if (columnkey === 'isEquipment' && columnValue === 'no') {
        columns = await this.columnValidation(this.state.columns, columnValue);
      } else if (columnkey === 'isEquipment' && columnValue === 'yes') {
        columns = await this.columnValidation(this.state.columns, columnValue);
      } else {
        columns = cloneDeep(this.state.columns);
      }

      this.setState({
        tableConfiguration,
        columns,
        initalRender
      });
    } else {
      message.warn('error');
    }
  };

  multiVariantonChange = async (pagination, filters) => {
    let { columns, currentDatasource } = this.state;
    let multiVaraintColumns = cloneDeep(this.props.nestedChildColumns);
    // let { tableConfiguration } = this.props
    //  let result= currentDatasource.map((dataItem,index) => {
    //     Object.keys(filters).map((i)=>{
    //       if(filters[i] !== null){
    //         filters[i].map((item)=>{
    //          return dataItem.includes(item)
    //         })
    //         // return dataItem.includes(filters[i][0])
    //       }
    //     })

    //   })

    let filterKeys = Object.keys(filters);
    let filterValues = [];
    let key = [];
    filterKeys &&
      Array.isArray(filterKeys) &&
      filterKeys.map((filterData) => {
        if (filterData && filters[filterData] && filters[filterData] !== null) {
          filterValues = [...filterValues, ...filters[filterData]];
          key.push(filterData);
        }
        return {};
      });
    let name = key[0];
    // let result = currentDatasource.filter(function (e) {
    //   return filterKeys.map(function (a,index) {
    //    return filterValues.includes(e[a])=== true ? filterValues.includes(e[a]) : false
    //   })
    // })
    var res = currentDatasource.filter(function (el) {
      return filterValues.indexOf(el[name]) >= 0;
    });

    let reportName = await getReportNametoDownloadMultiVaraint(
      columns,
      res,
      multiVaraintColumns,
      this.props.tableConfiguration.downloadExcelPayload
    );
    this.setState({
      reportName: reportName && reportName.file ? reportName.file : '',
      currentDatasource: res,
      filteredInfo: filters
    });
    this._getDataSource();
  };

  render() {
    let {
      tableConfiguration,
      columns,
      selectionWidget,
      currentDatasource,
      childDatasource,
      reportName,
      treeData,
      value,
      summaryValue,
      summaryTable,
      weekSummaryDatasource,
      pdfName,
      initalRender,
      translationCache
    } = this.state;
    const {
      bordered,
      tableSize,
      noResultMessage,
      isCursorPointer,
      title,
      downloadXLXS,
      downloadPdf,
      headerSelection
    } = tableConfiguration;
    const { columnFilter } = this.props.tableConfiguration || {};
    const { childTable, weekSummaryTable, nestedChildColumns, api } = this.props;
    const tProps = {
      treeData: treeData.filter(
        (e) =>
          e.key !== 'Date' &&
          e.key !== 'Total' &&
          e.key !== 'Total Consumption With Losses' &&
          e.key !== 'Total Cost per KWH' &&
          e.key !== 'Total ATM Consumption(Day-Wise kWh)'
      ),
      value: value.filter(
        (e) =>
          e !== 'Date' &&
          e !== 'Total' &&
          e !== 'Total Consumption With Losses' &&
          e !== 'Total Cost per KWH' &&
          e !== 'Total ATM Consumption(Day-Wise kWh)'
      ),
      onChange: this.onChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      searchPlaceholder: 'Select/Unselect',
      dropdownStyle: { maxHeight: 400, overflow: 'auto' },
      style: {
        width: '70%',
        margin: '0px 10px'
      }
    };
    return (
      <div>
        {api && api.payload.reportName && api.payload.reportName === 'Power Generation Report1' ? (
          <div>
            <TreeSelect {...tProps} />{' '}
            <Button type="primary" onClick={this.getReportData}>
              {translationCache && translationCache['Submit'] ? translationCache['Submit'] : ''}
            </Button>
          </div>
        ) : null}
        {selectionWidget ? (
          <SelectionComponent
            selectionWidget={selectionWidget}
            selectionpayload={this.selectionpayload}
          />
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
            marginBottom: '10px'
          }}
        >
          {/* headerSelection:[{
            key:"deviceId",
            title: "Select Device",
            placeHolder:"Select Device",
            displayName:"Device"
            table:{
              url:"",
              method:""
            },
            "option":{
              "display":"Name",
              "value":"Id"
            }
         }] */}
          {
            headerSelection && Array.isArray(headerSelection) && headerSelection[0] ? (
              // headerSelection.map((header, index) => {
              //   return (
              <SelectComponennt
                id={0}
                column={headerSelection[0]}
                record={{ key: headerSelection[0].key }}
                {...initalRender}
                handleFieldChange={(e, initialRender, column) =>
                  this.onInputChange(
                    e,
                    headerSelection[0].key,
                    headerSelection[0],
                    0,
                    initialRender,
                    column
                  )
                }
              />
            ) : null
            //   );
            // })
          }
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            cursor: 'pointer',
            marginBottom: '10px'
          }}
        >
          {downloadXLXS && reportName ? (
            <a href={`https://sgt.bmecomosolution.com/api/reports/${reportName}`}>
              {' '}
              <DownloadOutlined style={{ fontSize: '25px' }} />
            </a>
          ) : null}
          {downloadPdf && pdfName ? (
            <a href={`https://sgt.bmecomosolution.com/api/reports/${pdfName}`}>
              <FilePdfOutlined style={{ fontSize: '25px' }} />
            </a>
          ) : null}
          {this.props.tableConfiguration && this.props.tableConfiguration.link
            ? this.props.tableConfiguration.link.map((links, index) => {
                return (
                  <Tag key={index} color="#aaa3a3">
                    <Link to={{ pathname: links.path }} style={{ marginRight: '10px' }}>
                      {links.name}
                    </Link>
                  </Tag>
                );
              })
            : null}
          {columnFilter && (columnFilter === true || columnFilter === 'true') ? (
            <TreeSelect {...tProps} />
          ) : null}
        </div>
        {columns && columns.length >= 0 && !nestedChildColumns ? (
          <Table
            className="downtimeTable"
            columns={columns.filter((col) => value.includes(col.key))}
            dataSource={
              currentDatasource && Array.isArray(currentDatasource) ? currentDatasource : []
            }
            bordered={bordered ? bordered : false}
            size={tableSize ? tableSize : 'default'}
            locale={{ emptyText: noResultMessage }}
            isCursorPointer={isCursorPointer === true ? 'not-allowed' : 'pointer'}
            onChange={this.multiVariantonChange}
            loading={false}
            scroll={{ x: 120, y: 550 }}
            pagination={false}
            title={
              title
                ? () => (
                    <div
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center'
                      }}
                    >
                      {title}
                    </div>
                  )
                : null
            }
          />
        ) : null}

        {columns &&
        columns.length >= 0 &&
        nestedChildColumns &&
        Array.isArray(nestedChildColumns) &&
        nestedChildColumns[0] &&
        nestedChildColumns[0].columns ? (
          <Table
            className="downtimeTable"
            columns={columns.filter((col) => value.includes(col.key))}
            dataSource={
              currentDatasource && Array.isArray(currentDatasource) ? currentDatasource : []
            }
            bordered={bordered ? bordered : false}
            size={tableSize ? tableSize : 'default'}
            locale={{ emptyText: noResultMessage }}
            isCursorPointer={isCursorPointer === true ? 'not-allowed' : 'pointer'}
            onChange={this.multiVariantonChange}
            loading={false}
            scroll={{ x: 120, y: 550 }}
            pagination={false}
            title={
              title
                ? () => (
                    <div
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center'
                      }}
                    >
                      {title}
                    </div>
                  )
                : null
            }
            expandedRowRender={(record) => {
              return (
                <Table
                  columns={
                    nestedChildColumns &&
                    Array.isArray(nestedChildColumns) &&
                    nestedChildColumns[0] &&
                    nestedChildColumns[0].columns
                      ? nestedChildColumns[0].columns
                      : []
                  }
                  dataSource={
                    record.multiVariantChildren && Array.isArray(record.multiVariantChildren)
                      ? record.multiVariantChildren
                      : []
                  }
                  style={{ padding: '25px' }}
                  pagination={false}
                />
              );
            }}
          />
        ) : (
          <div />
        )}
        {childTable &&
        childTable[0] &&
        childTable[0].columns &&
        childTable[0].columns.length > 0 ? (
          <ChildTablesGroup>
            <Table
              className="downtimeTable"
              columns={summaryTable ? summaryTable : []}
              dataSource={childDatasource && Array.isArray(childDatasource) ? childDatasource : []}
              bordered={bordered ? bordered : false}
              size={tableSize ? tableSize : 'default'}
              locale={{ emptyText: noResultMessage }}
              loading={false}
              style={{ marginTop: '30px' }}
              scroll={{ x: 120, y: 550 }}
              pagination={false}
              title={
                title
                  ? () => (
                      <div
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          textAlign: 'center'
                        }}
                      >
                        {title}
                      </div>
                    )
                  : null
              }
            />
          </ChildTablesGroup>
        ) : null}
        {childTable &&
        childTable[0] &&
        childTable[0].columns &&
        childTable[0].columns.length > 0 &&
        weekSummaryTable ? (
          <ChildTablesGroup>
            <Table
              className="downtimeTable"
              columns={
                summaryTable ? summaryTable.filter((col) => summaryValue.includes(col.key)) : []
              }
              dataSource={weekSummaryDatasource}
              bordered={bordered ? bordered : false}
              size={tableSize ? tableSize : 'default'}
              locale={{ emptyText: noResultMessage }}
              loading={false}
              style={{ marginTop: '30px' }}
              scroll={{ x: 120, y: 550 }}
              pagination={false}
              title={
                title
                  ? () => (
                      <div
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          textAlign: 'center'
                        }}
                      >
                        {title}
                      </div>
                    )
                  : null
              }
            />
          </ChildTablesGroup>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(ReportTable);
