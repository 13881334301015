import React, { Component } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip, Drawer, Select } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { constant } from '../../../../Utils/constants';
import { StyledTable } from '../CSS/style';
import themeSettings from './themeSettings.json';
import translation from '../../../../Utils/translation';
import { getTranslationCache } from '../../../../../../selectors/language';
const { Option } = Select;
class FileBase64 extends Component {
  FolderViewOutlined;
  constructor(props) {
    super(props);
    this.state = {
      files:
        this.props.Attachments && Array.isArray(this.props.Attachments)
          ? this.props.Attachments
          : [],
      failureCodeAttachment: this.props.failureCodeAttachment
        ? this.props.failureCodeAttachment
        : false,
      allFiles: [],
      viewFilesList: false,
      translationCache: props.translationCache || {},
      disableAdd: props && props.disableAdd ? props.disableAdd : false
    };
  }

  componentDidUpdate = (prevprops) => {
    if (prevprops.Attachments !== this.props.Attachments) {
      this.setState({
        files:
          this.props.Attachments && Array.isArray(this.props.Attachments)
            ? this.props.Attachments
            : [],
        failureCodeAttachment: this.props.failureCodeAttachment
          ? this.props.failureCodeAttachment
          : false
      });
    }
  };
  myRef = React.createRef();
  handleChange = (e) => {
    var file = e.target.files[0],
      reader = new FileReader();
    reader.onloadend = function () {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      // var b64 = reader.result.replace(/^data:.+;base64,/, "");
      //-> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="
    };

    reader.readAsDataURL(file);
  };
  // var reader = new FileReader();
  // reader.readAsDataURL(fileInput.files[0]);

  // reader.onload = function () {
  // };
  // reader.onerror = function (error) {
  // };
  handleChange4 = (e) => {
    let files = e.target.files;
    var filesInfo1 = [];
    var filesInfo2 = [];
    // for (var i = 0; i < files.length; i++) {
    // let file = files[i];
    // let reader = new FileReader();
    // reader.readAsBinaryString(file, file.info);
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
      let fileInfo = {
        Name: file.name,
        AttachmentType: file.type,
        Size: Math.round(file.size / 1000) + ' kB'
        // file: reader.result
        // file: file,
      };
      let data = formData;
      filesInfo1.push(data);
      filesInfo2.push(fileInfo);
      // this.setState({ allFiles })

      this.props.fileUpload(filesInfo2, 'Attachments', file, this.props.name);
    }
    // reader.onload = () => {

    // };
    // }
  };
  downloadPDF(data, filename) {
    const linkSource = `data:application/txt;file,${data}`;
    const downloadlink = document.createElement('a');
    const fileName = filename;
    // + ".txt"
    downloadlink.href = linkSource;
    downloadlink.download = fileName;
    downloadlink.click();
  }
  downloadFile = () => {
    const { files } = this.state;
    let fileObject = files[0];
    this.downloadPDF(fileObject.filePath, fileObject.Name);
  };
  handleClick = (e) => {
    if (e === 'Add New Attachments') {
      this.myRef.current.click();
    } else {
      this.setState({ viewFilesList: true });
    }
  };
  render() {
    let { viewFilesList, failureCodeAttachment } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: 'File',
        dataIndex: 'Name',
        key: 'Name',
        align: 'center',
        render: (text, record) => (
          <>
            {/* {record && record.AliasName ? (
              <a
                href={`https://sgt.bmecomosolution.com/api/eamAttachments/${record.AliasName}`}
                rel="noreferrer"
                target="_blank"
              >
                {text}
              </a>
            ) : (
              <Tooltip placement="top" key="download" title="View will enable after submit">
                {' '}
                {text}
              </Tooltip>
            )} */}
            <>
              {failureCodeAttachment === true ? (
                <span>
                  <div style={{ marginLeft: '-25px' }}>
                    <a
                      href={`https://sgt.bmecomosolution.com/api/appconfigAttachments/${record.AliasName}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {text}
                    </a>
                  </div>
                </span>
              ) : (
                <span>
                  {record && record.AliasName ? (
                    <Tooltip placement="top" key="view" title="View">
                      <div style={{ marginLeft: '-25px' }}>
                        <a
                          href={`https://sgt.bmecomosolution.com/api/appconfigAttachments/${record.AliasName}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {text}
                        </a>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" key="viewT" title="View will enable after submit">
                      <div style={{ marginLeft: '-25px' }}>{text}</div>
                    </Tooltip>
                  )}
                </span>
              )}
            </>
          </>
        )
      },
      {
        title: 'Action',
        width: '600',
        align: 'center',
        render: (text, record) => (
          <>
            {failureCodeAttachment === true ? (
              <span>
                <Tooltip placement="top" key="download" title="Download">
                  <div style={{ marginLeft: '-25px' }}>
                    <a
                      href={`https://sgt.bmecomosolution.com/api/asset/downloadFile?Id=${record.Id}`}
                    >
                      <DownloadOutlined style={{ color: '#1a91ff' }} />
                    </a>
                  </div>
                </Tooltip>
              </span>
            ) : (
              <span>
                {record && record.AliasName ? (
                  <Tooltip placement="top" key="download" title="Download">
                    <div style={{ marginLeft: '-25px' }}>
                      <a
                        href={`https://sgt.bmecomosolution.com/api/asset/downloadFile?Id=${record.Id}`}
                      >
                        <DownloadOutlined style={{ color: '#1a91ff' }} />
                      </a>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" key="download" title="Download will enable after submit">
                    <div style={{ marginLeft: '-25px' }}>
                      <DownloadOutlined />
                    </div>
                  </Tooltip>
                )}
              </span>
            )}
          </>
        )
      }
    ];
    return (
      <>
        <Select
          // disabled={this.p rops.disableAdd}
          placeholder={translation(translationCache, constant.PleaseSelect)}
          onSelect={(e) => this.handleClick(e)}
          style={{ width: '85%' }}
        >
          <Option
            // disabled={this.props.disableAdd}
            key={'View Attachments'}
            value={'View Attachments'}
          >
            {translation(translationCache, constant.ViewAttachments)}
          </Option>
          <Option
            disabled={this.props.disableAdd}
            key={'Add New Attachments'}
            value={'Add New Attachments'}
          >
            {translation(translationCache, constant.AddNewAttachments)}
          </Option>
        </Select>
        <input
          type="file"
          // multiple
          ref={this.myRef}
          onChange={(e) => this.handleChange4(e)}
          style={{ display: 'none' }}
        />
        <Drawer
          title="Uploaded Files"
          open={viewFilesList}
          width={600}
          onOk={() => this.setState({ viewFilesList: false })}
          onClose={() => this.setState({ viewFilesList: false })}
        >
          <StyledTable
            theme={themeSettings}
            columns={columns}
            dataSource={
              this.props.Attachments &&
              Array.isArray(this.props.Attachments) &&
              this.props.Attachments
                ? [...this.props.Attachments]
                : []
            }
          />
        </Drawer>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(FileBase64);
