import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Drawer, Row, Col, Input, Select, Checkbox, message, Form } from 'antd';
import { EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';

import ColumnCustomization from './ColumnCustomization';
import localStorage from '../../../utils/localStorage';
import history from '../../../commons/history';
import config from '../../../constants/config.json';
import { getUserProfile } from '../../../selectors/layout';

import HeaderCustomization from './HeaderCustomization';
import ConfigEditor from './ConfigEditor';
import MobileConfigEditor from './MobileConfigEditor';
import {
  getDatasourceData,
  _getRolesBySite,
  getAdhocTableList,
  getRecurrencePatternData,
  getFormTypeData,
  getLocationData,
  getELogTypeData
} from './ElogConfigCalls';
import {
  StyledButton,
  StyledContentRight,
  StyledTable,
  DrawerFooter,
  StyledComponent
} from '../../../commons/styles/layout';

let { Option } = Select;

class ELogBookConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleData: false,
      userProfile: props.userProfile || {},
      editObj: {},
      roleList: [],
      userList: [],
      locationList: [],
      eLogTypeList: [],
      recurrencePatternList: [],
      formTypeList: [],
      columns: [],
      datasource: [],
      viewObject: {},
      processList: []
    };
    this.elogConfig = React.createRef();
  }

  componentDidMount() {
    this._getRolesList();
    this._getDataSource();
    this._getRecurrencePattern();
    this._getFormType();
    this._getLocation();
    this._getELogType();
    this._getAdhocTableList();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile
      });
    }
  };

  _getLocation = async () => {
    let locationList = await getLocationData();
    this.setState({ locationList });
  };

  _getELogType = async () => {
    let eLogTypeList = await getELogTypeData();
    this.setState({ eLogTypeList });
  };

  _getAdhocTableList = async () => {
    let adhocList = await getAdhocTableList();
    this.setState({ adhocList });
  };

  _getDataSource = async () => {
    let datasource = await getDatasourceData();
    this.setState({ datasource });
  };

  _getRecurrencePattern = async () => {
    let recurrencePatternList = await getRecurrencePatternData();
    this.setState({ recurrencePatternList });
  };

  _getFormType = async () => {
    let formTypeList = await getFormTypeData();
    this.setState({ formTypeList });
  };

  _getRolesList = async () => {
    let roleList = await _getRolesBySite();
    this.setState({ roleList });
  };

  onClose = () => {
    if (this.elogConfig.current) {
      this.elogConfig.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };

  onDataClose = () => {
    if (this.elogConfig.current) {
      this.elogConfig.current.resetFields();
    }
    this.setState({
      visibleData: false,
      editObj: {}
    });
  };

  addHeaderColumn = () => {
    let { editObj } = this.state;
    if (editObj && !editObj.JSON) {
      editObj = {
        ...editObj,
        JSON: {
          header: [
            {
              name: '',
              key: ''
            }
          ],
          form: {
            datasource: [],
            columns: []
          }
        }
      };
    } else {
      editObj = {
        ...editObj,
        JSON: {
          form: {
            datasource:
              editObj && editObj.JSON && editObj.JSON.form && editObj.JSON.form['datasource']
                ? editObj.JSON.form['datasource']
                : [],
            columns:
              editObj && editObj.JSON && editObj.JSON.form && editObj.JSON.form['columns']
                ? editObj.JSON.form['columns']
                : []
          },
          header: [
            ...editObj.JSON['header'],
            {
              name: '',
              key: ''
            }
          ]
        }
      };
    }
    this.setState({ editObj });
  };

  addColumn = () => {
    let { editObj } = this.state;
    if (editObj && !editObj.JSON && !editObj.JSON.form) {
      editObj = {
        ...editObj,
        JSON: {
          header: [],
          form: {
            datasource: [],
            columns: [
              {
                displayName: 'New Column',
                key: '',
                width: '',
                widget: 'input',
                placeHolder: ''
              }
            ]
          }
        }
      };
    } else {
      editObj = {
        ...editObj,
        JSON: {
          header: editObj.JSON['header'] || [],
          form: {
            datasource:
              editObj && editObj.JSON && editObj.JSON.form && editObj.JSON.form['datasource']
                ? editObj.JSON.form['datasource']
                : [],
            columns: [
              ...(editObj && editObj.JSON && editObj.JSON.form && editObj.JSON.form['columns']
                ? editObj.JSON.form['columns']
                : []),
              {
                displayName: 'New Column',
                key: '',
                width: '',
                widget: 'input',
                placeHolder: ''
              }
            ]
          }
        },
        MobileJSON: {
          header: editObj.JSON['header'] || [],
          form: {
            datasource:
              editObj && editObj.JSON && editObj.JSON.form && editObj.JSON.form['datasource']
                ? editObj.JSON.form['datasource']
                : [],
            columns: [
              ...(editObj && editObj.JSON && editObj.JSON.form && editObj.JSON.form['columns']
                ? editObj.JSON.form['columns']
                : []),
              {
                displayName: 'New Column',
                key: '',
                width: '',
                widget: 'input',
                placeHolder: ''
              }
            ]
          }
        }
      };
    }
    this.setState({ editObj });
  };

  _addELogBook = () => {
    if (this.elogConfig.current) {
      this.elogConfig.current.resetFields();
    }
    this.setState({
      visible: true,
      visibleData: false,
      editObj: {}
    });
  };

  updateElog = (e) => {
    e.preventDefault();
    let { editObj, userProfile } = this.state;
    this.elogConfig.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...editObj,
          ...values,
          ModifiedBy: userProfile && userProfile.user ? userProfile.user.Id : 0,
          ModifiedDate: moment().format('DD-MM-YYYY HH:mm:SS')
        };
        this._updateELOG(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //tested
  _updateELOG = (payload) => {
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    if (siteId === undefined) {
      siteId = 1;
    }
    const headers = {
      method: 'PATCH',
      url: `/api/logbook/update?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(headers)
      .then(() => {
        this.setState({
          visible: false,
          editObj: {}
        });
        if (this.elogConfig.current) {
          this.elogConfig.current.resetFields();
        }
        this._getDataSource();
        message.success('Updated Successfully');
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.info(err.response.data.message);
        }
      });
  };

  saveElog = (e) => {
    e.preventDefault();
    let { editObj, userProfile } = this.state;
    let siteId = localStorage.get('currentSite');
    this.elogConfig.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...values,
          JSON: editObj.JSON,
          MobileJSON: editObj.MobileJSON,
          createdBy: userProfile && userProfile.user ? userProfile.user.Id : 0,
          createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          ModifiedBy: null,
          ModifiedDate: null,
          site: siteId
        };
        this._saveELOG(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Tested
  _saveELOG = (payload) => {
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'POST',
      url: `/api/logbook/create?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(headers)
      .then(() => {
        this.setState({
          visible: false
        });
        if (this.elogConfig.current) {
          this.elogConfig.current.resetFields();
        }
        this._getDataSource();
        message.success('Saved Successfully');
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.info(err.response.data.message);
        }
      });
  };

  columnSaveObject = (columns) => {
    let { editObj } = this.state;
    if (columns) {
      editObj = {
        ...editObj,
        JSON: {
          form: {
            columns,
            datasource: editObj.JSON['datasource'] || []
          },
          header: editObj.JSON['header'] || []
        }
      };
    }
    this.setState({ editObj });
  };

  columnEditorObject = (form) => {
    let { editObj } = this.state;
    if (form) {
      editObj = {
        ...editObj,
        JSON: {
          form,
          header: editObj && editObj.JSON ? editObj.JSON['header'] : []
        },
        MobileJSON: {
          form,
          header:
            editObj && editObj.MobileJSON && editObj.MobileJSON['header']
              ? editObj.MobileJSON['header']
              : []
        }
      };
    }
    this.setState({ editObj });
  };

  columnEditorObjectMobile = (form) => {
    let { editObj } = this.state;
    if (form) {
      editObj = {
        ...editObj,
        MobileJSON: {
          form: form ? form : [],
          header:
            editObj && editObj.MobileJSON && editObj.MobileJSON['header']
              ? editObj.MobileJSON['header']
              : []
        }
      };
    }
    this.setState({ editObj });
  };
  columnHeaderSaveObject = (header) => {
    let { editObj } = this.state;
    if (header) {
      editObj = {
        ...editObj,
        JSON: {
          ...(editObj && editObj.JSON ? editObj.JSON : {}),
          header
        },
        MobileJSON: {
          ...(editObj && editObj.JSON ? editObj.JSON : {}),
          header
        }
      };
    }
    this.setState({ editObj });
  };
  columnHeaderSaveObjectMobile = (header) => {
    let { editObj } = this.state;
    if (header) {
      editObj = {
        ...editObj,
        JSON: {
          ...(editObj && editObj.JSON ? editObj.JSON : {}),
          header
        },
        MobileJSON: {
          ...(editObj && editObj.JSON ? editObj.JSON : {}),
          header
        }
      };
    }
    this.setState({ editObj });
  };

  getData = (list, text, type) => {
    var result = list.filter((obj) => {
      return obj.Id === text;
    });
    if (result && Array.isArray(result) && result.length > 0 && type === 'role') {
      return result[0].role;
    }
    if (result && Array.isArray(result) && result.length > 0 && type === 'user') {
      return result[0].firstName;
    }
    return '';
  };

  handleFormTypeChange = (value) => {
    let { editObj } = this.state;
    editObj['formType'] = value;
    this.setState({
      editObj
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <StyledButton
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          <SearchOutlined />
          Search
        </StyledButton>
        <StyledButton
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </StyledButton>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  render() {
    let {
      editObj,
      roleList,
      columns,
      datasource,
      recurrencePatternList,
      adhocList,
      formTypeList,
      locationList,
      eLogTypeList,
      userProfile
    } = this.state;
    let permissionsArray = [];
    const siteId = localStorage.get('currentSite');
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][siteId] &&
      userProfile['belongsTo']['sites'][siteId]['role'] &&
      userProfile['belongsTo']['sites'][siteId]['role']['Permission']
        .filter((item) => item.resource === 'elog_book')
        .map((name) => permissionsArray.push(name.action));
    columns = [
      {
        title: 'Logbook ID',
        dataIndex: 'logbookID',
        key: 'logbookID',
        ...this.getColumnSearchProps('logbookID'),
        render: (text, record) => {
          return (
            <Link
              style={{ color: '#173049' }}
              to={{ pathname: `/rubus/Logbook/${record.logbookID}` }}
            >
              {text}
            </Link>
          );
        }
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
      },
      {
        title: 'Form Type',
        dataIndex: 'formType',
        key: 'formType'
      },
      {
        title: 'Action',
        width: '600',
        render: (text, record) => (
          <div>
            <button
              type="button"
              onClick={() => {
                if (this.elogConfig.current) {
                  this.elogConfig.current.setFieldsValue(record);
                }
                this.setState({
                  visible: true,
                  editObj: record
                });
              }}
              className="ant-btn"
            >
              <EditOutlined />
            </button>
          </div>
        )
      }
    ];
    return (
      <StyledComponent style={{ minHeight: window.innerHeight - 173 }}>
        <StyledContentRight>
          <StyledButton
            icon={<PlusOutlined />}
            disabled={!permissionsArray.includes('create') ? true : false}
            onClick={this._addELogBook}
          >
            Add Logbook
          </StyledButton>
        </StyledContentRight>
        <Drawer
          title="Edit Configuration"
          placement="right"
          width={1100}
          closable
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Form
            layout="vertical"
            ref={this.elogConfig}
            name="Site_form"
            // labelCol={{ span: 4 }}
            // wrapperCol={{ span: 14 }}
            initialValues={editObj || {}}
            onFinishFailed={this.onFinishFailed}
          >
            {/* <Row gutter={16}> */}
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* <Col span={24}> */}
              <Col className="gutter-row" span={18}>
                <Form.Item
                  label="ELogBook Id"
                  name="logbookID"
                  rules={[{ required: true, message: 'Enter LogbookID' }]}
                >
                  <Input
                    placeholder="Please enter LogbookID"
                    disabled={!editObj.logbookID ? false : true}
                  />
                </Form.Item>
                <Form.Item
                  label="ELogReference Id"
                  name="eLogReferenceId"
                  rules={[{ required: true, message: 'Enter ELogReferenceId' }]}
                >
                  <Input
                    placeholder="Please enter eLogReferenceId"
                    disabled={!editObj.eLogReferenceId ? false : true}
                  />
                </Form.Item>
                <Form.Item
                  label="Location"
                  name="location"
                  rules={[{ required: true, message: 'Enter Location' }]}
                >
                  <Select placeholder="Please select Location">
                    {locationList.map((location, index) => {
                      return (
                        <Option key={index} value={location.Name}>
                          {location.Name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="ELogBook Name"
                  name="elogbookName"
                  rules={[{ required: true, message: 'Enter ElogbookName' }]}
                >
                  <Input
                    placeholder="Please enter elogbook Name"
                    disabled={!editObj.elogbookName ? false : true}
                  />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Enter Description' }]}
                >
                  <Input.TextArea placeholder="Please enter Description" />
                </Form.Item>
                <Form.Item
                  label="ELog Type"
                  name="eLogType"
                  rules={[{ required: true, message: 'Enter ELogType' }]}
                >
                  <Select placeholder="Please select users" mode="single">
                    {eLogTypeList.map((elogType, index) => {
                      return (
                        <Option key={index} value={elogType}>
                          {elogType}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {/* {getFieldValue("eLogType") === 2 ? ( */}
                <Form.Item
                  label="Adhoc Table"
                  name="adhocTable"
                  rules={[{ required: true, message: 'Enter Adhoc Table' }]}
                >
                  <Select placeholder="Please select adhoc table" mode="single">
                    {adhocList &&
                      Array.isArray(adhocList) &&
                      adhocList.map((elogType, index) => {
                        return (
                          <Option key={index} value={elogType.table}>
                            {elogType.table}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                {/* ) : null} */}

                <Form.Item
                  label="Recurrence Pattern"
                  name="recurrencePattern"
                  rules={[{ required: true, message: 'Enter Recurrence Pattern' }]}
                >
                  <Select placeholder="Please select RecurrencePattern">
                    {recurrencePatternList.map((recurrencePattern, index) => {
                      return (
                        <Option key={index} value={recurrencePattern}>
                          {recurrencePattern}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Assigned To"
                  name="assignedTo"
                  rules={[{ required: true, message: 'Enter Assigned To' }]}
                >
                  <Select placeholder="Please select users" mode="multiple">
                    {roleList.map((role, index) => {
                      return (
                        <Option key={index} value={role.Id}>
                          {role.Name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Authorized by"
                  name="authorizedBy"
                  rules={[{ required: true, message: 'Enter Assigned By' }]}
                >
                  <Select placeholder="Please select authorized">
                    {roleList.map((role, index) => {
                      return (
                        <Option key={index} value={role.Id}>
                          {role.Name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Active/Inactive"
                  name="checked"
                  rules={[{ required: false, message: 'Enter Status' }]}
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  label="Form Type"
                  name="formType"
                  rules={[{ required: true, message: 'Enter formType' }]}
                  onChange={this.handleFormTypeChange}
                >
                  <Select placeholder="Please select FormType">
                    {formTypeList &&
                      formTypeList.map((formType, index) => {
                        return (
                          <Option key={index} value={formType}>
                            {formType}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Process"
                  name="process"
                  rules={[{ required: true, message: 'Enter process' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ marginBottom: '50px' }}>
              <b>Header Configuration</b>
              <StyledButton
                size="small"
                onClick={this.addHeaderColumn}
                style={{ marginLeft: '5px', marginBottom: '20px' }}
                ghost
              >
                Add Header Column
              </StyledButton>
              {editObj.JSON && editObj.JSON['header'] ? (
                <HeaderCustomization
                  columnsProperties={editObj && editObj.JSON ? editObj.JSON['header'] : []}
                  columnSaveObject={this.columnHeaderSaveObject}
                />
              ) : null}
            </div>
            {editObj.formType === 'tableView2' ? (
              <div style={{ marginBottom: '50px' }}>
                <b>Column Configuration : </b>
                <StyledButton
                  size="small"
                  onClick={this.addColumn}
                  style={{ marginLeft: '5px', marginBottom: '20px' }}
                  ghost
                >
                  Add Column
                </StyledButton>
                {editObj.JSON ? (
                  <ColumnCustomization
                    columnsProperties={
                      editObj && editObj.JSON && editObj.JSON.form
                        ? editObj.JSON.form['columns']
                        : []
                    }
                    columnSaveObject={this.columnSaveObject}
                  />
                ) : null}
              </div>
            ) : null}

            {editObj.formType !== 'tableView1' ? (
              <div style={{ marginBottom: '50px' }}>
                <b>Form Configuration : </b>
                <StyledButton ghost onClick={() => history.push('/rubus/ELogBooks/FormTypeConfig')}>
                  Click to redirect to Form Config
                </StyledButton>
                <ConfigEditor
                  form={editObj && editObj.JSON && editObj.JSON.form ? editObj.JSON.form : []}
                  columnEditorObject={this.columnEditorObject}
                />
              </div>
            ) : null}
            {/* MobileConfig */}
            {editObj.formType !== 'tableView1' ? (
              <div style={{ marginBottom: '50px' }}>
                <b>Mobile Configuration : </b>
                <StyledButton ghost onClick={() => history.push('/rubus/ELogBooks/FormTypeConfig')}>
                  Click to redirect to Form Config
                </StyledButton>
                <MobileConfigEditor
                  form={
                    editObj && editObj.MobileJSON && editObj.MobileJSON.form
                      ? editObj.MobileJSON.form
                      : []
                  }
                  columnEditorObjectMobile={this.columnEditorObjectMobile}
                  // columnEditorObject={this.columnEditorObject}
                />
              </div>
            ) : null}
          </Form>
          <DrawerFooter>
            {config.production !== true ? (
              <StyledButton onClick={this.onClose} style={{ marginRight: 8 }}>
                Cancel
              </StyledButton>
            ) : null}
            {config.production !== true ? (
              <span>
                {!editObj.logbookID ? (
                  <StyledButton onClick={this.saveElog}>Create</StyledButton>
                ) : (
                  <StyledButton
                    disabled={!permissionsArray.includes('update') ? true : false}
                    onClick={this.updateElog}
                  >
                    Update
                  </StyledButton>
                )}
              </span>
            ) : null}
          </DrawerFooter>
        </Drawer>
        <StyledTable columns={columns} dataSource={datasource} />
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});

export default connect(mapStateToProps)(ELogBookConfiguration);
