import React from 'react';
import axios from 'axios';
import {
  Table,
  Drawer,
  Button,
  Form,
  Input,
  Checkbox,
  Row,
  Tree,
  Popconfirm,
  message,
  Select,
  Card,
  Tooltip
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import rolesPermissions from './Adapter';
import { connect } from 'react-redux';
import { constant } from '../../Constants';
import localStorage from '../../../../utils/localStorage';
import { StyledTable, lightTheme } from '../style';
import { getTranslationCache } from '../../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import '../style.css';

const { TreeNode } = Tree;
const { Option } = Select;
class Role extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      editObj: {},
      siteId: '',
      moduleList: [],
      configurationList: [],
      landingPageList: [],
      permissionsList: {},
      checkedConfiguartionKeys: [],
      checkedModuleKeys: [],
      checkedApplicationSettingsKeys: [],
      checkedPermissionsKeys: {
        asset_device: [],
        dashboard: [],
        timeScale: []
      },
      configurationActualList: {},
      modulesActualList: {},
      applicationActualList: {},
      datasource: [],
      selectedKeys: [],
      expandedKeys: [],
      autoExpandParent: true,
      translationCache: props.translationCache || []
    };
    this.RoleConfigRef = React.createRef();
  }

  componentDidMount() {
    let siteId = this.props.match.params.siteId;
    this.setState({ siteId });
    this._getRolesList(siteId);
    this._getPermissionsList();
    this._getModuleList();
    this._getLandingPageList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  _getPermissionsList = () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `/api/role/permissions?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ permissionsList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _getRolesList = () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `/api/role/get?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ datasource: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _getModuleList = () => {
    let siteId = this.props.match.params.siteId;
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `/api/menu/get?SiteId=${siteId}&Type=Structured`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        if (response.data[0]) {
          this.setState({
            configurationActualList: response.data[0].Configuration,
            modulesActualList: response.data[0].Modules,
            applicationSettingsActualList: response.data[0].ApplicationSettings
          });
          let configurationList = rolesPermissions.menuListAdapter(response.data[0].Configuration);
          let moduleList = rolesPermissions.menuListAdapter(response.data[0].Modules);
          let applicationList = rolesPermissions.menuListAdapter(
            response.data[0].ApplicationSettings
          );
          this.setState({ moduleList, configurationList, applicationList });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _getLandingPageList = () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `/api/landingPage/get?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ landingPageList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  showDrawer = () => {
    if (this.RoleConfigRef.current) {
      this.RoleConfigRef.current.resetFields();
    }
    this.setState({
      editObj: {},
      visible: true,
      checkedConfiguartionKeys: [],
      checkedModuleKeys: [],
      checkedApplicationSettingsKeys: [],
      checkedPermissionsKeys: {
        asset_device: [],
        dashboard: [],
        timeScale: []
      }
    });
  };

  onClose = () => {
    if (this.RoleConfigRef.current) {
      this.RoleConfigRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };

  _updateRole = (updateObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let updateHeader = {
      method: 'PATCH',
      url: `/api/role/update?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: updateObject
    };
    axios(updateHeader)
      .then((response) => {
        if (response && response.data) {
          this._getRolesList(updateHeader.site);
          this.RoleConfigRef.current.resetFields();
          message.success(response.data.message);
          this.setState({ visible: false, editObj: {} });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _saveRole = (createObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgCreateHeaders = {
      method: 'POST',
      url: `/api/role/create?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: createObject
    };
    axios(orgCreateHeaders)
      .then((response) => {
        if (response && response.data) {
          this._getRolesList(createObject.site);
          this.RoleConfigRef.current.resetFields();
          message.success(response.data.message);
          this.setState({ visible: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _deleteRole = (role) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgDeleteHeaders = {
      method: 'DELETE',
      url: `/api/role/delete?SiteId=${siteId}&Id=${role.Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgDeleteHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          message.success('Role Deleted');
          this._getRolesList(role.site);
          this.RoleConfigRef.current.resetFields();
          this.setState({ visible: false });
        } else {
          message.error('Error in delete');
        }
      })
      .catch(function () {});
  };

  openDrawer = (e, record) => {
    //   let checkedConfiguartionKeys = [];
    //   let checkedModuleKeys = [];
    //   let checkedApplicationSettingsKeys = [];

    // if (record.Configuration && record.Configuration.children) {
    //   checkedConfiguartionKeys = rolesPermissions.generateCheckedList(
    //     record.Configuration.children
    //   );
    // }
    // if (record.Modules && record.Modules.children) {
    //   checkedModuleKeys = rolesPermissions.generateCheckedList(record.Modules.children);
    // }
    // if (record.ApplicationSettings && record.ApplicationSettings.children) {
    //   checkedApplicationSettingsKeys = rolesPermissions.generateCheckedList(
    //     record.ApplicationSettings.children
    //   );
    // }

    let checkedPermissionsKeys = rolesPermissions.permissionAdapter(record.Permission);

    if (this.RoleConfigRef.current) {
      this.RoleConfigRef.current.setFieldsValue(record);
    }
    this.setState({
      visible: true,
      editObj: record,
      checkedPermissionsKeys,
      checkedModuleKeys: record.Modules,
      checkedConfiguartionKeys: record.Configuration,
      checkedApplicationSettingsKeys: record.ApplicationSettings
    });
  };

  onCheck = (name, checkedKey) => {
    if (name === 'configuration') {
      this.setState({ checkedConfiguartionKeys: checkedKey });
    } else if (name === 'module') {
      this.setState({ checkedModuleKeys: checkedKey });
    } else if (name === 'applicationSettings') {
      this.setState({ checkedApplicationSettingsKeys: checkedKey });
    }
  };

  onSubmit = (operation) => {
    let {
      checkedConfiguartionKeys,
      checkedApplicationSettingsKeys,
      checkedPermissionsKeys,
      checkedModuleKeys,
      siteId,
      editObj
    } = this.state;

    const values = this.RoleConfigRef.current.getFieldsValue('Role_form');
    let Permission = [];
    if (checkedPermissionsKeys) {
      Permission = rolesPermissions.createPermissions(checkedPermissionsKeys);
    }
    let saveObject = {
      ...editObj,
      ...values,

      OrganizationId: 1,
      SiteId: siteId,
      Permission,
      Modules: checkedModuleKeys,
      Configuration: checkedConfiguartionKeys,
      ApplicationSettings: checkedApplicationSettingsKeys
    };

    if (operation === 'create') {
      this._saveRole(saveObject);
    } else if (operation === 'update') {
      this._updateRole(saveObject);
    }
  };

  renderTreeNodes = (data) =>
    data &&
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });

  onChange = (checkedValues, title) => {
    let { checkedPermissionsKeys } = this.state;
    let newObj = {
      ...checkedPermissionsKeys,
      [title]: checkedValues
    };
    this.setState({ checkedPermissionsKeys: newObj });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };
  onFinishFailed = () => {
    message.error('please enter required fields');
  };
  render() {
    const {
      moduleList,
      configurationList,
      applicationList,
      checkedModuleKeys,
      checkedApplicationSettingsKeys,
      checkedConfiguartionKeys,
      checkedPermissionsKeys,
      datasource,
      editObj,
      landingPageList,
      permissionsList,
      visible
    } = this.state;
    const columns = [
      {
        title: this.translation('Role Name'),
        dataIndex: 'Name',
        key: 'Name'
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description',
        key: 'Description'
      },
      {
        title: this.translation('Action'),
        dataIndex: '',
        key: '',
        render: (text, record) => (
          <div>
            <Tooltip title={this.translation(constant.Edit)}>
              <button
                type="button"
                onClick={(e) => this.openDrawer(e, record)}
                style={{ marginRight: '10px' }}
                className="ant-btn"
              >
                <EditOutlined />
              </button>
            </Tooltip>
            <Popconfirm
              title={this.translation('Are you sure delete Site ?')}
              onConfirm={(e) => {
                e.stopPropagation();
                this._deleteRole(record);
              }}
              okText={this.translation(constant.yes)}
              cancelText={this.translation(constant.no)}
            >
              {' '}
              <Tooltip title={this.translation(constant.Delete)}>
                <button type="button" className="ant-btn">
                  <DeleteOutlined />
                </button>
              </Tooltip>
            </Popconfirm>
          </div>
        )
      }
    ];

    return (
      <Card bordered={false}>
        <Drawer
          title={
            editObj && editObj.Id
              ? this.translation('Update Role')
              : this.translation('Create Role')
          }
          placement="right"
          width={600}
          closable
          onClose={this.onClose}
          visible={visible}
        >
          <Form
            layout="vertical"
            ref={this.RoleConfigRef}
            name="Role_form"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={editObj || {}}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Role Name')}</div>}
              name="Name"
              rules={[{ required: true, message: 'Please input your rolename!' }]}
            >
              <Input
                placeholder={this.translation('Please enter Role Name')}
                disabled={editObj.Id ? true : false}
              />
            </Form.Item>

            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Description')}</div>}
              name="Description"
              rules={[{ required: true, message: 'Please input your description!' }]}
            >
              <Input placeholder={this.translation('Please enter Description')} />
            </Form.Item>

            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Landing Page')}</div>}
              name="LandingPageId"
              rules={[{ required: true, message: 'Please select Landing Page !' }]}
            >
              <Select>
                {landingPageList.map((value, index) => {
                  return (
                    <Option key={index} value={value.Id}>
                      {value.Name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            {Object.keys(permissionsList).map((permissionName, index) => {
              let { name, key, permission } = permissionsList[permissionName];
              return (
                <Form.Item
                  key={index}
                  label={<div style={{ fontWeight: 'bold' }}>{this.translation(name)}</div>}
                >
                  <Checkbox.Group
                    value={
                      checkedPermissionsKeys && checkedPermissionsKeys[key]
                        ? checkedPermissionsKeys[key]
                        : []
                    }
                    onChange={(e) => this.onChange(e, key)}
                  >
                    <Row>
                      {permission &&
                        Array.isArray(permission) &&
                        permission.map(({ label, value }) => {
                          return (
                            <Checkbox key={value} value={value}>
                              {this.translation(label)}
                            </Checkbox>
                          );
                        })}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              );
            })}

            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Configuration')}</div>}
            >
              <Tree
                checkable
                onCheck={(e) => this.onCheck('configuration', e)}
                checkedKeys={checkedConfiguartionKeys}
              >
                {this.renderTreeNodes(configurationList)}
              </Tree>
            </Form.Item>

            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Modules')}</div>}
            >
              <Tree
                checkable
                onCheck={(e) => this.onCheck('module', e)}
                checkedKeys={checkedModuleKeys}
              >
                {this.renderTreeNodes(moduleList)}
              </Tree>
            </Form.Item>

            <Form.Item
              label={
                <div style={{ fontWeight: 'bold' }}>{this.translation('Application Settings')}</div>
              }
            >
              <Tree
                style={{ marginBottom: '50px' }}
                checkable
                onCheck={(e) => this.onCheck('applicationSettings', e)}
                checkedKeys={checkedApplicationSettingsKeys}
              >
                {this.renderTreeNodes(applicationList)}
              </Tree>
            </Form.Item>
          </Form>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {' '}
              {this.translation('Cancel')}
            </Button>
            {Object.keys(editObj).length === 0 ? (
              <Button onClick={() => this.onSubmit('create')} type="primary">
                {this.translation('Create')}
              </Button>
            ) : (
              <Button onClick={() => this.onSubmit('update')} type="primary">
                {this.translation('Update')}
              </Button>
            )}
          </div>
        </Drawer>
        <Button
          type="primary"
          onClick={this.showDrawer}
          size={'large'}
          style={{
            marginBottom: '10px',
            backgroundColor: '#214972',
            border: 'white'
          }}
        >
          <PlusOutlined />
          {this.translation('Add')}
        </Button>

        <StyledTable theme={lightTheme}>
          <Table
            className="basictable"
            dataSource={datasource}
            columns={columns}
            style={{ marginTop: '20px' }}
          />
        </StyledTable>
      </Card>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Role);
