import React, { Fragment } from 'react';
import moment from 'moment';
import { Table, message, Popconfirm, Switch } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import localStorage from '../../utils/localStorage';
import { getUserProfile } from '../../selectors/layout';
import { ruleList, deleteRule, updateRuleStatus } from './Apicalls';
import { getTranslationCache } from '../../selectors/language';
import { StyledDashboard, StyledTable, SaveButton, RuleButton, lightTheme } from './style';
import constants from './constants';

class RulesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      userProfile: props.userProfile || {}
    };
  }
  async componentDidMount() {
    try {
      let data = await ruleList();
      this.setState({ tableData: data.data });
    } catch (err) {
      message.warn('OOPS Something went Wrong');
    }
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile
      });
    }
  };
  deleteRule = async (key) => {
    try {
      let data = await deleteRule(key);
      if (data.status === 200) {
        message.success('Deleted Rule');
        try {
          let tableData = await ruleList();
          this.setState({ tableData: tableData.data });
        } catch (err) {
          message.warn('Something went Wrong !');
        }
      }
    } catch (err) {
      message.warn('Something went Wrong !');
    }
  };

  ruleStatusUpdate = async (rule) => {
    let payLoad = {
      Id: rule.Id,
      Type: rule.Type,
      NotificationStatus: !rule.NotificationStatus
    };
    if (rule.Type === 'batch') {
      payLoad.FilterOption = rule.Priority;
    } else {
      payLoad.FilterOption = rule.ParameterType;
    }

    let data = await updateRuleStatus(payLoad);
    if (data.status === 200) {
      let data = await ruleList();
      this.setState({ tableData: data.data });
      // let data = this.state.tableData;
      // data[index]["NotificationStatus"] = !rule.NotificationStatus;
      // this.setState({ tableData: data });
    } else {
      message.error('something Went Wrong');
    }
  };

  handleRowClick = (ruleId) => {
    this.props.history.push(`/rubus/RulesFeedback/?ruleId=${ruleId}`);
  };

  render() {
    let permissionsArray = [];
    let { translationCache } = this.props;
    let { userProfile } = this.state;
    const siteId = localStorage.get('currentSite');
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][siteId] &&
      userProfile['belongsTo']['sites'][siteId]['role'] &&
      userProfile['belongsTo']['sites'][siteId]['role']['Permission']
        .filter((item) => item.resource === 'rule_engine')
        .map((name) => permissionsArray.push(name.action));

    const columns = [
      {
        title:
          translationCache && translationCache[`${constants.Rule_Name}`]
            ? translationCache[`${constants.Rule_Name}`]
            : `${constants.Rule_Name}`,
        dataIndex: 'Name',
        key: 'name'
      },
      {
        title:
          translationCache && translationCache[`${constants.Rule_Description}`]
            ? translationCache[`${constants.Rule_Description}`]
            : `${constants.Rule_Description}`,
        dataIndex: 'Description',
        key: 'description'
      },
      {
        title:
          translationCache && translationCache[`${constants.createdTime}`]
            ? translationCache[`${constants.createdTime}`]
            : `${constants.createdTime}`,
        dataIndex: 'CreatedTime',
        key: 'createdtime',
        render: (text) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
      },
      {
        title:
          translationCache && translationCache[`${constants.edit}`]
            ? translationCache[`${constants.edit}`]
            : `${constants.edit}`,
        key: 'Edit',
        render: (text, record) => (
          <span>
            <FormOutlined
              style={{ color: '#1a3652' }}
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`RuleEngine/${record.Id}`);
              }}
            />
          </span>
        )
      },
      {
        title:
          translationCache && translationCache[`${constants.delete}`]
            ? translationCache[`${constants.delete}`]
            : `${constants.delete}`,
        key: 'Delete',
        render: (text, record) => (
          <span>
            <Popconfirm
              placement="topRight"
              title="Are you sure delete this Rule?"
              onConfirm={(e) => {
                e.stopPropagation();
                this.deleteRule(record.Id);
              }}
              onCancel={(e) => {
                e.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined
                //  theme="filled"
                style={{ color: '#f5222d' }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          </span>
        )
      },
      {
        title:
          translationCache && translationCache[`${constants.status}`]
            ? translationCache[`${constants.status}`]
            : `${constants.status}`,
        key: 'NotificationStatus',
        render: (text, record, index) => {
          return (
            <span>
              <Switch
                checkedChildren={
                  translationCache && translationCache['ON'] ? translationCache['ON'] : 'ON'
                }
                unCheckedChildren={
                  translationCache && translationCache['OFF'] ? translationCache['OFF'] : 'OFF'
                }
                checked={record.NotificationStatus}
                onChange={() => {
                  // e.stopPropagation();
                  this.ruleStatusUpdate(record, index);
                }}
              />

              {/* <Checkbox checked={record.NotificationStatus}
                onChange={(e) => {
                  e.stopPropagation();
                  this.ruleStatusUpdate(record, index);
                }}></Checkbox> */}
            </span>
          );
        }
      },
      {
        title: '',
        key: '',
        render: (text, record) => {
          return (
            <RuleButton
              type="primary"
              onClick={() => {
                this.handleRowClick(record.Id);
              }}
            >
              {translationCache && translationCache[`Rule History`]
                ? translationCache[`Rule History`]
                : `Rule History`}
            </RuleButton>
          );
        }
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 115 }}>
        <Fragment>
          <div>
            <SaveButton
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                margin: '23px',
                color: '#fff !important'
              }}
              type="primary"
              // disabled={!permissionsArray.includes("create") ? true : false}
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`/rubus/RuleEngine`);
              }}
            >
              {translationCache &&
              translationCache[`${constants.addrule}`] &&
              translationCache[`${constants.addrule}`]
                ? translationCache[`${constants.addrule}`]
                : `${constants.addrule}`}
            </SaveButton>
          </div>
          <div>
            <StyledTable theme={lightTheme}>
              <Table columns={columns} dataSource={this.state.tableData} />
            </StyledTable>
          </div>
        </Fragment>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(RulesList);
