import React from 'react';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { withTheme } from 'styled-components';
import { createStructuredSelector } from 'reselect';
import {
  CaretDownOutlined,
  FullscreenOutlined,
  GlobalOutlined,
  LogoutOutlined,
  UserOutlined,
  EllipsisOutlined
} from '@ant-design/icons';

import history from '../../../../../commons/history';
import localStorage from '../../../../../utils/localStorage';
import menuAdapter from '../Adapter/MenuAdapter';
import { StyledMenu } from '../styles';

import '../styles.css';
import { constant } from './Constants';

import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing, makeSelectTheme } from '../../../../../selectors/theme';

class SlideMenuComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMenu: '',
      userProfile: props.userProfile || {},
      loggedInRole: {},
      logoPath: '',
      rightMenuAlignment: 'right',
      selectedMenu: [] || '',
      firstLevelMenu: [],
      secondLevelMenu: [],
      menuHierarchy: props.menuHierarchy || {},
      notificationList: [],
      currentSite: localStorage.get('currentSite') || '',
      navigationMode: 'top',
      translationCache: props.translationCache || [],
      languageList: []
    };
  }

  componentDidMount() {
    const width = window.innerWidth;
    if (width > 1466) {
      this.setState({ rightMenuAlignment: 'right' });
    } else {
      this.setState({ rightMenuAlignment: 'none' });
    }
    this.getLanguageList();
  }

  componentDidUpdate(prevProps) {
    let { menuHierarchy } = this.state;
    let { selectedMenu } = this.props;
    if (prevProps.translationCache !== this.props.translationCache) {
      let firstLevelMenu = menuAdapter.firstMenuAdapter(menuHierarchy, this.props.translationCache);
      this.getSecondLevelMenu(menuHierarchy, selectedMenu[0]);

      this.setState({
        menuHierarchy,
        firstLevelMenu
      });
      this.setState({
        translationCache: this.props.translationCache
      });
    }

    //menuHierarchy change
    if (this.props.menuHierarchy !== prevProps.menuHierarchy) {
      menuHierarchy = this.props.menuHierarchy;
      let firstLevelMenu = menuAdapter.firstMenuAdapter(menuHierarchy, this.props.translationCache);

      this.getSecondLevelMenu(menuHierarchy, selectedMenu[0]);

      this.setState({
        menuHierarchy,
        firstLevelMenu
      });
    }
    // Selected Menu change
    if (this.props.selectedMenu !== prevProps.selectedMenu) {
      if (selectedMenu.length >= 2) {
        this.setState({ currentMenu: selectedMenu[1] });
      }
      this.getSecondLevelMenu(menuHierarchy, selectedMenu[0]);
    }
    // Current Language, SelectedMenu
    // if (this.props.currentLanguage !== prevProps.currentLanguage && selectedMenu[0]) {
    //   let firstLevelMenu = menuAdapter.firstMenuAdapter(menuHierarchy);
    //   this.setState({ firstLevelMenu });
    //   this.getSecondLevelMenu(menuHierarchy, selectedMenu[0]);
    // }

    // Userprofile change
    if (this.props.userProfile !== prevProps.userProfile) {
      this.getRole(this.props.userProfile);
    }
  }

  getLanguageList = () => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let changePassword = {
      method: 'GET',
      url: `/api/language/languageList?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(changePassword)
      .then((response) => {
        this.setState({
          languageList: response.data
        });
        this.props.actions.getTranslationsCache(
          response.data && response.data[0] ? response.data[0] : 'English'
        );
      })
      .catch(function () {});
  };

  getSecondLevelMenu = async (menuHierarchy, selectedMenu) => {
    let menuHierarchyClone = cloneDeep(menuHierarchy);
    let secondLevelMenu = await menuAdapter.secoundMenuAdapter(
      menuHierarchyClone,
      selectedMenu || 'Modules',
      this.props.translationCache
    );
    this.setState({ secondLevelMenu });
  };

  UNSAFE_componentWillReceiveProps({ currentLanguage, currentSite, userProfile, selectedMenu }) {
    if (userProfile.belongsTo) {
      this.setState({
        currentLanguage,
        currentSite,
        userProfile,
        selectedMenu
      });
    } else {
      this.setState({ currentLanguage, userProfile, selectedMenu });
    }
  }

  getRole = async (userProfile) => {
    let currentSiteId = localStorage.get('currentSite');
    let logoPath =
      currentSiteId &&
      userProfile &&
      userProfile.belongsTo &&
      userProfile.belongsTo.sites &&
      userProfile.belongsTo.sites[currentSiteId] &&
      userProfile.belongsTo.sites[currentSiteId].site &&
      userProfile.belongsTo.sites[currentSiteId].site['ImageURL']
        ? userProfile.belongsTo.sites[currentSiteId].site['ImageURL']
        : '';

    this.setState({
      logoPath
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  getSubMenuOrItem = (item, menuLevel) => {
    let { selectedMenu, menuHierarchy } = this.state;
    return (
      <Menu.Item
        key={item.key}
        className="customclass"
        onClick={() => {
          if (menuLevel === 'firstLevelMenu') {
            let defaultPageValueSetting = menuAdapter.setFirstDefaultPages(
              this.state.menuHierarchy,
              [],
              item
            );
            if (item && item.key === 'ApplicationSettings') {
              history.push('/rubus/settingsPage/Profile');
            } else if (defaultPageValueSetting.length === 1) {
              this.props.actions.setMenuSelection(defaultPageValueSetting);
              if (
                menuHierarchy &&
                menuHierarchy[defaultPageValueSetting[0]] &&
                menuHierarchy[defaultPageValueSetting[0]].path
              ) {
                history.push(menuHierarchy[defaultPageValueSetting[0]].path);
              }
            } else if (defaultPageValueSetting.length >= 2) {
              this.props.actions.setMenuSelection(defaultPageValueSetting);
              history.push(
                menuHierarchy[defaultPageValueSetting[0]].children[defaultPageValueSetting[1]].path
              );
            }
          } else if (menuLevel === 'secondLevelMenu') {
            this.setState({ secondLevelMenu: [] });
            selectedMenu =
              selectedMenu && Array.isArray(selectedMenu)
                ? selectedMenu
                : ['Modules', 'rt_dashboard'];
            const defaultSecondLevel = menuAdapter.setSecondDefaultPages(
              this.state.menuHierarchy,
              selectedMenu,
              item
            );
            let finalResult = [...defaultSecondLevel];
            this.props.actions.setMenuSelection(finalResult);
            history.push(item.path);
          }
        }}
      >
        {/* <Icon type="message" style={{ fontSize: '16px', color: '#08c' }} theme="outlined" /> */}
        <span className="nav-text">{this.translation(item.name)}</span>
      </Menu.Item>
    );
  };

  getNavMenuItems = (menusData, menuLevel) => {
    if (!menusData) {
      return [];
    }
    if (Array.isArray(menusData)) {
      return menusData
        .filter((item) => item && item.name && !item.hideInMenu)
        .map((item) => {
          return this.getSubMenuOrItem(item, menuLevel);
        })
        .filter((item) => item);
    }
  };

  getSiteMenuItems = (siteList) => {
    if (!siteList) {
      return [];
    }
    if (typeof siteList === 'object') {
      return (
        <Menu theme="dark">
          {Object.keys(siteList)
            .map((item) => {
              return this.getSiteSubMenuOrItem(siteList[item].site);
            })
            .filter((item) => item)}
        </Menu>
      );
    }
  };

  getSiteSubMenuOrItem = (item) => {
    return (
      <Menu.Item
        key={item.Id}
        className="customclass"
        onClick={() => {
          let modules = this.state.userProfile.belongsTo.sites[item._key].role;
          localStorage.set('role', modules.role);
          localStorage.set('currentSite', item._key);
          localStorage.set('landingPage', modules.landingPage);
          this.props.actions.setMenuHierarchy({
            modules: modules.Modules,
            configuration: modules.Configuration
          });
          window.location.reload();
          this.setState({ currentSite: item.Id });
        }}
      >
        <span className="nav-text"> {item.name}</span>
      </Menu.Item>
    );
  };

  setLanguage = (language) => {
    this.props.actions.getTranslationsCache(language);
  };

  getLandingPageById = (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `/api/landingPage/list?Id=${Id}&SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        if (data && Array.isArray(data) && data[0]) {
          let selectedMenu = ['Modules'];
          this.props.actions.setMenuSelection(selectedMenu);
          history.push(data[0].Path);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { currentMenu, userProfile, logoPath, firstLevelMenu, secondLevelMenu, languageList } =
      this.state;
    const {
      nativProps: { menuHierarchy }
    } = this.props;
    let siteId = localStorage.get('currentSite');
    const menu = (
      <Menu>
        {firstLevelMenu !== undefined && menuHierarchy
          ? this.getNavMenuItems(firstLevelMenu, 'firstLevelMenu')
          : null}
        <Menu.Item
          key="logOut"
          onClick={() => {
            localStorage.clear();
            this.props.history.push('/login');
            window.location.reload();
          }}
        >
          {' '}
          {
            <span>
              <LogoutOutlined />
              {this.translation(constant.LogOut)}
            </span>
          }
        </Menu.Item>
      </Menu>
    );
    const language = (
      <Menu>
        {languageList &&
          Array.isArray(languageList) &&
          languageList.map((language) => {
            return (
              <Menu.Item
                key={language}
                onClick={() => {
                  this.setLanguage(language);
                }}
              >
                {' '}
                {language}
              </Menu.Item>
            );
          })}
      </Menu>
    );
    return (
      <div className="ant-pro-global-header">
        <div className="ant-pro-global-header-logo">
          <img
            src={logoPath !== '' ? `/api/uploads/${logoPath}` : ''}
            alt="LOGO"
            style={{
              height: '60px',
              maxWidth: '100%',
              padding: '10px',
              color: 'white',
              marginTop: '18px',
              cursor: 'pointer'
            }}
            onClick={() => {
              let landingpageId =
                userProfile &&
                userProfile.belongsTo &&
                userProfile.belongsTo.sites &&
                userProfile.belongsTo.sites[1] &&
                userProfile.belongsTo.sites[1].role &&
                userProfile.belongsTo.sites[1].role.LandingPageId
                  ? userProfile.belongsTo.sites[1].role.LandingPageId
                  : '';
              this.getLandingPageById(landingpageId);
            }}
          />
        </div>
        <div className="horizMenu" style={{ flex: '1 1 0%' }}>
          <StyledMenu
            mode="horizontal"
            theme="dark"
            overflowedIndicator={<EllipsisOutlined />}
            style={{
              background: this.props.theme.main,
              left: 0
            }}
            selectedKeys={[currentMenu]}
          >
            {secondLevelMenu !== undefined && menuHierarchy
              ? this.getNavMenuItems(secondLevelMenu, 'secondLevelMenu')
              : null}
          </StyledMenu>
        </div>

        <div
          style={{
            display: 'flex',
            float: 'right',
            marginLeft: 'auto',
            overflow: 'hidden',
            color: 'white'
          }}
        >
          {userProfile &&
          userProfile.belongsTo &&
          userProfile.belongsTo.sites &&
          Object.keys(userProfile.belongsTo.sites).length > 1 ? (
            <Dropdown
              overlay={
                userProfile && userProfile.belongsTo && userProfile.belongsTo.sites
                  ? this.getSiteMenuItems(userProfile.belongsTo.sites)
                  : null
              }
              trigger={['click']}
            >
              <div>
                {siteId &&
                userProfile &&
                userProfile.belongsTo &&
                userProfile.belongsTo.sites &&
                userProfile.belongsTo.sites[siteId] &&
                userProfile.belongsTo.sites[siteId].site
                  ? userProfile.belongsTo.sites[siteId].site.name
                  : ''}
                {''}
                <CaretDownOutlined />
              </div>
            </Dropdown>
          ) : null}

          <Dropdown overlay={menu}>
            <div onClick={(e) => e.preventDefault()}>
              <span style={{ margin: '4px' }}>
                <UserOutlined
                  style={{
                    fontSize: '15px',
                    color: 'rgb(255, 255, 255)',
                    lineHeight: '72px',
                    marginTop: '13px'
                  }}
                />
                <span style={{ fontSize: '16px', margin: '4px' }}>
                  {this.translation(
                    userProfile.user && userProfile.user.FirstName ? userProfile.user.FirstName : ''
                  )}
                </span>
              </span>
            </div>
          </Dropdown>

          <Dropdown
            overlay={language}
            onClick={(language) => {
              this.setLanguage(language);
            }}
          >
            <div onClick={(e) => e.preventDefault()}>
              <GlobalOutlined
                style={{
                  fontSize: '15px',
                  color: 'rgb(255, 255, 255)',
                  lineHeight: '72px',
                  margin: '13px 10px 1px 18px'
                }}
              />
            </div>
          </Dropdown>

          <FullscreenOutlined
            title={'fullscreen'}
            onClick={() => {
              document.fullscreenEnabled =
                document.fullscreenEnabled ||
                document.mozFullScreenEnabled ||
                document.documentElement.webkitRequestFullScreen;

              if (document.fullscreenEnabled) {
                this.props.fullScreen(document.documentElement);
              } else {
                alert('full screen not supported');
              }
            }}
            style={{
              fontSize: '15px',
              color: 'rgb(255, 255, 255)',
              lineHeight: '72px',
              margin: '14px 23px 5px 13px'
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  selectedTheme: makeSelectTheme()
});
export default connect(mapStateToProps)(withTheme(SlideMenuComponent));
