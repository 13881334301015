import React from 'react';
import axios from 'axios';
import { Select, Form, Button, Tree, message } from 'antd';
import localStorage from '../../../utils/localStorage';
import { constant } from '../../Settings/Constants';
import { StyledDashboard } from './style';

const { Option } = Select;
const { TreeNode } = Tree;

class RoleBaseSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      datasource: [],
      editObj: {},
      treeData: [],
      selectedHierarchyName: '',
      selectedHierarchyId: '',
      checkedKeys: [],
      roleList: []
    };
  }

  componentDidMount() {
    this._getHierarchyList();
    this._getRolesList();
  }
  _getRolesList = () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `/api/role/get?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ roleList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  renderTreeNodes = (data) =>
    data &&
    Array.isArray(data) &&
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.Name} key={item.Id} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.Id} title={item.Name} {...item} />;
    });

  _getHierarchyList = () => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let headers = {
      method: 'GET',
      url: `/api/hierarchymodule/mainHierarchyList?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({ datasource: data });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  onRoleBaseHierarchy = (value) => {
    const { datasource } = this.state;
    let selectedHierarchy = datasource.filter((item) => {
      return item.Id === Number(value);
    });
    if (selectedHierarchy && selectedHierarchy[0]) {
      this.setState({
        selectedHierarchyName: selectedHierarchy[0].Name,
        selectedHierarchyId: selectedHierarchy[0].Id
      });
      this._getHierarchyLevels(selectedHierarchy[0].Id);
    }
  };

  _getHierarchyLevels = (id) => {
    let payload = {
      HierarchyId: id
    };
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/getHierarchyLevels?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({ treeData: data });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  onSelect = () => {};
  onCheck = (e) => {
    this.setState({ checkedKeys: e });
  };
  submitHierarchy = () => {
    const { checkedKeys, roleId } = this.state;
    let payload = {
      id: checkedKeys,
      roleId: roleId
    };
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let headers = {
      method: 'POST',
      url: `/api/hierarchymodule/linkRole?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(headers)
      .then(() => {
        message.success('updated successfully');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let { datasource, selectedHierarchyName, treeData, checkedKeys, roleList } = this.state;
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Select
            style={{ width: 250 }}
            placeholder={this.translation(constant.Select_Hierarchy)}
            onChange={this.onRoleBaseHierarchy}
          >
            {datasource.map((Hierarchy) => (
              <Option key={Hierarchy.Id}>{Hierarchy.HierarchyName}</Option>
            ))}
          </Select>
          <Select
            style={{ width: 250 }}
            placeholder={this.translation(constant.Select_a_Role)}
            onChange={(value) => this.setState({ roleId: value })}
          >
            {roleList.map((role) => (
              <Option key={role.Id}>{role.Name}</Option>
            ))}
          </Select>
          <Button type={'primary'} onClick={() => this.submitHierarchy()}>
            {this.translation(constant.Submit)}
          </Button>
        </div>

        <Form.Item
          style={{ width: 250, marginLeft: '50px', marginTop: '10px' }}
          label={selectedHierarchyName}
        >
          <Tree
            defaultExpandAll={true}
            checkable
            onCheck={(e) => this.onCheck(e)}
            checkedKeys={checkedKeys}
          >
            treeData && Array.isArray(treeData) ? {this.renderTreeNodes(treeData)} : null
          </Tree>
        </Form.Item>
      </StyledDashboard>
    );
  }
}

export default RoleBaseSelection;
