import React, { Component } from 'react';
import axios from 'axios';
import { message, Tree } from 'antd';

import localStorage from '../../../../../utils/localStorage';
import { DownloadOutlined } from '@ant-design/icons';

const { TreeNode } = Tree;

class HierarchyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      value: [],
      hierarchy: this.getMenuHierarchyStructure(),
      deviceList: [],
      autoExpandParent: true.valueOf,
      expandedKeys: []
    };
  }

  componentDidMount() {
    this.getDeviceList();
  }

  getDeviceList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'GET',
      url: `/api/device/list?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((res) => {
        let deviceList = res.data;
        this.setState({ deviceList });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  renameProperty = (obj, oldName1, newName1, oldName2, newName2) => {
    if (obj && obj.children) {
      let children = obj.children.map((objChildren) => {
        return this.renameProperty(objChildren, oldName1, newName1, oldName2, newName2);
      });
      obj['children'] = children;
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    } else {
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    }
  };

  getMenuHierarchyStructure = () => {
    const userData = JSON.parse(localStorage.get('modules'));
    if (userData && userData.hierarchy) {
      let final = userData.hierarchy.map((userHierarchy) => {
        return this.renameProperty(userHierarchy, 'label', 'title', 'value', 'key');
      });
      return final;
    }
  };

  getData = (selectedKey) => {
    this.onSelect([selectedKey.key]);
  };

  renderTreeNodes = (data) => {
    return data.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <TreeNode
            title={`${this.setTranslation(item.title)}${
              item.ShortDescription ? `-${this.setTranslation(item.ShortDescription)}` : ''
            }`}
            key={item.key}
            dataRef={item}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.key}
          title={
            <div
              style={{ fontWeight: item.Type === 'Device' ? 'bold' : 'none' }}
              onClick={() => this.getData(item)}
            >
              {`${this.setTranslation(item.title)}${
                item.ShortDescription ? `-${this.setTranslation(item.ShortDescription)}` : ''
              }`}
            </div>
          }
          dataRef={item}
        />
      );
    });
  };

  setTranslation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onSelect = (selectedKeys) => {
    let { deviceList } = this.state;
    let deviceObject = deviceList.filter((device) => {
      return device.AliasCode === selectedKeys[0];
    });
    if (deviceObject && deviceObject.length > 0) {
      this.props.handleDropdownChange(selectedKeys, deviceList);
    } else {
      message.error('Please select valid sensor');
    }
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  render() {
    let { hierarchy } = this.state;
    let { value } = this.props;
    return (
      <div style={{ height: '70%', marginTop: '10px' }}>
        <Tree
          showLine
          defaultExpandAll={true}
          switcherIcon={<DownloadOutlined />}
          // onSelect={this.onSelect}
          onExpand={this.onExpand}
          // expandedKeys={this.state.expandedKeys}
          // autoExpandParent={this.state.autoExpandParent}
          selectedKeys={value ? [value] : ''}
        >
          {this.renderTreeNodes(hierarchy)}
        </Tree>
      </div>
    );
  }
}

export default HierarchyModal;
