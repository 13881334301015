import axios from 'axios';
import { message } from 'antd';
import localStorage from '../../../utils/localStorage';

export async function getDatasourceData() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(`/api/logbook/get?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  if (data && Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function _updateSubmitBatch(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let siteId = localStorage.get('currentSite');
  let data = await fetch(`/api/logbook/logbooksubmit?SiteId=${siteId}  `, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      ...payload,
      actualTimeSelection: {
        startDate: '2020-10-20 00:00:00',
        endDate: '2020-10-20 16:00:00'
      },
      status: 'submitted',
      logId: '51'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function deleteFileUpload(fileName) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/master/deletefile `, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      fileName
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function getAdhocTableList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/logbook/tableslist `, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function getById(payload) {
  let { LogBookId, roleId, userId } = payload;
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(
    `/api/logbook/get?SiteId=${siteId}&LogBookId=${LogBookId}&roleId=${roleId}&userId=${userId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      }
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.message) {
        message.warning(response.message);
      } else if (Array.isArray(response)) {
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function getELogAdhocJSONData(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(`/api/logbook/editlist?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.message) {
        message.warning(response.message);
      } else if (response) {
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}
export async function _getEquipmentList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/logbook/equipmentlist`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({ ProcessSelection: 'Milling' })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  return data;
}

export async function _updateELogEdit(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(`/api/logbook/save?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function updateStatus(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/master/updatestatus`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function updateBatchStatus(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let siteId = localStorage.get('currentSite');
  let data = await fetch(`/api/logbook/submit?SiteId=${siteId} `, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  return data;
}

export async function _getRolesBySite() {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  let data = await fetch(`/api/role/get?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getRecurrencePatternData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(`/api/logbook/recurrence?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return [];
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getFormTypeData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(`/api/logbook/dropdown?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      tableName: 'master."Master_FormTypes"',
      columnName: 'Type'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getLocationData() {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/asset/get?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getELogTypeData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');

  let data = await fetch(`/api/logbook/dropdown?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      tableName: 'master."Master_FormTypes"',
      columnName: 'Type'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getTableMasters() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;

  let data = await fetch(`/api/logbook/tableslist`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getList(editData) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `/api/logbook/list?SiteId=${siteId}&Id=${editData ? editData.Id : ''}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
}
