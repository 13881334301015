import React from 'react';
import moment from 'moment';
import axios from 'axios';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
  message,
  Upload,
  Button,
  Drawer,
  Select,
  Row,
  Col,
  Table,
  Form,
  Modal,
  Checkbox
} from 'antd';

import localStorage from '../../../utils/localStorage';
import { getTranslationCache } from '../../../selectors/language';
import themeSettings from './themeSettings.json';
import { StyledApplication, StyledSubHeading } from './style';

import './styles.css';
moment.locale('English');
let { Option } = Select;
const FormItem = Form.Item;

class ConfigurationUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      current: 0,
      visible: false,
      FileNames: {},
      datasource: [],
      assetDatasource: [],
      parameterDataSource: [],
      filenamevisible: false,
      Type: 'Asset',
      Limit: 1,
      assetLimit: 1,
      paramLimit: 1,
      visibleEdit: false,
      visibleEditForParameter: false,
      downloadPayload: {},
      deviceCache: '',
      parameterChacheList: [],
      downloadFilter: {
        module: '',
        moduleList: [],
        deviceType: '',
        deviceTypeList: [],
        deviceCode: '',
        deviceCodeList: []
      },
      AssetFileName: '',
      verifyStatus: false,
      verifyAssetStatus: false,
      verifyParameterstatus: false,
      parameterVisible: false,
      assetVisible: false,
      validatedFile: '',
      FilesName: '',
      paramsFileName: '',
      deviceFileName: '',
      downloadParameter: false
    };
  }

  assetRef = React.createRef();
  componentDidMount() {
    this.getDeviceCache();
  }
  getDeviceCache = () => {
    let siteId = localStorage.get('currentSite');
    siteId = siteId ? siteId : 1;
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const deviceListData = {
      method: 'POST',
      url: `/api/device/cache?SiteId=${siteId}`,
      headers: {
        Authorization: Authorization
      },
      data: {
        SiteId: siteId,
        Module: ''
      }
    };

    axios(deviceListData)
      .then((response) => {
        this.setState({
          deviceCache: response.data
        });
        if (response && response.data) {
          const resp = Object.keys(response.data).map((key) => response.data[key]);
          const module = resp.reduce((r, a) => {
            if (!r.find((item) => item.Module === a.Module)) {
              r.push(a);
            }
            return r;
          }, []);
          this.setState({
            downloadFilter: {
              ...this.state.downloadFilter,
              moduleList: module
            }
          });
        }
      })
      .catch(function () {});
  };

  handleChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  onChange = (current) => {
    this.setState({ current });
    if (current === 0) {
      this.setState({
        Type: 'Asset'
      });
    } else {
      this.setState({ Type: 'Device' });
    }
  };

  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isCSV = file.type === 'text/csv';
    if (!isCSV) {
      message.error('You can only upload CSV file!');
    }
    if (!isLt2M) {
      message.error('CSV file must be smaller than 10MB!');
    }
    return isLt2M && isCSV;
  };

  handleButton = (Type) => {
    const { deviceCache } = this.state;
    if (Type === 'Parameter') {
      const parameterChacheList = Object.keys(deviceCache).map((itemKey) => {
        return deviceCache[itemKey];
      });
      this.setState({ parameterVisible: true, parameterChacheList: parameterChacheList });
      this.GetLastParameterRecord();
    } else if (Type === 'asset') {
      this.setState({ assetVisible: true });
      this.GetLastAssetRecord();
    } else if (Type === 'device') {
      this.setState({
        visible: true
      });
      this.handleOk();
    }
  };

  handleCancel = (Type) => {
    if (Type === 'device') {
      this.setState({
        visible: false,
        datasource: []
      });
    } else if (Type === 'asset') {
      this.setState({
        assetVisible: false,
        assetDatasource: []
      });
    } else if (Type === 'parameter') {
      this.setState({
        parameterVisible: false,
        parameterDataSource: []
      });
    }
  };
  GetLastParameterRecord = (e) => {
    this.setState({
      parameterDataSource: []
    });
    const accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    const parameterObject = {
      method: 'POST',
      url: `/api/parameter/getLastConfigRecordsByType?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        Type: 'Parameter',
        Limit: 1
      }
    };
    return axios(parameterObject)
      .then((response) => {
        this.setState({ parameterDataSource: response.data, paramLimit: e });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  GetLastAssetRecord = (e) => {
    this.setState({
      assetDatasource: []
    });
    const accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    const assetObject = {
      method: 'POST',
      url: `/api/asset/getLastConfigRecordsByType?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        Type: 'Asset',
        Limit: 1
      }
    };
    return axios(assetObject)
      .then((response) => {
        this.setState({
          assetDatasource: response.data,
          assetLimit: e,
          FilesName: response.data[0].FileName
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleOk = (e) => {
    this.setState({
      datasource: []
    });
    const accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    const deviceTypeObject = {
      method: 'POST',
      url: `/api/device/getLastConfigRecordsByType?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        Type: 'Device',
        Limit: e || 1
      }
    };
    return axios(deviceTypeObject)
      .then((response) => {
        this.setState({ datasource: response.data, Limit: e });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  generateVersion = (Type) => {
    const accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    if (Type === 'device') {
      const deviceTypeObject = {
        method: 'GET',
        url: `/api/download/generateVersionsDevices?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
      return axios(deviceTypeObject)
        .then(() => {
          this.handleOk(this.state.Limit || 1);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (Type === 'asset') {
      const assetObject = {
        method: 'GET',
        url: `/api/download/generateVersionsAssets?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
      return axios(assetObject)
        .then(() => {
          this.GetLastAssetRecord(this.state.assetLimit || 1);
          this.generateAssetsFile();
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (Type === 'parameter') {
      const parameterObject = {
        method: 'GET',
        url: `/api/download/generateVersionsParams?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
      return axios(parameterObject)
        .then(() => {
          this.GetLastParameterRecord(this.state.paramLimit || 1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  generateAssetsFile = () => {
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const siteId = localStorage.get('currentSite');
    let data = {
      url: `/api/download/generateExcelAssets?SiteId=${siteId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      }
    };
    return axios(data)
      .then((response) => {
        this.setState({ AssetFileName: response.data.file });
      })
      .catch(() => {});
  };

  onCancelModal = (type) => {
    const { visibleEditForParameter, visibleEdit } = this.state;
    if (type === 'parameter') {
      this.setState({
        visibleEditForParameter: !visibleEditForParameter
      });
    } else if (type === 'device') {
      this.setState({ visibleEdit: !visibleEdit });
    }
  };
  generateParameterFile = (selectedDeviceCode) => {
    let { downloadParameter } = this.state;
    let conditionList = [];
    if (downloadParameter === true) {
      conditionList = [];
      this.setState({ downloadParameter: true });
    } else {
      conditionList = [
        {
          key: 'Device',
          value: selectedDeviceCode
        }
      ];
    }
    const accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    const downloadFilterObject = {
      method: 'POST',
      url: `/api/download/generateExcelParams?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { conditionList }
    };
    return axios(downloadFilterObject)
      .then((response) => {
        this.setState({
          paramsFileName: response.data.file
        });
        window.open(`https://sgt.bmecomosolution.com/api/configuration/${response.data.file}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  generateFile = (value) => {
    let { downloadFilter, downloadAll } = this.state;
    let conditionList = [];
    if (downloadAll === true) {
      conditionList = [];
      this.setState({ downloadAll: true });
    } else {
      conditionList = [
        {
          key: 'DeviceType',
          value: downloadFilter.deviceType
        },
        {
          key: 'DeviceCode',
          value: value
        },
        {
          key: 'Module',
          value: downloadFilter.module
        }
      ];
    }
    const accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    const downloadFilterObject = {
      method: 'POST',
      url: `/api/download/generateExcelDevices?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { conditionList }
    };
    return axios(downloadFilterObject)
      .then((response) => {
        this.setState({ deviceFileName: response.data.file });
        window.open(`https://sgt.bmecomosolution.com/api/configuration/${response.data.file}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  parameterDownloadOnchange = (e) => {
    this.generateParameterFile(e);
  };
  onChangeDownload = (value, dropdown) => {
    let { deviceCache } = this.state;
    if (dropdown === 'Module') {
      const res = Object.keys(deviceCache)
        .map((key) => deviceCache[key])
        .filter((device) => device.Module === value);
      const deviceRes = res.reduce((r, a) => {
        if (!r.find((item) => item.DeviceType === a.DeviceType)) {
          r.push(a);
        }
        return r;
      }, []);
      this.setState({
        downloadFilter: {
          ...this.state.downloadFilter,
          module: value,
          deviceTypeList: deviceRes,
          deviceType: '',
          deviceCode: '',
          deviceCodeList: []
        }
      });
    }
    if (dropdown === 'DeviceType') {
      const res = Object.keys(deviceCache)
        .map((key) => deviceCache[key])
        .filter((device) => device.DeviceType === value);
      this.setState({
        downloadFilter: {
          ...this.state.downloadFilter,
          deviceType: value,
          deviceCode: '',
          deviceCodeList: res
        }
      });
    }
    if (dropdown === 'DeviceCode') {
      this.setState({
        downloadFilter: {
          ...this.state.downloadFilter,
          deviceCode: value
        }
      });
      this.generateFile(value);
    }

    // let { downloadPayload } = this.state;
    // downloadPayload = {
    //   ...downloadPayload,
    //   [dropdown]: value,
    // };
    // this.setState({ downloadPayload });
  };
  onChangeAll = (e, Type) => {
    if (Type === 'device') {
      this.setState({ downloadAll: e.target.checked });
    } else if (Type === 'parameter') {
      this.setState({ downloadParameter: e.target.checked });
      this.generateParameterFile();
    }
  };
  render() {
    let {
      visibleEdit,
      verifyStatus,
      assetVisible,
      downloadAll,
      verifyParameterstatus,
      AssetFileName,
      downloadFilter,
      visibleEditForParameter,
      verifyAssetStatus,
      datasource,
      assetDatasource,
      parameterDataSource,
      parameterChacheList
    } = this.state;
    const columns = [
      {
        title: 'FileName',
        dataIndex: 'FileName',
        key: 'FileName'
      },
      {
        title: 'Download',
        dataIndex: 'Download',
        key: 'Download',
        render: (text, record) => {
          return {
            children: (
              <div>
                <Modal
                  title="Download Filter"
                  width={550}
                  className="onOkCancelDevice"
                  footer={null}
                  // okText={"Download"}
                  // onOk={() => this.generateFile()}
                  // onCancel={() => this.setState({ visibleEdit: !visibleEdit })}
                  visible={visibleEdit}
                >
                  <FormItem>
                    <Checkbox onChange={(e) => this.onChangeAll(e, 'device')}>Select All</Checkbox>
                  </FormItem>
                  <Form disabled={downloadAll === true ? true : false}>
                    <FormItem label="Module">
                      <Select
                        placeholder="Select Module"
                        onChange={(e) => this.onChangeDownload(e, 'Module')}
                      >
                        {this.state.downloadFilter.moduleList
                          ? this.state.downloadFilter.moduleList.map((m, index) => (
                              <Option key={index} value={m.Module}>
                                {m.Module}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </FormItem>
                    <FormItem label="Equipment Type">
                      <Select
                        placeholder="Select EquipmentType"
                        onChange={(e) => this.onChangeDownload(e, 'DeviceType')}
                      >
                        {this.state.downloadFilter.deviceTypeList
                          ? this.state.downloadFilter.deviceTypeList.map((m, i) => (
                              <Option key={i} value={m.DeviceType}>
                                {m.DeviceType}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </FormItem>
                    <FormItem label="Equipment Code">
                      <Select
                        placeholder="Select Equipment"
                        onChange={(e) => this.onChangeDownload(e, 'DeviceCode')}
                      >
                        {this.state.downloadFilter.deviceCodeList
                          ? this.state.downloadFilter.deviceCodeList.map((m, index) => (
                              <Option key={index} value={m.DeviceCode}>
                                {m.DeviceCode}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </FormItem>
                    <div style={{ float: 'right' }}>
                      <Button
                        className="button"
                        style={{ marginRight: '20px' }}
                        type="primary"
                        onClick={() => this.onCancelModal('device')}
                      >
                        cancel
                      </Button>
                      <Button
                        className="button"
                        type="primary"
                        onClick={() => this.onCancelModal('device')}
                      >
                        <a
                          href={`https://sgt.bmecomosolution.com/api/downloads/${this.state.deviceFileName}`}
                        >
                          Download
                        </a>
                      </Button>
                    </div>
                  </Form>
                </Modal>
                <a href={`https://sgt.bmecomosolution.com/api/versions/${record.FileName}.csv`}>
                  <DownloadOutlined
                    style={{ marginLeft: '20px', color: '#1890ff' }}
                    type="download"
                  />
                </a>
              </div>
            )
          };
        }
      }
    ];

    const parameterColumns = [
      {
        title: 'FileName',
        dataIndex: 'FileName',
        key: 'FileName'
      },
      {
        title: 'Download',
        dataIndex: 'Download',
        key: 'Download',
        render: (text, record) => {
          return {
            children: (
              <div>
                <Modal
                  title="Download Filter"
                  width={550}
                  className="onOkCancel"
                  footer={null}
                  // okText={"Download"}
                  // onOk={() => this.generateParameterFile()}
                  // onCancel={() =>
                  //   this.setState({
                  //     visibleEditForParameter: !visibleEditForParameter,
                  //   })
                  // }
                  visible={visibleEditForParameter}
                >
                  <FormItem>
                    <Checkbox onChange={(e) => this.onChangeAll(e, 'parameter')}>
                      Select All
                    </Checkbox>
                  </FormItem>
                  <Form disabled={downloadFilter === true ? true : false}>
                    <FormItem label="Equipment Code">
                      <Select
                        placeholder="Select Equipment"
                        onChange={(e) => this.parameterDownloadOnchange(e, 'DeviceCode')}
                      >
                        {parameterChacheList
                          ? parameterChacheList.map((m, index) => (
                              <Option key={index} value={m.DeviceCode}>
                                {m.DeviceCode}
                              </Option>
                            ))
                          : null}
                      </Select>
                    </FormItem>
                    <div style={{ float: 'right' }}>
                      <Button
                        className="button"
                        style={{ marginRight: '20px' }}
                        type="primary"
                        onClick={() => this.onCancelModal('parameter')}
                      >
                        cancel
                      </Button>
                      <Button
                        className="button"
                        type="primary"
                        onClick={() => this.onCancelModal('parameter')}
                      >
                        <a
                          href={`https://sgt.bmecomosolution.com/api/downloads/${this.state.paramsFileName}`}
                        >
                          Download
                        </a>
                      </Button>
                    </div>
                  </Form>
                </Modal>
                <a href={`https://sgt.bmecomosolution.com/api/versions/${record.FileName}.csv`}>
                  <DownloadOutlined
                    style={{ marginLeft: '20px', color: '#1890ff' }}
                    type="download"
                  />
                </a>
              </div>
            )
          };
        }
      }
    ];

    const assetColumns = [
      {
        title: 'FileName',
        dataIndex: 'FileName',
        key: 'FileName'
      },
      {
        title: 'Download',
        dataIndex: 'Download',
        key: 'Download',
        render: (text, record) => {
          return {
            children: (
              <div>
                <a
                  href={`https://sgt.bmecomosolution.com/api/versions/${record.FileName}.csv?SiteId=${siteId}`}
                >
                  <DownloadOutlined
                    style={{ marginLeft: '20px', color: '#1890ff' }}
                    type="download"
                  />
                </a>
              </div>
            )
          };
        }
      }
    ];

    let siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const props = {
      name: 'file',
      action: `/api/device/deviceValidation?SiteId=${siteId}`,
      accept: 'text/csv',
      headers: {
        Authorization: Authorization
      },
      onChange: (info) => {
        if (info.file.status === 'done') {
          message.success('File Validated Successfully');
          this.setState({ verifyStatus: true, validatedFile: info.file.name });
        } else if (info.file.status === 'error') {
          message.error('Validation Failed');
        }
      }
    };
    const assetprops = {
      name: 'file',
      action: `/api/asset/assetValidation?SiteId=${siteId}`,
      accept: 'text/csv',
      multiple: 'false',
      headers: {
        Authorization: Authorization
      },
      onChange: (info) => {
        if (info.file.status === 'done') {
          message.success('File Validated Successfully');
          this.setState({
            verifyAssetStatus: true,
            validatedFile: info.file.name
          });
        } else if (info.file.status === 'error') {
          message.error('Validation Failed');
        }
      }
    };
    const parameterProps = {
      name: 'file',
      action: `/api/parameter/paramValidation?SiteId=${siteId}`,
      accept: 'text/csv',
      headers: {
        Authorization: Authorization
      },
      onChange: (info) => {
        if (info.file.status === 'done') {
          message.success('File Validated Successfully');
          this.setState({
            verifyParameterstatus: true,
            validatedFile: info.file.name
          });
        } else if (info.file.status === 'error') {
          message.error('Validation Failed');
        }
      }
    };
    return (
      <StyledApplication theme={themeSettings} style={{ minHeight: window.innerHeight - 300 }}>
        <Form labelCol={{ span: 12 }} wrapperCol={{ span: 14 }} layout="horizontal">
          <div style={{ marginBottom: '15px', border: '1px dashed' }}>
            <StyledSubHeading>Asset Bulk Upload/Download</StyledSubHeading>
            <Form.Item label="Download Sample Template">
              <Button>
                <DownloadOutlined />
                <a
                  style={{ color: '#262222', marginLeft: '7px' }}
                  href={`https://sgt.bmecomosolution.com/api/sample/asset.csv?SiteId=${siteId}`}
                >
                  Click to Download
                </a>
              </Button>
            </Form.Item>
            <Form.Item label="Download Previous Versions">
              <Button onClick={() => this.handleButton('asset')}>
                <DownloadOutlined />
                Click to Download
              </Button>
            </Form.Item>
            <Form.Item label="Validate Configuration Files before Uploading">
              <Upload {...assetprops}>
                <Button icon={<UploadOutlined />}>Upload a file</Button>
              </Upload>
            </Form.Item>
            <Form.Item label="Upload Configuration File">
              <Upload
                name="file"
                action={`/api/asset/bulk?SiteId=${siteId}`}
                accept="text/csv"
                multiple="false"
                disabled={verifyAssetStatus === false ? true : false}
                headers={{
                  Authorization: Authorization
                }}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                {verifyAssetStatus === true ? (
                  <Button icon={<UploadOutlined />}>Upload a file</Button>
                ) : (
                  <div>Download will enable upon verfiying file in above option</div>
                )}
              </Upload>
            </Form.Item>
            <Drawer
              title={'Download options'}
              Previous
              Downloads
              width={550}
              closable
              visible={this.state.parameterVisible}
              onClose={() => this.handleCancel('parameter')}
            >
              <Form>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row" span={18}>
                    <FormItem label="Generate New Version">
                      <Button type="primary" onClick={() => this.generateVersion('parameter')}>
                        Click to Generate
                      </Button>
                    </FormItem>
                    <FormItem label="Download active configuration">
                      <Button
                        type="primary"
                        onClick={() => this.setState({ visibleEditForParameter: true })}
                      >
                        Click to Download
                      </Button>
                    </FormItem>
                    <Form.Item label="Select to get last 5 generated versions">
                      <Select
                        value={this.state.paramLimit || 1}
                        onChange={this.GetLastParameterRecord}
                      >
                        <Option value="1">Last Version</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                      </Select>
                    </Form.Item>
                    <FormItem>
                      <Table columns={parameterColumns} dataSource={parameterDataSource}></Table>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Drawer>
            <Drawer
              title={'Download options'}
              Previous
              Downloads
              width={550}
              closable
              visible={assetVisible}
              onClose={() => this.handleCancel('asset')}
            >
              <Form>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row" span={18}>
                    <FormItem label="Generate New Version">
                      <Button type="primary" onClick={() => this.generateVersion('asset')}>
                        Click to Generate
                      </Button>
                    </FormItem>
                    <FormItem label="Download active configuration">
                      <Button type="primary">
                        <a
                          href={`https://sgt.bmecomosolution.com/api/downloads/${AssetFileName}?SiteId=${siteId}`}
                        >
                          Click to Download
                        </a>
                      </Button>
                    </FormItem>
                    <Form.Item label="Select to get last 5 generated versions">
                      <Select value={this.state.assetLimit || 1} onChange={this.GetLastAssetRecord}>
                        <Option value="1">Last Version</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                      </Select>
                    </Form.Item>
                    <FormItem>
                      <Table columns={assetColumns} dataSource={assetDatasource}></Table>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Drawer>
            <Drawer
              title={'Download options'}
              Previous
              Downloads
              width={550}
              closable
              visible={this.state.visible}
              onClose={() => this.handleCancel('device')}
            >
              <Form>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row" span={18}>
                    <FormItem label="Generate New Version">
                      <Button type="primary" onClick={() => this.generateVersion('device')}>
                        Click to Generate
                      </Button>
                    </FormItem>
                    <FormItem label="Download active configuration ">
                      <Button type="primary" onClick={() => this.setState({ visibleEdit: true })}>
                        Click to Download
                      </Button>
                    </FormItem>
                    <Form.Item label="Select to get last 5 generated versions">
                      <Select value={this.state.Limit || 1} onChange={this.handleOk}>
                        <Option value="1">Last Version</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                      </Select>
                    </Form.Item>
                    <FormItem>
                      <Table columns={columns} dataSource={datasource}></Table>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Drawer>
          </div>

          <div style={{ marginBottom: '15px', border: '1px dashed' }}>
            <StyledSubHeading>Device Bulk Upload/Download</StyledSubHeading>

            <Form.Item label="Download Sample Template">
              <Button>
                <DownloadOutlined />
                <a
                  style={{ color: '#262222', marginLeft: '7px' }}
                  href={`https://sgt.bmecomosolution.com/api/sample/device.csv?SiteId=${siteId}`}
                  // href={`https://sgt.bmecomosolution.com/api/sample/device.csv`}
                >
                  Click to Download
                </a>
              </Button>
            </Form.Item>
            <Form.Item label="Download Previous Versions">
              <Button onClick={() => this.handleButton('device')}>
                <DownloadOutlined />
                Click to Download
              </Button>
            </Form.Item>
            <Form.Item label="Validate Configuration Files before Uploading">
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Upload a file</Button>
              </Upload>
            </Form.Item>
            <Form.Item label="Upload Configuration File">
              <Upload
                name="file"
                action={`/api/device/bulk?SiteId=${siteId}`}
                accept="text/csv"
                multiple="false"
                disabled={verifyStatus === false ? true : false}
                headers={{
                  Authorization: Authorization
                }}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                {verifyStatus === true ? (
                  <Button icon={<UploadOutlined />}>Upload a file</Button>
                ) : (
                  <div>Download will enable upon verfiying file in above option</div>
                )}
              </Upload>
            </Form.Item>
          </div>
          <div style={{ marginBottom: '15px', border: '1px dashed' }}>
            <StyledSubHeading>Parameter Bulk Upload/Download</StyledSubHeading>

            <Form.Item label="Download Sample Template">
              <Button>
                <DownloadOutlined />
                <a
                  style={{ color: '#262222', marginLeft: '7px' }}
                  href={`https://sgt.bmecomosolution.com/api/sample/parameter.csv?SiteId=${siteId}`}
                >
                  Click to Download
                </a>
              </Button>
            </Form.Item>
            <Form.Item label="Download Previous Versions">
              <Button onClick={() => this.handleButton('Parameter')}>
                <DownloadOutlined />
                Click to Download
              </Button>
            </Form.Item>
            <Form.Item label="Validate Configuration Files before Uploading">
              <Upload {...parameterProps}>
                <Button icon={<UploadOutlined />}>Upload a file</Button>
              </Upload>
            </Form.Item>
            <Form.Item label="Upload Configuration File">
              <Upload
                name="file"
                action={`/api/parameter/bulk?SiteId=${siteId}`}
                accept="text/csv"
                multiple="false"
                disabled={verifyParameterstatus === false ? true : false}
                headers={{
                  Authorization: Authorization
                }}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                {verifyParameterstatus === true ? (
                  <Button icon={<UploadOutlined />}>Upload a file</Button>
                ) : (
                  <div>Download will enable upon verfiying file in above option</div>
                )}
              </Upload>
            </Form.Item>
          </div>
        </Form>
      </StyledApplication>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ConfigurationUpload);
