import React, { Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tabs, Breadcrumb } from 'antd';

import { HomeOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';

import hierarchy from './hierarchy.json';
import DeviceComponent from './DeviceComponent';
import { getCurrentDevice, getDeviceBreadcrumbList } from '../../../selectors/device';
import { setDeviceBreadcrumbs, currentDevice } from '../../../modules/device/device.duck';
import { getCurrentAsset } from '../../../selectors/asset';

import { HomeSubMenu } from './style';
const { TabPane } = Tabs;

class DeviceHierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hierarchy: hierarchy || {},
      nextDeviceTypeList: [],
      parentDeviceId: '',
      deviceBreadcrumbList: []
    };
  }

  componentDidMount() {
    this.generateObject();
  }

  componentDidUpdate = (prevprops) => {
    let { currentDevice, currentAsset, deviceBreadcrumbList, data } = this.props;
    if (
      currentDevice !== prevprops.currentDevice ||
      currentAsset !== prevprops.currentAsset ||
      data !== prevprops.data ||
      deviceBreadcrumbList !== prevprops.deviceBreadcrumbList
    ) {
      this.generateObject();
    }
  };

  generateObject = async () => {
    await this._prepareView();
  };

  _prepareView = () => {
    let { hierarchy } = this.state;
    let { currentDevice, data } = this.props;
    let parentDeviceId =
      currentDevice && currentDevice.DeviceCode ? currentDevice.DeviceCode : null;

    let currentDeviceTypeList = [];
    let nextDeviceTypeList = [];
    if (_.isEmpty(currentDevice) && data) {
      nextDeviceTypeList =
        hierarchy &&
        Object.keys(hierarchy.device).filter((deviceTypeKeys) => {
          currentDeviceTypeList = hierarchy.device[deviceTypeKeys].parent;
          return currentDeviceTypeList.includes(data.AssetType);
        });
    } else {
      nextDeviceTypeList =
        hierarchy &&
        Object.keys(hierarchy.device).filter((deviceTypeKeys) => {
          currentDeviceTypeList = hierarchy.device[deviceTypeKeys].parent;
          if (typeof currentDeviceTypeList !== 'string' && currentDeviceTypeList !== null) {
            return currentDeviceTypeList.includes(currentDevice.EquipmentType);
          } else {
            return currentDeviceTypeList === currentDevice.EquipmentType;
          }
        });
    }
    this.setState({
      nextDeviceTypeList,
      parentDeviceId
    });
  };

  changeOnBreadCrumb = (device) => {
    let { deviceBreadcrumbList } = this.props;
    let checkCondition = false;
    let newBreadcrumbList =
      deviceBreadcrumbList &&
      Array.isArray(deviceBreadcrumbList) &&
      deviceBreadcrumbList.filter((breadcrumb) => {
        if (JSON.stringify(breadcrumb) === JSON.stringify(device)) {
          checkCondition = true;
          return true;
        }
        return !checkCondition;
      });
    if (device && (typeof device === 'string' || Object.keys(device).length === 0)) {
      newBreadcrumbList = [];
    }
    this.props.actions.setDeviceBreadcrumbs(newBreadcrumbList);
    this.props.actions.currentDevice(device);
  };

  render() {
    let { nextDeviceTypeList, parentDeviceId } = this.state;
    let { data, deviceBreadcrumbList } = this.props;

    const breadcrumbItems =
      deviceBreadcrumbList &&
      Array.isArray(deviceBreadcrumbList) &&
      deviceBreadcrumbList.map((deviceBreadcrumb) => {
        return (
          <Breadcrumb.Item key={{ deviceBreadcrumb }}>
            <span
              style={{ color: '#1890ff', cursor: 'pointer' }}
              onClick={(e) => {
                this.changeOnBreadCrumb(deviceBreadcrumb, e);
              }}
            >
              {deviceBreadcrumb.Name}({deviceBreadcrumb.DeviceCode})
            </span>
          </Breadcrumb.Item>
        );
      });
    const breadcrumbItemsParent = [
      <Breadcrumb.Item key="home">
        <HomeOutlined
          style={{ color: '#1890ff', cursor: 'pointer' }}
          onClick={() => {
            this.changeOnBreadCrumb({});
          }}
        ></HomeOutlined>
      </Breadcrumb.Item>
    ].concat(breadcrumbItems);

    return (
      <Fragment>
        <div style={{ margin: '10px 4px' }}>
          <Breadcrumb>
            {breadcrumbItemsParent && breadcrumbItemsParent.length > 1
              ? breadcrumbItemsParent
              : null}
          </Breadcrumb>
        </div>

        <Tabs tabPosition="top" type="card" style={{ margin: '30px', color: 'white' }}>
          {nextDeviceTypeList &&
            Array.isArray(nextDeviceTypeList) &&
            nextDeviceTypeList.map((DeviceType) => {
              return (
                <TabPane
                  tab={
                    <HomeSubMenu>
                      {DeviceType ? _.startCase(_.toLower(DeviceType)) : _.startCase(_.toLower(''))}
                    </HomeSubMenu>
                  }
                  key={DeviceType}
                >
                  <DeviceComponent
                    data={{
                      ...data,
                      parentDeviceId,
                      parentDeviceType: DeviceType
                    }}
                    currentDevice
                  />
                </TabPane>
              );
            })}
        </Tabs>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setDeviceBreadcrumbs,
        currentDevice
      },
      dispatch
    )
  };
}

const mapStateToProps = createStructuredSelector({
  currentAsset: getCurrentAsset(),
  currentDevice: getCurrentDevice(),
  deviceBreadcrumbList: getDeviceBreadcrumbList()
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceHierarchy);
