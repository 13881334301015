import styled from 'styled-components';
import { Button } from 'antd';

export const LoginButton = styled(Button)`
  height: auto;
  padding: 0.6em 3.5em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 2;
  font-size: 0.75rem;
  width: 100%;
  backgroundcolor: #0066b8 !important;
  bordercolor: #0066b8 !important;
  width: 380px;
`;
export const LoginPageBackground = styled.div`
  width: 100%;
  min-height: 95%;
  border-radius: 0.375rem 0 0 0.375rem;
  border-radius: 0.375rem 0.375rem 0 0;
  padding: 0;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  flex: 0 0 50%;
  max-width: 42%;
  float: left;
`;
export const LoginBox = styled.div`
  padding-left: 8%;
  padding: 3%;
  padding-right: 8%;
  right: 0%;
  top: 25%;
  display: flex !important;
  flex: 0 0 50%;
  max-width: 50%;
  position: absolute;
  width: 100%;
  min-height: 1px;
`;
export const Login = styled.div`
  opacity: 1;
  transform: translate(0px, 0px);
`;
export const LoginSection = styled.section`
  height: 100vh;
  /* overflow-y: scroll; */
  border-radius: 0;
  background: #fff;
  border-radius: 0.375rem;
  margin-right: 0;
  margin-left: 127px;
`;

export const HeaderLogo = styled.h2`
  font-weight: 300;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 2.0625rem;
  line-height: 1.3;
  color: inherit;
`;
export const Welcome = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 300;
  text-align: center;
  opacity: 0.9;
  color: black;
`;
export const WelcomeModule = styled.p`
  margin-bottom: 1.5rem;
  line-height: 1;
  font-weight: 800;
  text-align: center;
  opacity: 0.9;
  font-size: 17px;
  color: #041b51;
`;
export const Add = styled(Welcome)`
  font-size: 0.75rem;
  margin-bottom: 0;
  line-height: 1.5rem;
  text-align: center;
`;
export const LoginWith = styled.a`
  background-color: #335397;
  cursor: pointer;
  color: #fff;
  height: auto;
  padding: 0.5em 2.5em;
  margin-bottom: 0.5rem !important;
  display: block;
  width: 100%;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.71429;
  border-radius: 0.375rem;
  border: 1px solid transparent;
`;
