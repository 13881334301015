import React, { Component } from 'react';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import axios from 'axios';
import { createStructuredSelector } from 'reselect';
import { Button, Input, message, Row, Col, Tabs, Table, Popconfirm } from 'antd';
import { DoubleLeftOutlined, SearchOutlined } from '@ant-design/icons';
import history from '../../../commons/history';
import localStorage from '../../../utils/localStorage';
import { Header } from './Widgets';
import { StyledDashboard } from './styles';
import { getCurrentTimezone, getUserProfile } from '../../../selectors/layout';
import FormType from './formtype.js';
import { updateBatchStatus, getById, getList, _updateELogEdit } from './eLogCalls';
import { getTranslationCache } from '../../../selectors/language';
import './CSS/eLog.css';
import { StyledTable, lightTheme } from './CSS/style.js';

const { TabPane } = Tabs;
const newLocal = true;
class ELogbookView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      loading: false,
      activeKey: '1',
      location: '',
      data: [],
      date: '',
      json: {},
      userName: '',
      header: [],
      recordType: 'user',
      remarks: '',
      formType: '',
      elog: {},
      headerValue: {},
      form: {},
      saveStatus: false,
      logList: [],
      dataSource: [],
      action: '',
      userProfile: props.userProfile || {},
      open: false
    };
    this.index = 0;
    this.clickedCancel = false;
  }

  componentDidMount = async () => {
    let processName = this.props.match.params.name;
    this._getAdhocDataSource(processName);
  };

  _getAdhocDataSource = async (processName) => {
    const roleId = localStorage.get('roleId');
    let payload = {
      userId: 1,
      roleId: roleId,
      LogBookId: processName
    };
    let data = await getById(payload);
    let editData = data && data[0] && data[0] ? data[0] : {};
    this._initialDataSetup(editData, this.props.userProfile);
    let dataSource = await getList(editData);
    this.setState({
      userProfile: this.props.userProfile,
      editData,
      dataSource,
      action: ''
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.userProfile !== this.props.userProfile ||
      prevProps.match.params.name !== this.props.match.params.name
    ) {
      let processName = this.props.match.params.name;
      this._getAdhocDataSource(processName);
      this.setState({
        activeKey: '1'
      });
    }
  };

  _initialDataSetup = async (json, userProfile) => {
    if (json && !json.Id) {
      this.setState({
        json,
        form: json && json.JSON && json.JSON.form ? json.JSON.form : {},
        headerValue: {
          CurrentDate: moment().format('YYYY-MM-DD'),
          CurrentTime: moment().format('HH:mm:SS'),
          userName: userProfile.user ? userProfile.user.FirstName : '',
          location: json && json.Location ? json.Location : '',
          ...(json && json.JSON && json.JSON.headerValue ? json.JSON.headerValue : {})
        },
        location: json && json.Location,
        remarks: '',
        process: json && json.Process,
        formType: json && json.FormType,
        adhocTable: json && json.TableName ? json.TableName : '',
        elog: json,
        logList: [],
        header: json && json.JSON && json.JSON.header ? json.JSON.header : [],
        date: moment(),
        userName: json && json.userName,
        recordType: json && json.recordType,
        status: ''
      });
    } else if (json && json.Id) {
      this.setState({
        json,
        form: json && json.JSON && json.JSON.form ? json.JSON.form : {},
        headerValue: {
          CurrentDate: moment().format('YYYY-MM-DD HH:mm:SS'),
          userName: userProfile.user ? userProfile.user.FirstName : '',
          location: json && json.Location ? json.Location : '',
          ...(json && json.JSON && json.JSON.headerValue ? json.JSON.headerValue : {})
        },
        location:
          json && json.headerValue && json.headerValue.Location ? json.headerValue.Location : '',
        date: moment(),
        userName: json && json.userName,
        header: json && json.JSON && json.JSON.header ? json.JSON.header : [],
        recordType: json && json.recordType,
        remarks: json && json.remarks ? json.remarks : '',
        process: json && json.Process ? json.Process : '',
        formType: json && json.FormType,
        elog: json,
        adhocTable: json && json.TableName ? json.TableName : '',
        logList: json.Id,
        logId: json.logId,
        status: json.status
      });
    }
  };

  checkAllRows = (formType, form) => {
    let row = false;
    if (formType === 'EquipmentBatch') {
      form &&
        Object.keys(form).map((tabs) => {
          let mainHeaderValue = Object.keys(form.mainHeaderValue).length;

          tabs !== 'mainHeader' &&
            tabs !== 'mainHeaderValue' &&
            form &&
            form[tabs] &&
            Object.keys(form[tabs]).map((dynamicTabs) => {
              let HeaderData =
                form[tabs] &&
                form[tabs][dynamicTabs] &&
                form[tabs][dynamicTabs].headerValue &&
                dynamicTabs !== 'defaultData';

              if (mainHeaderValue !== 0 && HeaderData !== false && HeaderData !== undefined) {
                if (dynamicTabs !== 'defaultData') {
                  let HeaderValue =
                    form[tabs] &&
                    form[tabs][dynamicTabs] &&
                    Object.keys(form[tabs][dynamicTabs].headerValue).length;
                  if (HeaderValue !== 0) {
                    form[tabs] &&
                      form[tabs][dynamicTabs] &&
                      form[tabs][dynamicTabs].header &&
                      Object.keys(form[tabs][dynamicTabs].header).map((header) => {
                        if (header && header.required && header.required === true) {
                          if (!form[tabs][dynamicTabs].headerValue[header]) {
                            row = true;
                          }
                        }
                        return {};
                      });
                    let active =
                      form[tabs] &&
                      form[tabs][dynamicTabs] &&
                      form[tabs][dynamicTabs].headerValue &&
                      form[tabs][dynamicTabs].headerValue.EquipmentActiveStatus;
                    if (
                      active === 'Yes' &&
                      form[tabs][dynamicTabs]['form'].datasource !== undefined &&
                      form[tabs][dynamicTabs]['form'].datasource
                    ) {
                      let dataSource =
                        form[tabs] &&
                        form[tabs][dynamicTabs] &&
                        form[tabs][dynamicTabs]['form'] &&
                        Object.keys(form[tabs][dynamicTabs]['form'].datasource).length;
                      if (dataSource !== 0) {
                        let batchColumns =
                          form[tabs] &&
                          form[tabs][dynamicTabs] &&
                          form[tabs][dynamicTabs]['form'] &&
                          form[tabs][dynamicTabs]['form'].columns &&
                          Array.isArray(form[tabs][dynamicTabs]['form'].columns) &&
                          form[tabs][dynamicTabs]['form'].columns.map((dataColumns) => {
                            return dataColumns.key;
                          });

                        Object.keys(form[tabs][dynamicTabs]['form'].datasource).map((keys) => {
                          if (batchColumns && batchColumns.length > 0) {
                            batchColumns &&
                              Array.isArray(batchColumns) &&
                              batchColumns.map((batchkeys) => {
                                if (
                                  !form[tabs][dynamicTabs]['form'].datasource[keys][batchkeys] &&
                                  batchkeys !== 'Defloccular'
                                ) {
                                  row = true;
                                }
                                return {};
                              });
                          }
                          return {};
                        });
                      } else {
                        if (dataSource === 0) {
                          row = true;
                        }
                      }
                    }
                  } else {
                    if (HeaderValue === 0) {
                      row = true;
                    }
                  }
                }
              } else {
                if (
                  mainHeaderValue === 0 ||
                  (HeaderData === undefined && dynamicTabs !== 'defaultData')
                ) {
                  row = true;
                }
              }
              return {};
            });
          return {};
        });
    } else if (formType === 'EquipmentBodyCodeForm') {
      form &&
        Object.keys(form).map((tabs) => {
          let objectLength =
            tabs !== 'mainHeaderValue' && tabs !== 'mainHeader' && Object.keys(form[tabs]).length;
          let mainHeaderValue = Object.keys(form.mainHeaderValue).length;
          if (mainHeaderValue !== 0) {
            if (objectLength === 1) {
              row = true;
            }
            let batchColumns =
              form['mainHeader'] &&
              form['mainHeader'] &&
              Array.isArray(form['mainHeader']) &&
              form['mainHeader'].map((dataColumns) => {
                return dataColumns.key;
              });
            batchColumns &&
              Array.isArray(batchColumns) &&
              batchColumns.map((batchkeys) => {
                if (!form['mainHeaderValue'][batchkeys]) {
                  row = true;
                }
                return {};
              });

            tabs !== 'mainHeader' &&
              tabs !== 'mainHeaderValue' &&
              form &&
              form[tabs] &&
              Object.keys(form[tabs]).map((sections) => {
                if (sections !== 'defaultData' && sections !== 'ReferenceAttributes') {
                  let headerValue =
                    form &&
                    form[tabs] &&
                    form[tabs][sections] &&
                    form[tabs][sections].headerValue.length;
                  if (headerValue !== 0) {
                    form &&
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections].header &&
                      form[tabs][sections].header.map((header) => {
                        if (header && header.required && header.required === true) {
                          let active =
                            form[tabs][sections].headerValue &&
                            form[tabs][sections].headerValue.EquipmentActiveStatus;
                          if (
                            active === 'Yes' &&
                            form[tabs][sections].headerValue !== undefined &&
                            form[tabs][sections].headerValue
                          ) {
                            if (!form[tabs][sections].headerValue) {
                              row = true;
                            } else {
                              if (
                                !form[tabs][sections].headerValue[header.key] ||
                                form[tabs][sections].headerValue[header.key] === ''
                              ) {
                                row = true;
                              }
                            }
                            form &&
                              form[tabs] &&
                              form[tabs][sections].subSection &&
                              Object.keys(form[tabs][sections].subSection).map((subSectionName) => {
                                if (
                                  subSectionName !== 'bodycode' &&
                                  subSectionName !== 'ReferenceAttributes' &&
                                  form
                                ) {
                                  let subHeaderValue =
                                    form[tabs] &&
                                    form[tabs][sections].subSection[subSectionName].headerValue &&
                                    Object.keys(
                                      form[tabs][sections].subSection[subSectionName].headerValue
                                    ).length;
                                  if (subHeaderValue !== 0) {
                                    form &&
                                      form[tabs] &&
                                      form[tabs][sections] &&
                                      form[tabs][sections].subSection &&
                                      form[tabs][sections].subSection[subSectionName] &&
                                      form[tabs][sections].subSection[subSectionName].header.map(
                                        (header) => {
                                          if (
                                            header &&
                                            header.required &&
                                            header.required === true
                                          ) {
                                            if (
                                              form[tabs][sections].subSection[subSectionName]
                                                .headerValue !== undefined &&
                                              form[tabs][sections].subSection[subSectionName]
                                                .headerValue
                                            ) {
                                              if (
                                                !form[tabs][sections].subSection[subSectionName]
                                                  .headerValue
                                              ) {
                                                row = true;
                                              } else {
                                                if (
                                                  !form[tabs][sections].subSection[subSectionName]
                                                    .headerValue[header.key] ||
                                                  form[tabs][sections].subSection[subSectionName]
                                                    .headerValue[header.key] === ''
                                                ) {
                                                  row = true;
                                                }
                                              }
                                            }
                                          }
                                          return {};
                                        }
                                      );
                                    let batchColumns =
                                      form &&
                                      form[tabs] &&
                                      form[tabs][sections] &&
                                      form[tabs][sections].subSection &&
                                      form[tabs][sections].subSection[subSectionName] &&
                                      form[tabs][sections].subSection[subSectionName].form &&
                                      form[tabs][sections].subSection[subSectionName].form
                                        .columns &&
                                      Array.isArray(
                                        form[tabs][sections].subSection[subSectionName].form.columns
                                      ) &&
                                      form[tabs][sections].subSection[
                                        subSectionName
                                      ].form.columns.map((dataColumns) => {
                                        return dataColumns.key;
                                      });
                                    if (
                                      form &&
                                      form[tabs] &&
                                      form[tabs][sections] &&
                                      form[tabs][sections].subSection &&
                                      form[tabs][sections].subSection[subSectionName] &&
                                      form[tabs][sections].subSection[subSectionName].form &&
                                      form[tabs][sections].subSection[subSectionName].form
                                        .datasource &&
                                      Object.keys(
                                        form[tabs][sections].subSection[subSectionName].form
                                          .datasource
                                      ) &&
                                      Object.keys(
                                        form[tabs][sections].subSection[subSectionName].form
                                          .datasource
                                      ).length > 0
                                    ) {
                                      Object.keys(
                                        form[tabs][sections].subSection[subSectionName].form
                                          .datasource
                                      ).map((data) => {
                                        batchColumns &&
                                          Array.isArray(batchColumns) &&
                                          batchColumns.map((batchkeys) => {
                                            if (
                                              !form[tabs][sections].subSection[subSectionName].form
                                                .datasource[data][batchkeys]
                                            ) {
                                              row = true;
                                            } else if (
                                              !form[tabs][sections].subSection[subSectionName].form
                                                .datasource[data]
                                            ) {
                                              row = true;
                                            }
                                            return {};
                                          });
                                        return {};
                                      });
                                    } else {
                                      if (batchColumns && batchColumns.length > 0) {
                                        row = true;
                                      }
                                    }
                                  } else {
                                    if (subHeaderValue === 0) {
                                      row = true;
                                    }
                                  }
                                }
                                return {};
                              });
                          } else if (active === 'No') {
                            row = false;
                          }
                        }
                        return {};
                      });
                  } else {
                    if (headerValue === 0) {
                      row = true;
                    }
                  }
                }
                return {};
              });
          } else {
            if (mainHeaderValue === 0) {
              row = true;
            }
          }
          return {};
        });
    } else if (formType === 'FormWithSubModelsTable' || formType === 'FormWithSubModels') {
      form &&
        Object.keys(form).map((tabs) => {
          form &&
            form[tabs] &&
            Object.keys(form[tabs]).map((sections) => {
              let mainHeaderValue = Object.keys(form.mainHeaderValue).length;
              let batchColumns =
                form['mainHeader'] &&
                form['mainHeader'] &&
                Array.isArray(form['mainHeader']) &&
                form['mainHeader'].map((dataColumns) => {
                  return dataColumns.key;
                });
              if (mainHeaderValue !== 0) {
                batchColumns &&
                  Array.isArray(batchColumns) &&
                  batchColumns.map((batchkeys) => {
                    if (
                      !form['mainHeaderValue'][batchkeys] &&
                      form['mainHeaderValue'][batchkeys] !== 0
                    ) {
                      row = true;
                    }
                    return {};
                  });
                if (
                  form[tabs] &&
                  form[tabs][sections] &&
                  form[tabs][sections] &&
                  Object.keys(form[tabs][sections])
                ) {
                  if (sections !== 'defaultData') {
                    let formColumnsArray =
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections]['form'] &&
                      form[tabs][sections]['form'].columns
                        ? form[tabs][sections]['form'].columns
                        : [];
                    let formColumnsObject =
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections]['form'] &&
                      Array.isArray(formColumnsArray) &&
                      formColumnsArray.map((dataColumns) => {
                        return dataColumns.key;
                      });
                    if (form[tabs] && form[tabs][sections] && form[tabs][sections]['form']) {
                      formColumnsObject &&
                        Array.isArray(formColumnsObject) &&
                        formColumnsObject.map((batchkeys) => {
                          if (!form[tabs][sections].form.datasource[batchkeys]) {
                            row = true;
                          }
                          return {};
                        });
                    }
                    if (
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections].table &&
                      form[tabs][sections].table
                    ) {
                      let batchNo =
                        form[tabs] &&
                        form[tabs][sections] &&
                        form[tabs][sections].table &&
                        form[tabs][sections].table.datasource;
                      let tableObject =
                        form[tabs] &&
                        form[tabs][sections] &&
                        form[tabs][sections].table &&
                        form[tabs][sections].table.columns;
                      let tableColumns =
                        form[tabs] &&
                        form[tabs][sections] &&
                        form[tabs][sections].table &&
                        Array.isArray(form[tabs][sections].table.columns) &&
                        tableObject &&
                        Array.isArray(tableObject) &&
                        tableObject.map((dataColumns) => {
                          return dataColumns.key;
                        });
                      tableColumns &&
                        Array.isArray(tableColumns) &&
                        tableColumns.map((batchkeys) => {
                          // if (form[tabs][sections].table.validation === "atleastOne") {
                          //   Object.keys(batchNo).map((keys) => {
                          //     if (batchNo[0].editable === true) {
                          //       row = true

                          //     } else {
                          //       if (!batchNo[0][batchkeys]) {
                          //         row = true
                          //       }
                          //     }
                          //   })
                          // }
                          // else {
                          //-----new comm
                          // Object.keys(batchNo).map((keys) => {
                          //   if (!batchNo[keys].editable) {
                          //     if (!batchNo[keys][batchkeys]) {
                          //       row = true
                          //     }
                          //     else {
                          //       row = false
                          //     }

                          //   } else {
                          //     if (!batchNo[keys][batchkeys]) {
                          //       row = true
                          //     }
                          //   }
                          // })
                          //-----new comm
                          // }
                          ////swathi

                          if (form[tabs][sections].table.validation === 'atleastOne') {
                            Object.keys(batchNo).map(() => {
                              if (batchNo[0].editable && batchNo[0].editable === true) {
                                row = true;
                              } else {
                                if (!batchNo[0][batchkeys]) {
                                  row = true;
                                }
                              }
                              return {};
                            });
                          } else {
                            Object.keys(batchNo).map((keys) => {
                              if (batchNo[keys].editable && batchNo[keys].editable === true) {
                                row = true;
                              } else {
                                if (!batchNo[keys][batchkeys]) {
                                  row = true;
                                }
                              }
                              return {};
                            });
                          }
                          return {};
                        });
                    }
                  }
                }
              } else {
                if (mainHeaderValue === 0) {
                  row = true;
                }
              }
              return {};
            });
          return {};
        });
    } else if (formType === 'GasLogForm' || formType === 'tableView') {
      form &&
        Object.keys(form).map((sections) => {
          let dataSource = Object.keys(form[sections].datasource).length && sections !== 'table';
          if (dataSource !== 0 && sections !== 'table') {
            let batchColumns =
              form[sections] &&
              form[sections].columns &&
              Array.isArray(form[sections].columns) &&
              form[sections].columns.map((dataColumns) => {
                if (
                  form[sections].datasource.SampleType &&
                  form[sections].datasource.SampleType === 'Production'
                ) {
                  if (!dataColumns.show && dataColumns.required === true) {
                    return dataColumns.key;
                  }
                } else {
                  if (dataColumns.required === true) {
                    return dataColumns.key;
                  }
                }
                return {};
              });

            if (
              form[sections] &&
              form[sections].datasource &&
              Object.keys(form[sections].datasource) &&
              Object.keys(form[sections].datasource).length > 0
            ) {
              Object.keys(form[sections].datasource).map(() => {
                batchColumns &&
                  Array.isArray(batchColumns) &&
                  batchColumns.map((batchkeys) => {
                    if (!form[sections].datasource[batchkeys] && batchkeys !== undefined) {
                      row = true;
                    }
                    // else if (!form[sections].datasource[data] ) {

                    //     row = true
                    // }
                    return {};
                  });
                return {};
              });
            }
          }
          if (sections === 'table') {
            let tableColumns = form[sections].columns;
            let batchColumns =
              form[sections] &&
              tableColumns &&
              Array.isArray(tableColumns) &&
              tableColumns.map((dataColumns) => {
                if (dataColumns.required === true) {
                  return dataColumns.key;
                }
                return {};
              });
            batchColumns &&
              Array.isArray(batchColumns) &&
              batchColumns.map((tableColumns) => {
                if (form[sections].validation === 'atleastOne') {
                  Object.keys(form[sections].datasource).map(() => {
                    if (
                      form[sections].datasource[0].editable &&
                      form[sections].datasource[0].editable === true
                    ) {
                      row = true;
                    } else {
                      if (!form[sections].datasource[0][tableColumns]) {
                        row = true;
                      }
                    }
                    return {};
                  });
                } else {
                  Object.keys(form[sections].datasource).map((key) => {
                    if (
                      form[sections].datasource[key].editable &&
                      form[sections].datasource[key].editable === true
                    ) {
                      row = true;
                    } else {
                      if (!form[sections].datasource[key][tableColumns]) {
                        row = true;
                      }
                    }
                    return {};
                  });
                }
                return {};
              });
          } else {
            if (dataSource === 0) {
              row = true;
            }
          }
          return {};
        });
    } else if (formType === 'FormWithFormSubSectionFormTable' || formType === 'FormTable') {
      // form = {
      //   "mainHeader": [
      //     {
      //       "displayName": "Date Selection",
      //       "key": "Date",
      //       "placeHolder": "Select Date Selection",
      //       "required": true,
      //       "widget": "date-picker"
      //     },
      //     {
      //       "displayName": "Equipment",
      //       "key": "Equipment",
      //       "placeHolder": "Select equipment",
      //       "required": true,
      //       "table": {
      //         "url": "/api/logbook/dropdownforequipment",
      //         "method": "POST",
      //         "payload": {
      //           "Name": "Kiln"
      //         }
      //       },
      //       "widget": "select"
      //     },
      //     {
      //       "displayName": "Shift",
      //       "key": "Shift",
      //       "placeHolder": "Select Shift",
      //       "required": true,
      //       "table": {
      //         "url": "/api/logbook/dropdown",
      //         "method": "POST",
      //         "payload": {
      //           "tableName": "master.\"Master_Shifts\"",
      //           "columnName": "Shift"
      //         }
      //       },
      //       "widget": "select"
      //     },
      //     {
      //       "displayName": "Kiln Gap(Min)",
      //       "key": "kilnGap",
      //       "placeHolder": "Enter Kiln Gap(Min)",
      //       "required": true,
      //       "widget": "inputNumber"
      //     },
      //     {
      //       "displayName": "Gas Reading(Min)",
      //       "key": "gasReading",
      //       "placeHolder": "Enter Gas Reading",
      //       "required": true,
      //       "widget": "inputNumber"
      //     },
      //     {
      //       "displayName": "How Many Tile Size?",
      //       "key": "TileSizeCount",
      //       "placeHolder": "Enter TileSizeCount",
      //       "required": true,
      //       "widget": "inputNumber"
      //     }
      //   ],
      //   "mainHeaderValue": {
      //     "Date": "2022-11-02 14:08:08",
      //     "Equipment": "Kiln-01",
      //     "Shift": "Night Shift",
      //     "kilnGap": 1,
      //     "gasReading": 1,
      //     "TileSizeCount": 1
      //   },
      //   "tabs": {
      //     "defaultData": {
      //       "form": {
      //         "columns": [
      //           {
      //             "displayName": "Tile Size Selection",
      //             "key": "TileSize",
      //             "placeHolder": "Select Tile Size Selection",
      //             "required": true,
      //             "widget": "select",
      //             "table": {
      //               "url": "/api/logbook/dropdownfortilesize",
      //               "method": "POST",
      //               "payload": {
      //                 "tableName": "appconfig.\"Site_Master_Tiles\"",
      //                 "columnName": "Tilesize"
      //               }
      //             }
      //           },
      //           {
      //             "displayName": "Start & End Time",
      //             "key": "TimeSelection",
      //             "placeHolder": "Enter StartDateTime & EndDateTime",
      //             "required": true,
      //             "widget": "dateTimeRange"
      //           },
      //           {
      //             "displayName": "Kiln Cycle(m2)",
      //             "key": "kilnCycle",
      //             "placeHolder": "Enter Kiln Cycle",
      //             "required": true,
      //             "widget": "inputNumber"
      //           },
      //           {
      //             "displayName": "Glazed Tile Moisture",
      //             "key": "glazedTileMoisture",
      //             "placeHolder": "Enter Glazed Tile Moisture",
      //             "required": true,
      //             "widget": "input"
      //           },
      //           {
      //             "displayName": "No of Models?",
      //             "key": "noOfModels",
      //             "placeHolder": "Enter No of Models",
      //             "required": true,
      //             "widget": "inputNumber"
      //           }
      //         ],
      //         "datasource": {}
      //       },
      //       "subsection": {
      //         "model": {
      //           "form": {
      //             "columns": [
      //               {
      //                 "displayName": "Model Name",
      //                 "key": "modelName",
      //                 "placeHolder": "Enter Model Name",
      //                 "required": true,
      //                 "widget": "select",
      //                 "table": {
      //                   "url": "/api/logbook/dropdownfortilesize",
      //                   "method": "POST",
      //                   "payload": {
      //                     "tableName": "appconfig.\"Site_Master_Tiles\"",
      //                     "columnName": "MaterialModel",
      //                     "conditionList": [
      //                       {
      //                         "referenceKey": "TileSize",
      //                         "key": "Tilesize",
      //                         "value": "30X60X9"
      //                       }
      //                     ]
      //                   }
      //                 }
      //               },
      //               {
      //                 "displayName": "Number Batches in Model?",
      //                 "key": "noOfBatches",
      //                 "placeHolder": "Enter No of Batches",
      //                 "required": true,
      //                 "widget": "inputNumber"
      //               }
      //             ],
      //             "datasource": {}
      //           },
      //           "table": {
      //             "columns": [
      //               {
      //                 "title": "",
      //                 "dataIndex": "Batch",
      //                 "key": "batch",
      //                 "required": true,
      //                 "widget": "text"
      //               },
      //               {
      //                 "title": "Shade Number",
      //                 "dataIndex": "Shade Number",
      //                 "key": "shadeNumber",
      //                 "required": true,
      //                 "widget": "input"
      //               },
      //               {
      //                 "title": "Weight(Kg/m2)",
      //                 "dataIndex": "Weight",
      //                 "key": "weight",
      //                 "required": true,
      //                 "widget": "input",
      //                 "table": {
      //                   "url": "/api/logbook/dropdown",
      //                   "method": "POST",
      //                   "payload": {
      //                     "tableName": "master.\"Masters_KilnTileSizeProperties\"",
      //                     "columnName": "TileWeight",
      //                     "conditionList": [
      //                       {
      //                         "referenceKey": "Tilesize",
      //                         "key": "Tilesize",
      //                         "value": ""
      //                       }
      //                     ]
      //                   }
      //                 }
      //               },
      //               {
      //                 "title": "Production(M2)",
      //                 "dataIndex": "Production",
      //                 "key": "production",
      //                 "required": true,
      //                 "widget": "inputNumber"
      //               },
      //               {
      //                 "title": "Rejection(m2)",
      //                 "dataIndex": "Rejection",
      //                 "key": "rejection",
      //                 "required": true,
      //                 "widget": "inputNumber"
      //               }
      //             ],
      //             "datasource": [
      //               {
      //                 "batch": "Batch-01",
      //                 "key": 1
      //               },
      //               {
      //                 "batch": "Batch-02",
      //                 "key": 2
      //               }
      //             ],
      //             "action": true
      //           }
      //         }
      //       }
      //     },
      //     "TileSize-1": {
      //       "form": {
      //         "columns": [
      //           {
      //             "displayName": "Tile Size Selection",
      //             "key": "TileSize",
      //             "placeHolder": "Select Tile Size Selection",
      //             "required": true,
      //             "widget": "select",
      //             "table": {
      //               "url": "/api/logbook/dropdownfortilesize",
      //               "method": "POST",
      //               "payload": {
      //                 "tableName": "appconfig.\"Site_Master_Tiles\"",
      //                 "columnName": "Tilesize"
      //               }
      //             }
      //           },
      //           {
      //             "displayName": "Start & End Time",
      //             "key": "TimeSelection",
      //             "placeHolder": "Enter StartDateTime & EndDateTime",
      //             "required": true,
      //             "widget": "dateTimeRange"
      //           },
      //           {
      //             "displayName": "Kiln Cycle(m2)",
      //             "key": "kilnCycle",
      //             "placeHolder": "Enter Kiln Cycle",
      //             "required": true,
      //             "widget": "inputNumber"
      //           },
      //           {
      //             "displayName": "Glazed Tile Moisture",
      //             "key": "glazedTileMoisture",
      //             "placeHolder": "Enter Glazed Tile Moisture",
      //             "required": true,
      //             "widget": "input"
      //           },
      //           {
      //             "displayName": "No of Models?",
      //             "key": "noOfModels",
      //             "placeHolder": "Enter No of Models",
      //             "required": true,
      //             "widget": "inputNumber"
      //           }
      //         ],
      //         "datasource": {
      //           "TileSize": "30X60X9",
      //           "kilnCycle": 1,
      //           "glazedTileMoisture": "1",
      //           "noOfModels": 1,
      //           "TimeSelection": {
      //             "startDate": "2022-11-02 18:01:00",
      //             "endDate": "2022-11-02 00:05:00"
      //           }
      //         },
      //         "ReferenceAttributes": {
      //           "JsonKey": [
      //             "tabs",
      //             "TileSize-1",
      //             "form"
      //           ]
      //         }
      //       },
      //       "subsection": {
      //         "Model-1": {
      //           "form": {
      //             "columns": [
      //               {
      //                 "displayName": "Model Name",
      //                 "key": "modelName",
      //                 "placeHolder": "Enter Model Name",
      //                 "required": true,
      //                 "widget": "select",
      //                 "table": {
      //                   "url": "/api/logbook/dropdownfortilesize",
      //                   "method": "POST",
      //                   "payload": {
      //                     "tableName": "appconfig.\"Site_Master_Tiles\"",
      //                     "columnName": "MaterialModel",
      //                     "conditionList": [
      //                       {
      //                         "referenceKey": "TileSize",
      //                         "key": "Tilesize",
      //                         "value": "30X60X9"
      //                       }
      //                     ]
      //                   }
      //                 }
      //               },
      //               {
      //                 "displayName": "Number Batches in Model?",
      //                 "key": "noOfBatches",
      //                 "placeHolder": "Enter No of Batches",
      //                 "required": true,
      //                 "widget": "inputNumber"
      //               }
      //             ],
      //             "datasource": {
      //               "modelName": "A00GARTX#LIG.X0X0F",
      //               "noOfBatches": 1
      //             },
      //             "ReferenceAttributes": {
      //               "JsonKey": [
      //                 "tabs",
      //                 "TileSize-1",
      //                 "subsection",
      //                 "Model-1",
      //                 "form"
      //               ]
      //             }
      //           },
      //           "table": {
      //             "columns": [
      //               {
      //                 "title": "",
      //                 "dataIndex": "Batch",
      //                 "key": "batch",
      //                 "required": true,
      //                 "widget": "text"
      //               },
      //               {
      //                 "title": "Shade Number",
      //                 "dataIndex": "Shade Number",
      //                 "key": "shadeNumber",
      //                 "required": true,
      //                 "widget": "input"
      //               },
      //               {
      //                 "title": "Weight(Kg/m2)",
      //                 "dataIndex": "Weight",
      //                 "key": "weight",
      //                 "required": true,
      //                 "widget": "input",
      //                 "table": {
      //                   "url": "/api/logbook/dropdown",
      //                   "method": "POST",
      //                   "payload": {
      //                     "tableName": "master.\"Masters_KilnTileSizeProperties\"",
      //                     "columnName": "TileWeight",
      //                     "conditionList": [
      //                       {
      //                         "referenceKey": "Tilesize",
      //                         "key": "Tilesize",
      //                         "value": ""
      //                       }
      //                     ]
      //                   }
      //                 }
      //               },
      //               {
      //                 "title": "Production(M2)",
      //                 "dataIndex": "Production",
      //                 "key": "production",
      //                 "required": true,
      //                 "widget": "inputNumber"
      //               },
      //               {
      //                 "title": "Rejection(m2)",
      //                 "dataIndex": "Rejection",
      //                 "key": "rejection",
      //                 "required": true,
      //                 "widget": "inputNumber"
      //               }
      //             ],
      //             "datasource": [
      //               {
      //                 "batch": "Batch-1",
      //                 "key": 1,
      //                 "editable": false,
      //                 "shadeNumber": "1",
      //                 "weight": "11",
      //                 "production": 1,
      //                 "rejection": 1
      //               }
      //             ],
      //             "action": true,
      //             "ReferenceAttributes": {
      //               "JsonKey": [
      //                 "tabs",
      //                 "TileSize-1",
      //                 "subsection",
      //                 "Model-1",
      //                 "table"
      //               ]
      //             }
      //           }
      //         }
      //       },
      //       "ReferenceAttributes": {
      //         "JsonKey": [
      //           "tabs",
      //           "TileSize-1",
      //           "form"
      //         ]
      //       }
      //     },
      //     "samples": {
      //       "form": {
      //         "columns": [
      //           {
      //             "displayName": "Production Samples m2",
      //             "key": "productionSamples",
      //             "placeHolder": "Enter Production Samples m2",
      //             "required": true,
      //             "widget": "inputNumber"
      //           },
      //           {
      //             "displayName": "PDI Samples m2",
      //             "key": "pdiSamples",
      //             "placeHolder": "Enter PDI Samples m2",
      //             "required": true,
      //             "widget": "inputNumber"
      //           }
      //         ],
      //         "datasource": {
      //           "productionSamples": 1,
      //           "pdiSamples": 1
      //         },
      //         "ReferenceAttributes": {
      //           "JsonKey": [
      //             "tabs",
      //             "samples",
      //             "form"
      //           ]
      //         }
      //       }
      //     }
      //   },
      //   "model": {
      //     "defaultData": {
      //       "form": {
      //         "columns": [
      //           {
      //             "displayName": "Tile Size Selection",
      //             "key": "TileSize",
      //             "placeHolder": "Select Tile Size Selection",
      //             "required": true,
      //             "widget": "select",
      //             "table": {
      //               "url": "/api/logbook/dropdownfortilesize",
      //               "method": "POST",
      //               "payload": {
      //                 "tableName": "appconfig.\"Site_Master_Tiles\"",
      //                 "columnName": "Tilesize"
      //               }
      //             }
      //           },
      //           {
      //             "displayName": "Start & End Time",
      //             "key": "TimeSelection",
      //             "placeHolder": "Enter StartDateTime & EndDateTime",
      //             "required": true,
      //             "widget": "dateTimeRange"
      //           },
      //           {
      //             "displayName": "Kiln Cycle(m2)",
      //             "key": "kilnCycle",
      //             "placeHolder": "Enter Kiln Cycle",
      //             "required": true,
      //             "widget": "inputNumber"
      //           },
      //           {
      //             "displayName": "Glazed Tile Moisture",
      //             "key": "glazedTileMoisture",
      //             "placeHolder": "Enter Glazed Tile Moisture",
      //             "required": true,
      //             "widget": "input"
      //           },
      //           {
      //             "displayName": "No of Models?",
      //             "key": "noOfModels",
      //             "placeHolder": "Enter No of Models",
      //             "required": true,
      //             "widget": "inputNumber"
      //           }
      //         ],
      //         "datasource": {}
      //       },
      //       "subsection": {
      //         "model": {
      //           "form": {
      //             "columns": [
      //               {
      //                 "displayName": "Model Name",
      //                 "key": "modelName",
      //                 "placeHolder": "Enter Model Name",
      //                 "required": true,
      //                 "widget": "select",
      //                 "table": {
      //                   "url": "/api/logbook/dropdownfortilesize",
      //                   "method": "POST",
      //                   "payload": {
      //                     "tableName": "appconfig.\"Site_Master_Tiles\"",
      //                     "columnName": "MaterialModel",
      //                     "conditionList": [
      //                       {
      //                         "referenceKey": "TileSize",
      //                         "key": "Tilesize",
      //                         "value": "30X60X9"
      //                       }
      //                     ]
      //                   }
      //                 }
      //               },
      //               {
      //                 "displayName": "Number Batches in Model?",
      //                 "key": "noOfBatches",
      //                 "placeHolder": "Enter No of Batches",
      //                 "required": true,
      //                 "widget": "inputNumber"
      //               }
      //             ],
      //             "datasource": {}
      //           },
      //           "table": {
      //             "columns": [
      //               {
      //                 "title": "",
      //                 "dataIndex": "Batch",
      //                 "key": "batch",
      //                 "required": true,
      //                 "widget": "text"
      //               },
      //               {
      //                 "title": "Shade Number",
      //                 "dataIndex": "Shade Number",
      //                 "key": "shadeNumber",
      //                 "required": true,
      //                 "widget": "input"
      //               },
      //               {
      //                 "title": "Weight(Kg/m2)",
      //                 "dataIndex": "Weight",
      //                 "key": "weight",
      //                 "required": true,
      //                 "widget": "input",
      //                 "table": {
      //                   "url": "/api/logbook/dropdown",
      //                   "method": "POST",
      //                   "payload": {
      //                     "tableName": "master.\"Masters_KilnTileSizeProperties\"",
      //                     "columnName": "TileWeight",
      //                     "conditionList": [
      //                       {
      //                         "referenceKey": "Tilesize",
      //                         "key": "Tilesize",
      //                         "value": ""
      //                       }
      //                     ]
      //                   }
      //                 }
      //               },
      //               {
      //                 "title": "Production(M2)",
      //                 "dataIndex": "Production",
      //                 "key": "production",
      //                 "required": true,
      //                 "widget": "inputNumber"
      //               },
      //               {
      //                 "title": "Rejection(m2)",
      //                 "dataIndex": "Rejection",
      //                 "key": "rejection",
      //                 "required": true,
      //                 "widget": "inputNumber"
      //               }
      //             ],
      //             "datasource": [
      //               {
      //                 "batch": "Batch-01",
      //                 "key": 1
      //               },
      //               {
      //                 "batch": "Batch-02",
      //                 "key": 2
      //               }
      //             ],
      //             "action": true
      //           }
      //         }
      //       }
      //     },
      //     "samples": {
      //       "form": {
      //         "columns": [
      //           {
      //             "displayName": "Production Samples m2",
      //             "key": "productionSamples",
      //             "placeHolder": "Enter Production Samples m2",
      //             "required": true,
      //             "widget": "inputNumber"
      //           },
      //           {
      //             "displayName": "PDI Samples m2",
      //             "key": "pdiSamples",
      //             "placeHolder": "Enter PDI Samples m2",
      //             "required": true,
      //             "widget": "inputNumber"
      //           }
      //         ],
      //         "datasource": {},
      //         "ReferenceAttributes": {
      //           "JsonKey": [
      //             "tabs",
      //             "samples",
      //             "form"
      //           ]
      //         }
      //       }
      //     }
      //   }
      // }

      form &&
        Object.keys(form).map((tabs) => {
          let mainHeaderValue = Object.keys(form.mainHeaderValue).length;
          let batchColumns =
            form['mainHeader'] &&
            form['mainHeader'] &&
            Array.isArray(form['mainHeader']) &&
            form['mainHeader'].map((dataColumns) => {
              if (dataColumns.required === true) {
                return dataColumns.key;
              }
              return {};
            });
          if (mainHeaderValue !== 0) {
            batchColumns &&
              Array.isArray(batchColumns) &&
              batchColumns.map((batchkeys) => {
                // if (
                //   !form["mainHeaderValue"][batchkeys] && form["mainHeaderValue"][batchkeys] === 0
                //&& form["mainHeaderValue"][batchkeys] === "" &&
                //   batchkeys !== "NoOfChangeovers" || form["mainHeaderValue"][batchkeys] === undefined
                // ) {
                if (
                  (batchkeys !== 'NoOfChangeovers' && !form['mainHeaderValue'][batchkeys]) ||
                  (form['mainHeaderValue'][batchkeys] === 0 &&
                    form['mainHeaderValue'][batchkeys] === '' &&
                    form['mainHeaderValue'][batchkeys] === undefined)
                ) {
                  row = true;
                }
                return {};
              });

            tabs !== 'mainHeader' &&
              tabs !== 'mainHeaderValue' &&
              tabs !== 'model' &&
              form &&
              form[tabs] &&
              Object.keys(form[tabs]).map((sections) => {
                if (sections !== 'defaultData') {
                  let formDataSource = {};
                  let formDataSourcelength = {};
                  if (
                    form &&
                    form[tabs] &&
                    form[tabs][sections] &&
                    form[tabs][sections]['form'] &&
                    form[tabs][sections]['form'].datasource
                  ) {
                    formDataSource =
                      form &&
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections]['form'] &&
                      form[tabs][sections]['form'].datasource;

                    formDataSourcelength =
                      form &&
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections]['form'].datasource &&
                      Object.keys(form[tabs][sections]['form'].datasource).length;
                  } else {
                    formDataSource =
                      form &&
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections] &&
                      form[tabs][sections].datasource;

                    formDataSourcelength =
                      form &&
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections].datasource &&
                      Object.keys(form[tabs][sections].datasource).length;
                  }
                  // Object.keys(form[tabs][sections]["form"].datasource).length
                  if (formDataSourcelength !== 0) {
                    let columnsArray =
                      form[tabs] &&
                      form[tabs][sections] &&
                      form[tabs][sections]['form'] &&
                      form[tabs][sections]['form'].columns;

                    let formColumns =
                      form[tabs] &&
                      form[tabs][sections] &&
                      Array.isArray(columnsArray) &&
                      columnsArray.map((dataColumns) => {
                        if (!dataColumns.show && dataColumns.required === true) {
                          return dataColumns.key;
                        }
                        return {};
                      });

                    formColumns &&
                      Array.isArray(formColumns) &&
                      formColumns.map((batchkeys) => {
                        if (batchkeys !== undefined) {
                          if (!form[tabs][sections]['form'].datasource[batchkeys]) {
                            row = true;
                          }
                        }
                        return {};
                      });

                    let subsection =
                      form[tabs] && form[tabs][sections] && form[tabs][sections].subsection;

                    subsection &&
                      Object.keys(subsection).map((subSectionKeys) => {
                        let subsectionData = form[tabs][sections].subsection[subSectionKeys];
                        if (
                          subsectionData &&
                          subsectionData['form'] &&
                          subsectionData['form'].columns
                        ) {
                          let subColumns =
                            subsectionData &&
                            subsectionData['form'] &&
                            subsectionData['form'].columns;
                          let subsectionColumns =
                            Array.isArray(subColumns) &&
                            subColumns.map((columns) => {
                              if (
                                formDataSource &&
                                formDataSource['Typology'] &&
                                formDataSource['Typology'] === 'SINGLECHARGE'
                              ) {
                                if (!columns.show && columns.required === true) {
                                  return columns.key;
                                }
                              } else {
                                if (columns.required === true) {
                                  return columns.key;
                                }
                              }
                              return {};
                            });

                          let subsectionDataSourceLength =
                            subsectionData &&
                            subsectionData['form'] &&
                            subsectionData['form'].datasource &&
                            Object.keys(subsectionData['form'].datasource).length;
                          if (subsectionDataSourceLength !== 0) {
                            subsectionColumns &&
                              Array.isArray(subsectionColumns) &&
                              subsectionColumns.map((batchkeys) => {
                                if (batchkeys !== undefined) {
                                  if (
                                    !subsectionData['form'].datasource[batchkeys] &&
                                    subsectionData['form'].datasource[batchkeys] === null
                                  ) {
                                    row = true;
                                  }
                                }
                                return {};
                              });

                            let dynamicsubsection =
                              form[tabs] &&
                              form[tabs][sections] &&
                              form[tabs][sections].subsection &&
                              form[tabs][sections].subsection[subSectionKeys] &&
                              form[tabs][sections].subsection[subSectionKeys]['form'] &&
                              form[tabs][sections].subsection[subSectionKeys]['form'][
                                'dynamicSubForm'
                              ];
                            dynamicsubsection &&
                              Object.keys(dynamicsubsection).map((dynamicsubsectionkey) => {
                                let dynamicformdata =
                                  form[tabs][sections].subsection[subSectionKeys]['form'][
                                    'dynamicSubForm'
                                  ][dynamicsubsectionkey];

                                if (
                                  form[tabs][sections].subsection[subSectionKeys]['form']
                                    .datasource[dynamicsubsectionkey]
                                ) {
                                  if (
                                    dynamicformdata &&
                                    dynamicformdata['form'] &&
                                    dynamicformdata['form'].columns
                                  ) {
                                    let subColumns =
                                      dynamicformdata &&
                                      dynamicformdata['form'] &&
                                      dynamicformdata['form'].columns;

                                    let subsectionColumns =
                                      Array.isArray(subColumns) &&
                                      subColumns.map((columns) => {
                                        if (
                                          columns.required === true &&
                                          columns.disabled !== true
                                        ) {
                                          return columns.key;
                                        }
                                        return {};
                                      });
                                    let dynamicformDataSource =
                                      dynamicformdata &&
                                      dynamicformdata['form'] &&
                                      dynamicformdata['form'].datasource &&
                                      Object.keys(dynamicformdata['form'].datasource).length;
                                    if (dynamicformDataSource !== 0) {
                                      subsectionColumns &&
                                        Array.isArray(subsectionColumns) &&
                                        subsectionColumns.map((batchkeys) => {
                                          if (batchkeys !== undefined) {
                                            dynamicformdata['form'].datasource &&
                                              Object.keys(dynamicformdata['form'].datasource).map(
                                                (dataKey) => {
                                                  if (
                                                    !dynamicformdata['form'].datasource[dataKey][
                                                      batchkeys
                                                    ]
                                                  ) {
                                                    row = true;
                                                  }

                                                  return {};
                                                }
                                              );
                                          }
                                          return {};
                                        });
                                    } else {
                                      if (dynamicformDataSource < 0) {
                                        row = true;
                                      }
                                    }
                                  }
                                }
                                return {};
                              });
                          } else {
                            if (subsectionDataSourceLength === 0) {
                              row = true;
                            }
                          }
                        }

                        if (
                          subsectionData &&
                          subsectionData['table'] &&
                          subsectionData['table'].columns
                        ) {
                          let subColumns =
                            subsectionData &&
                            subsectionData['table'] &&
                            subsectionData['table'].columns;

                          let subsectionColumns =
                            Array.isArray(subColumns) &&
                            subColumns.map((columns) => {
                              if (
                                formDataSource &&
                                formDataSource['Typology'] &&
                                formDataSource['Typology'] === 'SINGLECHARGE'
                              ) {
                                if (!columns.show && columns.required === true) {
                                  return columns.key;
                                }
                              } else {
                                if (columns.required === true) {
                                  return columns.key;
                                }
                              }
                              return {};
                            });
                          let subsectionDataSourceLength =
                            subsectionData &&
                            subsectionData['table'] &&
                            subsectionData['table'].datasource &&
                            subsectionData['table'].datasource.length;

                          if (subsectionDataSourceLength !== 0) {
                            subsectionColumns &&
                              Array.isArray(subsectionColumns) &&
                              subsectionColumns.map((batchkeys) => {
                                if (batchkeys !== undefined) {
                                  subsectionData['table'].datasource.map(() => {
                                    //   if (!subsectionData["table"].datasource[index].editable) {
                                    //     if (
                                    //       !subsectionData["table"].datasource[index][batchkeys]
                                    //     ) {
                                    //       row = true;
                                    //     }
                                    //   }
                                    // })
                                    if (subsectionData['table'].validation === 'atleastOne') {
                                      Object.keys(subsectionData['table'].datasource).map(() => {
                                        if (
                                          subsectionData['table'].datasource[0].editable &&
                                          subsectionData['table'].datasource[0].editable === true
                                        ) {
                                          row = true;
                                        } else {
                                          if (!subsectionData['table'].datasource[0][batchkeys]) {
                                            row = true;
                                          }
                                        }
                                        return {};
                                      });
                                    } else {
                                      Object.keys(subsectionData['table'].datasource).map(
                                        (datakey) => {
                                          if (
                                            subsectionData['table'].datasource[datakey].editable &&
                                            subsectionData['table'].datasource[datakey].editable ===
                                              true
                                          ) {
                                            row = true;
                                          } else {
                                            if (
                                              !subsectionData['table'].datasource[datakey][
                                                batchkeys
                                              ]
                                            ) {
                                              row = true;
                                            }
                                          }
                                          return {};
                                        }
                                      );
                                    }
                                    return {};
                                  });
                                }
                                return {};
                              });
                            // if (batchkeys !== undefined) {
                            // if (subsectionData["table"].validation === "atleastOne") {
                            //   Object.keys( subsectionData["table"].datasource).map((datakey, index) => {
                            //     if (!subsectionData["table"].datasource[0].editable === true) {
                            //       row = true

                            //     } else {
                            //       if (!subsectionData["table"].datasource[0]) {
                            //         row = true
                            //       }
                            //     }
                            //   })
                            // }
                            // else {
                            //   Object.keys( subsectionData["table"].datasource).map((datakey, index) => {
                            //     if (!subsectionData["table"].datasource[index].editable === true) {
                            //       row = true

                            //     } else {
                            //       if (!subsectionData["table"].datasource[index]) {
                            //         row = true
                            //       }
                            //     }
                            //   })
                            // }
                            // }

                            let dynamicsubsection =
                              form[tabs] &&
                              form[tabs][sections] &&
                              form[tabs][sections].subsection &&
                              form[tabs][sections].subsection[subSectionKeys] &&
                              form[tabs][sections].subsection[subSectionKeys]['form'] &&
                              form[tabs][sections].subsection[subSectionKeys]['form'][
                                'dynamicSubForm'
                              ];

                            dynamicsubsection &&
                              Object.keys(dynamicsubsection).map((dynamicsubsectionkey) => {
                                let dynamicformdata =
                                  form[tabs][sections].subsection[subSectionKeys]['form'][
                                    'dynamicSubForm'
                                  ][dynamicsubsectionkey];

                                if (
                                  form[tabs][sections].subsection[subSectionKeys]['form']
                                    .datasource[dynamicsubsectionkey]
                                ) {
                                  if (
                                    dynamicformdata &&
                                    dynamicformdata['form'] &&
                                    dynamicformdata['form'].columns
                                  ) {
                                    let subColumns =
                                      dynamicformdata &&
                                      dynamicformdata['form'] &&
                                      dynamicformdata['form'].columns;

                                    let subsectionColumns =
                                      Array.isArray(subColumns) &&
                                      subColumns.map((columns) => {
                                        if (
                                          columns.required === true &&
                                          columns.disabled !== true
                                        ) {
                                          return columns.key;
                                        }
                                        return {};
                                      });
                                    let dynamicformDataSource =
                                      dynamicformdata &&
                                      dynamicformdata['form'] &&
                                      dynamicformdata['form'].datasource &&
                                      Object.keys(dynamicformdata['form'].datasource).length;
                                    if (dynamicformDataSource !== 0) {
                                      subsectionColumns &&
                                        Array.isArray(subsectionColumns) &&
                                        subsectionColumns.map((batchkeys) => {
                                          if (batchkeys !== undefined) {
                                            dynamicformdata['form'].datasource &&
                                              Object.keys(dynamicformdata['form'].datasource).map(
                                                (dataKey) => {
                                                  if (
                                                    !dynamicformdata['form'].datasource[dataKey][
                                                      batchkeys
                                                    ]
                                                  ) {
                                                    row = true;
                                                  }
                                                  return {};
                                                }
                                              );
                                          }
                                          return {};
                                        });
                                    } else {
                                      if (dynamicformDataSource === 0) {
                                        row = true;
                                      }
                                    }
                                  }
                                }
                                return {};
                              });
                          } else {
                            if (subsectionDataSourceLength === 0) {
                              row = true;
                            }
                          }
                        }
                        return {};
                      });

                    if (sections === 'samples') {
                      Object.keys(form[tabs][sections]['form'].datasource).map((key) => {
                        if (form[tabs][sections]['form'].datasource[key] === null) {
                          row = true;
                        }

                        return {};
                      });
                    }
                  } else {
                    if (formDataSourcelength === 0) {
                      row = true;
                    }
                  }
                }
                return {};
              });
          } else {
            if (mainHeaderValue === 0) {
              row = true;
            }
          }
          return {};
        });
    }

    return row;
  };

  saveEditObject = async () => {
    let siteId = localStorage.get('currentSite');
    let { header, elog, headerValue, formType, form, remarks, adhocTable, logList, process } =
      this.state;
    let { userProfile } = this.props;
    let status = false;
    if (form) {
      status = await this.checkAllRows(formType, form);
    }
    if (form && !status === true) {
      // if (form) {
      delete form.batch;
      let payload = {
        logId: elog.Id,
        formType,
        JSON: {
          form: { ...form },
          header,
          headerValue
        },
        userId: userProfile && userProfile.user ? userProfile.user.Id : '',
        roleId: userProfile.belongsTo.sites[siteId]
          ? userProfile.belongsTo.sites[siteId].role.Id
          : '',
        adhocTable,
        remarks,
        process,
        Id: logList
      };
      let updateResponse = await _updateELogEdit(payload);
      let logId = updateResponse && updateResponse[0] && updateResponse[0].Id;
      this.setState({
        saveStatus: true,
        logList: updateResponse
      });
      message.success('You have saved Elog book no.' + logId);
    } else {
      message.error('Save all records before saving e-log');
    }
  };

  _submitStatusAPI = async (payload) => {
    let { logList, form } = this.state;
    let logId = logList && logList[0] && logList[0].Id ? logList[0].Id : [];
    let statusResponse = await updateBatchStatus(payload);
    if (statusResponse && statusResponse.message) {
      this.setState({
        activeKey: '2'
      });
      message.success('You have Submitted Elog book no.' + logId);
      if (form && form['dataSource'] && form['dataSource']['Type'] === 'Observation') {
        form &&
          form['columns'] &&
          Array.isArray(form['columns']) &&
          form['columns'].map((item) => {
            if (item.key === 'SubEquipment') {
              // item.table = []
              if (form && form['dataSource']) {
                form['dataSource'][item.key] = '';
              }
            }
            return {};
          });
        form = {
          ...form,
          dynamicSubForm: {}
        };
      } else {
        this.setState({ status: 'Completed' });
      }
      this.setState({ form });
      // history.goBack()
    } else if (statusResponse && statusResponse.Error) {
      // message.error(statusResponse.Error)
    } else {
      message.error('Error Occured');
    }
  };

  // submitBatch = async (submit) => {
  //   let { formType, form, logList } = this.state;
  //   let fieldValidation = false;
  //   if (form) {
  //     fieldValidation = await this.checkAllRows(formType, form);
  //   }
  //   let payload = {
  //     status: "submitted",
  //     Id: logList,
  //   };
  //   if (!fieldValidation) {
  //     this._submitStatusAPI(payload);
  //   }
  // };
  submitBatch = async () => {
    let { formType, form, logList, elogbookName } = this.state;
    let siteId = localStorage.get('currentSite');
    let fieldValidation = false;
    if (form) {
      fieldValidation = await this.checkAllRows(formType, form);
    }
    let payload = {};
    payload = {
      status: 'submitted',
      Id: logList,
      SiteId: siteId,
      elogbookName: elogbookName
    };
    if (!fieldValidation) {
      this._submitStatusAPI(payload);
    }
  };

  // showConfirm = async () => {
  //   let { formType, form, logList } = this.state;
  //   let fieldValidation = false;
  //   if (form) {
  //     fieldValidation = await this.checkAllRows(formType, form);
  //   }
  //   // if (this.state.saveStatus === true) {
  //   //   confirm({
  //   //     title: "Do you want to submit?",
  //   //     content: "",
  //   //     onOk: () => {
  //   //       this.submitBatch("Submit");
  //   //     },
  //   //     onCancel: () => {
  //   //       // message.error("cancel")
  //   //     },
  //   //   });
  //   // } else {
  //   //   message.error("Save all records before submiting e-log");
  //   // }
  //   if (this.state.saveStatus === true && logList && logList.length > 0) {
  //     // confirm({
  //     //   title: "Do you want to submit?",
  //     //   content: "",
  //     //   onOk: () => {
  //     //     this.submitBatch("Submit");
  //     //   },
  //     //   onCancel: () => {
  //     //     // message.error("cancel")
  //     //   },
  //     // });
  //   } else {
  //     message.error("Save all records before submiting e-log");
  //   }
  // };

  getColumnSearchOptionsProps = (dataIndex) => ({
    onFilter: (value, record) => record[dataIndex].startsWith(value)
  });

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  onChangeRemarks = (e) => {
    this.setState({
      remarks: e.target.value
    });
  };

  setValue = (e) => {
    let { headerValue } = this.state;
    headerValue = {
      ...headerValue,
      [e.key]: e.value
    };
    this.setState({
      headerValue
    });
  };

  saveSubSectionTableViewData = (form) => {
    this.setState({
      form
    });
  };

  downTimeData = (form) => {
    this.setState({
      form
    });
  };

  checkDeviceExistsOrNot = (dataExitsObject) => {
    let { form } = this.state;
    form = {
      ...form,
      ...(dataExitsObject &&
      dataExitsObject[0] &&
      dataExitsObject[0].JSON &&
      dataExitsObject[0].JSON.form
        ? dataExitsObject[0].JSON.form
        : {})
    };
    this.setState({
      form
    });
  };

  setMainSaveId = (Id) => {
    this.setState({
      Id
    });
  };

  getRecord = async (record) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const Object = {
      method: 'POST',
      url: `/api/logbook/getLogBookById?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        // eLogReferenceId: editData ? editData.eLogReferenceId : "",
        Id: record && record.Id ? record.Id : '',
        Type: 'JSON'
      }
    };
    try {
      return axios(Object).then((response) => {
        return response.data;
      });
    } catch (e) {
      return {};
    }
  };
  handleOpenChange = async (newOpen) => {
    let { formType, form, logList } = this.state;
    if (form) {
      await this.checkAllRows(formType, form);
    }
    if (this.state.saveStatus === true && logList && logList.length > 0) {
      this.setState({ open: newOpen });
    } else {
      message.error('Please Save Before Submit');
      this.setState({ open: false });
    }
  };

  SubmitConfirm = () => {
    this.setState({ open: false });
    this.submitBatch('Submit');
  };
  cancel = () => {
    this.setState({ open: false });
    message.error('Click on cancel.');
  };
  render() {
    let {
      loading,
      header,
      recordType,
      formType,
      headerValue,
      form,
      remarks,
      elog,
      status,
      dataSource
      // userProfile
    } = this.state;
    let { translationCache } = this.props;

    let columns = [
      {
        title: 'Id',
        key: 'Id',
        dataIndex: 'Id'
      },
      {
        title: 'LogBookId',
        key: 'LogBookId',
        dataIndex: 'LogBookId'
      },
      {
        title: 'Location',
        key: 'Location',
        dataIndex: 'Location'
      },
      {
        title: 'TableName',
        key: 'TableName',
        dataIndex: 'TableName'
      },
      {
        title: 'Process',
        key: 'Process',
        dataIndex: 'Process'
      },
      {
        title: 'CreatedDate',
        key: 'CreatedDate',
        dataIndex: 'CreatedDate'
      }
    ];
    let permissionsArray = [];
    // let rolePermissions =
    //   userProfile &&
    //   userProfile["belongsTo"] &&
    //   userProfile["belongsTo"]["sites"] &&
    //   userProfile["belongsTo"]["sites"][localStorage.get('currentSite')] &&
    //   userProfile["belongsTo"]["sites"][localStorage.get('currentSite')]["role"] &&
    //   userProfile["belongsTo"]["sites"][localStorage.get('currentSite')]["role"]["Permission"]
    //     .filter((item) => item.resource === "elog_book")
    //     .map((name) => permissionsArray.push(name.action));

    return (
      <StyledDashboard className="adhocView" style={{ minHeight: window.innerHeight - 173 }}>
        <Button type="dashed" style={{ marginBottom: '20px' }} onClick={() => history.goBack()}>
          <DoubleLeftOutlined />
          Go Back
        </Button>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          {this.state.process !== 'Field Data Measurement' && this.state.activeKey !== '2' ? (
            <Button
              type=""
              size="large"
              className="saveButton"
              style={{ marginTop: '10px', float: 'right', marginRight: '20px' }}
              onClick={() => {
                this.setState({
                  activeKey: '2',
                  status: 'Inprogress',
                  editObj: {},
                  form,
                  action: 'new'
                });
              }}
            >
              {translationCache && translationCache['Add New']
                ? translationCache['Add New']
                : 'Add New'}
            </Button>
          ) : null}
        </div>
        <Tabs
          type="card"
          defaultActiveKey="1"
          style={{ color: 'white' }}
          activeKey={this.state.activeKey}
          onChange={async (activeKey) => {
            let action = '';
            if (activeKey === '2' && this.state.process === 'Field Data Measurement') {
              return {};
            } else {
              if (activeKey === '2') {
                this._initialDataSetup(this.state.editData, this.props.userProfile);
                action = 'new';
              }
              if (activeKey === '1') {
                let processName = this.props.match.params.name;
                this._getAdhocDataSource(processName);
              }
              this.setState({ activeKey, action });
            }
          }}
        >
          <TabPane
            tab={
              translationCache && translationCache['List'] && translationCache['List']
                ? translationCache['List']
                : 'List'
            }
            key="1"
          >
            <StyledTable style={{ width: '100%' }} theme={lightTheme}>
              <Table
                dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
                columns={columns}
                onRow={(tableRowData) => {
                  return {
                    onClick: async () => {
                      let { form } = this.state;
                      let data = await this.getRecord(tableRowData);
                      let record = data && Array.isArray(data) && data[0] ? data[0] : {};

                      form = {
                        ...(record && record.JSON && record.JSON.form && record.JSON.form
                          ? record.JSON.form
                          : {})
                      };
                      this.setState({
                        activeKey: '2',
                        status:
                          tableRowData && tableRowData.Status && tableRowData.Status === 'submitted'
                            ? 'Completed'
                            : this.state.status,
                        form: form,
                        headerValue: record.JSON.headerValue,
                        action: '',
                        editObj: record,
                        logList: record && record['Id'] ? [{ Id: record['Id'] }] : [],
                        saveStatus: false
                      });
                    }
                  };
                }}
              />
            </StyledTable>
          </TabPane>

          <TabPane
            tab={
              translationCache && translationCache['Details'] && translationCache['Details']
                ? translationCache['Details']
                : 'Details'
            }
            key="2"
          >
            <div>
              <Row>
                {header && Array.isArray(header) && header.length > 0 ? (
                  <Col span={8}>
                    <Header
                      header={header}
                      headerValue={headerValue}
                      recordType={recordType}
                      setValue={this.setValue}
                      status={this.state.status}
                    />
                  </Col>
                ) : null}
              </Row>

              <FormType
                formType={formType}
                formObject={form}
                loading={loading}
                header={header}
                headerValue={headerValue}
                recordType={recordType}
                elog={elog}
                userProfile={this.props.userProfile}
                translationCache={this.props.translationCache}
                status={status}
                getMainSaveId={this.setMainSaveId}
                setValue={this.setValue}
                downTimeData={this.downTimeData}
                action={this.state.action}
                checkDeviceExistsOrNot={this.checkDeviceExistsOrNot}
                currentTimeZone={this.props.currentTimeZone}
              />

              <div>
                <div
                  style={{
                    ...(status === 'Completed'
                      ? {
                          opacity: newLocal ? 0.75 : 1,
                          pointerEvents: newLocal ? 'none' : 'initial',
                          margin: '20px 0px'
                        }
                      : { margin: '20px 0px' })
                  }}
                >
                  <div className="remarksHeading">
                    {translationCache && translationCache['Remarks'] && translationCache['Remarks']
                      ? translationCache['Remarks']
                      : 'Remarks'}{' '}
                  </div>
                  <Input.TextArea
                    value={remarks && remarks !== null && remarks !== 'null' ? remarks : ''}
                    onChange={(e) => this.onChangeRemarks(e)}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    style={{ width: '100%' }}
                    maxLength={250}
                  />
                  <div style={{ color: 'white' }}>
                    {translationCache &&
                    translationCache['Characters left'] &&
                    translationCache['Characters left']
                      ? translationCache['Characters left']
                      : 'Characters left'}{' '}
                    :{' '}
                    <span style={{ color: 'white', fontWeight: 'bold' }}>
                      {remarks && remarks !== null && remarks !== 'null'
                        ? 250 - remarks.length
                        : 250}{' '}
                    </span>
                  </div>
                </div>

                <div style={{ margin: '20px 0px' }}>
                  <Button
                    className="saveButton"
                    disabled={
                      !permissionsArray.includes('create') || !permissionsArray.includes('update')
                        ? true
                        : false
                    }
                    style={{
                      ...(status === 'Completed'
                        ? {
                            opacity: newLocal ? 0.75 : 1,
                            pointerEvents: newLocal ? 'none' : 'initial',
                            marginRight: '5px'
                          }
                        : { marginRight: '5px' })
                    }}
                    type=""
                    onClick={(e) => this.saveEditObject(e)}
                  >
                    {translationCache && translationCache['Save'] && translationCache['Save']
                      ? translationCache['Save']
                      : 'Save'}
                  </Button>
                  <Popconfirm
                    placement="right"
                    title="Do you want to submit?"
                    open={this.state.open}
                    onOpenChange={(e) => this.handleOpenChange(e)}
                    onConfirm={(e) => this.SubmitConfirm(e)}
                    onCancel={(e) => this.cancel(e)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        ...(status === 'Completed'
                          ? {
                              opacity: newLocal ? 0.75 : 1,
                              pointerEvents: newLocal ? 'none' : 'initial'
                            }
                          : {})
                      }}
                      type="danger"
                      disabled={!permissionsArray.includes('submit') ? true : false}
                      // onClick={this.showConfirm}
                    >
                      {' '}
                      {translationCache && translationCache['Submit']
                        ? translationCache['Submit']
                        : 'Submit'}
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(ELogbookView);
