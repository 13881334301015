import React, { Component } from 'react';
import moment from 'moment';
import { Input, Descriptions, InputNumber, TimePicker, message } from 'antd';

import { SelectComponent } from '../Widgets';

import '../CSS/eLog.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      open: false
    };
  }

  handleFieldChange = (value, columnKey) => {
    this.props.setValue({
      value,
      key: columnKey
    });
  };

  handleKeyPress = () => {};

  checkOverlap = (timeSegments) => {
    timeSegments = timeSegments.filter(function (element) {
      return element !== undefined;
    });

    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1, timeSegment2) =>
      timeSegment1[0].localeCompare(timeSegment2[0])
    );

    for (let i = 0; i < timeSegments.length - 1; i++) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];

      if (currentEndTime > nextStartTime) {
        return true;
      }
    }

    return false;
  };

  onChange = (time, columnKey, key) => {
    let { headerValue, form, sectioName } = this.props;
    let timeSelectionArray = Object.keys(form).map((timeKey) => {
      if (
        form[timeKey] &&
        form[timeKey]['headerValue'] &&
        timeKey !== sectioName &&
        form[timeKey]['headerValue'][key]
      ) {
        return form[timeKey]['headerValue'][key].split('-');
      }
      return {};
    });

    let previousValue = headerValue && headerValue[key] && headerValue[key].split('-');
    if (
      columnKey === 'startTime' &&
      previousValue &&
      Array.isArray(previousValue) &&
      previousValue.length > 1
    ) {
      var startTime = moment(time);
      var endTime = moment(previousValue[1], 'h:mm');

      let newTimeRange = moment(time).format('HH:mm') + '-' + previousValue[1];
      let newObj = [moment(time).format('HH:mm'), previousValue[1]];
      timeSelectionArray.push(newObj);
      let result = this.checkOverlap(timeSelectionArray);
      if (result && result === true) {
        message.warn('TimeRanges overlapping with others');
      } else if (!startTime.isBefore(endTime)) {
        message.warn('Start Time is more than End Time');
      } else {
        this.props.setValue({
          value: newTimeRange,
          key
        });
      }
    } else if (
      columnKey === 'endTime' &&
      previousValue &&
      Array.isArray(previousValue) &&
      previousValue.length > 1
    ) {
      let newObj = [previousValue[0], moment(time).format('HH:mm')];
      timeSelectionArray.push(newObj);
      let result = this.checkOverlap(timeSelectionArray);
      let newTimeRange = previousValue[0] + '-' + moment(time).format('HH:mm');
      if (result && result === true) {
        message.warn('TimeRanges overlapping with others');
      } else if (!startTime.isBefore(endTime)) {
        message.warn(' Start Time is more than End Time');
      } else {
        this.props.setValue({
          value: newTimeRange,
          key
        });
      }
    } else {
      if (columnKey === 'startTime') {
        let newObj = [moment(time).format('HH:mm'), moment(time).add('hour', 1).format('HH:mm')];
        timeSelectionArray.push(newObj);
        let result = this.checkOverlap(timeSelectionArray);
        if (result && result === true) {
          message.warn('TimeRanges overlapping with others');
        } else {
          this.props.setValue({
            value: moment(time).format('HH:mm') + '-' + moment(time).add('hour', 1).format('HH:mm'),
            key
          });
        }
      } else if (columnKey === 'endTime') {
        let newObj = [moment(time).format('HH:mm'), moment(time).add('hour', 1).format('HH:mm')];
        timeSelectionArray.push(newObj);
        let result = this.checkOverlap(timeSelectionArray);
        if (result && result === true) {
          message.warn('TimeRanges overlapping with others');
        } else {
          this.props.setValue({
            value:
              moment().subtract('hour', 1).format('HH:mm') + '-' + moment(time).format('HH:mm'),
            key
          });
        }
      }
    }
  };

  handleClose = (open) => {
    this.setState({ open });
  };

  render() {
    let { header, headerValue, recordType, status } = this.props;

    return (
      <Descriptions className="batchHeader" bordered size="small" column={2}>
        {headerValue &&
          headerValue !== undefined &&
          header &&
          header.map((eachHeader, index) => {
            if (eachHeader.key === 'CurrentDate') {
              return (
                <Descriptions.Item key={index} label={eachHeader.name} className="batchHeader">
                  {headerValue['CurrentDate']}
                </Descriptions.Item>
              );
            } else if (eachHeader.key === 'CurrentTime') {
              return (
                <Descriptions.Item key={index} label={eachHeader.name} className="batchHeader">
                  {headerValue['CurrentTime']}
                </Descriptions.Item>
              );
            } else if (eachHeader.key === 'date' || eachHeader.key === 'location') {
              return (
                <Descriptions.Item key={index} label={eachHeader.name} className="batchHeader">
                  {headerValue[eachHeader.key]}
                </Descriptions.Item>
              );
            } else if (
              (eachHeader.key === 'SubmitDate' || eachHeader.key === 'SubmitTime') &&
              status !== 'Completed'
            ) {
              return null;
            } else {
              return (
                <Descriptions.Item key={index} label={eachHeader.name} className="batchHeader">
                  <div>
                    {eachHeader.widget === 'input' ? (
                      <Input
                        style={{ fontWeight: 'bold' }}
                        defaultValue={headerValue ? headerValue[eachHeader.key] : ''}
                        disabled={recordType === 'Approver' ? true : false}
                        placeholder={eachHeader.placeHolder}
                        onChange={(e) =>
                          this.props.setValue({
                            value: e.target.value,
                            key: eachHeader.key
                          })
                        }
                      />
                    ) : null}
                    {eachHeader.widget === 'inputNumber' ? (
                      <InputNumber
                        style={{ fontWeight: 'bold', width: 300 }}
                        min={eachHeader.min || 1}
                        max={eachHeader.max || 10000}
                        placeholder={eachHeader.placeHolder}
                        defaultValue={headerValue ? headerValue[eachHeader.key] : ''}
                        disabled={recordType === 'Approver' ? true : false}
                        onChange={(e) =>
                          this.props.setValue({
                            value: e,
                            key: eachHeader.key
                          })
                        }
                      />
                    ) : null}
                    {eachHeader.widget && eachHeader.widget === 'select' ? (
                      <SelectComponent
                        column={eachHeader}
                        record={{}}
                        text={headerValue ? headerValue[eachHeader.key] : ''}
                        handleFieldChange={this.handleFieldChange}
                        handleKeyPress={this.handleKeyPress}
                      />
                    ) : null}

                    {eachHeader.widget && eachHeader.widget === 'timeRange' ? (
                      <div>
                        <TimePicker
                          placeholder="Start Time"
                          value={
                            headerValue && headerValue[eachHeader.key]
                              ? moment(headerValue[eachHeader.key].split('-')[0], 'HH:mm')
                              : ''
                          }
                          onChange={(e) => this.onChange(e, 'startTime', eachHeader.key)}
                        />
                        -
                        <TimePicker
                          placeholder="End Time"
                          value={
                            headerValue && headerValue[eachHeader.key]
                              ? moment(headerValue[eachHeader.key].split('-')[1], 'HH:mm')
                              : ''
                          }
                          onChange={(e) => this.onChange(e, 'endTime', eachHeader.key)}
                        />
                      </div>
                    ) : null}

                    {!eachHeader.widget || eachHeader.widget === 'text' ? (
                      eachHeader.name === 'Submitted Date' ? (
                        <div>
                          {headerValue && headerValue[eachHeader.key]
                            ? moment
                                .utc(headerValue[eachHeader.key])
                                .local()
                                .format('YYYY-MM-DD HH:mm:ss')
                            : null}
                        </div>
                      ) : null
                    ) : (
                      <div>{headerValue && headerValue[eachHeader.key]} </div>
                    )}
                  </div>
                </Descriptions.Item>
              );
            }
          })}
      </Descriptions>
    );
  }
}

export default Header;
