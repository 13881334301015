import React, { Component } from 'react';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { Table } from 'antd';

import SelectionComponent from './SelectionComponent';
import { getDataSourceFromBackend, geDataWatchNametoDownload } from './DataWatchCalls';

import './style.css';
import { DownCircleOutlined } from '@ant-design/icons';
const newLocal = false;
class DataWatchTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns || [],
      currentDatasource: [],
      tableConfiguration: {
        bordered:
          props.tableConfiguration && props.tableConfiguration.bordered
            ? props.tableConfiguration.bordered
            : true,
        horizontalScroll: props.tableConfiguration
          ? props.tableConfiguration.horizontalScroll
          : false,
        tableSize: props.tableConfiguration ? props.tableConfiguration.tableSize : 'default',
        noResultMessage: props.tableConfiguration
          ? props.tableConfiguration.noResultMessage
          : 'No Records',
        isCursorPointer: props.tableConfiguration
          ? props.tableConfiguration.isCursorPointer
          : false,
        title: props.tableConfiguration ? props.tableConfiguration.title : 'No Title',
        pageSize: props.tableConfiguration ? props.tableConfiguration.pageSize : '10',
        downloadXLXS: props.tableConfiguration ? props.tableConfiguration.downloadXLXS : false,
        downloadExcelPayload: props.tableConfiguration
          ? props.tableConfiguration.downloadExcelPayload
          : {},
        style: {
          textAlign:
            props.tableConfiguration &&
            props.tableConfiguration.style &&
            props.tableConfiguration.style.textAlign
              ? props.tableConfiguration.style.textAlign
              : 'center',
          fontWeight:
            props.tableConfiguration &&
            props.tableConfiguration.style &&
            props.tableConfiguration.style.fontWeight
              ? props.tableConfiguration.style.fontWeight
              : 'normal',
          evenRowColor:
            props.tableConfiguration &&
            props.tableConfiguration.style &&
            props.tableConfiguration.style.evenRowColor
              ? props.tableConfiguration.style.evenRowColor
              : 'rgb(251, 250, 250)',
          oldRowColor:
            props.tableConfiguration &&
            props.tableConfiguration.style &&
            props.tableConfiguration.style.oldRowColor
              ? props.tableConfiguration.style.oldRowColor
              : '#fff'
        }
      },
      selectionWidget: props.selectionWidget || {}
    };
  }
  componentDidMount() {
    this._getDataSource(this.props);
  }

  componentDidUpdate({ api }, nextProps) {
    if (api !== this.props.api) {
      this._getDataSource(this.props);
    }
    this.setState({
      selectionWidget: nextProps.selectionWidget
    });
    this._getDataSource(nextProps);
  }

  _getDataSource = async (props) => {
    let api = cloneDeep(this.props.api);
    let currentDatasource = [];
    let columns = cloneDeep(this.props.columns);
    let { selectionWidget } = this.props;
    this.setState({ columns });

    let month = moment().month();
    let year = moment().year();
    let payload = {};

    if (selectionWidget && selectionWidget.payload) {
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1 && key === 0) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .startOf('month')
            .format(payloadItem.format);
        } else if (key <= 1 && key === 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .endOf('months')
            .format(payloadItem.format);
        }
        if (payloadParams === 'month') {
          payload[`${payloadParams}`] = moment().format('MMMM');
        }
        if (payloadParams === 'year') {
          payload[`${payloadParams}`] = year;
        }
        if (payloadParams === 'Date') {
          payload[`${payloadParams}`] = moment().format('YYYY-MM-DD');
        }
        return {};
      });
    }
    api.payload = {
      ...api.payload,
      ...payload
    };

    if (
      api.payload.tabName === 'Operational DataWatch' &&
      api.payload.tabName === props.currentTab &&
      props.deviceId &&
      props.groupName
    ) {
      api.payload = {
        ...api.payload,
        deviceId: props.deviceId,
        groupName: props.groupName
      };
      currentDatasource = await getDataSourceFromBackend(api);
    } else if (
      api.payload.tabName === 'Alert DataWatch' &&
      api.payload.tabName === props.currentTab &&
      props.deviceId
    ) {
      api.payload = {
        ...api.payload,
        deviceId: props.deviceId
      };

      currentDatasource = await getDataSourceFromBackend(api);
    } else if (
      api.payload.tabName !== 'Operational DataWatch' &&
      api.payload.tabName !== 'Alert DataWatch'
    ) {
      currentDatasource = await getDataSourceFromBackend(api);
    }
    this.setState({
      currentDatasource
    });
  };

  selectionpayload = async (selectionpayload) => {
    let api = cloneDeep(this.props.api);
    let columns = cloneDeep(this.props.columns);
    api.payload = {
      ...api.payload,
      ...selectionpayload
    };
    let currentDatasource = await getDataSourceFromBackend(api);
    let reportName = await geDataWatchNametoDownload(
      columns,
      currentDatasource,
      this.props.tableConfiguration.downloadExcelPayload
    );
    this.setState({
      currentDatasource,
      reportName: reportName && reportName.file ? reportName.file : ''
    });
  };

  render() {
    let { tableConfiguration, columns, selectionWidget, currentDatasource } = this.state;
    const {
      bordered,
      tableSize,
      noResultMessage,
      isCursorPointer,
      title,
      pageSize,
      horizontalScroll
    } = tableConfiguration;

    return (
      <div>
        {selectionWidget ? (
          <SelectionComponent
            selectionWidget={selectionWidget}
            selectionpayload={this.selectionpayload}
          />
        ) : null}
        <div style={{ display: 'flex', flexDirection: 'row-reverse', cursor: 'pointer' }}>
          {newLocal ? (
            <a href={`https://sgt.bmecomosolution.com/api/reports/myreport`}>
              {' '}
              <DownCircleOutlined style={{ fontSize: '18px' }} />
            </a>
          ) : null}
        </div>

        {columns && columns.length > 0 ? (
          <Table
            columns={columns}
            dataSource={currentDatasource}
            bordered={bordered ? bordered : false}
            size={tableSize ? tableSize : 'default'}
            locale={{ emptyText: noResultMessage }}
            isCursorPointer={isCursorPointer === true ? 'not-allowed' : 'pointer'}
            loading={false}
            rowKey={this.props.api.payload.reportName}
            scroll={horizontalScroll === true ? { x: 120, y: 600 } : { x: 'max-content' }}
            pagination={pageSize ? { pageSize } : false}
            title={
              title
                ? () => (
                    <div style={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>
                      {title}
                    </div>
                  )
                : null
            }
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default DataWatchTable;
