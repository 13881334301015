import React, { Fragment } from 'react';
import _ from 'lodash';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Table, Spin, Button, Input, Tag } from 'antd';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import axios from 'axios';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { tableTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getCurrentLanguage } from '../../../../../selectors/language';
import { defaultRefreshTime } from '../../constant.json';
import { tableMock } from '../../../Mock/MockJson';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import localStorage from '../../../../../utils/localStorage';
import '../../CSS/Table.css';
import { getCurrentTimezone } from '../../../../../selectors/layout';
import { getTranslationCache } from '../../../../../selectors/language';

class TableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      tabledata: [],
      loading: false,
      translationCache: props.translationCache || [],
      language: props.language || 'English',
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      trainList: [],
      reportName: ''
    };
  }

  columns = [];
  tabledata = [];

  config = {
    redirectTo: 'counter'
  };

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  rowUpdateInterval = null;

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.state.deviceId);
    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.state.deviceId);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.state.deviceId);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text && text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColumnSelectProps = (dataIndex, data) => {
    let dataFiltered = _.uniqBy(data, function (e) {
      return e[dataIndex];
    });
    return {
      filters:
        dataFiltered &&
        Array.isArray(dataFiltered) &&
        dataFiltered.map((columnData) => {
          return {
            text: columnData[dataIndex],
            value: columnData[dataIndex]
          };
        }),
      filterMultiple: true,
      onFilter: (value, record) => {
        return (
          record[dataIndex]
            .toString()
            // .toLowerCase()
            .includes(value)
        );
      }
    };
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  updateData = async (data) => {
    try {
      this.columns = [];
      let { graphprops } = this.props;
      let tabledata = [];
      let tableVal = [];
      let columnConfig = JSON.parse(graphprops[graphprops.graph].columns);

      let columnsList = [];
      if (columnConfig && Array.isArray(columnConfig)) {
        columnsList = columnConfig.map((column) => {
          if (column.isUTC && column.isUTC === true) {
            data = data.map((dataa) => {
              let date = moment.utc(dataa[column.key]).toDate();
              return {
                ...dataa,
                [column.key]: moment(date).local().format('YYYY-MM-DD HH:mm:ss')
              };
            });
          }
          return {
            title: column.title,
            dataIndex: column.key,
            key: column.key,
            render: (text, record) => (
              <span>
                {column &&
                column.showColor &&
                column.showColor === true &&
                record[`${column.key}_Color`] ? (
                  <Tag color={record[`${column.key}_Color`]} key={column.key}>
                    {text}
                  </Tag>
                ) : (
                  text
                )}
              </span>
            )
          };
        });
      }

      if (data && data[0] && data[0]['noData']) {
        return {};
      } else {
        data.map((dataval) => {
          // dataval.key = dataindex + 1;
          tabledata.push(dataval);
          return {};
        });
      }
      tableVal = tabledata.map((data) => {
        return {
          // ...data,
          [this.translation('Period')]: data['Period'],
          [this.translation('Planned Amount(kW)')]: data['Planned Amount(kW)'],
          [this.translation('Actual Amount(kW)')]: data['Actual Amount(kW)'],
          [this.translation('Achievement Rate(%)')]: data['Achievement Rate(%)']
        };
      });
      let reportName = await this.getReportNametoDownload(tableVal);
      let translationTable = await tableTranslation(
        columnsList,
        this.props.translationCache,
        this.props.language
      );

      this.setState({
        columns: translationTable,
        tabledata,
        reportName: reportName && reportName.file ? reportName.file : ''
      });
    } catch (e) {
      this.setState({ columns: [], tabledata: [] });
    }
  };

  getReportNametoDownload(excelData) {
    let { graphprops, dayMonthYear } = this.props;
    let SelectedDate = moment().format('YYYY-MM-DD HH:mm:ss');
    if (graphprops && graphprops.selection && graphprops.selection === 'dateMonthYearPicker') {
      if (dayMonthYear && dayMonthYear.selectionType === 'day') {
        SelectedDate = moment(dayMonthYear.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      } else if (dayMonthYear && dayMonthYear.selectionType === 'month') {
        SelectedDate = moment(dayMonthYear.endDate, 'YYYY-MM-DD HH:mm:ss').format('MMMM');
      } else if (dayMonthYear && dayMonthYear.selectionType === 'year') {
        SelectedDate = moment(dayMonthYear.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY');
      }
    }
    let payload = {
      Consumption: this.props.graphprops.title,
      Data: excelData || [],
      ...this.props.graphprops.excelObj,
      Name: this.props.graphprops.dashboardName,
      PortName: 'SGT',
      ReportType: 'singleHierarchy',
      Summary: [],
      SummaryReportType: 'singleHierarchy',
      SummaryType: 'false',
      WeeklySummary: [],
      SelectedDate,
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    let accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    let options = {
      method: 'POST',
      url: `/api/file/generateExcel?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(payload)
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return '';
      });
  }

  getData = async () => {
    this.requestCheck = false;
    let data = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      data = tableMock;
      this.requestCheck = true;
    } else {
      if (
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops &&
          (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops && graphprops.monthExists === 'true' && this.props.selectionMonth) ||
        (graphprops && graphprops.weekExists === 'true' && this.props.selectionWeek) ||
        (graphprops && graphprops.shiftExists === 'true' && this.props.shift) ||
        (graphprops &&
          (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.dayMonthYearExists === 'true' ||
            graphprops.selection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        data = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
        this.setState({ loading: false });
      } else {
        data = await getChartDataWithoutSelection(
          graphprops,
          this.abortController,
          this.props.dropdownSelection,
          this.props.selectionDateLive,
          this.props.DeviceCode,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
        this.setState({
          loading: false,
          refreshDateTime: moment()
        });
      }
    }
    if (
      (data && data.message) ||
      (Array.isArray(data) && data.length === 0 && graphprops.enableMock !== true)
    ) {
      if (data.message) {
        errorInAPI = true;
      } else if (data.length === 0) {
        noDataInDB = true;
      }
    } else {
      this.updateData(data);
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  trainStatusChange = (deviceId) => {
    this.setState({ deviceId });
    this.getData(deviceId);
  };

  render() {
    let { loading, columns, errorInAPI, noDataInDB, tabledata, reportName } = this.state;
    let tableSize = 10;
    if (
      this.props &&
      this.props.graphprops &&
      this.props.graphprops.table &&
      this.props.graphprops.table.tableSize
    ) {
      tableSize = this.props.graphprops.table.tableSize;
    }
    return (
      <Fragment>
        {reportName !== '' ? (
          <a
            href={`https://sgt.bmecomosolution.com/api/reports/${reportName}`}
            style={{ fontSize: '20px', fontWeight: 'bold' }}
          >
            <DownloadOutlined
              style={{ color: 'rgb(191, 191, 191)', fontSize: '20px', float: 'right' }}
            />
          </a>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <span>
            {loading === false && columns && columns.length > 0 ? (
              <Table
                className="TableLightWidget"
                style={{ padding: '0px 10px', marginTop: '12px' }}
                columns={columns && Array.isArray(columns) ? columns : []}
                dataSource={tabledata && Array.isArray(tabledata) ? tabledata : []}
                scroll={{ y: 'auto', x: 200 }}
                pagination={
                  tabledata && tabledata.length <= tableSize ? false : { pageSize: tableSize }
                }
              />
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  color: 'white',
                  marginTop: '10%'
                }}
              >
                <h2 style={{ color: 'white' }}>
                  <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                </h2>
              </div>
            )}
          </span>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(TableComponent);
