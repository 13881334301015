import React from 'react';
import { Avatar, Tooltip } from 'antd';
import '../style.css';
import { MailOutlined, MobileOutlined } from '@ant-design/icons';
export const defaultState = {
  inputList: [
    {
      tagName: '',
      operater: '',
      value: '',
      action: [],
      device: '',
      priventive: '',
      recommandation: ''
    }
  ],
  downtimeinputList: {
    device: '',
    action: []
    // "delay":""
  },
  elsifList: [{ tagName: '', operater: '', value: '' }],
  elseList: [],
  elsifLength: [],
  elsifNumber: 0,
  rulebody: '',
  ruleType: 'realTime',
  ruleMode: 'Device',
  tabType: '',
  EditType: '',
  batchPriority: '',
  conif: true,
  conlseif: false,
  conelse: false,
  checkedValues: [],
  ruleDesc: '',
  ruleName: '',
  script: '',
  tagNames: [],
  mailRecipients: [],
  edit: false,
  deviceList: [],
  selectedDevices: [],
  onChange: false,
  tabActivationkey: 'DeviceRules',
  replicateDevices: [],
  parametersList: [],
  recipientList: [],
  optionsSelected: [],
  setOptionsSelected: []
};
export const deviceTypedefaultState = {
  inputList: [
    {
      tagName: '',
      operater: '',
      value: '',
      action: [],
      device: '',
      deviceDisable: true,
      priventive: '',
      recommandation: ''
    }
  ],

  elsifList: [{ tagName: '', operater: '', value: '' }],
  elseList: [],
  elsifLength: [],
  elsifNumber: 0,
  rulebody: '',
  ruleType: 'realTime',
  ruleMode: 'DeviceType',
  tabType: '',
  EditType: '',
  batchPriority: '',
  conif: true,
  conlseif: false,
  conelse: false,
  checkedValues: [],
  ruleDesc: '',
  ruleName: '',
  script: '',
  tagNames: [],
  mailRecipients: [],
  edit: false,
  deviceTypeList: [],
  selectedDevices: [],
  onChange: false,
  tabActivationkey: 'DeviceTypeRule',
  replicateDevices: [],
  parametersList: [],
  recipientList: [],
  optionsSelected: [],
  setOptionsSelected: []
};
export const predictionDefaultState = {
  inputList: [
    {
      tagName: '',
      operater: '',
      value: '',
      action: [],
      device: '',
      priventive: '',
      recommandation: ''
    }
  ],
  elsifList: [{ tagName: '', operater: '', value: '' }],
  elseList: [],
  elsifLength: [],
  elsifNumber: 0,
  conif: true,
  conlseif: false,
  conelse: false,
  checkedValues: [],
  ruleDesc: '',
  ruleName: '',
  ruleType: 'restApi',
  script: '',
  tagNames: [],
  edit: false,
  tabActivationkey: 'RestApi',
  urlList: [],
  onChange: false
};

export const noDeviceDefault = {
  inputList: [
    {
      tagName: '',
      operater: '',
      value: '',
      action: [],
      device: '',
      priventive: '',
      recommandation: ''
    }
  ],
  elsifList: [{ tagName: '', operater: '', value: '' }],
  elseList: [],
  elsifLength: [],
  elsifNumber: 0,
  conif: true,
  conlseif: false,
  conelse: false,
  checkedValues: [],
  ruleDesc: '',
  ruleName: '',
  ruleType: 'noDeviceRealTime',
  script: '',
  tagNames: [],
  edit: false,
  tabActivationkey: 'noDevice',
  nodeviceparametersList: [],
  onChange: false
};

export const tableState = {
  inputList: [
    {
      tagName: '',
      operater: '',
      value: '',
      action: [],
      device: '',
      priventive: '',
      recommandation: ''
    }
  ],
  elsifList: [{ tagName: '', operater: '', value: '' }],
  elseList: [],
  elsifLength: [],
  elsifNumber: 0,
  ruleType: 'tableRule',
  batchPriority: '',
  conif: true,
  conlseif: false,
  conelse: false,
  checkedValues: [],
  ruleDesc: '',
  ruleName: '',
  script: '',
  tagNames: [],
  edit: false,
  tableList: [],
  deviceList: [],
  columnList: [],
  selectedDevices: [],
  onChange: false,
  tabActivationkey: 'DeviceRules',
  replicateDevices: [],
  parametersList: []
};

export const actionList = [
  {
    label: (
      <Tooltip title="Email">
        <Avatar
          style={{ color: '#e34133', backgroundColor: '#eaeaea' }}
          shape="square"
          icon={
            <span className="notifyicons">
              <MailOutlined />
            </span>
          }
        />
      </Tooltip>
    ),
    value: 'Email'
  },
  {
    label: (
      <Tooltip title="Mobile">
        <Avatar
          style={{ color: '#006abc', backgroundColor: '#eaeaea  ' }}
          shape="square"
          icon={
            <span className="notifyicons">
              <MobileOutlined />
            </span>
          }
        />
      </Tooltip>
    ),
    value: 'Mobile'
  }
  // {
  //   label: (
  //     <Tooltip title="Slack">
  //       <Avatar
  //         style={{ color: '#da1c5a', backgroundColor: '#4f145f' }}
  //         shape="square"
  //         icon={
  //           <span className="notifyicons">
  //             <SlackSquareOutlined />
  //           </span>
  //         }
  //       />
  //     </Tooltip>
  //   ),
  //   value: 'Slack'
  // }
];
export const options = [
  // "SMS",
  `Email`,
  //"Post To Server",
  //"Rest Api",
  //"Mqtt",
  //"Web Services",
  // "Slack",
  'Mobile'
  // "Line",
  // "RestAPI"
  //  "Maximo",
];

export const recipientState = {
  recipientList: []
};
