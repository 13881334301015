import React from 'react';
import moment from 'moment';
import axios from 'axios';
import { Tooltip, Table, Input, Button } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import NoRecords from '../Utils/NoRecords';
import localStorage from '../../../utils/localStorage';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../selectors/language';
import { getCurrentTimezone } from '../../../selectors/layout';

class TrendTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      language: props.language || 'English',
      reportName: '',
      dataSource: [],
      viewType: props.viewType || 'Single'
    };
  }

  async componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate = (prevprops) => {
    if (
      prevprops.panelData !== this.props.panelData ||
      prevprops.translationCache !== this.props.translationCache
    ) {
      this.prepareData(this.props.graphType);
    }
  };

  getReportNametoDownload(excelData, type, viewType, SelectedDate, trendIndex, param) {
    let { translationCache } = this.props;
    let Consumption = this.translation('Trend');
    if (viewType === 'Multiple' && (type === 'timeRange' || type === 'timeWithSelectionType')) {
      //Consumption
      Consumption = param ? `${this.translation(param)}${trendIndex}` : this.translation('Trend');
    } else if (viewType === 'Multiple') {
      Consumption = param ? this.translation(param) : this.translation('Trend');
    }
    let payload = {
      PortName: this.translation('SGT'),
      Name: type && type ? this.translation(type) : this.translation('Trend'),
      Consumption,
      Data: excelData || [],
      ReportType: 'singleHierarchy',
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      SelectedDate:
        moment.utc(SelectedDate.From).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm:ss') +
        '~' +
        moment.utc(SelectedDate.To).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm:ss'),
      SelectionType: 'dateRange',
      IsTranslate: translationCache && translationCache['GeneratedDate'] ? true : false,
      translationList: {
        GeneratedDate: this.translation('GeneratedDate'),
        SelectedDate: this.translation('SelectedDate')
      }
    };
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    let options = {};
    options = {
      method: 'POST',
      url: `/api/file/generateExcel?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(payload)
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return '';
      });
  }

  prepareData = async () => {
    const { panelData, viewType } = this.props;
    let tableData = [];
    let dataSource = [];
    if (
      panelData &&
      panelData.trendData &&
      Array.isArray(panelData.trendData) &&
      panelData.trendData.length > 0
    ) {
      let columnsData = panelData.trendData[0] ? Object.keys(panelData.trendData[0]) : [];
      columnsData.splice(5, 2);
      let columns = columnsData.map((column) => {
        return {
          title: this.translation(column),
          dataIndex: column,
          key: column,
          ...this.getColumnSearchProps(column),
          sorter: (a, b) => a.Value - b.Value
        };
      });
      // {
      //   "graph": "trends",
      //   "trendData": [
      //     {
      //       "DateTime": "2024-01-10 22:30:00",
      //       "Name": "Cooling tower I frequency setting (Cooling_Tower)",
      //       "Value": 94,
      //       "Description": "Cooling tower I frequency setting (Cooling_Tower)",
      //       "DeviceName": "Cooling_Tower",
      //       "ParameterName": "Cooling tower I frequency setting"
      //     },
      //     {
      //       "DateTime": "2024-01-10 22:30:00",
      //       "Name": "Deaerator water supply pump A current (Boiler)",
      //       "Value": 173,
      //       "Description": "Deaerator water supply pump A current (Boiler)",
      //       "DeviceName": "Boiler",
      //       "ParameterName": "Deaerator water supply pump A current"
      //     },
      //     {
      //       "DateTime": "2024-01-10 22:30:00",
      //       "Name": "drum pressure (Boiler)",
      //       "Value": 73,
      //       "Description": "drum pressure (Boiler)",
      //       "DeviceName": "Boiler",
      //       "ParameterName": "drum pressure"
      //     },
      //     {
      //       "DateTime": "2024-01-10 22:30:00",
      //       "Name": "Deaerator level control valve B (large valve) (Boiler)",
      //       "Value": 199,
      //       "Description": "Deaerator level control valve B (large valve) (Boiler)",
      //       "DeviceName": "Boiler",
      //       "ParameterName": "Deaerator level control valve B (large valve)"
      //     },
      //     {
      //       "DateTime": "2024-01-10 22:30:00",
      //       "Name": "Deaerator level (Boiler)",
      //       "Value": 65,
      //       "Description": "Deaerator level (Boiler)",
      //       "DeviceName": "Boiler",
      //       "ParameterName": "Deaerator level"
      //     }
      //   ],
      //   "colors": [
      //     "orange",
      //     "tomato",
      //     "steelblue",
      //     "green",
      //     "pink",
      //     "voilet",
      //     "yellow",
      //     "red",
      //     "blue",
      //     "gray"
      //   ],
      //   "linetype": "",
      //   "update": true,
      //   "Type": "historic",
      //   "tunedData": {
      //     "Type": "historic",
      //     "ViewType": "Single",
      //     "ParameterList": [
      //       {
      //         "DeviceCode": "Cooling_Tower1",
      //         "ParameterCode": "CTKF"
      //       },
      //       {
      //         "DeviceCode": "Boiler1",
      //         "ParameterCode": "DWSPAC"
      //       },
      //       {
      //         "DeviceCode": "Boiler1",
      //         "ParameterCode": "DP"
      //       },
      //       {
      //         "DeviceCode": "Boiler1",
      //         "ParameterCode": "DLCVB"
      //       },
      //       {
      //         "DeviceCode": "Boiler1",
      //         "ParameterCode": "DL"
      //       }
      //     ],
      //     "Operator": "LAST",
      //     "GraphType": "line",
      //     "Interval": "1d",
      //     "FromDate": "2024-01-10 22:30:00",
      //     "ToDate": "2024-01-11 01:30:00"
      //   }
      // }
      tableData = panelData.trendData.map((data) => {
        return {
          // ...data,
          [this.translation('DateTime')]: moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm:ss'),
          [this.translation('Name')]: `${this.translation(data.ParameterName)} (${this.translation(
            data.DeviceName
          )})`,
          [this.translation('Value')]: this.translation(data.Value),
          [this.translation('Description')]: `${this.translation(
            data.ParameterName
          )} (${this.translation(data.DeviceName)})`,
          [this.translation('DeviceName')]: this.translation(data.DeviceName)
          // [this.translation('ParameterName')]: this.translation(data.ParameterName),
          // val: this.translation(data.ParameterName)
        };
      });
      dataSource = panelData.trendData.map((data) => {
        return {
          // ...data,
          DateTime: moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm:ss'),
          Name: `${this.translation(data.ParameterName)} (${this.translation(data.DeviceName)})`,
          Value: this.translation(data.Value),
          Description: `${this.translation(data.ParameterName)} (${this.translation(
            data.DeviceName
          )})`,
          DeviceName: this.translation(data.DeviceName)
          // ParameterName: this.translation(data.ParameterName)
        };
      });
      if (panelData && panelData.tunedData) {
        let { Type } = panelData;
        let From = moment().format('YYYY-MM-DD HH:mm:ss');
        let To = moment().format('YYYY-MM-DD HH:mm:ss');
        let { FromDate, ToDate, FromDate1, ToDate1, FromDate2, ToDate2 } = panelData.tunedData;
        if (Type === 'timeRange' && panelData.trendIndex === 0) {
          From = FromDate1;
          To = ToDate1;
        } else if (Type === 'timeRange' && panelData.trendIndex === 1) {
          From = FromDate2;
          To = ToDate2;
        } else {
          From = FromDate;
          To = ToDate;
        }
        let value = tableData && tableData[0] && tableData[0].val;
        tableData = tableData.map((report) => {
          delete report.val;
          return {
            ...report
          };
        });
        let reportName = await this.getReportNametoDownload(
          tableData,
          panelData.Type,
          viewType,
          { From, To },
          panelData.trendIndex,
          value
        );

        this.setState({
          columns,
          dataSource,
          reportName: reportName && reportName.file ? reportName.file : ''
        });
      }
    }
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={'Search'}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  render() {
    let { reportName, dataSource, columns } = this.state;
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Tooltip placement="top" title={this.translation('Download')}>
            <a href={`https://sgt.bmecomosolution.com/api/reports/${reportName}`}>
              {' '}
              <DownloadOutlined style={{ fontSize: '25px' }} />
            </a>
          </Tooltip>{' '}
        </div>
        {dataSource && Array.isArray(dataSource) && dataSource.length > 0 ? (
          <Table
            className="trendTable"
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            columns={columns}
            pagination={true}
            bordered={true}
            style={{ padding: '0px 10px', marginTop: '12px' }}
          />
        ) : (
          <NoRecords />
        )}
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(TrendTable);
