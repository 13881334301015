import React, { Fragment } from 'react';

import SinglePlot from '../../../../HMI';
import { LinkOverlay } from '../../CSS/style';

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicName: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.setState({ dynamicName: this.props.DeviceCode });
    }
  }

  images(hmiName, type, dynamicName) {
    return (
      <div>
        {type && type === 'static' ? (
          <LinkOverlay to={{ pathname: `/rubus/singleHMI/${type}/${hmiName}` }} />
        ) : (
          <LinkOverlay to={{ pathname: `/rubus/singleHMI/${type}/${hmiName}/${dynamicName}` }} />
        )}
        <SinglePlot
          triggerPoint="dashboard"
          match={{
            params: {
              hmiName,
              type,
              dynamicName
            }
          }}
        />
      </div>
    );
  }

  render() {
    let { graphprops, DeviceCode } = this.props;
    return (
      <Fragment>
        {this.images(
          graphprops.image && graphprops.image.path ? graphprops.image.path : '',
          graphprops.image && graphprops.image.Type ? graphprops.image.Type : '',
          DeviceCode
        )}
      </Fragment>
    );
  }
}

export default Image;
