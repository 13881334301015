import React from 'react';
import { Route } from 'react-router';

import {
  ConfigurationUpload,
  HierarchyUpload,
  BridgeConfiguration,
  EdgeConfiguration,
  ValidConfiguration
} from '../pages';

export default class ApplicationSettingsRouter extends React.Component {
  render() {
    return (
      <div>
        <Route
          path="/rubus/configurationSettings/configurationUpload"
          component={ConfigurationUpload}
        />
        <Route path="/rubus/configurationSettings/hierarchyUpload" component={HierarchyUpload} />
        <Route
          path="/rubus/configurationSettings/BridgeConfiguration"
          component={BridgeConfiguration}
        />
        <Route
          path="/rubus/configurationSettings/EdgeConfiguration"
          component={EdgeConfiguration}
        />
        <Route
          path="/rubus/configurationSettings/ValidationConfiguration"
          component={ValidConfiguration}
        />
      </div>
    );
  }
}
